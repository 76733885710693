import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { UserService } from "app/auth/_services/user.service";
import { IFupChassiFilter } from './interfaces/fup-chassi-filter.interface';

export class FupChassiFilter {
    // tslint:disable: object-literal-key-quotes
    // tslint:disable: no-string-literal

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _userService = AppModule.injector.get(UserService);

    private _initialFupChassiFilter: IFupChassiFilter = <IFupChassiFilter>{};
    private _fupChassiFilter: IFupChassiFilter = <IFupChassiFilter>{
        imp: '',
        chassi: '',
        clientGroups: [],
        status: [
            { name: 'Aguard. Embarque', code: 'EMBARQUE', statusEntityName: 'preShipping'},
            { name: 'Em Trânsito', code: 'TRANSITO', statusEntityName: 'inTransit'},
            { name: 'Aguard. Presença de Carga', code: 'CARGA', statusEntityName: 'cargoPresence'},
            { name: 'Aguard. Liberação', code: 'LIBERACAO', statusEntityName: 'liberation'},
            { name: 'Aguard. Registro DA', code: 'REGISTRO_DA', statusEntityName: 'registrationDa'},
            { name: 'Aguard. Registro DI', code: 'REGISTRO', statusEntityName: 'registration'},
            { name: 'Aguard. Desembaraço DA', code: 'DESEMBARACO_DA', statusEntityName: 'clearanceDa'},
            { name: 'Aguard. Desembaraço DI', code: 'DESEMBARACO', statusEntityName: 'clearance'},
            { name: 'Aguard. Faturamento', code: 'FATURAMENTO', statusEntityName: 'invoicing'},
            { name: 'Faturado Comexport', code: 'FATURADO', statusEntityName: 'invoiced'},
            { name: 'Bloqueado', code: 'BLOQUEADO', statusEntityName: 'blocked' },
            { name: 'Aguard. PDI', code: 'VPCEND', statusEntityName: 'waitingVPCEnd'},
            { name: 'Disponível para Faturar', code: 'DISPFATURAMENTO', statusEntityName: 'availableToClientInvoice'},
        ],
        referenceDateType: 'registration',
        clientReference: '',
        typeSearch: this._userService.getCurrentUser().has('INTERNAL_FILTERS') ? 'IMP' : 'REF'
    };

    private startDate;
    private endDate;
    private offset: number;
    private limit: number;

    constructor( userService: UserService, filter?: FupChassiFilter) {

        this._fupChassiFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'fupChassiFilter', this._fupChassiFilter, filter);
        this._initialFupChassiFilter = _.cloneDeep(this._fupChassiFilter);

        this.startDate = filter ? filter.startDate : moment().add(-24, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 50;
    }

    public getSearch(): string { return this._fupChassiFilter.imp; }
    public getChassi(): string { return this._fupChassiFilter.chassi; }
    public getClientGroups(): string[] { return this._fupChassiFilter.clientGroups; }
    public getStatus(): any[] { return this._fupChassiFilter.status; }
    public getStartDate() { return this.startDate; }
    public getEndDate() { return this.endDate; }
    public getReferenceDateType(): string { return this._fupChassiFilter.referenceDateType; }
    public getOffset(): number { return this.offset; }
    public getLimit(): number { return this.limit; }
    public getClientReference(): string { return this._fupChassiFilter.clientReference; }
    public getTypeSearch(): string { return this._fupChassiFilter.typeSearch; }

    public setSearch(imp): void { this._fupChassiFilter.imp = imp; }
    public setChassi(chassi): void { this._fupChassiFilter.chassi = chassi; }
    public setClientGroups(clientGroups): void { this._fupChassiFilter.clientGroups = clientGroups; }
    public setStatus(status): void { this._fupChassiFilter.status = status; }
    public setStartDate(startDate): void { this.startDate = startDate; }
    public setEndDate(endDate): void { this.endDate = endDate; }
    public setReferenceDateType(referenceDateType): void { this._fupChassiFilter.referenceDateType = referenceDateType; }
    public setOffset(offset): void { this.offset = offset; }
    public setLimit(limit): void { this.limit = limit; }
    public setClientReference(clientReference): void { this._fupChassiFilter.clientReference = clientReference; }
    public setTypeSearch(typeSearch): void { this._fupChassiFilter.typeSearch = typeSearch; }

    public getFilterData() {
        const data = {
            imp: this.getSearch(),
            chassi: this.getChassi(),
            clientGroups: this.getClientGroups(),
            status: this.getStatus(),
            clientReference: this.getClientReference(),
            typeSearch: this.getTypeSearch(),
            referenceDateType: this.getReferenceDateType(),
            startDate: this.getStartDate(),
            endDate: this.getEndDate(),
            offset: this.getOffset(),
            limit: this.getLimit()
        } as const;
        return data;
    }

    public getRequest(allStatus, currentType?) {

        this._initialFupChassiFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialFupChassiFilter, this._fupChassiFilter, 'fupChassiFilter', this._userCacheService.getUserPreferences().id);

        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups() === [] ? '' :
        this.getClientGroups().map((c) => c['id']).join(','));
        let params = new HttpParams();
        const status = this.getSearch() === '' ? this.getStatus() : allStatus;

        params = params.append('imp', `${this.getTypeSearch() === 'IMP' ? this.getSearch() : ''}`);
        params = params.append('chassi', this.getChassi());
        params = params.append('field', `status.code::${status.map((c) => c['code']).join(',')}`);
        params = params.append('startDate', `${this.getSearch() === '' ?
                moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                moment().add(-90, 'days').format('YYYY-MM-DD')}`);
        params = params.append('endDate', `${this.getSearch() === '' ?
                moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                moment().format('YYYY-MM-DD')}`);
        params = params.append('referenceDateType', this.getReferenceDateType());
        params = params.append('limit', `${this.getLimit()}`);
        params = params.append('offset', `${this.getOffset() * this.getLimit()}`);
        params = params.append('origin', 'IMP');
        params = params.append('imps', '');
        params = params.append('clientReference', `${this.getTypeSearch() === 'REF' ? this.getSearch() : ''}`);
        return { params, headers };
    }
}
