<div id="searchLine" class="reduzed">
    <div class="search-container" style="margin-right: 50px">
        <cmx-filter-search
            *ngIf="this.currentUser?.has('AUTOMOTIVE_USER') || !this.currentUser?.has('AUTOMOTIVE_USER') && filterState.getAutomotiveMode() === 'Ligado'"
            [type]="filterState.getTypeSearch()"
            [types]="['Chassi']"
            [width]="'130px'"
            [value]="filterState.getChassiSearch()"
            (onInput)="filterState.setChassiSearch($event)"
            (onChangeType)="filterState.setTypeSearch($event)"
            (onApply)="loadCost()"
        ></cmx-filter-search>
        <cmx-filter-search
            *ngIf="!currentUser?.has('AUTOMOTIVE_USER') && filterState.getAutomotiveMode() === 'Desligado'"
            [type]="filterState.getTypeSearch()"
            [types]="['IMP', 'REF']"
            [width]="'130px'"
            [value]="filterState.getImpSearch()"
            (onInput)="filterState.setImpSearch($event)"
            (onChangeType)="filterState.setTypeSearch($event)"
            (onApply)="loadCost()"
        ></cmx-filter-search>
    </div>

    <div class="search-container">
        <ng-container *ngIf="!currentUser?.has('AUTOMOTIVE_USER') || filterState.getAutomotiveMode() === 'Desligado'">
            <div class="input-group"
                *ngIf="currentUser?.has('INTERNAL_FILTERS') && filterState.getAutomotiveMode() === 'Desligado'">
                <cmx-dropdown
                    name="crs"
                    [styles]="{ width: '100px' }"
                    [data]="costsDropdownService.options.cr"
                    [settings]="costsDropdownService.dropdownSettings.profitCenters"
                    [ngModel]="filterState.getProfitCenters()"
                    (ngModelChange)="filterState.setProfitCenters($event)"
                ></cmx-dropdown>
                <span class="input-group-addon" style="margin-left: -15px"></span>
            </div>
            <div class="input-group" *ngIf="currentUser?.has('INTERNAL_FILTERS')"
                style="margin-left: -20px">
                <cmx-dropdown
                    [styles]="{ width: '120px'}"
                    name="clients"
                    [data]="costsDropdownService.options.client"
                    [settings]="costsDropdownService.dropdownSettings.clientGroups"
                    [ngModel]="filterState.getClientGroups()"
                    (ngModelChange)="filterState.setClientGroups($event)"
                ></cmx-dropdown>
                <span class="input-group-addon" style="margin-left: -22px"></span>
            </div>
        </ng-container>

        <ng-container *ngIf="currentUser?.has('AUTOMOTIVE_USER') || filterState.getAutomotiveMode() === 'Ligado'">
            <div class="input-group">
                <cmx-dropdown
                    [styles]="{ width: '120px' }"
                    name="models"
                    [data]="costsDropdownService.options.models"
                    [settings]="costsDropdownService.dropdownSettings.models"
                    [ngModel]="filterState.getModels()"
                    (ngModelChange)="filterState.setModels($event)">
                </cmx-dropdown>
                <span class="input-group-addon" style="margin-left: -22px"></span>
            </div>
            <div class="input-group">
                <cmx-dropdown
                    [styles]="{ width: '100px' }"
                    name="colors"
                    [data]="costsDropdownService.options.colors"
                    [settings]="costsDropdownService.dropdownSettings.colors"
                    [ngModel]="filterState.getColors()"
                    (ngModelChange)="filterState.setColors($event)">
                </cmx-dropdown>
                <span class="input-group-addon" style="margin-left: -22px"></span>
            </div>
            <div class="input-group">
                <cmx-dropdown
                    [styles]="{ width: '110px' }"
                    name="modelYears"
                    [data]="costsDropdownService.options.modelYears"
                    [settings]="costsDropdownService.dropdownSettings.modelYears"
                    [ngModel]="filterState.getModelYears()"
                    (ngModelChange)="filterState.setModelYears($event)">
                </cmx-dropdown>
                <span class="input-group-addon" style="margin-left: -22px"></span>
            </div>
            <div class="input-group">
                <cmx-dropdown
                    [styles]="{ width: '100px' }"
                    name="manufacturerYears"
                    [data]="costsDropdownService.options.manufacturerYears"
                    [settings]="costsDropdownService.dropdownSettings.manufacturerYears"
                    [ngModel]="filterState.getManufacturerYears()"
                    (ngModelChange)="filterState.setManufacturerYears($event)">
                </cmx-dropdown>
                <span *ngIf="currentUser?.has('ROUTE_AUTOMOTIVE') && !currentUser?.has('AUTOMOTIVE_USER')"
                class="input-group-addon" style="margin-left: -22px"></span>
            </div>
        </ng-container>

        <div *ngIf="currentUser?.has('ROUTE_AUTOMOTIVE') && !currentUser?.has('AUTOMOTIVE_USER')"
            title="Modo Automotivo: {{ filterState.getAutomotiveMode() }}"
            class="input-group filterMode {{ filterState.getAutomotiveMode() === 'Ligado' ? 'clicked' : '' }}"
            (click)="toggleFilterMode()">
            <div><em class="material-icons">directions_car</em></div>
        </div>

        <div class="input-group">
            <cmx-filter-datepicker
                id="costs-datepicker"
                [startDate]="filterState.getStartDate()"
                [endDate]="filterState.getEndDate()"
                (onChangeDates)="datesChanged($event)"
            ></cmx-filter-datepicker>
        </div>

        <button (click)="loadCost()"
            class="applyImpStatus">
            <em class="fa fa-check"></em>
        </button>

        <div
            *ngIf="filterState.getChassiSearch().length !== 0 || filterState.getImpSearch().length !== 0"
            class="disableSearch"
            title="Os filtros são desativados ao realizar uma busca específica."
        ></div>

    </div>
</div>
