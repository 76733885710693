import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '#environment';

import { LogisticsHandlingModalTypes } from 'app/shared/types/logistics-handling-types.type';

import { ILogisticsHandlingTypeResponse } from 'app/shared/interfaces/logistics-handling-types.interface';
import { ILogisticsHandlingResponse } from 'app/shared/interfaces/logistics-handling.interface';
import { ILogisticsState, ILogisticsWarehouse } from 'app/shared/interfaces/logistics-types.interface';

@Injectable()
export class LogisticsHandlingService {
    constructor(private _http: HttpClient) {}

    getLogisticsHandling(
        year: string,
        modalType: LogisticsHandlingModalTypes,
        state: string,
        warehouse: string,
        clients: string,
        typeView: 'teus' | 'containers',
    ): Observable<ILogisticsHandlingResponse> {
        const baseUrl =
            modalType === 'VEHICLES'
                ? this.getVehiclesBaseUrl(modalType)
                : `${environment.endpoints.importationMongoDBService}/stats/logistics-handling?modalType=${modalType}`;
        const resquestOptions = {
            params: {
                year: year,
                state: state,
                warehouse: warehouse,
                ...(modalType === 'MARITIME' && { typeView: typeView })
            },
            headers: {
                'X-Requested-Client-Groups': clients
            }
        }
        return this._http.get<ILogisticsHandlingResponse>(baseUrl, resquestOptions);
    }

    getVehiclesBaseUrl(modalType): string {
        return `${environment.endpoints.automotiveV2QueryService}/api/v2/stats/operational/logistics-handling?type=${modalType}`;
    }

    getLogisticsHandlingTypes(
        year: string,
        modalType: LogisticsHandlingModalTypes,
        type: 'state' | 'warehouse',
        clients: string,
        typeView: 'teus' | 'containers',
    ): Observable<ILogisticsHandlingTypeResponse> {
        const baseUrl = `${environment.endpoints.importationMongoDBService}/stats/logistics-handling-types`;
        const resquestOptions = {
            params: {
                year: year,
                modalType: modalType,
                type: type,
                ...(modalType === 'MARITIME' && { typeView: typeView })
            },
            headers: {
                'X-Requested-Client-Groups': clients
            }
        }
        return this._http.get<ILogisticsHandlingTypeResponse>(baseUrl, resquestOptions);
    }

    getLogisticsStates(): Observable<ILogisticsState[]> {
        return this._http.get<ILogisticsState[]>(`${environment.endpoints.importationMongoDBService}/stats/logistics-states`);
    }

    getLogisticsWarehouses(): Observable<ILogisticsWarehouse[]> {
        return this._http.get<ILogisticsWarehouse[]>(
            `${environment.endpoints.importationMongoDBService}/stats/logistics-warehouses`
        );
    }
}
