export const DROPDOWN_SETTINGS = {
    clients: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Cliente',
        badgeShowLabel: 'Clientes selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    clientGroups: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Grupo Cliente',
        badgeShowLabel: 'Grupo Clientes selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    status: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Status',
        badgeShowLimit: 1,
        disabled: false,
    },
    currency: {
        enableCheckAll: false,
        singleSelection: true,
        text: 'Moeda',
        enableSearchFilter: false,
        badgeShowLabel: 'Moedas selecionadas',
        badgeShowLimit: 1,
        disabled: false,
    },
    subsidiaryNames: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Filial',
        badgeShowLabel: 'Filiais selecionadas',
        badgeShowLimit: 1,
        disabled: false,
    },
    profitCenters: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'CR',
        badgeShowLabel: 'selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    subsidiaryClients: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Filial Cliente',
        badgeShowLabel: 'Filiais Cliente selecionadas',
        badgeShowLimit: 1,
        disabled: false,
    },
    companies: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Empresa',
        badgeShowLabel: 'Empresas selecionadas',
        badgeShowLimit: 1,
        disabled: false,
    },
    costCenter: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Centro de Custo',
        badgeShowLabel: 'selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    costs: {
        enableCheckAll: false,
        singleSelection: true,
        text: 'Custos',
        badgeShowLimit: 1,
        disabled: false,
    },
    suppliers: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Fornecedor',
        badgeShowLabel: 'Fornecedores selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    referenceDateTypes: {
        enableCheckAll: false,
        singleSelection: true,
        enableSearchFilter: false,
        preventEmpty: true,
        disabled: false,
    },
    traders: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Trader',
        badgeShowLabel: 'Traders selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    directors: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Diretor',
        badgeShowLabel: 'Diretores selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    // invoice-external
    externalInvoiceSituations: {
        singleSelection: true,
        text: 'Situação',
    },
     // invoice-external
     salesOrderSituations: {
        singleSelection: true,
        text: 'Situação',
    },
    // exchange-accounts
    banks: {
        singleSelection: true,
        text: 'Banco',
        badgeShowLabel: 'Bancos selecionados',
    },
    externalInvoiceType: {
        singleSelection: true,
        enableCheckAll: false,
        enableSearchFilter: false,
        text: 'Tipo de Pedido',
    },
    settlmentDates: {
        enableCheckAll: false,
        singleSelection: true,
        enableSearchFilter: false,
        text: 'Data de Liquidação',
        disabled: false,
    },
    sps: {
        enableCheckAll: false,
        singleSelection: false,
        text: 'SPs',
        enableSearchFilter: false,
        emptyDataMessage: 'Sem SPs para vínculo',
        badgeShowLabel: 'SPs selecionadas',
        badgeShowLimit: 1,
        disabled: false,
    },
    responsibleUsers: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Responsáveis',
        badgeShowLimit: 1,
        disabled: false,
    },
    responsibleUserOdfs: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Parametrizador',
        badgeShowLabel: 'Parametrizadores selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    invoiceItems: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Itens',
        badgeShowLimit: 1,
        disabled: false,
    },
    modals: {
        text: 'Modal',
        singleSelection: false,
        enableCheckAll: false,
        icon: true,
    },
    urfs: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'URF',
        badgeShowLabel: 'URFs selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    fupModels: {
        enableCheckAll: false,
        singleSelection: true,
        text: 'Modelo',
        enableSearchFilter: false,
        preventEmpty: true,
        disabled: false,
    },
    userPreferences: {
        enableCheckAll: false,
        singleSelection: true,
        enableSearchFilter: false,
        text: 'Selecione o Perfil',
        disabled: false,
    },
    kpis: {
        text: 'KPI',
        singleSelection: false,
        enableSearchFilter: false,
        badgeShowLabel: 'KPIs selecionados',
        badgeShowLimit: 1,
    },
    documentTypes: {
        singleSelection: false,
        enableCheckAll: true,
        text: 'Tipo do Documento',
        disabled: false,
    },
    states: {
        singleSelection: true,
        text: 'Estado'
    },
    warehouses: {
        singleSelection: true,
        text: 'Recinto',
    },
    roadcarriers: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Transportadora',
        badgeShowLabel: 'Transportadoras selecionadas',
        badgeShowLimit: 1,
        disabled: false,
    },

    // Automotive
    vehicles: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Veículos',
        badgeShowLabel: 'Veículos selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    origins: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Origem',
        badgeShowLabel: 'Origens selecionadas',
        badgeShowLimit: 1,
        disabled: false,
    },
    destinations: {
        enableCheckAll: true,
        singleSelection: false,
        text: 'Destino',
        badgeShowLabel: 'Destinos selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    models: {
        text: 'Modelo',
        singleSelection: false,
        enableCheckAll: false,
        badgeShowLabel: 'Modelos selecionados',
        badgeShowLimit: 1,
        disabled: false,
    },
    colors: {
        text: 'Cor',
        singleSelection: false,
        enableCheckAll: false,
        badgeShowLabel: 'Cores selecionadas',
        badgeShowLimit: 1,
        disabled: false,
    },
    modelYears: {
        text: 'Ano Modelo',
        singleSelection: false,
        enableCheckAll: false,
        disabled: false,
    },
    manufacturerYears: {
        text: 'Ano Fabr.',
        singleSelection: false,
        enableCheckAll: false,
        disabled: false,
    },
    // Fechamento
    handlings: {
        text: 'Tratativa',
        singleSelection: true,
        enableCheckAll: false,
        disabled: false,
    },
    actions: {
        text: 'SELECIONE',
        singleSelection: true,
        enableCheckAll: false,
        disabled: false,
    },
};
