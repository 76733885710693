import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_pt_BR from '@amcharts/amcharts5/locales/pt_BR';

export function createDraggableRangeChart(root, chartConfig, data, visibleDataPoints) {
    root.locale = am5locales_pt_BR;

    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'none',
            wheelY: 'none',
            pinchZoomX: false,
            paddingTop: chartConfig?.padding?.top || 20,
            paddingBottom: chartConfig?.padding?.bottom || 20,
            paddingLeft: chartConfig?.padding?.left || 0,
            paddingRight: chartConfig?.padding?.right || 20,
        })
    );

    let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
            behavior: 'zoomX',
            lineX: am5.Line.new(root, {
                stroke: am5.color(0x68dc76),
                strokeWidth: 1,
            }),
            lineY: am5.Line.new(root, {
                stroke: am5.color(0x68dc76),
                strokeWidth: 1,
            }),
        })
    );
    cursor.lineX.set('forceHidden', false);
    cursor.lineY.set('forceHidden', false);

    let xAxis;
    if (chartConfig.axis[0].type === 'category') {
        xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: chartConfig.axis[0].categoryField,
                renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
                tooltip: am5.Tooltip.new(root, { labelText: '{category}' }),
            })
        );
        xAxis.data.setAll(data);
    } else {
        xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: 'day', count: 1 },
                renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 90 }),
                tooltip: am5.Tooltip.new(root, {}),
            })
        );
    }

    let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            extraMax: chartConfig.axisConfig?.y?.extraMax || 0.15,
            renderer: am5xy.AxisRendererY.new(root, {}),
            tooltip: am5.Tooltip.new(root, {}),
        })
    );

    yAxis.children.unshift(
        am5.Label.new(root, {
            text: chartConfig.axisTitle?.y || '',
            rotation: -90,
            fontSize: chartConfig.axisTitle?.fontSize || 12,
            fontWeight: 'bold',
            y: am5.percent(85),
            centerY: am5.p50,
            paddingRight: 15,
        })
    );

    let series;

    series = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: chartConfig.content[0].name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: chartConfig.content[0].valueYField,
            valueXField: chartConfig.content[0].valueXField,
            tooltip: am5.Tooltip.new(root, { labelText: 'X: {valueX}, Y: {valueY}' }),
            snapTooltip: true,
        })
    );

    series.strokes.template.setAll({
        strokeCap: 'round',
        strokeWidth: 2,
        stroke: am5.color(0xff0000),
    });

    series.fills.template.setAll({
        fillOpacity: 0.2,
        visible: true,
    });

    series.set(
        'tooltip',
        am5.Tooltip.new(root, {
            labelText: '{valueY}',
            pointerOrientation: 'horizontal',
            autoTextColor: false,
            dy: -5,
            tooltipY: am5.percent(50),
            keepTargetHover: true,
            delayShow: 0,
            delayHide: 100,
        })
    );

    series.data.setAll(data);

    return chart;
}
