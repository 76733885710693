import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from '#environment';
import { IAssembledData } from 'app/shared/components/cmx-assembled-data/interfaces/assembled-data.interface';
import { UtilsService } from '#services/_utils/utils.service';

@Injectable()
export class PoStatusService {

    asyncData: boolean = false;
    private _subscription;
    private _poDetails: IAssembledData[];
    private _poDates: IAssembledData[];
    private _poItems;
    private _impList;

    constructor(private _http: HttpClient, private _utilsService: UtilsService) { }

    getImpList() { return this._impList }
    getPoDetail() { return this._poDetails }
    getPoDates() { return this._poDates }
    getPoItems() { return this._poItems }

    loadPoData(poId, imps) {
        if (this._subscription) { this._subscription.unsubscribe() }
        this.asyncData = true;
        this._subscription = forkJoin([
            this._getPoData(poId),
            this._getIMPData(imps),
        ]).subscribe((values) => {
            const [poData, impList] = values;
            this._setPoData(poData);
            this._setImpList(impList);
            this.asyncData = false;
        }, (error) => {
            this._setPoData({});
            this._setImpList([]);
            this.asyncData = false;
            this._utilsService.errorHandler(error, 'Detalhes da P.O');
        });
    }

    getPoList(request): Observable<any> {
        return this._http.get(`${environment.endpoints.purchaseOrderService}/status`, request);
    }

    getSubsidiaryNames(): Observable<any> {
        return this._http.get(`${environment.endpoints.importationMongoDBService}/imps/subsidiaryNames`);
    }

    getSubsidiaryClients(): Observable<any> {
        return this._http.get(`${environment.endpoints.purchaseOrderService}/status/client-subsidiary-cnpj`);
    }

    getPoStatusList(request): Observable<any> {
        return this._http.get(`${environment.endpoints.purchaseOrderService}/imp-status/count`, request).pipe(
            map((poStatusList: any) => {
                return _.reduce(poStatusList, (prev, curr) => {
                    return [...prev, _.omit(_.merge(curr, curr.status), 'status')]
                }, []);
            }));
    }

    private _getPoData(poId): Observable<any> {
        return this._http.get(`${environment.endpoints.purchaseOrderService}/status/detail?id=${poId}`);
    }

    private _getIMPData(impList): Observable<any> {
        const imps = impList.map((c) => c).join(',');
        return this._http.get(`${environment.endpoints.importationMongoDBService}/imps/purchase-order-status-imp?imps=${imps}`);
    }

    private _setImpList(impList) {
        let rows = []
        impList.forEach((imp, index) => {
            rows.push({
                id: index,
                diChannel: imp.clearanceData && imp.clearanceData.diDataList[0].channel || '',
                diRegistrationNumber: imp.clearanceData && imp.clearanceData.diDataList[0].registrationNumber || '',
                diDate: imp.clearanceData && imp.clearanceData.diDataList[0].registrationDate || '',
                vendorInvoice: imp.vendorInvoices && imp.vendorInvoices.invoices || '',
                vendorInvoiceAmount: imp.vendorInvoices && imp.vendorInvoices.totalAmount || '',
                clientInvoiceAmount: imp.clientInvoices.totalAmountBRL || '',
                shippingDate: imp.shippingDate || '',
                ...imp
            })
        });
        this._impList = rows;
    }

    private _setPoData(poData) {
        // Details
        this._poDetails = [{
            title: 'Pedido Exportador',
            value: poData.salesOrderVendorNumber || '-'
        }, {
            title: 'Centro de Custo',
            value: poData.costCenter || '-'
        }, {
            title: 'Tipo',
            value: poData.orderType || '-'
        }, {
            title: 'Condição Pagamento',
            value: poData.paymentCondition || '-'
        }, {
            title: 'INCOTERM',
            value: poData.incoterm || '-'
        }, {
            title: 'Moeda',
            value: poData.currency || '-'
        }, {
            title: 'País de Orígem',
            value: poData.arrivalCountry || '-'
        }, {
            title: 'Porto de Orígem',
            value: poData.harborFrom || '-'
        }, {
            title: 'Porto de Destino',
            value: poData.harborDestination || '-'
        }];

        poData.hasOwnProperty('poDates') ? this._setPoDates(poData.poDates) : this._setPoDates({})
        poData.hasOwnProperty('poItems') ? this._setPoItems(poData.poItems) : this._poItems = [];

    }

    private _setPoDates(podates) {
        this._poDates = [{
            title: 'Emissão do Pedido',
            value: podates.issueDate || '-'
        }, {
            title: 'Envio P.O Exportador',
            value: podates.poExporterDate || '-'
        }, {
            title: 'Confirmação Exportador',
            value: podates.poConfirmationExporterDate || '-'
        }, {
            title: 'Prev. Início Produção',
            value: podates.previsionOfManufacturingStartDate || '-'
        }, {
            title: 'Início Produção',
            value: podates.manufacturingStartDate || '-'
        }, {
            title: 'Prev. Disponibilidade',
            value: podates.previsionOfAvailabilityDate || '-'
        }, {
            title: 'Disponibilidade',
            value: podates.availabilityDate || '-'
        }]
    }

    private _setPoItems(poItems) {
        this._poItems = poItems || [];
        let total = {
            number: 'TOTAL',
            quantity: 0,
            quantityMet: 0,
            balance: 0,
            quantityDeducted: 0,
            fullfillmentItem: 'empty',
        }
        if (this._poItems.length > 0) {
            this._poItems.forEach((item) => {
                total.quantity += item.quantity;
                total.quantityMet += item.quantityMet;
                total.balance += item.balance;
                total.quantityDeducted += item.quantityDeducted;
            });
            const formatedTotal = {
                quantity: this._utilsService.formatNumber(total.quantity, { currency: 'none', style: 'currency', maximumFractionDigits: 4, minimumFractionDigits: 4 }),
                quantityMet: this._utilsService.formatNumber(total.quantityMet, { currency: 'none', style: 'currency', maximumFractionDigits: 4, minimumFractionDigits: 4 }),
                balance: this._utilsService.formatNumber(total.balance, { currency: 'none', style: 'currency', maximumFractionDigits: 4, minimumFractionDigits: 4 }),
                quantityDeducted: this._utilsService.formatNumber(total.quantityDeducted, { currency: 'none', style: 'currency', maximumFractionDigits: 4, minimumFractionDigits: 4 }),
            }
            const formatedItens = poItems.map((poItems) => { return { 
                ...poItems,
                quantity: this._utilsService.formatNumber(poItems.quantity, { currency: 'none', style: 'currency', maximumFractionDigits: 4, minimumFractionDigits: 4 }),
                quantityMet: this._utilsService.formatNumber(poItems.quantityMet, { currency: 'none', style: 'currency', maximumFractionDigits: 4, minimumFractionDigits: 4 }),
                balance: this._utilsService.formatNumber(poItems.balance, { currency: 'none', style: 'currency', maximumFractionDigits: 4, minimumFractionDigits: 4 }),
                quantityDeducted: this._utilsService.formatNumber(poItems.quantityDeducted, { currency: 'none', style: 'currency', maximumFractionDigits: 4, minimumFractionDigits: 4 }),
            } })
            this._poItems = formatedItens;
            this._poItems.push(formatedTotal);
        }
    }
}
