import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import * as _ from 'lodash';
import { forkJoin } from 'rxjs';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { DomainService } from '#services/_domain/domain.service';
import { ImportationServiceService } from '#services/_importation/importation.service';
import { UserService } from "app/auth/_services/user.service";
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { MODALS } from 'app/shared/constants/modals.constants';


@Component({
    selector: 'preferences-crud',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PreferencesCRUDComponent implements OnInit, AfterViewInit {

    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    async = false;
    pageFiltersLoading = true;
    currentUser$;
    preferences: any[] = [];

    options: {
        clientGroups: DropdownOption[];
        subsidiaryNames: DropdownOption[];
        modals: DropdownOption[];
        companies: DropdownOption[];
        profitCenters: DropdownOption[];
    } = {
        clientGroups: [],
        subsidiaryNames: [],
        modals: [],
        companies: [],
        profitCenters: [],
    };
    showDeletePreferenceModal = false;

    preference: any;
    form: FormGroup;

    constructor(
        private _fb: FormBuilder,
        private _notificationService: NotificationService,
        private _utilsService: UtilsService,
        private userService: UserService,
        private domainService: DomainService,
        private importationServiceService: ImportationServiceService,
        private userCacheService: UserCacheService,
        private userPreferenceService: UserPreferencesService,
    ) {
    }

    ngOnInit(): void {
        this.currentUser$ = this.userService.currentUser$;
        this._createForm();
        this._getPreferences();
    }

    ngAfterViewInit(): void {

        this.options.clientGroups = this._toDropdownItems(this.userCacheService.getClientGroups());
        this.options.modals = _.cloneDeep(MODALS);

        forkJoin([
            this.importationServiceService.getSubsidiaryNames(),
            this.domainService.getCompanies(),
            this.domainService.getProfitCenter()
        ]).subscribe((values) => {
            const [subsidiaryNames, companies, profitCenters] = values;
            this.options.subsidiaryNames = this._toDropdownItems(subsidiaryNames);
            this.options.companies = this._toDropdownItems(companies);
            this.options.profitCenters = this._toDropdownItems(profitCenters);
            this.pageFiltersLoading = false;
        }, (error) => {
            this._utilsService.errorHandler(error, 'Preferências do Usuário');
        });
    }

    selectPreference(preference: any) {
        this._createForm(_.cloneDeep(preference));
    }

    newPreference() {
        this._createForm();
    }

    saveOrEdit() {
        if (this.form.invalid) {
            this._notificationService.openNotification('error', 'Por favor verifique todos os campos antes de prosseguir');
            return;
        }
        this.async = false;
        this._savePreference();
    }

    delete() {
        this.showConfirmDelete();
    }

    handleDeletePreference() {
        this.async = false;
        setTimeout(() => this.showDeletePreferenceModal = false, 500);

        this.userPreferenceService.deletePreference(this.preference).subscribe(data => {
            this._notificationService.openNotification('success', 'Visão deletada com sucesso');
            this._getPreferences();
            this._createForm()
        }, error => {
            this._notificationService.openNotification('error', error.error?.message, 'Falha ao deletar visão');
            this.async = true;
        });
    }

    cancelEditing() {
        const preference = this.preferences.find(preference => preference.id == this.preference.id);
        this._createForm(_.cloneDeep(preference));
    }

    onChangeDefault() {
        this.form.controls['defaultPreference'].setValue(!(this.form.controls['defaultPreference'].value));
        this.form.controls['defaultPreference'].markAsDirty();
        this.form.updateValueAndValidity();
    }

    get defaultPreference$(): boolean {
        return this.form.controls['defaultPreference']?.value;
    }

    private _savePreference() {
        this.formatDataForSave();

        this.userPreferenceService.savePreference(this.preference).subscribe(data => {
            this._notificationService.openNotification('success', 'Para utilizar esta visão selecione dentro perfil no menu superior.', 'Visão salva com sucesso');
            if(this.userCacheService.getDefaultUserPreference() === data.id){
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                this._getPreferences();
                this._createForm(data);
            }
        }, error => {
            this.handleError(error, 'Falha ao salvar visão');
            this.async = true;
        });

    }


    private formatDataForSave() {
        const rawData = this.form.getRawValue();
        let filters = this.userPreferenceService.formatDataForSave(rawData.filters);

        Object.keys(this.form.get('filters')["controls"]).forEach(filter => {
            if (Array.isArray(rawData.filters[filter])) {
                if (rawData.filters[filter].length === this.options[filter].length) {
                    filters.filters[filter].content.value = [];
                } else if (rawData.filters[filter].length === 0) {
                    filters.filters[filter].content.value = null;
                }
            }
        });

        this.preference.name = rawData.name;
        this.preference.defaultPreference = rawData.defaultPreference;
        this.preference.filters = filters.filters;

    }

    private _getPreferences() {
        this.async = false;
        this.userPreferenceService.getUserPreferences().subscribe(preferences => {
            preferences.forEach(preference => preference.favorite = preference.defaultPreference);
            this.preferences = preferences;
            this.async = true;
        });
    }

    private _createForm(preference?) {
        this.form = this._fb.group({
            name: [null],
            defaultPreference: [false, null],
            filters: this._fb.group({
                clientGroups: [[], null],
                subsidiaryNames: [[], null],
                modals: [[], null],
                companies: [[], null],
                profitCenters: [[], null],
            })
        });

        if (preference) {
            preference.filters = this.mapFilter(preference.filters);
        } else {
            preference = {};
        }
        this.preference = preference;
        this.form.patchValue(preference);
    }

    private mapFilter(filters) {
        const form = {}
        const filterList: any = Object.values(filters);
        filterList.forEach(filter => {
            if (Array.isArray(filter.content.value) && filter.content.value.length === 0) {
                form[filter.name] = _.cloneDeep(this.options[filter.name]);
            } else {
                form[filter.name] = filter.content.value;
            }
        });
        return form;
    }

    private showConfirmDelete(): void {
        this.showDeletePreferenceModal = true;
    }


    private handleError(error, title) {
        let message = error.error?.message;
        if (error.error?.subErrors) {
            for (let subError of error.error.subErrors) {
                message = message + '\n' + subError.message;
            }
        }
        this._notificationService.openNotification('error', message, title);
    }

    private _toDropdownItems(items: any[]) {
        return items != null ? items.map(this._toDropdownItem) : [];
    }

    private _toDropdownItem(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.name}`);
    }


}
