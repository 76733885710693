import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UserService } from "app/auth/_services/user.service";
import { ISwitchOption } from '../cmx-switch/cmx-switch.interface';
import { IPageTabs } from './interfaces/tabs.interface';
import { IBottomButton, IRightButton, ITopButton } from './interfaces/top-button.interface';

import { IPageWrapperTitle } from './page-wrapper-interface';
import { LayoutTypePageWrapper } from './page-wrapper.types';

type AccordionSides = 'top' | 'bottom' | 'left' | 'right';

@Component({
    selector: 'cmx-page-wrapper',
    templateUrl: './page-wrapper.component.html',
    styleUrls: ['./page-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class PageWrapperComponent {
    @Input() layout: LayoutTypePageWrapper = 'full';
    @Input() borderColor: string;
    @Input() titleComponent: IPageWrapperTitle;
    @Input() iconAfterTitle: string;
    @Input() tabs: IPageTabs[] = [];
    @Input() checkChangeTab?: boolean = false;
    @Input() alertTabMessage?: string = 'Importante! Ao continuar, as alterações serão perdidas.';
    @Input() alertTabOptions? = ['Continuar', 'Cancelar'];
    @Input('tab-blur') tabBlur: IPageTabs = null;
    @Input('image') imgSrc: string;
    @Input('height-section') heightSection: string;
    @Input() skeletonLoader?: boolean = false;
    // Right Buttons
    @Input() rightButtonList?: IRightButton[] = [];
    @Input() selectedRightButton: IRightButton;
    // Switch
    @Input() switch?: ISwitchOption[];
    @Input() selectedSwitch?: string;
    @Input() checkChangeSwitch?: boolean = false;
    @Input() alertSwitchMessage?: string = 'Importante! Ao continuar, as alterações serão perdidas.';
    @Input() alertSwitchOptions? = ['Continuar', 'Cancelar'];
    // Page Info
    @Input() helpDescription?: object[] = [];
    @Input() helpTitle: string = 'Informação';
    // Top Buttons
    @Input() topButtonList?: ITopButton[] = [];
    @Input() rightExtraPaddingPage?: boolean = false;
    @Input() topButtonListOutside?: boolean = false;
    // Bottom Buttons
    @Input() bottomButtonList?: IBottomButton[] = [];
    // Accordion
    @Input() internalAccordion?: AccordionSides = null;
    @Input() accordionSize?: string = '';
    @Input() accordionName?: string = '';
    @Input() accordionOpen?: boolean = false;
    @Input() showAccordion?: boolean = true;
    @Input() showDevInfo?: 'ALFA' | 'BETA';

    @Output('onSelectTab') onSelectTab = new EventEmitter();
    @Output('onSwitchSelect') onSwitchSelect = new EventEmitter();
    @Output('onTopButtonClick') onTopButtonClick = new EventEmitter();
    @Output('onRightButtonClick') onRightButtonClick = new EventEmitter();
    @Output('onBottomButtonClick') onBottomButtonClick = new EventEmitter();

    asyncBottomButtonList: boolean = true;
    tabList: IPageTabs[] = [];
    selectedTab: IPageTabs;
    selectedTopMultipleButton: ITopButton;
    surpassWidth: boolean = false;
    showSwitchConfirmationModal: boolean = false;
    showTabConfirmationModal: boolean = false;
    tabEvent;
    switchEvent;
    maxWidth: number;
    accordionOptions = {
        flexDirection: 'row',
        width: '0%',
        height: '0%',
        contentSize: '100%',
        padding: '20px',
        arrowIcon: 'chevron_right',
        name: {
            rotate: 'auto',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto'
        }
    }

    private _currentUser = this._userService.getCurrentUser();

    constructor(private _userService: UserService) { }

    ngOnInit() {
        this. _verifyPermitedTabs(this.tabs);
        if (this.tabs.length) {
            this.heightSection = 'calc(100vh - 110px)';
            if (this.tabBlur) {
                this.selectedTab = this.tabBlur;
            } else {
                this.selectedTab = this.tabs[0];
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this._defineInitialTopButonColor();
        if (changes.switch && changes.switch.previousValue != this.switch && !this.selectedSwitch) {
            this.selectedSwitch = Array.isArray(this.switch) && this.switch.length ? this.switch[0].name : null;
            this._defineInitialTopButonColor();
        }
        if (changes && changes?.tabBlur?.currentValue) {
            this.selectedTab = this.tabBlur;
            this._scrollContainerOnChange();
        }
        if (changes && changes?.bottomButtonList?.currentValue) {
            this.bottomButtonList = changes.bottomButtonList.currentValue;
            this._defineDangerousBottomButton();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.maxWidth = this._defineMaxWidthTabs();
            setTimeout(() => {
                this._verifySurpassWidth();
                if (this.internalAccordion) { this._defineAccordion() }
                if (this.bottomButtonList.length) { this._defineDangerousBottomButton() }
            }, 100);
        });
    }

    selectTab(event) {
        if (!this.checkChangeTab) {
            this.changeTabPage(event);
        } else {
            this.tabEvent = event;
            this.showTabConfirmationModal = true;
        }
    }

    selectSwitch(event) {
        if (!this.checkChangeSwitch) {
            this.changeSwitchPage(event);
        } else {
            this.switchEvent = event;
            this.showSwitchConfirmationModal = true;
        }
    }

    changeTabPage(event) {
        this.showTabConfirmationModal = false
        this.selectedTab = event;
        this.onSelectTab.emit(event);
        this._scrollContainerOnChange();
    }

    changeSwitchPage(event) {
        this.showSwitchConfirmationModal = false;
        this.selectedSwitch = event.name;
        this.onSwitchSelect.emit(event);
    }

    topButtonClicked(button: ITopButton, event) {
        if(!button.disabled){
            if (button.multiple) {
                this.selectedTopMultipleButton = this.selectedTopMultipleButton?.code === button.code ? null : button;
            } else {
                this.selectedTopMultipleButton = null;
                this.onTopButtonClick.emit(button);
            }
            event.stopPropagation();
        }
    }

    bottomButtonClicked(button: IBottomButton) {
        if (!button.disabled) {
            this.onBottomButtonClick.emit(button);
        }
    }

    rightButtonClicked(button: ITopButton) {
        this.selectedRightButton = button;
        this.onRightButtonClick.emit(button);
    }

    topButtonMouseHover(button, out?, subItem?) {
        const element = $(`#top-button-list #${subItem ? subItem.code : button.code}`);
        if (out) {
            element.css("background-color", button.color || '#b1b1b1')
        } else {
            element.css("background-color", button.hoverColor || button.color || '#b1b1b1')
        }
        event.stopPropagation();
    }

    scrollContainer(direction: string, element) {
        element.preventDefault();
        const container = $('.tabs-container');
        const halfWidth = container.width() / 2;
        container.animate({ scrollLeft: container.scrollLeft() + (direction === 'left' ? -halfWidth : halfWidth) }, 1000);
    }

    toogleAccordion() {
        this.accordionOpen = !this.accordionOpen;
        this._defineAccordion()
    }

    verifyRightButtonSelected(button) {
        return this.selectedRightButton?.code === button?.code;
    }

    private _scrollContainerOnChange() {
        setTimeout(() => {
            if (this.surpassWidth) {
                const selectedTabElement = document.getElementById(this.selectedTab.code);
                const containerElement = document.getElementById('tabs-container');

                const containerWidth = containerElement.clientWidth;
                const tabOffsetLeft = selectedTabElement.offsetLeft;
                const tabWidth = selectedTabElement.clientWidth;

                const margin = 50;
                if (tabOffsetLeft < containerElement.scrollLeft) {
                    containerElement.scrollLeft = Math.max(tabOffsetLeft - margin, 0);
                } else if (tabOffsetLeft + tabWidth > containerElement.scrollLeft + containerWidth) {
                    containerElement.scrollLeft = tabOffsetLeft + tabWidth - containerWidth + margin;
                }
            }
        }, 50);
    }

    private _defineAccordion() {
        if (this.accordionSize) {
            this.accordionSize = this.accordionSize
        } else {
            this.accordionSize = this.internalAccordion === 'top' || this.internalAccordion === 'bottom' ? '200px' : '20%';
        }
        const contentDistance = $('.page-body-content').offset().top;
        switch (this.internalAccordion) {
            case 'left':
                this.accordionOptions.flexDirection = 'row';
                this.accordionOptions.width = this.accordionOpen ? this.accordionSize : '20px';
                this.accordionOptions.height = `calc(100vh - ${contentDistance}px)`
                this.accordionOptions.contentSize = this.accordionOpen ? 'calc(100% - '+ this.accordionSize +')' : '100%';
                this.accordionOptions.padding = this.accordionOpen ? '20px 40px 20px 20px' : '20px';
                this.accordionOptions.arrowIcon = this.accordionOpen ? 'chevron_left' : 'chevron_right';
                break;
            case 'right':
                this.accordionOptions.flexDirection = 'row-reverse';
                this.accordionOptions.width = this.accordionOpen ? this.accordionSize : '20px';
                this.accordionOptions.height = `calc(100vh - ${contentDistance}px)`
                this.accordionOptions.contentSize = this.accordionOpen ? 'calc(100% - '+ this.accordionSize +')' : '100%';
                this.accordionOptions.padding = this.accordionOpen ? '20px 20px 20px 40px' : '20px';
                this.accordionOptions.arrowIcon = this.accordionOpen ? 'chevron_right' : 'chevron_left';
                break;
            case 'top':
                this.accordionOptions.flexDirection = 'column';
                this.accordionOptions.width = '100%';
                this.accordionOptions.height = this.accordionOpen ? this.accordionSize : '20px';
                this.accordionOptions.contentSize = `calc(100vh - ${contentDistance}px - ${this.accordionSize})`
                this.accordionOptions.padding = this.accordionOpen ? '20px 20px 40px 20px' : '20px';
                this.accordionOptions.arrowIcon = this.accordionOpen ? 'expand_less' : 'expand_more';
                break;
            case 'bottom':
                this.accordionOptions.flexDirection = 'column-reverse';
                this.accordionOptions.width = '100%';
                this.accordionOptions.height = this.accordionOpen ? this.accordionSize : '20px';
                this.accordionOptions.contentSize =
                    this.accordionOpen ? `calc(100vh - ${contentDistance}px - ${this.accordionSize})` : `calc(100vh - ${this.accordionSize})`
                this.accordionOptions.padding = this.accordionOpen ? '40px 20px 20px 20px' : '20px';
                this.accordionOptions.arrowIcon = this.accordionOpen ? 'expand_more' : 'expand_less';
                break;
        }
        this._defineAccordionNamePosition();
    }

    private _defineAccordionNamePosition() {
        const nameWidth = $('.accordion-name').width() + 20;
        switch (this.internalAccordion) {
            case 'left':
            case 'right':
                this.accordionOptions.name.rotate = 'rotate(-90deg)';
                this.accordionOptions.name.top = `calc(${nameWidth}px / 2)`;
                this.accordionOptions.name.bottom = 'auto'
                this.accordionOptions.name.left = `calc(-${nameWidth}px / 2)`;
                this.accordionOptions.name.right = 'auto';
            break;
            case 'top':
            case 'bottom':
                this.accordionOptions.name.rotate = 'auto';
                this.accordionOptions.name.top = '-10px';
                this.accordionOptions.name.bottom = 'auto';
                this.accordionOptions.name.left = 'auto';
                this.accordionOptions.name.right = '15px';
            break;
        }
    }

    private _defineMaxWidthTabs(): number {
        return $('.top-border').width() - 80;
    }

    private _verifySurpassWidth() {
        const containerWidth = $('.top-border').width();
        this.surpassWidth = $('.tabs-container').innerWidth() >= containerWidth - 80 ? true : false;
    }

    private _verifyPermitedTabs(tabs) {
        tabs.forEach((tab) => {
            const tabPermission = tab.permission || 'ROUTE_INDEX';
            if (this._currentUser.has(tabPermission)) {
                this.tabList.push(tab)
            }
        })
    }

    private _defineInitialTopButonColor() {
        this.topButtonList?.forEach((button) => {
            button.color = button.color ? button.color : '#3163B1'
            button.hoverColor = button.hoverColor ? button.hoverColor : '#265b87'
            button.textColor = button.textColor ? button.textColor : 'white'
        })
    }

    private _defineDangerousBottomButton() {
        const dangerousIndex = this.bottomButtonList.findIndex(button => { return button.dangerous });
        const dangerousButton = this.bottomButtonList[dangerousIndex];
        if (dangerousButton) {
            this.bottomButtonList.splice(dangerousIndex, 1);
            this.bottomButtonList.splice(0, 0, dangerousButton);
        }
        this.asyncBottomButtonList = false;
    }
}
