import { AfterViewInit, Component, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core';
import { DownloadModalService } from '#services/_download/download-modal.service';
import { MessagesService } from '#services/_messages/messages.service';
import { NewFeaturesConsumerService } from '#services/_newFeatureConsumer/newFeatureConsumer.service';
import { HeaderNavService } from '../header-nav/header-nav.service';

@Component({
    selector: 'overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OverlayComponent implements AfterViewInit {

    private onCloseAlert: Function;

    constructor(
        public headerNavService: HeaderNavService,
        public messagesService: MessagesService,
        public newFeaturesConsumerService: NewFeaturesConsumerService,
        private _downloadModalService: DownloadModalService,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) { }

    public ngAfterViewInit(): void {
        this.onCloseAlert = this.renderer
            .listen(this.elementRef.nativeElement, 'click', () => {
                if (this._downloadModalService.isVisibleDownloads) {
                    this._downloadModalService.closeDownloads();
                }
                if (this.headerNavService.isVisibleAlerts) {
                    this.headerNavService.closeAlert();
                    this.headerNavService.enableWheelInAllComponents();
                }
                if (this.messagesService.isVisibleMessages) {
                    this.messagesService.closeMessagesModal();
                }
                if (this.newFeaturesConsumerService.isVisibleFeatures) {
                    this.newFeaturesConsumerService.closeReleaseList();
                }
        });
    }

    public ngOnDestroy() {
        this.onCloseAlert();
    }
}
