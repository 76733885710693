

import { environment } from '#environment';
import { DomainTypeService } from '#services/_domain/domainType.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from "app/auth/_services/user.service";
import { Observable } from 'rxjs';

import { FollowUpModel } from './model.model';

@Injectable()
export class FollowUpModelService {

    private _modelList: FollowUpModel[];
    private _selectedFollowUpEditModel: FollowUpModel;
    private _currentUser = this._userService.getCurrentUser();
    private _userConfigs = {
        userId: this._currentUser.getUid(),
        isCustomer: !this._currentUser.has('FOLLOWUP_COMPLETE'),
    };

    constructor(
        private _http: HttpClient,
        private _userService: UserService,
        private _domainTypeService: DomainTypeService
    ) { }

    getSelectedFollowUpEditModel(): FollowUpModel {
        return this._selectedFollowUpEditModel;
    }
    getModelList(): FollowUpModel[] {
        return this._modelList;
    }

    setSelectedFollowUpEditModel(selectedFollowUpEditModel: FollowUpModel) {
        this._selectedFollowUpEditModel = selectedFollowUpEditModel;
    }
    setModelList(modelList: FollowUpModel[]) {
        this._modelList = modelList;
        this._modelList[0] = new FollowUpModel(modelList[0]);
    }

    getSaveModelList(): Observable<any> {
        return this._http.get(`${environment.endpoints.customService
        }/customization/models?userId=${this._userConfigs.userId
        }&name=${this._domainTypeService.getFollowUpType(this._domainTypeService.getDomainType())
        }&isCustomer=${this._userConfigs.isCustomer}`);
    }

    saveNewModel(save): Observable<any> {
        return this._http.post(`${environment.endpoints.customService
        }/customization/models?userId=${this._userConfigs.userId
        }&name=${this._domainTypeService.getFollowUpType(this._domainTypeService.getDomainType())
        }&isCustomer=${this._userConfigs.isCustomer}`, {
            ...save,
        });
    }

    editModel(save, lastName): Observable<any> {
        return this._http.put(`${environment.endpoints.customService
        }/customization/models?userId=${this._userConfigs.userId
        }&name=${this._domainTypeService.getFollowUpType(this._domainTypeService.getDomainType())
        }&modelName=${lastName}`, {
            ...save,
        });
    }

    deleteModel(modelName): Observable<any> {
        return this._http.delete(`${this._domainTypeService.getFollowUpEndpoint(this._domainTypeService.getDomainType(), false)
        }/customization/report?name=${this._domainTypeService.getFollowUpType(this._domainTypeService.getDomainType())
        }&userId=${this._userConfigs.userId
        }&modelName=${modelName}`);
    }

    sortModelList(modelList): Observable<any> {
        return this._http.put(`${environment.endpoints.customService
        }/customization/models/sorting?name=${this._domainTypeService.getFollowUpType(
            this._domainTypeService.getDomainType())
        }&userId=${this._userConfigs.userId}`, modelList);
    }

    getModelListFupUnified(type): Observable<any> {
        return this._http.get(`${environment.endpoints.customService
        }/customization/models?userId=${this._userConfigs.userId
        }&name=${this._domainTypeService.getFollowUpType(type)
        }&isCustomer=${this._userConfigs.isCustomer}`);
    }
}
