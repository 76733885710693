import { FilterStateService } from '#services/_filters/filter-state.service';
import { Component } from '@angular/core';
import { UserService } from "app/auth/_services/user.service";
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import * as _ from 'lodash';
import { ProjectPricingFilter } from '../../projectPricingFilter.model';
import { ProjectPricingService } from '../../project-pricing.service';
import { PROJECT_PRICING_COLUMNS } from '../../constants/project-pricing-columns.constant';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';

@Component({
    selector: 'project-pricing-proposes',
    templateUrl: './project-pricing-proposes.component.html',
    styleUrls: ['./project-pricing-proposes.component.scss'],
})
export class ProjectPricingProposesComponent {
    filterState: ProjectPricingFilter = this._filterStateService.getProjectPricingFilter();
    gridColumns: IGridColumn[] = this._prepareColumns();
    gridRows: IGridRow[] = [];
    priority: boolean = false;

    constructor(
        public projectPricingService: ProjectPricingService,
        private _filterStateService: FilterStateService,
        private _customDialogService: CustomDialogService,
        private _userService: UserService,
    ) {
        this.gridRows = this.projectPricingService.defineActionButtons(
            this.projectPricingService.getSelectedProposes(), true);
            this.gridRows = this.gridRows.map((row) => {
                return {
                    ...row,
                    totalQuotationValue: row.totalQuotationValue
                        ? '$ ' + (row.totalQuotationValue).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        : '',
                };
            });
    }

    onCellClick({ item, column, multiButton }): void {
        if (multiButton) {
            this._executeMultiButton(item, column, multiButton);
        } else if (column === 'requestFiles' || column === 'proposalFileIds') {
            this._downloadFile(item, column);
        }
    }

    onPopoverHover({ data, _element, cellElement }) {
        const cellPosition = cellElement[0].getBoundingClientRect();
        this.projectPricingService.popoverData = data;
        this.projectPricingService.popoverTopDistance = cellPosition.top + cellPosition.height + 2;
        this.projectPricingService.popoverLeftDistance = cellPosition.left + (cellPosition.width / 2) + 2;
    }

    onPopoverMouseLeave() {
        this.projectPricingService.forceFadeOut = true;
        setTimeout(() => {
            this.projectPricingService.forceFadeOut = false;
        }, 500);
    }

    removeFile() {
        this.filterState.file = undefined;
        this.filterState.fileName = '';
        $('#selecao-arquivo').val('');
        this.priority = false;
    }

    private _executeMultiButton(item, column, multiButton) {
        if (
            multiButton === 'insert_propose' &&
            item.status !== 'Enviado' &&
            item.status !== 'Revisão Solicitada'
        ) {
            this.projectPricingService.openModal('Proposta');
        } else if (multiButton === 'ask_revision' && item.status === 'Enviado') {
            this.projectPricingService.dialogRevisionPricingNumber = item.pricingId;
            this.projectPricingService.openModal('Revisão');
        } else if (multiButton === 'approve' && item.status !== 'Aguard. Proposta') {
            this.projectPricingService.selectedProposeId = item.proposalId;
            this.projectPricingService.confirmationType = multiButton;
            this.projectPricingService.openModal('P.O');
        } else if (column === 'requestFiles' || column === 'proposalFileIds') {
            this._downloadFile(item, column);
        } else if (multiButton === 'updatable_propose' && item.status === 'Enviado') {
            this.removeFile();
            this.projectPricingService.openModal('Editar');
        } else if (multiButton === 'ask_revision_historic' && item.status === 'PO Solicitada') {
            this.projectPricingService.setSelectedProposalItem(item);
            this._customDialogService.open('historic-request-review');
        } else if (multiButton === 'edit_proposal' && item.status === 'Aguard. Proposta') {
            this.projectPricingService.setSelectedProposalItem(item);
            this._customDialogService.open('historic-request-review');
        }
    }

    private _downloadFile(item, type) {
        this.projectPricingService.callDownloadFile(item, type);
    }

    private _prepareColumns() {
        let columns = _.cloneDeep(PROJECT_PRICING_COLUMNS).filter((column) => {
            return (
                column.field !== 'cotationNumber' &&
                column.field !== 'clientGroupName' &&
                column.field !== 'responsibleUsers' &&
                column.field !== 'note'
            );
        });
        columns[0].field = 'id';
        columns[2].field = 'requestFiles';
        columns[3].field = 'proposalFileIds';
        if (this.filterState.getTab() === 'project_pricing_historic') {
            if (this._userService.getCurrentUser().has('INTERNAL_FILTERS')) {
                columns.splice(5, 1);
            }
            const actionsColumn = columns.find(column => column.field === 'multiButtons');
            if (actionsColumn) {
                actionsColumn.width = 170;
            }
        }
        if (this.filterState.getTab() === 'project_pricing_progress') {
            columns.splice(9, 4);
        }
        return columns;
    }
}
