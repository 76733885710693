import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { IOperationalAlert,  IOperationalAlertBySeverityChart, IOperationalAlertsResponse } from 'app/shared/interfaces/operational-alerts.interface';
import { IAlertBySeverity } from './interfaces/alert-by-severity.interface';
import { AlertClientsDialogComponent } from './components/alert-clients-dialog/alert-clients-dialog.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { OperationalAlertsService } from './services/operational-alerts.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from "app/auth/_services/user.service";
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { environment } from '#environment';

import * as _ from 'lodash';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { OperationalAlertsFilter } from './operationalAlertsFilter.model';
import { ALERT_TYPE_INFO } from './constants/info.constant';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';

@Component({
    selector: 'operational-alerts',
    templateUrl: './operational-alerts.component.html',
    styleUrls: ['./operational-alerts.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OperationalAlertsComponent implements OnInit, OnDestroy {

    @ViewChild(AlertClientsDialogComponent, { static: true }) alertClientsDialog: AlertClientsDialogComponent;
    @ViewChild(InfoDialogComponent, { static: true }) infoDialog: InfoDialogComponent;

    currentUser$ = this._userService.currentUser$;
    filterState: OperationalAlertsFilter = this._filterStateService.getOperationalAlertsFilter();
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    options: {
        subsidiaryNames: DropdownOption[];
        client: DropdownOption[];
    } = {
        subsidiaryNames: [],
        client: [],
    };

    alertInfos = ALERT_TYPE_INFO;
    alertDetailLink: string;
    alerts: any[];
    loading: boolean = true;
    useMongodbService: boolean = false;
    alertsBySeverity: any;

    chartData = {
        DEFERMENT_LI: [],
        BOARDING_LIMIT: [],
        RESCHEDULE_CLEARANCE: [],
        DA_EXPIRATION: [],
        DOCUMENT_DELIVERY: [],
        REGISTRATION_DEADLINE: [],
        FREE_TIME: [],
        STORAGE_LIMIT: [],
        MERCHANDISE_NEEDED: [],
        BOARDING_INSTRUCTION: [],
        FREIGHT_FORWARDER: [],
    }

    private _subscription;

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this._utilsService.autoIncrementValue);
    }

    constructor(
        private _operationalAlertsService: OperationalAlertsService,
        private _featureFlagService: FeatureFlagService,
        private _userService: UserService,
        private _utilsService: UtilsService,
        private _cmxDropdownService: CmxDropdownService,
        private _userCacheService: UserCacheService,
        private _filterStateService: FilterStateService,
        private _customDialogService: CustomDialogService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2
    ) {
        this.options.client = this._cmxDropdownService.toDropdownItems(this._userCacheService.getClientGroups());
        this.options.subsidiaryNames = this._cmxDropdownService.toDropdownItems(this._userCacheService.getSubsidiaries());
    }

    async ngOnInit() {
        this.useMongodbService = await this._featureFlagService.isFeatureFlagEnabledV2('operational-alerts-mongodb-enabled');
        if (this.useMongodbService) {
            this._operationalAlertsService.setBaseUrl(environment.endpoints.importationMongoDBService);
        }
        this._getOperationalAlerts();
    }

    ngOnDestroy(): void {
        if (this._subscription) { this._subscription.unsubscribe() }
    }

    openDetail(link, alert): void {
        const item = alert.items.find(o => o.severity === link.code);
        this.alertClientsDialog.link = item._link || '';
        this.alertClientsDialog.open();
    }

    openInfo(type: string): void {
        this.infoDialog.type = type;
        this.infoDialog.open();
    }

    identify(_, item): string {
        return item.type;
    }

    applyFilter() {
        this._getOperationalAlerts();
    }

    openConfigModal() {
        this._customDialogService.open('operational-alerts-configuration-dialog');
    }

    verifySelectedAlert(title) {
        return this.filterState.getAlertsEnabled().includes(this.defineTitle(title).replace('*', ''));
    }

    changeSelectedAlert(alert) {
        let alertList = this.filterState.getAlertsEnabled();
        if (alertList.includes(alert.title)) {
            alertList = alertList.filter(e => e !== alert.title);
        } else {
            alertList.push(alert.title)
            if (alert.title === 'Média Total') {
                setTimeout(() => {
                    this._createChart();
                }, 400);
            }
        }
        this.filterState.setAlertsEnabled(alertList);
        this.filterState.saveUserPreferences();
    }

    defineHelpDescription(type) {
        return ALERT_TYPE_INFO.find((info) => info.type === type)?.value || [];
    }

    defineTitle(title) {
        return title.replace('Validade', 'Valid.')
            .replace('despacho', 'Despacho')
            .replace('armazenagem', 'Armazenagem')
    }

    private _getFormattedAlertsBySeverityData(
        alertsBySeverity: IOperationalAlertBySeverityChart[],
        alerts: IOperationalAlert[]
    ): IAlertBySeverity[] {
        const alertsQuantityBySeverity = {};
        for (const alert of alerts) {
            for (const item of alert.items) {
                if (!alertsQuantityBySeverity[item.severity]) {
                    alertsQuantityBySeverity[item.severity] = [];
                }
                if (item.quantity) {
                    const title = alert.title.replace('*', '');
                    const labelData = `• ${title}: ${item.quantity} IMP\`s<br>`;
                    alertsQuantityBySeverity[item.severity].push(labelData);
                }
            }
        }
        return alertsBySeverity.map((alertBySeverity: IOperationalAlertBySeverityChart) => ({
            ...alertBySeverity,
            tooltip: alertsQuantityBySeverity[alertBySeverity.severity].join(''),
        }));
    }

    private _getOperationalAlerts(): void {
        if (this._subscription) { this._subscription.unsubscribe() }
        this.loading = true;
        this._subscription = this._operationalAlertsService.getOperationalAlerts(this.filterState.getRequest())
        .subscribe(({ alertsBySeverity, alerts }: IOperationalAlertsResponse) => {
            this.loading = false;
            this.alerts = alerts;
            this.alerts.forEach((alert) => {
                this._defineChartData(alert.type, alert.items)
            })

            this.alertsBySeverity = this._getFormattedAlertsBySeverityData(alertsBySeverity, alerts);
            setTimeout(() => {
                this._createChart();
            }, 400);
        }, ((error) => {
            this.loading = false;
            this._utilsService.errorHandler(error, 'Alertas Operacionais');
        }));
    }

    private _defineChartData(type, data) {
        this.chartData[type] = [{
            code: data[0].severity,
            value: data[0].quantity,
            name: `${(data[0].percentage).toFixed(0)}% • ${data[0].severityName}`,
            color: '#0ba100',
        }, {
            code: data[1].severity,
            value: data[1].quantity,
            name: `${(data[1].percentage).toFixed(0)}% • ${data[1].severityName}`,
            color: '#d8a600'
        }, {
            code: data[2].severity,
            value: data[2].quantity,
            name: `${(data[2].percentage).toFixed(0)}% • ${data[2].severityName}`,
            color: '#ff0000'
        }]
        if (data.length > 3) {
            this.chartData[type].push({
                code: data[3].severity,
                value: data[3].quantity,
                name: `${(data[3].percentage).toFixed(0)}% • ${data[3].severityName}`,
                color: '#495057',
            })
        }
    }

    private _createChart() {
        const biggestValue =  Math.max(...this.alertsBySeverity.map(alert => alert.quantity));
        const minValue = (biggestValue * 5) / 100;
        const data = this.alertsBySeverity.map((alert) => {
            return { ...alert,
                percentage: alert.percentage > 0 ? alert.percentage.toFixed(1) : 0,
                quantity: alert.quantity < minValue ? minValue : alert.quantity
            }
        })

        const chart = am4core.create('operational-alerts-chart', am4charts.XYChart);
        chart.language.locale['_decimalSeparator'] = ',';
        chart.language.locale['_thousandSeparator'] = '.';
        chart.data = data;
        chart.colors.list = [
            am4core.color("#0ba100"),
            am4core.color("#d8a600"),
            am4core.color("#ff0000"),
            am4core.color("#495057"),
        ];

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "severity";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        categoryAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "quantity";
        series.dataFields.customValue = "percentage";
        series.dataFields.categoryX = "severity";
        series.name = "Visits";

        // series.columns.template.tooltipText = "{categoryX}: [bold]{customValue}[/]";
        series.columns.template.fillOpacity = .8;
        series.columns.template.adapter.add("fill", function(fill, target) {
            return chart.colors.getIndex(target.dataItem.index);
        });
        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 0;

        let bullet = series.bullets.push(new am4charts.LabelBullet);
        bullet.label.text = "{percentage}%";
        bullet.label.fontSize = 12;
        bullet.label.fontWeight = '400';
        bullet.label.dy = -8;
        bullet.label.truncate = false;
        bullet.label.hideOversized = false;

        $("g[filter*='id']").remove();
    }
}
