<div id="recovery-area" *ngIf="initialized">
    <div class="login-container">
        <div class="form-container">
            <div class="header-form">
                <h1>
                    <img [src]="themeLogo" [alt]="themeClient" class="logo" />
                </h1>
            </div>
            <form class="formFields" autocomplete="off" [formGroup]="formRecoveryPassword">
                <span class="pwd-recovery-message">{{ msgPasswordRecovery }}</span>
                <span class="throw-error-message">{{ errorPasswordRecovery }}</span>
                <div class="form-group align-center">
                    <input
                        *ngIf="!sentEmail"
                        #txtEmail
                        class="input-text-email"
                        type="email"
                        placeholder="E-mail"
                        name="email"
                        formControlName="email"
                        autocomplete="off"
                    />
                </div>
                <div class="recaptcha-container align-center" *ngIf="showCaptcha && captchaEnable">
                    <p-captcha #captcha [siteKey]="captchaKey" (onResponse)="validateCaptcha($event)"></p-captcha>
                </div>
                <div class="form-group align-center actions-form">
                    <button *ngIf="!sentEmail" type="submit" [disabled]="loading || !formRecoveryPassword.valid" class="button-submit" (click)="sendEmail()">Enviar</button>
                    <button type="button" class="button-back" [disabled]="loading" (click)="goBack()">Voltar para tela de login</button>
                </div>
            </form>
            <div *ngIf="asyncLogin">
                <div class="loaderModel4" style="position: relative; margin-top: 30px">
                    <div class="cube cube1"></div>
                    <div class="cube cube2"></div>
                    <div class="cube cube3"></div>
                    <div class="cube cube4"></div>
                    <div class="cube cube5"></div>
                    <div class="cube cube6"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-container" [ngStyle]="{'background-position': themeClient === 'bi' ? 'left center' : 'right center'}" [style.background-image]="themeBackground"></div>
</div>
