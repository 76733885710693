import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable()
export class ImportDetailsService {

    private imp;
    private company;
    private details;
    private status;

    constructor(private _http: HttpClient) { }

    getImpNumber() { return this.imp; }
    getCompanyCode() { return this.company; }
    getDetails() { return this.details; }
    getStatus() { return this.status; }

    setImpNumber(imp): void { this.imp = imp; }
    setCompanyCode(company): void { this.company = company; }
    setDetails(details): void { this.details = details; }
    setStatus(status): void { this.status = status; }

    getImportDetails(impNumber, companyCode): Observable<any> {
        return this._http.get(`${environment.endpoints.importationMongoDBService}/imps/${impNumber}/${companyCode}`);
    }
}
