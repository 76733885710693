import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import Inputmask from 'inputmask';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { environment } from '#environment';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { ChartsServices } from './../charts.services';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from "app/auth/_services/user.service";

declare let AmCharts: any;

@Component({
    selector: 'invoices-processes-chart',
    templateUrl: './invoices-processes.component.html',
    styleUrls: ['../charts.scss', './invoices-processes.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class InvoicesProcessesComponent implements AfterViewInit {
    readonly dropdownSettingsClients = {
        singleSelection: true,
        text: 'Cliente',
    };

    options: {
        clients: DropdownOption[];
    } = {
        clients: [],
    };
    currentUser$ = this.userService.currentUser$;
    selectedClients = [];
    currentUser;
    InvoicesProcessesChart: any;
    asyncInvoiceAmountImpQty: boolean = false;
    // Default values for dataset
    invoiceAmountSwitch = true;
    impQtySwitch = true;
    // Default name component
    invoiceAmountName = 'Faturamento x';
    impQtyName = 'Notas Fiscais';
    startMonth: any = moment().format('01/YYYY');
    endMonth: any = moment().format('MM/YYYY');
    filterAutomotiveMode = 'Desligado';

    chartData;

    private automotiveRequest = false;

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private http: HttpClient,
        private userService: UserService,
        private el: ElementRef,
        private permissionBlocker: UserPermissionBlockerService,
        private utilsService: UtilsService,
        private userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
    ) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.updateDateInputs();
        }, 300);
        this.options.clients = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
        this.userService.currentUser$.subscribe((user) => {
            this.currentUser = user;
            this.loadInvoiceAmount_impQty(user);
        });
    }

    verifyAutomotiveMode(user) {
        this.automotiveRequest =
            user.has('AUTOMOTIVE_USER') || (user.has('ROUTE_AUTOMOTIVE') && this.filterAutomotiveMode === 'Ligado')
                ? true
                : false;
    }

    togglePeriodSwitch() {
        this.loadInvoiceAmount_impQty(this.currentUser);
    }

    toggleFilterMode() {
        if ($('invoices-processes-chart .filterMode').hasClass('clicked')) {
            this.filterAutomotiveMode = 'Desligado';
            $('invoices-processes-chart .filterMode').removeClass('clicked');
        } else {
            this.filterAutomotiveMode = 'Ligado';
            $('invoices-processes-chart .filterMode').addClass('clicked');
        }
        this.loadInvoiceAmount_impQty(this.currentUser);
    }

    toggleInvoiceAmountSwitch() {
        this.invoiceAmountSwitch = !this.invoiceAmountSwitch;
        if (this.invoiceAmountSwitch === true) {
            $('#invoices-processes .amcharts-graph-column.amcharts-graph-g1').attr(
                'style',
                'display: block; opacity: 1;'
            );
            $('#invoices-processes .amcharts-value-axis.value-axis-a1').attr('style', 'display: block; opacity: 1;');
            $('#invoices-processes .amcharts-legend-item-g1').attr('style', 'display: block; opacity: 1;');
            $('#InvoiceAmountSwitch').attr('style', 'background-color: #537054;');
            if (this.impQtySwitch === true) {
                this.invoiceAmountName = 'Faturamento x ';
            } else {
                this.invoiceAmountName = 'Faturamento';
            }
        } else {
            this.invoiceAmountName = '';
            $('#invoices-processes .amcharts-graph-column.amcharts-graph-g1').attr(
                'style',
                'display: none; opacity: 0;'
            );
            $('#invoices-processes .amcharts-value-axis.value-axis-a1').attr('style', 'display: none; opacity: 0;');
            $('#invoices-processes .amcharts-legend-item-g1').attr('style', 'display: none; opacity: 0;');
            $('#InvoiceAmountSwitch').attr('style', 'background-color: #6e4545;');
            if (this.impQtySwitch === false) {
                this.toggleImpQtySwitch();
            }
        }
    }

    toggleImpQtySwitch() {
        this.impQtySwitch = !this.impQtySwitch;
        if (this.impQtySwitch === true) {
            $('#invoices-processes .amcharts-graph-line.amcharts-graph-g2').attr(
                'style',
                'display: block; opacity: 1;'
            );
            $('#invoices-processes .amcharts-value-axis.value-axis-a3').attr('style', 'display: block; opacity: 1;');
            $('#invoices-processes .amcharts-legend-item-g2').attr('style', 'display: block; opacity: 1;');
            $('#ImpQtySwitch').attr('style', 'background-color: #537054;');
            if (this.invoiceAmountSwitch === true) {
                this.invoiceAmountName = 'Faturamento x ';
            }
            this.impQtyName = 'Notas Fiscais';
        } else {
            this.impQtyName = '';
            if (this.invoiceAmountSwitch === true) {
                this.invoiceAmountName = 'Faturamento';
            }
            $('#invoices-processes .amcharts-graph-line.amcharts-graph-g2').attr('style', 'display: none; opacity: 0;');
            $('#invoices-processes .amcharts-value-axis.value-axis-a3').attr('style', 'display: none; opacity: 0;');
            $('#invoices-processes .amcharts-legend-item-g2').attr('style', 'display: none; opacity: 0;');
            $('#ImpQtySwitch').attr('style', 'background-color: #6e4545;');
            if (this.invoiceAmountSwitch === true) {
                this.invoiceAmountName = 'Faturamento';
            }
            if (this.invoiceAmountSwitch === false) {
                this.toggleInvoiceAmountSwitch();
            }
        }
    }

    loadInvoiceAmount_impQty(user) {
        this.utilsService.setInvisible('invoices-processes-chart', ['.chart']);
        this.asyncInvoiceAmountImpQty = false;
        this.verifyAutomotiveMode(user);
        if (this.permissionBlocker.permissionValidator(this.el.nativeElement.tagName)) {
            const newStart = moment(this.startMonth, 'MM/YYYY').format('YYYY-MM');
            const newEnd = moment(this.endMonth, 'MM/YYYY').format('YYYY-MM');
            this.getInvoiceAmount_impQty(newStart, newEnd).subscribe(
                (data) => {
                    this.chartData = this.generateLastMonths(data);
                    this.asyncInvoiceAmountImpQty = true;

                    const chart = AmCharts.makeChart('invoices-processes', {
                        type: 'serial',
                        fontSize: 12,
                        language: 'pt',
                        fontFamily: 'Poppins; sans serif',
                        dataDateFormat: 'YYYY-MM',
                        decimalSeparator: '.',
                        thousandsSeparator: '',
                        dataProvider: this.chartData,
                        addClassNames: !0,
                        startDuration: 0,
                        color: '#6c7b88',
                        marginLeft: 0,
                        categoryField: 'date',
                        listeners: [
                            {
                                event: 'clickGraphItem',
                                method: (event) => {
                                    if (this.currentUser.has('ROUTE_SUPPLY_CHAIN_INVOICE_STATUS')) {
                                        const date = event.item.dataContext.date;
                                        const startDate = `${date}-01`;
                                        const current = moment().format('YYYY-MM');
                                        const endDate = moment(date)
                                            .add('1', 'M')
                                            .add('-1', 'days')
                                            .format('YYYY-MM-DD');

                                        const queryParams = {
                                            force: true,
                                        }
                                        this.router.navigate([
                                            `./supply-chain/notas-fiscais/0/0/${startDate}/${endDate}`, { queryParams }
                                        ]);
                                    }
                                },
                            },
                        ],
                        categoryAxis: {
                            parseDates: !0,
                            minPeriod: 'MM',
                            axisColor: '#555555',
                            dateFormats: [
                                {
                                    period: 'DD',
                                    format: 'DD',
                                },
                                {
                                    period: 'WW',
                                    format: 'MMM DD',
                                },
                                {
                                    period: 'MM',
                                    format: 'MMM',
                                },
                                {
                                    period: 'YYYY',
                                    format: 'YYYY',
                                },
                            ],
                        },
                        valueAxes: [
                            {
                                id: 'a1',
                                title: 'Milhões de R$',
                                axisAlpha: 1,
                                minimum: 0,
                                labelFunction: (value) => (value / 1000000).toLocaleString('pt-BR'),
                            },
                            {
                                id: 'a3',
                                title: 'Notas Fiscais',
                                position: 'right',
                                axisAlpha: 0,
                                minimum: 0,
                                gridAlpha: 0,
                                labelFunction: (value) => value.toLocaleString('pt-BR'),
                            },
                        ],
                        graphs: [
                            {
                                id: 'notasfiscais',
                                valueField: 'imps',
                                classNameField: 'bulletClass',
                                title: ' ',
                                type: 'line',
                                valueAxis: 'a3',
                                lineColor: '#786c56',
                                lineThickness: 1,
                                legendValueText: '[[value]]',
                                legendPeriodValueText: '[[value.sum]]',
                                bullet: 'round',
                                bulletBorderColor: '#02617a',
                                bulletBorderAlpha: 1,
                                bulletBorderThickness: 2,
                                bulletColor: '#0091D6',
                                labelPosition: 'right',
                                balloonFunction: this.formatBalloon,
                                showBalloon: !0,
                                animationPlayed: !0,
                            },
                            {
                                id: 'receita',
                                valueField: 'income',
                                title: 'Receita',
                                type: 'column',
                                fillAlphas: 0.7,
                                valueAxis: 'a1',
                                balloonFunction: this.formatBalloon,
                                legendValueText: '[[value]]',
                                legendPeriodValueText: '[[value.sum]]',
                                lineColor: '#082442',
                                alphaField: 'alpha',
                                dashLengthField: 'dashLengthColumn',
                            },
                        ],
                        chartCursor: {
                            zoomable: !1,
                            categoryBalloonDateFormat: 'MMM',
                            cursorAlpha: 0,
                            categoryBalloonColor: '#808083',
                            categoryBalloonAlpha: 1,
                            valueBalloonsEnabled: !1,
                        },
                        legend: {
                            equalWidths: false,
                            position: 'bottom',
                            valueAlign: 'left',
                            valueWidth: 100,
                            valueFunction: this.formatLegendLabel.bind(this),
                            bulletType: 'round',
                            useGraphSettings: !0,
                            color: '#6c7b88',
                        },
                        export: {
                            enabled: true,
                            pageOrigin: false,
                            menu: [
                                {
                                    format: 'PDF',
                                    label: "<i class='flaticon-download'></i>",
                                    title: 'Gerar PDF',
                                    // tslint:disable-next-line:max-line-length
                                    fileName: `Faturamento x Notas Fiscais (${moment(this.endMonth)
                                        .add(-11, 'M')
                                        .format('MM/YYYY')} • ${moment(this.endMonth).format('MM/YYYY')})`,
                                    content: [
                                        // tslint:disable-next-line:max-line-length
                                        `Faturamento x Notas Fiscais (${moment(this.endMonth)
                                            .add(-11, 'M')
                                            .format('MM/YYYY')} • ${moment(this.endMonth).format('MM/YYYY')})`,
                                        {
                                            image: 'reference',
                                            fit: [523.28, 769.89],
                                        },
                                    ],
                                },
                            ],
                        },
                    });

                    chart.addListener('drawn', () => {
                        $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
                    });
                    $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
                    this.utilsService.setVisible('invoices-processes-chart', ['.chart']);
                },
                (error) => {
                    this.chartData = undefined;
                    this.asyncInvoiceAmountImpQty = true;
                    this.utilsService.errorHandler(error, 'Faturamento x NF');
                }
            );
        }
        setTimeout(() => {
            this.updateDateInputs();
        }, 300);
    }

    private formatBalloon(item, graph) {
        const amount = (item.dataContext.income / 1000000).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const imps = item.dataContext.imps.toLocaleString('pt-BR');
        return `
            <span style='font-size:12px;'>
                R$ <span style='font-size:20px;'>${amount}</span> mi
            </span>
            <br>
            <hr class='hrChart'>
            <span style='font-size:14px;'>${imps}</span> NFs`;
    }

    private formatLegendLabel(graphDataItem: any, valueText: string) {
        if (this.isInvoiceGraph(graphDataItem)) {
            const quantity = parseInt(valueText, 10).toLocaleString('pt-BR');

            return graphDataItem.id === 'notasfiscais' ? `Total NFs: ${quantity}` : `${quantity} NFs`;
        } else {
            const amount = (parseFloat(valueText) / 1000000).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            return graphDataItem.id === 'receita' ? `Total: R$ ${amount} mi` : `R$ ${amount} mi`;
        }
    }

    private isInvoiceGraph(graphDataItem: any) {
        return (
            graphDataItem.id === 'notasfiscais' || (graphDataItem.graph && graphDataItem.graph.id === 'notasfiscais')
        );
    }

    private generateLastMonths(data) {
        const dataImpsLastMonths = data.invoiceQty.data.slice(0).slice(-12);
        const dataInvoicesAmountLastMonths = data.invoiceAmount.data.slice(0).slice(-12);
        const chartData = [];
        // generate values
        for (let i = 0; i < dataImpsLastMonths.length; i++) {
            const newImps = dataImpsLastMonths[i].value;
            const newIncome = dataInvoicesAmountLastMonths[i].value;
            const newDate = dataImpsLastMonths[i].referenceMonth;
            // add data item to the array
            chartData.push({
                imps: newImps,
                income: newIncome,
                date: newDate,
            });
        }
        return chartData;
    }

    // Get data for Invoice Amount and Imp Quantity
    private getInvoiceAmount_impQty(start, end): Observable<any> {
        const origin = this.automotiveRequest ? 'CHASSI' : 'IMP';
        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.selectedClients.map((c) => c.id).join(','));
        params = params.set('origin', origin);
        params = params.set('startMonth', `${start}`);
        params = params.set('endMonth', `${end}`);
        return this.http.get(`${environment.endpoints.supplyChainService}/stats/invoiceAmount,invoiceQty`, {
            params,
            headers,
        });
    }

    private verifyDates(datePicker, date) {
        if (date.replace(/\D/g, '').length === 6) {
            if (datePicker === 'startDate') {
                if (moment(date, 'MM/YYYY').isAfter(moment(this.endMonth, 'MM/YYYY'))) {
                    this.endMonth = this.startMonth = date;
                    setTimeout(() => {
                        this.updateDateInputs();
                    }, 300);
                } else {
                    this.startMonth = date;
                    this.updateDateInputs();
                }
            } else {
                if (moment(this.startMonth, 'MM/YYYY').isAfter(moment(date, 'MM/YYYY'))) {
                    this.startMonth = this.endMonth = date;
                    setTimeout(() => {
                        this.updateDateInputs();
                    }, 300);
                } else {
                    this.endMonth = date;
                    this.updateDateInputs();
                }
            }
        }
    }

    private updateDateInputs() {
        if (!moment(this.startMonth, 'MM/YYYY').isValid()) {
            this.startMonth = moment().format('MM/YYYY');
        }
        if (!moment(this.endMonth, 'MM/YYYY').isValid()) {
            this.endMonth = moment().format('MM/YYYY');
        }
        ($('invoices-processes-chart input#startDatePicker') as any).val(this.startMonth).Zebra_DatePicker({
            format: 'm Y',
            onSelect: (selectedDate) => {
                this.verifyDates('startDate', selectedDate);
            },
            onClear: () => {
                this.startMonth = undefined;
                this.endMonth = undefined;
                this.updateDateInputs();
            },
        });
        setTimeout(() => {
            ($('invoices-processes-chart input#endDatePicker') as any).val(this.endMonth).Zebra_DatePicker({
                format: 'm Y',
                direction: [moment(this.startMonth, 'MM/YYYY').format('MM YYYY'), false],
                onSelect: (selectedDate) => {
                    this.endMonth = selectedDate;
                },
            });
        }, 300);
        Inputmask({ mask: '99/9999' }).mask($('invoices-processes-chart input#startDatePicker'));
        Inputmask({ mask: '99/9999' }).mask($('invoices-processes-chart input#endDatePicker'));
    }

}
