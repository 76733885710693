import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

import { ClientsFilter } from './cmx-settings-clients.filter.model';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserService } from "app/auth/_services/user.service";
import { UserCacheService } from '#services/_user/app-user-cache.service';

@Component({
    selector: 'cmx-settings-clients',
    templateUrl: './cmx-settings-clients.component.html',
    styleUrls: ['./cmx-settings-clients.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SettingsClientsComponent {
    @Input('filterState') filterState = new ClientsFilter();
    @Input('singleSelection') singleSelection = false;
    @Output('onSelectClient')
    onSelectClient: EventEmitter<any> = new EventEmitter<any>();
    @Output('onDeSelectClient')
    onDeSelectClient: EventEmitter<any> = new EventEmitter<any>();

    dropdownSettingsClients;

    options: {
        client: DropdownOption[];
    } = {
        client: [],
    };

    currentUser = this.userService.getCurrentUser();
    currentUser$ = this.userService.currentUser$;

    constructor(
        private userService: UserService,
        private userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
    ) {}

    ngAfterContentInit() {
        this.dropdownSettingsClients = {
            singleSelection: this.singleSelection,
            text: 'Cliente',
            badgeShowLabel: 'Clientes selecionados',
        };

        this.userService.currentUser$.subscribe((user) => {
            if (user.has('INTERNAL_FILTERS')) {
                const clientGroups = this.userCacheService.getClientGroups();
                this.options.client = this._cmxDropdownService.toDropdownItems(clientGroups);
                if (clientGroups && clientGroups.length === 1) {
                    this.filterState.setClientGroups(this._cmxDropdownService.toDropdownItems(clientGroups));
                    this.onSelect(clientGroups[0]);
                }
            }
        });
    }

    onSelect(item) {
        this.onSelectClient.emit(item);
    }
    onDeSelect(item) {
        this.onDeSelectClient.emit(item);
    }

}
