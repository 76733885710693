import { FilterStateService } from '#services/_filters/filter-state.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from "app/auth/_services/user.service";
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { SlaConsolidationFilter } from 'app/theme/layouts/charts/sla-consolidation/slaConsolidationFilter.model';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import * as _ from 'lodash';
import moment from 'moment';
import { forkJoin } from 'rxjs';

import { SLA_DETAIL_RESULT_COLUMNS } from './constants/detail-results-columns.constant';
import { SlaConsolidationService } from './sla-consolidation.service';

@Component({
    selector: 'sla-consolidation-widget',
    templateUrl: './sla-consolidation.component.html',
    styleUrls: ['./../charts.scss', './sla-consolidation.component.scss'],
    providers: [SlaConsolidationService],
    encapsulation: ViewEncapsulation.None,
})
export class SlaConsolidationComponent {
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    options: { client: DropdownOption[] } = { client: [] };
    selectedClients = [];
    endDateRestriction = moment().format('DD/MM/YYYY');
    async: boolean = false;

    resultValues = [
        { code: 'BEFORE_TIME', name: 'Antes do Prazo', value: 0, color: '#0ba100' },
        { code: 'ON_TIME', name: 'No Prazo', value: 0, color: '#008eff' },
        { code: 'EXPIRED', name: 'Expirado', value: 0, color: '#ff0000' },
    ]

    resultColumns: IGridColumn[] = SLA_DETAIL_RESULT_COLUMNS;
    resultRows: IGridRow = [];

    public typeSwitch = {
        values: ['Criação', 'Faturamento'],
        index: 0,
    }

    currentUser = this._userService.currentUser$;
    filterState: SlaConsolidationFilter = this._filterStateService.getSlaConsolidationFilter();
    showDetailTable: boolean = false;
    customGridHeight: number;
    errorMessage: string = '';
    helpDescription = this._defineHelpDescription();

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        private _router: Router,
        private _userService: UserService,
        private _userCacheService: UserCacheService,
        private _slaConsolidationService: SlaConsolidationService,
        private _filterStateService: FilterStateService,
        private utilsService: UtilsService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2
    ) {
        if (this.filterState.getReferenceDateType() === 'invoiceDate') {
            this.typeSwitch.index = 1;
        }
        this.options.client = this._toDropdownItems(this._userCacheService.getClientGroups());
    }

    ngAfterViewInit() {
        this.applyFilter();
    }

    datesChanged(dates) {
        if (!dates.invalid) {
            this.filterState.setStartDate(dates.startDate);
            this.filterState.setEndDate(dates.endDate);
        }
    }

    toggleTypeSwitch(values) {
        this.filterState.setReferenceDateType(values.value === 'Criação' ? 'impIssueDate' : 'invoiceDate')
    }

    showDetail() {
        this.showDetailTable = !this.showDetailTable;
        setTimeout(() => {
            this.resultRows = _.cloneDeep(this.resultRows);
        }, 50);
    }

    onRowClick(row) {
        this.filterState.setSelectedKpi(row.KPI);
        this._router.navigate([`./performance/sla-status/result`]);
    }

    getGridHeight(): number {
        return this.customGridHeight > 300 ? 300 : this.customGridHeight;
    }

    applyFilter() {
        this.async = true;
        this.errorMessage = '';
        forkJoin([
            this._slaConsolidationService.getSlaConsolidationResult(this.filterState.getRequest()),
            this._slaConsolidationService.getSlaDetailResult(this.filterState.getRequest()),
        ]).subscribe((values) => {
            const [results, details] = values;
            this._populateConsolidation(results);
            this._populateDetails(details);
            this.async = false;
        }, (error) => {
            this.async = false;
            this.resultRows = [];
            if (error.status === 404) {
                this.errorMessage = error.error.message;
            } else {
                this.errorMessage = 'Não foram encontrados registros'
            }
        });
    }

    private _populateConsolidation(results) {
        this.resultValues[0].value = results.BEFORE_TIME || 0;
        this.resultValues[1].value = results.ON_TIME || 0;
        this.resultValues[2].value = results.EXPIRED || 0;
    }

    private _populateDetails(details) {
        this.resultRows = details;
        this.customGridHeight = 50;
        this.resultRows.forEach(() => { this.customGridHeight += 40 });
    }

    private _toDropdownItems(items: any[]) {
        return items != null ? items.map(this._toDropdownItem) : [];
    }

    private _toDropdownItem(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.name}`);
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text', value: "Informa resumidamente o andamento de todas as etapas dos SLA's acordados com o cliente, sendo elas : Antes do prazo , No Prazo , Expirado."},
        ]
    }
}
