import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { environment } from '#environment';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { UserService } from "app/auth/_services/user.service";
import { ChartsServices } from './../charts.services';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { TransportModalService } from '#services/_transportModal/transportModal.service';
import { UtilsService } from '#services/_utils/utils.service';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { LogisticCostsFilter } from './logistic-costs.filter';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { AmCharts5Service, IChartConfig } from '#services/_charts/charts.service';

@Component({
    selector: 'logistic-costs-chart',
    templateUrl: './logistic-costs.component.html',
    styleUrls: ['./../charts.scss', './logistic-costs.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LogisticCostsComponent {
    filterState: LogisticCostsFilter = this._filterStateService.getLogisticCostsFilter();
    subscription;
    editableElements = ['#logisticCosts', '.checkmark-container'];
    periodType = 'LAST_12_MONTHS';
    asyncLogisticCosts = false;
    chartData;
    costsOptions: DropdownOption[] = [];
    subsidiaryNames: DropdownOption[] = [];
    summaryLine = [];
    filterTypes = [{ name: 'Média' }, { name: 'Total' }, { name: 'Tonelada Líquida' }];
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    readonly dropdownSettingsCosts = {
        text: 'Custo Operacional',
        maxHeight: 200,
        badgeShowLabel: 'Custos logísticos selecionados',
    };

    clientOptions: DropdownOption[] = [];
    modalOptions: DropdownOption[] = [];
    currentUser$ = this.userService.currentUser$;
    currentUser = this.userService.getCurrentUser();
    isInternalUser = this.currentUser.has('INTERNAL_FILTERS') ? true : false;
    isMixedUser = this.currentUser.has('ROUTE_AUTOMOTIVE') && !this.currentUser.has('AUTOMOTIVE_USER') ? true : false;
    manualSearch = this.isInternalUser;
    public typeSwitch = {
        values: ['Média', 'Total'],
        index: 0,
    };
    public visualSwitch = {
        values: ['Colunas', 'Linhas'],
        index: 0,
    };

    periodTypes = [
        {
            code: 'CURRENT_YEAR',
            name: 'Ano Corrente',
        },
        {
            code: 'LAST_12_MONTHS',
            name: '12 Meses',
        },
    ];

    preSelectedPeriod = ['CURRENT_YEAR'];
    selectedPeriod = _.cloneDeep(this.preSelectedPeriod);
    helpDescription = this._defineHelpDescription();

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private userService: UserService,
        private http: HttpClient,
        private utilsService: UtilsService,
        private userCacheService: UserCacheService,
        private transportModalService: TransportModalService,
        private _cmxDropdownService: CmxDropdownService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _filterStateService: FilterStateService,
        private _AmCharts5Service: AmCharts5Service,
        private _utilsService: UtilsService
    ) {}

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.subsidiaryNames = this._cmxDropdownService.toDropdownItems(this.userCacheService.getSubsidiaries());
        this.clientOptions = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
        this.modalOptions = this._cmxDropdownService.toDropdownItemsImg(
            _.cloneDeep(this.transportModalService.getModalList())
        );
        this.loadOperationalCosts().subscribe((data) => {
            this.costsOptions = this._cmxDropdownService.toDropdownItems(data);
        });
        if (this.clientOptions.length === 1) {
            this.loadLogisticCosts('LAST_12_MONTHS');
        }
    }

    public toggleFilterMode() {
        if ($('logistic-costs-chart .filterMode').hasClass('clicked')) {
            this.filterState.setAutomotiveMode('Desligado');
            $('logistic-costs-chart .filterMode').removeClass('clicked');
        } else {
            this.filterState.setAutomotiveMode('Ligado');
            $('logistic-costs-chart .filterMode').addClass('clicked');
        }
        this.loadLogisticCosts(this.periodType);
    }

    toggleTypeSwitch(value) {
        this.filterState.setType(value?.name);
        this.loadLogisticCosts(this.periodType);
    }

    toggleVisualSwitch(values) {
        this.filterState.setVisual(values.value);
        this.loadLogisticCosts(this.periodType);
    }

    toggleStorage() {
        if ($('logistic-costs-chart .storageToogle').hasClass('clicked')) {
            $('logistic-costs-chart .storageToogle').removeClass('clicked');
            this.filterState.setStorageToogle('Desconsiderando');
        } else {
            $('logistic-costs-chart .storageToogle').addClass('clicked');
            this.filterState.setStorageToogle('Considerando');
        }
        this.loadLogisticCosts(this.periodType);
    }

    changeDateParam({ selected }): void {
        let periodCod = selected[0].code;

        if (periodCod === 'CURRENT_YEAR' || periodCod === 'LAST_12_MONTHS') {
            this.periodType = periodCod;
            this.loadLogisticCosts(periodCod);
        } else {
            this.periodType = '';
            this.filterState.setStartDate(moment().add(-periodCod, 'days').format('DD/MM/YYYY'));
            this.filterState.setEndDate(moment().format('DD/MM/YYYY'));
            this.loadLogisticCosts();
        }
    }

    loadLogisticCosts(preset?) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.filterState.getClientGroups().length === 0 && this.isInternalUser) {
            this.asyncLogisticCosts = false;
            this.manualSearch = true;
        } else {
            this.manualSearch = false;
            this.asyncLogisticCosts = true;
            this.utilsService.setInvisible('logistic-costs-chart', this.editableElements);
            this.subscription = this.getLogisticCosts(preset).subscribe(
                (logisticCosts) => {
                    this.chartData = logisticCosts;
                    logisticCosts.consolidatedCosts.forEach((cost) => {
                        cost.value = cost.value.toFixed(2);
                    });
                    this._loadChart(logisticCosts.consolidatedCosts);
                    this._populateSummaryLine(logisticCosts.consolidatedCosts);
                    this.asyncLogisticCosts = false;
                    this.utilsService.setVisible('logistic-costs-chart', this.editableElements);
                },
                (error) => {
                    this.chartData = undefined;
                    this.asyncLogisticCosts = false;
                    this.utilsService.errorHandler(error, 'Custos Logísticos');
                }
            );
        }
    }

    private _populateSummaryLine(costs) {
        const total = costs.reduce(
            (acc, p) => acc + parseFloat(this.filterState.getType() === 'Média' ? p.value : p.totalValue),
            0
        );
        this.summaryLine = [
            {
                title: 'Valor Total',
                value: this._utilsService.formatNumber(total || 0, { style: 'currency' }) || 0,
            },
        ];
    }

    private loadOperationalCosts() {
        return this.http.get(`${environment.endpoints.expensesService}/costs/operational`);
    }

    private getLogisticCosts(preset?): Observable<any> {
        const request = this.filterState.getRequest(this.costsOptions, preset);
        return this.http.get(`${environment.endpoints.expensesService}/importationExpenses/logistic-cost`, request);
    }

    private _loadChart(dataChart) {

        const chartType = this.filterState.getVisual() === 'Colunas' ? 'XYChart' : 'DraggableRange';
    
        const data = dataChart.map((item) => ({
            date: item.date, // Formato "MM/yy"
            value: parseFloat(item.value),
        }));
    
    
        if (this.filterState.getVisual() === 'Colunas') {
            const chartConfig: IChartConfig = {
                chartId: 'logisticCosts',
                chartType: chartType,
                padding: { top: 20, bottom: 20, left: 0, right: 20 },
                axis: [
                    {
                        axisPosition: 'x',
                        categoryField: 'date',
                    },
                    {
                        axisPosition: 'y',
                        categoryField: 'value',
                    },
                ],
                axisConfig: {
                    y: { extraMax: 0.2 }, 
                },
                axisTitle: {
                    y: 'Custos em R$ milhares',
                },
                content: [
                    {
                        name: 'Custos Logísticos',
                        valueYField: 'value',
                        valueXField: 'date',
                    },
                ],
                showLegend: false,
                showTooltip: false,
                showValuesAboveBars: true,
                labelConfig: {
                    fontSize: 12,
                    fontWeight: 'bold',
                },
            };
            this._AmCharts5Service.createChart(chartConfig, { data });

        } else if (this.filterState.getVisual() === 'Linhas') {
            const chartConfig: IChartConfig = {
                chartId: 'logisticCosts',
                chartType: 'LineChart',
                dataType: 'Média',
                axis: [
                    {
                        axisPosition: 'x',
                        categoryField: 'date',
                        type: 'category',
                    },
                    {
                        axisPosition: 'y',
                        categoryField: 'value',
                    },
                ],
                axisConfig: {
                    y: { extraMax: 0.05 }, 
                },
                axisTitle: {
                    y: 'Custos em R$ milhares',
                },
                content: [
                    {
                        name: 'Custos Logísticos',
                        valueYField: 'value',
                        valueXField: 'date',
                    },
                ],
                showLegend: false,
                showTooltip: true,
            };
    
            this._AmCharts5Service.createChart(chartConfig, { data });
        }
    }

    private _defineHelpDescription(): object[] {
        return [
            {
                type: 'text',
                value: 'Informa a quantidade total ou média de custos logísticos dos últimos 12 meses ou ano corrente.',
            },
        ];
    }
}
