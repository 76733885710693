<div *ngIf="asyncExport" class="fullLoader"></div>
<div *ngIf="!asyncSlaTable"><loader></loader></div>

<div class="slaStatusContent" *ngIf="filterState && currentUser">
    <div class="checkmark-container">
        <span id="ON_TIME" (click)="toogleStatus($event)" class="checkmark" [ngClass]="{'checked': filterState.getToggleStatus().includes('ON_TIME') }"><div class="ball info ON_TIME"></div>No Prazo</span>
        <span id="IN_PROGRESS" (click)="toogleStatus($event)" class="checkmark" [ngClass]="{'checked': filterState.getToggleStatus().includes('IN_PROGRESS') }"><div class="ball info IN_PROGRESS"></div>Em Andamento</span>
        <span id="TO_EXPIRE" (click)="toogleStatus($event)" class="checkmark" [ngClass]="{'checked': filterState.getToggleStatus().includes('TO_EXPIRE') }"><div class="ball info TO_EXPIRE"></div>A Expirar</span>
        <span id="EXPIRED" (click)="toogleStatus($event)" class="checkmark" [ngClass]="{'checked': filterState.getToggleStatus().includes('EXPIRED') }"><div class="ball info EXPIRED"></div>Expirado</span>
        <div class="d-flex" *ngIf="filterState.getToggleStatus().length === 1 && filterState.getToggleStatus()[0] === 'EXPIRED' && currentUser.has('INTERNAL_FILTERS')">
            <span id="UNJUSTIFIED" (click)="toogleJustify($event)" class="checkmark justification" [ngClass]="{'checked': filterState.getToogleJustify().includes('UNJUSTIFIED') }">
                <em class="fa fa-close unjustified"></em>Não Justificados •<span class="number">{{ countUnjustified }}</span>
            </span>
            <span id="PREJUSTIFIED" (click)="toogleJustify($event)" class="checkmark justification" [ngClass]="{'checked': filterState.getToogleJustify().includes('PREJUSTIFIED') }">
                <em class="fa fa-clock-o prejustified"></em>Pré Justificados • <span class="number">{{ countPreJustified }}</span>
            </span>
            <span id="JUSTIFIED" (click)="toogleJustify($event)" class="checkmark justification" [ngClass]="{'checked': filterState.getToogleJustify().includes('JUSTIFIED') }">
                <em class="fa fa-check justified"></em>Justificados • <span class="number">{{ countJustified }}</span>
            </span>
        </div>
    </div>

    <h5 id="quickTitle">
        <span id="quick">Quick</span> SLA Status
            <div
                *ngIf="currentUser.has('ROUTE_AUTOMOTIVE') && currentUser.has('INTERNAL_FILTERS')"
                class="filterMode" [ngClass]="{'clicked': filterState.getAutomotiveMode() === 'Ligado' }"
                title="Modo Automotivo: {{ filterState.getAutomotiveMode() }}"
            >
                <div><i (click)="toggleFilterMode()" class="material-icons">directions_car</i></div>
            </div>
    </h5>

    <div id="searchLine" class="reduzed">
        <div class="search-container">
            <div class="searchOptions hidden" style="flex-wrap: wrap; z-index: 2; width: calc(100% - 10px)">
                <div (click)="selectSearchType($event, 'IMP')" class="searchItem" style="border-right: 1px solid #eaeaea"
                    [ngClass]="{'clicked': filterState.getTypeSearch() === 'IMP' }">IMP
                </div>
                <div (click)="selectSearchType($event, 'Ref')" class="searchItem"
                    [ngClass]="{'clicked': filterState.getTypeSearch() === 'Ref' }">REF
                </div>
                <div *ngIf="currentUser.has('AUTOMOTIVE_USER') || (currentUser.has('ROUTE_AUTOMOTIVE') && filterState.getAutomotiveMode() == 'Ligado')">
                    <div (click)="selectSearchType($event, 'Chassi')" class="searchItem full" [ngClass]="{'clicked': filterState.getTypeSearch() === 'Chassi' }" >Chassi</div>
                </div>
            </div>
            <div class="searchInput">
                <input
                    id="impSearch"
                    class="search"
                    type="text"
                    name="imp"
                    [(placeholder)]="placeholderSearch"
                    style="z-index: 4"
                    [(ngModel)]="slaSearch"
                />
                <button (click)="changeSearch()" class="applyImpStatus dropdownSearch" style="z-index: 3">
                    <i style="pointer-events: none" class="fa fa-angle-down"></i>
                </button>
            </div>
        </div>
        <cmx-dropdown
            [styles]="{ width: '130px' }"
            id="modal"
            name="modal"
            [data]="options.modal"
            [ngModel]="filterState.getModals()"
            (ngModelChange)="filterState.setModals($event)"
            [settings]="dropdownSettings.modals"
        ></cmx-dropdown>
        <span class="input-group-addon first" style="margin-right: -25px"></span>
        <cmx-dropdown
            [styles]="{ width: '130px' }"
            *ngIf="currentUser.has('INTERNAL_FILTERS')"
            id="clients"
            name="clients"
            [data]="options.client"
            [ngModel]='filterState.getClientGroups()'
            (ngModelChange)="filterState.setClientGroups($event)"
            [settings]="dropdownSettings.clientGroups"
        ></cmx-dropdown>
        <div style="margin-left: 5px"></div>
        <cmx-dropdown
            [styles]="{ width: '130px' }"
            name="kpis"
            [data]="options.kpi"
            [ngModel]='filterState.getSelectedKpi()'
            (ngModelChange)="filterState.setSelectedKpi($event)"
            [settings]="dropdownSettings.kpis"
        ></cmx-dropdown>
        <span class="input-group-addon first">Por</span>
        <div (click)="toggleTypeSwitch($event)" class="toogleContainer">
            <div class="toogleContent">
                <label class="toogleLeft" [ngClass]="{'clicked': filterState.getTypeSearch() === 'getTypeSwitch' }" >Criação</label>
                <span class="middleHandler"></span>
                <label class="toogleRight">Faturamento</label>
            </div>
        </div>
        <div class="input-group">
            <span class="input-group-addon">De</span>
            <input
                (keyup)="verifyDates('startDate', startDate)"
                [(ngModel)]="startDate"
                name="startDatePicker"
                id="startDatePicker"
                type="text"
                class="form-control m-input date-impStatusTable"
                style="width: 125px"
            />
        </div>
        <div class="input-group">
            <span class="input-group-addon">Até</span>
            <input
                (keyup)="verifyDates('endDate', endDate)"
                [(ngModel)]="endDate"
                name="endDatePicker"
                id="endDatePicker"
                type="text"
                class="form-control m-input date-impStatusTable"
                style="width: 125px"
            />
        </div>
        <div *ngIf="showAutoBtn" class="input-group right" title="{{ autoTitle }}" style="width: fit-content !important">
            <span (click)="switchAutoGrid()" class="input-group-addon"><i class="comexport-exchange checked"></i></span>
            <input
                disabled
                [(ngModel)]="countdown.dateTimer"
                type="text"
                class="form-control m-input date-reportTable"
                style="max-width: 90px"
            />
        </div>
        <button (click)="applyFilter()" class="applyImpStatus applyFilter">
            <i class="fa fa-check"></i>
        </button>
    </div>

    <div class="download-container">
        <div class="content left" title="Download" (click)="export('xls')">
            <em class="flaticon-download"></em>
        </div>
        <div class="content right" title="Datamart" (click)="openDatamartModal()">
            <em class="fa fa-database"></em>
        </div>
    </div>

    <div *ngIf="!noContent" [id]="componentId" class="ImpSlaGrid"></div>
    <div *ngIf="noContent" class="noMsg">
        <span>Não foram encontrados registros para essa Busca / Filtro</span>
    </div>
    <div *ngIf="pages.length > 1">
        <pagination [numberOfPages]="pages.length" [queryId]="queryId" (onPageChange)="changePage($event)"></pagination>
    </div>

    <!-- SlaModal -->
    <div class="slaModal hidden">
        <div class="modalContent">
            <i (click)="closeSlaModal()" class="close la la-close"></i>
            <div class="modalTitle">
                <b>IMP <span (click)="toImpDetail()" class="ImpValue" style="margin-left: 5px">{{ selectedImp }}</span></b>
            </div>
            <hr />
            <p class="clientName">{{ selectedClient }}</p>
            <div class="table">
                <table>
                    <tr *ngFor="let sla of selectedHistoric; let i = index; trackBy: trackByFn">
                        <td id="{{ sla.columnName }}" (click)="selectFromHistoric($event, i)">
                            <div class="imgContent">
                                <img [src]="sla.dateParam1" />
                                <img class="arrow" src="assets/img/icons/kpis/arrow.png" />
                                <img [src]="sla.dateParam2" />
                            </div>
                            <div>
                                <span *ngIf="sla.status !== 'UNKNOWN'" class="ball {{ sla.status }}">{{
                                    sla.accomplished
                                }}</span>
                                <span *ngIf="sla.status === 'UNKNOWN'" class="ball {{ sla.status }}">?</span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="slaDetail {{ selectedSla.status }}">
                <div class="detailTitle">
                    <span style="font-size: 12px">{{ selectedSla.kpiName }}</span>
                </div>
                <div class="detailContent">
                    <div class="detailField">
                        <span *ngIf="selectedSla.dateValue1 === null" style="font-weight: 600">-</span>
                        <span *ngIf="selectedSla.dateValue1 !== null" style="font-weight: 600">{{
                            moment(selectedSla.dateValue1).format('DD/MM/YYYY')
                        }}</span>
                    </div>
                    <div class="detailField">
                        <span *ngIf="selectedSla.dateValue2 === null" style="font-weight: 600">-</span>
                        <span *ngIf="selectedSla.dateValue2 !== null" style="font-weight: 600">{{
                            moment(selectedSla.dateValue2).format('DD/MM/YYYY')
                        }}</span>
                    </div>
                    <div class="detailField">
                        <span class="fieldTitle">Realizado</span>
                        <span *ngIf="selectedSla.status !== 'UNKNOWN'" class="ball {{ selectedSla.status }}">{{
                            selectedSla.accomplished
                        }}</span>
                        <span *ngIf="selectedSla.status === 'UNKNOWN'" class="ball {{ selectedSla.status }}">?</span>
                    </div>
                    <div class="detailField">
                        <span class="fieldTitle">Esperado</span>
                        <span class="ball" style="background-color: grey">{{ selectedSla.expected }}</span>
                    </div>
                </div>
                <div (click)="openReasonModal()" *ngIf="selectedSla.status === 'EXPIRED'" class="justify">
                    Justificativa de Atraso
                </div>
            </div>
        </div>
    </div>

    <!-- ReasonModal -->
    <div class="reasonArea hidden">
        <div class="reasonModal">
            <div *ngIf="asyncJustification === false">
                <loader></loader>
            </div>
            <i *ngIf="asyncJustification === true" (click)="closeReasonModal()" class="close la la-close"></i>
            <div class="reasonTitle">
                <h5>Justificativa de Atraso</h5>
                <span (click)="toImpDetail()" class="ImpValue">{{ selectedImp }}</span>
            </div>
            <div class="kpiArea">
                <img [src]="selectedSla.dateParam1" />
                <span>{{ selectedSla.kpiName }}</span>
                <img [src]="selectedSla.dateParam2" />
            </div>

            <div *ngIf="verifyJustification(); else disabledJustification" class="enabledContent my-4">
                <div class="input-group">
                    <cmx-dropdown
                        size="md"
                        id="categories"
                        name="categories"
                        [data]="options.category"
                        [(ngModel)]="options.selectedCategory"
                        [settings]="dropdownSettingsCategories"
                    >
                    </cmx-dropdown>
                </div>
                <div class="p-field textJustification">
                    <textarea
                        pInputTextarea
                        id="sla-status-textarea-justification"
                        [(ngModel)]="selectedJustification"
                        maxlength="1000"
                        required
                    ></textarea>
                    <span class="p-help-text">{{ 1000 - selectedJustification.length }} caracteres restantes</span>
                </div>
            </div>
            <ng-template #disabledJustification>
                <div class="disabledContent my-4">
                    <div class="hasJustification" *ngIf="selectedSla.justification !== null">
                        <div class="input-group">
                            <cmx-dropdown
                                disabled
                                size="md"
                                id="categories"
                                name="categories"
                                [data]="options.category"
                                [(ngModel)]="options.selectedCategory"
                                [settings]="dropdownSettingsCategories"
                            ></cmx-dropdown>
                        </div>
                        <div class="p-field textJustification">
                            <textarea
                                disabled
                                pInputTextarea
                                id="sla-status-textarea-justification"
                                [(ngModel)]="selectedSla.justification.comment"
                            ></textarea>
                        </div>
                    </div>
                    <div class="noJustification" *ngIf="selectedSla.justification === null">
                        <span>Nenhuma justificativa foi adicionada até o momento</span>
                    </div>
                </div>
            </ng-template>
            <div class="footerModal mb-1" *ngIf="asyncJustification === true">
                <div class="left"> </div>
                <div class="middle">
                    <div
                        class="dateJustification"
                        *ngIf="selectedSla.justification?.approvalDate && currentUser.has('INTERNAL_FILTERS')"
                    >
                        Aprovada {{ selectedSla.justification.approvalDate | date: 'dd/MM/yyyy' }}
                    </div>
                </div>
                <div class="right actionButtons">
                    <div class="ignoreResult" *ngIf="verifyIgnoreResult()">
                        <input type="checkbox" [(ngModel)]="hasApproval" />
                        <span (click)="clickCheckbox()">Ignorar Resultado</span>
                    </div>
                    <button
                        *ngIf="
                            newReason === true &&
                            currentUser.has('SLA_KPI_JUSTIFICATION') &&
                            selectedSla.justificationStatus === 'UNJUSTIFIED'
                        "
                        (click)="sendJustification('add')"
                        class="saveBtn"
                    >
                        Justificar
                    </button>
                    <button
                        *ngIf="
                        currentUser.has('SLA_KPI_APPROVE_JUSTIFICATION') && selectedSla.justificationStatus !== 'UNJUSTIFIED'
                        "
                        (click)="sendJustification('approve')"
                        class="approveBtn"
                    >
                        Aprovar
                    </button>
                    <button
                        *ngIf="
                            currentUser.has('SLA_KPI_EDIT_JUSTIFICATION') &&
                            !currentUser.has('SLA_KPI_APPROVE_JUSTIFICATION') &&
                            selectedSla.justificationStatus === 'PREJUSTIFIED'
                        "
                        (click)="sendJustification('edit')"
                        class="editButton"
                    >
                        Editar
                    </button>
                </div>
            </div>

            <div class="actionMessages">
                <p class="success created hidden"><i (click)="hideMsg()" class="la la-close"></i>SLA Justificada.</p>
                <p class="success approved hidden"
                    ><i (click)="hideMsg()" class="la la-close"></i>Justificativa aprovada.</p
                >
                <p class="success edited hidden"><i (click)="hideMsg()" class="la la-close"></i>Justificativa alterada.</p>
                <p class="error kpi hidden"><i (click)="hideMsg()" class="la la-close"></i>Selecione uma categoria.</p>
                <p class="error reason hidden"><i (click)="hideMsg()" class="la la-close"></i>Adicione um motivo.</p>
            </div>
        </div>
    </div>

    <!-- RefModal -->
    <div class="RefModal hidden">
        <div class="fixedHeader">
            <h5>Referências</h5>
            <hr />
        </div>
        <div class="modalContent">
            <i (click)="closeRefs()" class="close la la-close"></i>
            <table>
                <tr *ngFor="let reference of references; let i = index; trackBy: trackByFn">
                    <td>{{ reference }}</td>
                </tr>
            </table>
        </div>
    </div>

    <!-- Datamart -->
    <div class="DatamartModal">
        <cmx-datamart-preferences
            *ngIf="datamartFilters.length"
            serviceId="sla-kpi-service"
            datamartName="SLA"
            generatorId="slas-status-detail"
            modalTitle="Data Mart - SLA Status"
            hideEmail="false"
            hideGuide="true"
            [filters]="datamartFilters"
            [filterState]="filterState"
        ></cmx-datamart-preferences>
    </div>
</div>
