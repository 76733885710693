import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'cmx-filter-frozen-column',
    templateUrl: './cmx-filter-frozen-columns.component.html',
    styleUrls: ['./cmx-filter-frozen-columns.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FilterFrozenColumnComponent {

    @Input() quantity: number = 0;
    @Output('onChangeNumber') onChangeNumber = new EventEmitter();

    changeQuantity(number: number) {
        const newValue = this.quantity + number;
        if (newValue < 7 && newValue > -1) {
            this.quantity = newValue;
            this.onChangeNumber.emit(this.quantity);
        }
    }

}