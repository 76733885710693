import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export function clusteredBarChart(root, chartConfig, data) {
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            paddingLeft: 20,
            layout: root.verticalLayout,
        })
    );

    if (chartConfig.title) {
        chart.children.unshift(
            am5.Label.new(root, {
                text: chartConfig.title.text,
                fontSize: chartConfig.title.size || 14,
                fontWeight: 'bold',
                x: am5.p50,
                centerX: am5.p50,
                paddingBottom: 10,
                paddingTop: 10,
            })
        );
    }

    const yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: chartConfig.axis.find((a) => a.axisPosition === 'y').categoryField,
            renderer: am5xy.AxisRendererY.new(root, {
                inversed: true,
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                minGridDistance: 10,
            }),
        })
    );

    yAxis.get('renderer').labels.template.setAll({
        fontSize: 11,
    });

    yAxis.data.setAll(data);

    if (chartConfig.axisTitle?.y) {
        chart.plotContainer.children.unshift(
            am5.Label.new(root, {
                text: chartConfig.axisTitle.y,
                fontSize: 12,
                rotation: -90,
                y: am5.p50,
                centerY: am5.p50,
                x: -40,
                paddingRight: 10,
                fontWeight: 'bold',
            })
        );
    }

    const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
                minGridDistance: 50,
            }),
            min: 0,
        })
    );

    xAxis.get('renderer').labels.template.setAll({
        fontSize: 11,
    });

    if (chartConfig.axisTitle?.x) {
        chart.children.push(
            am5.Label.new(root, {
                text: chartConfig.axisTitle.x,
                fontSize: 12,
                x: am5.p50,
                centerX: am5.p50,
                y: am5.percent(95),
                paddingTop: 10,
                fontWeight: 'bold',
            })
        );
    }

    chartConfig.content.forEach((seriesConfig) => {
        const series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: seriesConfig.name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: seriesConfig.valueXField,
                categoryYField: seriesConfig.valueYField,
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: 'horizontal',
                    labelText: seriesConfig.tooltipMessage,
                }),
            })
        );

        series.columns.template.setAll({
            height: am5.p100,
            strokeOpacity: 0,
        });

        series.data.setAll(data);
    });

    if (chartConfig.showLegend) {
        const legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50,
            })
        );
        legend.data.setAll(chart.series.values);
    }

    chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
            behavior: 'none',
        })
    );

    chart.appear(1000, 100);

    return chart;
}
