import { UserService } from "app/auth/_services/user.service";
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IInvoiceReceivableFilter } from './interface/invoices-receivable.interface';

export class InvoiceReceivableFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _userService = AppModule.injector.get(UserService);

    private _initialInvoiceReceivableFilter: IInvoiceReceivableFilter = <IInvoiceReceivableFilter>{};
    private _invoiceReceivableFilter: IInvoiceReceivableFilter = <IInvoiceReceivableFilter>{

        type: 'Parcela',
        clientGroups: [],
        companies: [],
        profitCenters: [],
        intraCompanies: true,
        isAutomotive: this._userService.getCurrentUser().has('ROUTE_AUTOMOTIVE') || this._userService.getCurrentUser().has('AUTOMOTIVE_USER'),
        sortField: 'installmentDueDate',
        sort: 'asc',
        dates: [],
        status: [],
        subsidiaryNames: [],
        size: 50,

    };

    private startInvoiceDate: string;
    private endInvoiceDate: string;
    private startDueDate: string;
    private endDueDate: string;
    private page: number;
    private filterField: string;
    private filterValue: string;

    constructor(_userService: UserService, filter?: InvoiceReceivableFilter) {

        this._invoiceReceivableFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'invoiceReceivableFilter', this._invoiceReceivableFilter, filter);
        this._initialInvoiceReceivableFilter = _.cloneDeep(this._invoiceReceivableFilter);

        this.startInvoiceDate = filter ? filter.startInvoiceDate : null;
        this.endInvoiceDate = filter ? filter.endInvoiceDate : null;
        this.startDueDate = filter ? filter.startDueDate : null;
        this.endDueDate = filter ? filter.endDueDate : null;
        this.page = filter ? filter.page : 0;
    }

    getType(): string { return this._invoiceReceivableFilter.type }
    getClientGroupCodes(): string[] { return this._invoiceReceivableFilter.clientGroups }
    getCompanies(): string[] { return this._invoiceReceivableFilter.companies }
    getProfitCenters(): string[] { return this._invoiceReceivableFilter.profitCenters }
    getIntraCompanies(): boolean { return this._invoiceReceivableFilter.intraCompanies }
    getIsAutomotive(): boolean { return this._invoiceReceivableFilter.isAutomotive }
    getPage(): number { return this.page }
    getSize(): number { return this._invoiceReceivableFilter.size }
    getSortField(): string { return this._invoiceReceivableFilter.sortField }
    getSort(): string { return this._invoiceReceivableFilter.sort }
    getStartInvoiceDate(): string { return this.startInvoiceDate }
    getEndInvoiceDate(): string { return this.endInvoiceDate }
    getStartDueDate(): string { return this.startDueDate }
    getEndDueDate(): string { return this.endDueDate }
    getStatus(): any[] { return this._invoiceReceivableFilter.status }
    getFilterField() { return this.filterField; }
    getFilterValue() { return this.filterValue; }
    getSubsidiaryNames() { return this._invoiceReceivableFilter.subsidiaryNames; }

    setType(type): void { this._invoiceReceivableFilter.type = type; }
    setClientGroupCodes(clientGroups): void { this._invoiceReceivableFilter.clientGroups = clientGroups }
    setCompanies(companies): void { this._invoiceReceivableFilter.companies = companies }
    setProfitCenters(profitCenters): void { this._invoiceReceivableFilter.profitCenters = profitCenters }
    setIntraCompanies(intraCompanies): void { this._invoiceReceivableFilter.intraCompanies = intraCompanies }
    setIsAutomotive(isAutomotive): void { this._invoiceReceivableFilter.isAutomotive = isAutomotive }
    setPage(page): void { this.page = page }
    setSize(size): void { this._invoiceReceivableFilter.size = size }
    setSortField(sortField): void { this._invoiceReceivableFilter.sortField = sortField }
    setSort(sort): void { this._invoiceReceivableFilter.sort = sort }
    setStartInvoiceDate(startInvoiceDate): void { this.startInvoiceDate = startInvoiceDate }
    setEndInvoiceDate(endInvoiceDate): void { this.endInvoiceDate = endInvoiceDate }
    setStartDueDate(startDueDate): void { this.startDueDate = startDueDate }
    setEndDueDate(endDueDate): void { this.endDueDate = endDueDate }
    setStatus(status): void { this._invoiceReceivableFilter.status = status }
    setFilterField(filterField): void { this.filterField = filterField }
    setFilterValue(filterValue): void { this.filterValue = filterValue }
    setSubsidiaryNames(subsidiaryNames): void { this._invoiceReceivableFilter.subsidiaryNames = subsidiaryNames; }

    spliceStatusField(index): void { this._invoiceReceivableFilter.status.splice(index, 1); }

    getRequest(): { headers: { [key: string]: any }; params: { [key: string]: any } } {
        this._initialInvoiceReceivableFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialInvoiceReceivableFilter, this._invoiceReceivableFilter, 'invoiceReceivableFilter', this._userCacheService.getUserPreferences().id);
        const request = {
            headers: {
                clientGroupCodes: this.getClientGroupCodes().length === 0 ? '' : this.getClientGroupCodes().map((c) => c['id']).join(','),
            },
            params: {
                companies: this.getCompanies().map((c) => c['id']).join(','),
                profitCenters: this.getProfitCenters().map((c) => c['id']).join(','),
                subsidiaryNames: this.getSubsidiaryNames().map((c) => c['itemName']).join(','),
                intraCompanies: this.getIntraCompanies().toString(),
                isAutomotive: this.getIsAutomotive().toString(),
                page: this.getPage(),
                size: this.getSize(),
                invoiceStatus: this.getType() === 'Nota Fiscal' ? this.getStatus().map((c) => this._formatStatusParam(c.code)).join(',') : '',
                installmentStatus: this.getType() === 'Parcela' ? this.getStatus().map((c) => this._formatStatusParam(c.code)).join(',') : '',
            },
        };
        if (this.getStartInvoiceDate())
            request.params['startInvoiceDate'] = moment(this.getStartInvoiceDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (this.getEndInvoiceDate())
            request.params['endInvoiceDate'] = moment(this.getEndInvoiceDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (this.getStartDueDate())
            request.params['startDueDate'] = moment(this.getStartDueDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (this.getEndDueDate())
            request.params['endDueDate'] = moment(this.getEndDueDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (this.getSortField() && this.getSort()) {
            request.params['sort'] = `${this._getDuoDateSort(this.getSortField())},${this.getSort()}`;
        }
        if (this.getFilterValue()) {
            request.params[`${this._formatFilterField(this.getFilterField())}`] = `${this.getFilterValue()}`;
        }
        return request;
    }

    private _getDuoDateSort(value) {
        return value === 'installmentDueDateTable' ? 'installmentDueDate' : value;
    }

    private _formatStatusParam(status) {
        return status.replace('INVOICES_RECEIVABLE_', '').replace('_', ' ');
    }

    private _formatFilterField(field) {
        if (field === 'imps') return 'importationNumber';
        if (field === 'chassis') return 'chassisNumber';
        return field;
    }

}
