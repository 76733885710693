import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'lodash';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { debounceTime, map, share, switchMap, tap } from 'rxjs/operators';

import { FollowUpReportSlickGrid } from './report-followup.slickGrid';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';

import { UserService } from "app/auth/_services/user.service";
import { FilterStateService } from '#services/_filters/filter-state.service';
import { FollowUpModelService } from './report-followup-model/report-followup-model.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { DownloadService } from '#services/_download/download.service';
import { DomainTypeService } from '#services/_domain/domainType.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { DomainService } from '../../../../services/_domain/domain.service';
import { TransportModalService } from '#services/_transportModal/transportModal.service';
import { ReportPreferencesService } from '#services/_report-preferences/report-preferences.service';
import { forkJoin } from 'rxjs'
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { IPageWrapperTitle } from 'app/shared/components/cmx-page-wrapper/page-wrapper-interface';
import { ITopButton } from 'app/shared/components/cmx-page-wrapper/interfaces/top-button.interface';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { DINAMIC_ATRIBUTE_VALUES } from '../../costs/detailed/constants/dinamic-data-values.constants';
import { FOLLOWUP_MODAL } from './report-followup-unified-modal.constant';
import { ImportDetailsService } from '../../import-details/import-details.service';
import { FollowUpFilter } from './report-followup-filter/followUpFilter.model';
import { FollowUpReportImpFormatter } from './formatters/report-followup.formatter.imp';
import { FollowUpReportChassiFormatter } from './formatters/report-followup.formatter.chassi';
import { FollowUpReportSkuFormatter } from './formatters/report-followup.formatter.sku';
import { FollowUpReportExchangeFormatter } from './formatters/report-followup.formatter.exchange';
import { FollowUpReportInvoiceFormatter } from './formatters/report-followup.formatter.invoice';
import { FollowUpReportLiFormatter } from './formatters/report-followup.formatter.li';
import { FollowUpReportSailingFormatter } from './formatters/report-followup.formatter.sailing';
import { FollowUpReportClearenceFormatter } from './formatters/report-followup.formatter.clearence';
import { FollowUpReportPenaltyFormatter } from './formatters/report-followup.formatter.penalty';
import { FollowUpReportContainerFormatter } from './formatters/report-followup.formatter.container';
import { FollowUpReportDeliveryFormatter } from './formatters/report-followup.formatter.delivery';
import { FollowUpReportNfFormatter } from './formatters/report-followup.formatter.nf';
import { FollowUpReportWarehousesFormatter } from './formatters/report-followup.formatter.warehouses';
import { FollowUpReportFormatter } from './formatters/report-followup.formatter';
import { FollowUpUnifiedDatamartModalComponent } from './followup-unified-exporters/followup-unified-datamart-modal/followup-unified-datamart-modal.component';
import moment from 'moment';
import { HeaderNavService } from 'app/theme/layouts/header-nav/header-nav.service';
import { CustomGridComponent } from 'app/shared/components/cmx-custom-grid/custom-grid.component';

@Component({
    selector: 'report-followup-unified',
    templateUrl: './report-followup-unified.component.html',
    styleUrls: ['../reports.component.scss', './report-followup-unified.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FollowUpUnifiedReportComponent implements OnDestroy {

    @ViewChild(FollowUpUnifiedDatamartModalComponent) private datamartModalComponent: FollowUpUnifiedDatamartModalComponent;
    @ViewChild(CustomGridComponent) private customGrid: CustomGridComponent;

    filterState: FollowUpFilter = this.filterStateService.getFupFollowUpFilter();

    titleComponent: IPageWrapperTitle = { title: 'Follow UP' };
    smallSize = window.innerWidth <= 1280 ? true : false;
    helpDescription = this._defineHelpDescription();
    topButtonList: ITopButton[];

    allStatus = [];
    preSelectedStatus = this.filterState.getStatus().map((status) => status.code);

    options: {
        profitCenters: DropdownOption[];
        client: DropdownOption[];
        status: DropdownOption[];
        referenceDateType: DropdownOption[];
        subsidiaryNames: DropdownOption[];
        modal: DropdownOption[];
    } = {
        client: [],
        status: [],
        referenceDateType: [
            { id: 'registration', itemName: 'Criação' },
            { id: 'arrival', itemName: 'Atracação' },
            { id: 'previsionArrival', itemName: 'ETA' },
            { id: 'billing', itemName: 'Faturamento' },
        ],
        subsidiaryNames: [],
        profitCenters: [],
        modal: [],
    };
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    readonly dropdownSettingsReferenceDateType = {
        singleSelection: true,
        text: 'Selecionar',
    };

    keyFilterTypes = ['IMP', 'REF'];
    asyncFollowUP: boolean = true;
    asyncExport: boolean = false;
    asyncFollowUPDetail: boolean = false;
    useServerlessReport: boolean = false;

    currentUser = this.userService.getCurrentUser();
    currentUser$ = this.userService.currentUser$;

    columnsMap: Map<string, any> = new Map<string, any>();
    gridColumns: IGridColumn[] = [];
    gridRows: IGridRow[] = [];
    previousGridRows: IGridRow[] = [];
    selectedGrid: boolean = false;
    gridOptions = {
        rowHeight: 35,
        forceFitColumns: false,
        useCategoryGrouping: true,
        horizontalAnchoring: true,
        enableAsyncPostRender: true,
        frozenColumn: this.currentUser.has('FUP_VALIDATION') ? 3 : 2
    }

    collapseActionColumnsSKU = [
        'skuCode', 'customerSku', 'skuInvoiceNumber', 'skuDescription', 'skuModel',
        'skuBrand', 'skuFamily', 'skuLine', 'skuCollection', 'skuNcm',
        'skuManufacturerName', 'skuWeightAsBigDecimal', 'skuGrossWeightAsBigDecimal', 'skuQuantity',
        'skuPrice', 'skuTotalPrice', 'skuUnitMeasureDescription', 'skuBillableQuantityDecimal',
        'skuBillableUnitMeasureDescription', 'skuBatchNumber', 'skuExpirationOnDate', 'skuBarcode','dateBtgDocuments',
        'btgProtocolDate','btgReleaseDate','finalTimeRelease','estimatedTimeRelease'
    ];

    collapseActionColumnsChassi = [
        'chassiChassisNumber', 'chassiCheckoutDate', 'chassiVehicleType', 'chassiChassiModel',
        'chassiChassiColor', 'chassiModelYear', 'chassiManufacturerYear', 'chassiEngineNumber',
        'chassiBlockSituation'
    ];

    actionColumnsMap = {
        "SKU": this.collapseActionColumnsSKU,
        "CHASSI": this.collapseActionColumnsChassi,
    };

    localSortedFields = [
        'skuCode', 'skuModel', 'skuQuantityByNumber'
    ]

    collapseActionColumns = this.collapseActionColumnsChassi;

    pages = [];
    queryId = Math.random();
    customDetailDataOpen: boolean = false;
    dinamicDetailDataOpen: boolean = false;
    detailFUPTabs = [];
    followUpData = [];

    selectedImp: any;
    selectedModalData: any;
    widthModal: string = '400px';
    gridModalMode: boolean = false;
    modalRows = [];
    modalHeaders = [];
    modalConfig = _.cloneDeep(FOLLOWUP_MODAL);

    countSubscription;
    subscription;
    selectedFilter = '';
    sort = 'imp,asc';
    importationEndpoint = '';
    currentEndpoint = '';
    filterSearch = '';
    startDateFilter = '';
    endDateFilter = '';
    dateSearch: boolean = false;
    filterSelectionMode: string;

    atlasSearch = false;

    followUpTypeSwitch = {
        values: ['SKU', 'CHASSI'],
        index: 0,
    };

    alertSubscription;
    private selectedStatusSubscription: Subscription;
    private selectedStatusSubject = new Subject<any>();
    private isPageLoaded : boolean = false;

    constructor(
        public router: Router,
        public domainTypeService: DomainTypeService,
        public followUpModelService: FollowUpModelService,
        private http: HttpClient,
        private userService: UserService,
        private urlValue: ActivatedRoute,
        private _downloadService: DownloadService,
        private _utilsService: UtilsService,
        private slick: FollowUpReportSlickGrid,
        private filterStateService: FilterStateService,
        private userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _customDialogService: CustomDialogService,
        private _domainService: DomainService,
        private _transportModalService: TransportModalService,
        public _reportPreferencesService: ReportPreferencesService,
        private _featureFlagService: FeatureFlagService,
        private _importDetailsService: ImportDetailsService,
        private _headerNavService: HeaderNavService,
        // formatters
        private impFormatter: FollowUpReportImpFormatter,
        private chassiFormatter: FollowUpReportChassiFormatter,
        private skuFormatter: FollowUpReportSkuFormatter,
        private exchangeFormatter: FollowUpReportExchangeFormatter,
        private invoiceFormatter: FollowUpReportInvoiceFormatter,
        private liFormatter: FollowUpReportLiFormatter,
        private sailingFormatter: FollowUpReportSailingFormatter,
        private clearenceFormatter: FollowUpReportClearenceFormatter,
        private penaltyFormatter: FollowUpReportPenaltyFormatter,
        private containerFormatter: FollowUpReportContainerFormatter,
        private deliveryFormatter: FollowUpReportDeliveryFormatter,
        private nfFormatter: FollowUpReportNfFormatter,
        private warehousesFormatter: FollowUpReportWarehousesFormatter,
        private formatter: FollowUpReportFormatter,
    ) {
        if (this.urlValue.snapshot.params.imp) {
            this.filterState.setSearch(this.urlValue.snapshot.params.imp);
        }

        if (!this.filterState.getFollowUpType()) {
            this.filterState.setFollowUpType("SKU");
        }

        this._loadKeyFilterTypes();

    }

    ngOnInit() {
        this.selectedStatusSubscription = this.selectedStatusSubject.pipe(
            debounceTime(200),
            switchMap(() => {
                this.getStatusCount();
                this.loadFollowUpTable(true);
                return of(null);
            }),
        ).subscribe();
    }

    async ngAfterViewInit() {
        this.asyncFollowUP = true;
        this.atlasSearch = await this._featureFlagService.isFeatureFlagEnabledV2(FeatureFlagService.FEATURE_FOLLOWUP_MONGODB_SERVICE)
        this.importationEndpoint = await this.domainTypeService.getFollowUpEndpoint('imp');
        this.currentEndpoint = await this.domainTypeService.getFollowUpEndpoint('followup');
        this.useServerlessReport = await this._featureFlagService.isFeatureFlagEnabledV2('followup_serverless_reports');

        this.selectExportModel();
        setTimeout(() => {
            forkJoin([
                this.getSubsidiaryNames(),
                this._domainService.getProfitCenter(),

                this._reportPreferencesService.getDefaultReport(this._getReportId(), this.currentUser.has('FOLLOWUP_COMPLETE') ? false: true),
                this._reportPreferencesService.getCustomReport(this._getReportId(), undefined, this.currentUser.has('FOLLOWUP_COMPLETE') ? false: true),
            ]).subscribe(
                (values) => {
                    const [subsidiaryNames, profitCenters, defaultValue, customValue] = values;

                    this.options.subsidiaryNames = this._cmxDropdownService.toDropdownItems(subsidiaryNames);
                    this.options.profitCenters = this._cmxDropdownService.toDropdownItems(profitCenters);
                    this.options.client = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
                    this.options.modal = this._cmxDropdownService.toDropdownItemsImg(_.cloneDeep(this._transportModalService.getModalList()));

                    this._updateColumnsMap(defaultValue);
                    this._reportPreferencesService.currentDefaultReport = _.cloneDeep(defaultValue);
                    this._determineColumns(defaultValue, customValue);

                    this.loadFollowUp(false);

                    this._reportPreferencesService.fupSaveSubject.subscribe((save) => {
                        this._reportPreferencesService.getCustomReport(this._getReportId(), undefined, this.currentUser.has('FOLLOWUP_COMPLETE') ? false: true).subscribe((customValue) => {
                            this._reportPreferencesService.saveList = this._reportPreferencesService.prepareSaveList(customValue.reports);
                            this._reportPreferencesService.rightButtonList = this._reportPreferencesService.prepareRightButtonList(customValue.reports)
                            this._reportPreferencesService.selectedRightButton = this._reportPreferencesService.prepareRightButton(_.cloneDeep(
                                save?.id ? save : this._reportPreferencesService.rightButtonList[this._reportPreferencesService.rightButtonList.length - 1]
                            ));
                            this._reportPreferencesService.defineLeftAndRightData(
                                this._reportPreferencesService?.currentDefaultReport?.groups,
                                customValue.reports[0]?.groups
                            );
                            this._mergeColumns(save);
                            this.datamartModalComponent.updateModelList(customValue);
                            this._customDialogService.close('followup-unified-customization-modal');
                        })
                    })

                    this._reportPreferencesService.fupDeleteSubject.subscribe((save) => {
                        const reportsModel = { reports: (Array.isArray(this._reportPreferencesService.saveList)) ? this._reportPreferencesService.saveList : [] };
                        this.datamartModalComponent.updateModelList(reportsModel);
                    })
                },
                (error) => {
                    this._utilsService.errorHandler(error, 'Customização Follow Up');
                }
            );
        });
    }

    ngOnDestroy(): void {
        if (this.selectedStatusSubscription) {
            this.selectedStatusSubscription.unsubscribe();
        }

        if(this.selectedStatusSubject) {
            this.selectedStatusSubject.complete();
        }
    }

    _getReportId() {
        return `FOLLOWUP_${this.filterState.getFollowUpType()}`;
    }

    toggleFollowUpTypeSwitch() {
        this.asyncFollowUP = true;
        this.filterState.setFollowUpType(this.filterState.getFollowUpType() == "CHASSI" ? 'SKU' : 'CHASSI');
        this._loadKeyFilterTypes();
        if (this.filterState.getTypeSearch() === 'SKU' || this.filterState.getTypeSearch() === 'Chassis') {
            this.filterState.setSearch('');
            setTimeout(() => { this.selectSearchType('IMP') }, 1);
        }
        this.selectExportModel();
        this.disableReportButton();
        setTimeout(() => {
            forkJoin([
                this._reportPreferencesService.getDefaultReport(this._getReportId(), this.currentUser.has('FOLLOWUP_COMPLETE') ? false: true),
                this._reportPreferencesService.getCustomReport(this._getReportId(), undefined, this.currentUser.has('FOLLOWUP_COMPLETE') ? false: true),
            ]).subscribe(
                (values) => {
                    const [defaultValue, customValue] = values;
                    this._reportPreferencesService.currentDefaultReport = _.cloneDeep(defaultValue);
                    this._updateColumnsMap(defaultValue);
                    this._determineColumns(defaultValue, customValue);
                    this.resetPages();
                    this.loadFollowUp(false);
                }
            );
        }, 300);
    }

    onTopButtonClick(button) {
        if(button.code === 'imp'){
            if (this.useServerlessReport) {
                this.export('xls', 'imp-serverless');
            } else {
                this.export('xls', button.code);
            }
        }
        if(button.code === 'sku'){
            if (this.useServerlessReport) {
                this.export('xls', 'sku-serverless');
            } else {
                this.export('xls', button.code);
            }
        }
        if(button.code === 'chassi'){
            if (this.useServerlessReport) {
                this.export('xls', 'chassis-serverless');
            } else {
                this.export('xls');
            }
        }
        if(button.code === 'datamart'){
            this.openDatamartModal();
        }
        if (button.code === 'customize') {
            this._openCustomizationModal();
        }
    }

    onRightButtonClick(save) {
        this.asyncFollowUP = true;
        this._reportPreferencesService.selectedRightButton = save;
        setTimeout(() => { this._mergeColumns(save) }, 300);
    }

    onSelectedStatus({ selected }): void {
        if(!this.isPageLoaded) return;
        this.resetPages();
        this.filterState.setStatus(_.cloneDeep(selected));
        this.selectedStatusSubject.next(selected);
    }

    selectSearchType(element) {
        const listItems = document.querySelectorAll('.content-input ul li');
        const nodeListItems = Array.prototype.slice.call(listItems);
        nodeListItems.forEach((node) => node.classList.remove('selected'));

        $(element.target).addClass('selected');
        this.filterState.setSearch('');
        this.filterState.setTypeSearch(element);
    }

    applyFilter() {
        if(!this.isPageLoaded) return;
        this.resetPages();
        this.loadFollowUp(true);
    }

    datesChanged(dates) {
        this.filterState.setStartDate(dates.startDate);
        this.filterState.setEndDate(dates.endDate);
    }

    disableReportButton(){
        this.topButtonList.find(button => button.name === 'Download').disabled = true;
        this.topButtonList = _.cloneDeep(this.topButtonList);
    }

    enableReportButton(){
        this.topButtonList.find(button => button.name === 'Download').disabled = false;
        this.topButtonList = _.cloneDeep(this.topButtonList);
    }

    selectExportModel(){
        if (this.filterState.getFollowUpType() === 'CHASSI') {
            this.topButtonList = [
                    { name: 'Download', code: 'chassi', leftIcon: 'file_download', disabled: true },
                    { code: 'customize', name: 'Personalizar', leftIcon: 'dashboard', rightIcon: 'add' }
                ];
        }
        if (this.filterState.getFollowUpType() === 'SKU') {
            this.topButtonList = [
                {
                    name: 'Download',
                    code: 'exporter',
                    multiple: true,
                    leftIcon: 'file_download',
                    disabled: true,
                    subItems: [
                        {
                            code: 'imp',
                            name: 'Importação',
                            leftIcon: 'file_download',
                        },
                        {
                            code: 'sku',
                            name: 'Imp + SKU',
                            leftIcon: 'file_download',
                        },
                    ],
                },
                { code: 'datamart', name: 'Data Mart', leftIcon: 'source' },
                { code: 'customize', name: 'Personalizar', leftIcon: 'dashboard', rightIcon: 'add' }
            ];
        }
    }

    export(type: any, reportModel?: string) {
        this.asyncExport = true;
        const request = this.filterState.getRequest(this.allStatus, this.filterState.getFollowUpType());
        const options = this._downloadService.getSourceOptions();
        const clientGroups = this.filterState.getSearch() !== '' ? '' : this.filterState.getClientGroups().map((c) => c['id']).join(',');
        options['headers'] = new HttpHeaders({
            'X-Requested-Client-Groups': this.filterState.getClientGroups().length === 0 ? '' : clientGroups,
            Accept: 'application/vnd.ms-excel',
        });
        this.currentUser.has('FOLLOWUP_COMPLETE')
            ? (request.params = request.params.append('isCustomer', 'false'))
            : (request.params = request.params.append('isCustomer', 'true'));
        request.params = request.params.append('userId', this.currentUser.getUid());
        request.params = request.params.append('sort', this.sort);
        request.params = request.params.append('modelName', this._reportPreferencesService.selectedSave.id);
        request.params = request.params.append('reportId', this._getReportId());
        if(reportModel){
            request.params = request.params.append('reportModel', reportModel);
        }
        this._setFilterParams(request);
        if (reportModel) {
            this.http.post(`${this.currentEndpoint}/followup-report`, request.params, options).subscribe(
                () => {
                    this._downloadService.onAsyncReport();
                    this.asyncExport = false;
                },
                (error) => {
                    this._utilsService.errorHandler(error, 'Follow Up');
                    this.asyncExport = false;
                }
            );
        } else {
            const url = this.http.post(`${this.currentEndpoint}/followup-report/chassis`, request.params, options);
            this._downloadService.downloadFrom(url, `FUP-${moment().format('DD-MM-YYYY')}.${type}`)
            .subscribe(() => {
                this.asyncExport = false;
            },
            (error) => {
                this._utilsService.errorHandler(error, 'Follow Up');
                this.asyncExport = false;
            }
        );
        }
    }

    openDatamartModal() {
        this._customDialogService.open('fup-unified-datamart-modal');
    }

    async onCellClick({ item, column, _multiButtom, element, event }): Promise<void> {
        const companyCode = item.companyCode?.toString()?.length === 1 ? `0${item.companyCode}` : item.companyCode;
        if (
            column === 'imp' &&
            this.userService.getCurrentUser().has('ROUTE_SUPPLY_CHAIN_IMP_DETAIL') &&
            (element.context &&
             element.context.className === 'collapsedBadge')
        ) {
            if (
                navigator.userAgent.indexOf('MSIE') !== -1 ||
                navigator.appVersion.indexOf('Trident/') > -1 ||
                window.navigator.userAgent.indexOf('Edge') > -1
            ) {
                this.router.navigate([`./supply-chain/importacao/${companyCode}/${item.imp}`]);
            } else {
                window.open(`./supply-chain/importacao/${companyCode}/${item.imp}`);
            }
        }

        if (column === 'validation' || $(element).hasClass('multipleViolations')) {
            this._openMultipleModal(item, column, true);
            return;
        }

        const clazzColumn = [...element[0].classList].find(clazz => FOLLOWUP_MODAL.hasOwnProperty(clazz.replace(/-/g, "")));
        if (clazzColumn && (event !== 'collapse' || item.rowClasses.includes('fupDetails'))) {
            this._openMultipleModal(item, clazzColumn.replace(/-/g, ""));
            return;
        }

        if (column === 'details') {
            if (!this.selectedGrid) {
                this.asyncFollowUPDetail = true;
                try {
                    const followupCustomDetail = await this._featureFlagService.isFeatureFlagEnabledV2('followup_custom_detail');
                    followupCustomDetail ? this._openCustomDetail(item) : this._openDinamicDetail(item);
                } catch (error) {
                    this._openCustomDetail(item);
                } finally {
                    this.asyncFollowUPDetail = false;
                }
            }
        }
    }

    async onSortColumn({field, sort}) {
        const column = this.columnsMap.get(field);
        if (column) {
            this.sort = `${column.path},${sort}`;
        } else if (field === 'imp') {
            this.sort = `imp,${sort}`;
        } else if (field=== 'chassiChassisNumber') {
            this.sort = `automotiveItems.chassisNumber,${sort}`;
        } else if (field=== 'customerSku') {
            this.sort = `vendorInvoices.invoices.items.customerSku,${sort}`;
        }
        this.applyFilter();
    }

    sortedBy(sorted) {
        if (!sorted || sorted.length === 0) return;

        const [field, sort] = sorted;

        if (!this.localSortedField(field)) return;

        this.sortBy(field, sort);
    }

    localSortedField(field) {
        return this.localSortedFields.includes(field);
    }

    sortBy(field, sort) {
        const compareRows = (a, b) => {
            const parentComparison = a.parent - b.parent;

            if (parentComparison !== 0) {
                return parentComparison;
            }

            const bothAreLeaves = !a.isLeaf && !b.isLeaf;

            if (bothAreLeaves) {
                return a.id - b.id;
            }

            if (!a.isLeaf) {
                return 1;
            } else if (!b.isLeaf) {
                return -1;
            }

            const aValue = String(a[field]);
            const bValue = String(b[field]);

            return sort === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        };

        this.gridRows.sort(compareRows);
    }

    async onFilterColumn({field, value, type, filterSelectionMode}) {
        if (value != null && value !== '' && value?.length > 0) {
            this.dateSearch = type === 'date';
            this.filterSelectionMode = filterSelectionMode;
            this.selectedFilter = this.columnsMap.get(field).path;
            if (filterSelectionMode === 'range') {
                this.startDateFilter = this.filterSearch = type === 'currency' || type === 'currency_exchange' ? value[0].replace(/\./g, '').replace(',', '.') : value[0];
                this.endDateFilter = this.filterSearch = type === 'currency' || type === 'currency_exchange' ? value[1].replace(/\./g, '').replace(',', '.') : value[1];
            } else {
                this.filterSearch = type === 'currency' || type === 'currency_exchange' ? value.replace(/\./g, '').replace(',', '.') : value
            }
        } else {
            this.selectedFilter = '';
            this.filterSearch = '';
            this.filterSelectionMode = '';
        }
        this.applyFilter();
    }

    private _openCustomDetail(item: any) {
        this.customDetailDataOpen = true;
        setTimeout(() => {
            this._importDetailsService.setStatus(this._checkColorStatus(item.row.status));
            this._importDetailsService.setDetails(item.row);
            this._openSlickModal(item);
        }, 100);
    }

    private _openDinamicDetail(item: any) {
        this.dinamicDetailDataOpen = true;
        setTimeout(() => {
            this._defineDinamicData(item);
            this._openSlickModal(item);
        }, 100);
    }

    private _checkColorStatus(status) {
        const colors = {
            "EMBARQUE": "#29B473",
            "CARGA": "rgb(2, 121, 177)",
            "INICIADO": "#652D90",
            "FATURAMENTO": "#e89600",
            "DESEMBARACO": "#6b3922",
            "TRANSITO": "#F05A28",
            "ENTREGA": "rgb(145, 47, 136)",
            "FINALIZADO": "#6ACEF5",
            "REGISTRO": "#808083",
        }

        return {code: status.code, name: status.name, color: colors[status.code]}
    }

    changePage(pageNumber) {
        this.filterState.setOffset(pageNumber - 1);
        $('.scrollerContainer').animate({ scrollTop: 0 }, 10 );
        this.loadFollowUpTable(true);
    }

    onDinamicClick(event) {
        if (Array.isArray(event.value)) {
            this._openMultipleModal(this.gridRows[0], event.field)
        }
    }

    closeSlickModal() {
        this.customDetailDataOpen = false;
        this.dinamicDetailDataOpen = false;
        this.gridRows = this.previousGridRows;
        this.selectedGrid = false;
    }

    closeCustomModal() {
        this.gridModalMode = false;
    }

    loadFollowUpTable(onlyRows: boolean) {
        this.asyncFollowUP = true;
        if (this.subscription) { this.subscription.unsubscribe() }
        this.disableReportButton();
        this.subscription = this.getFollowUp().subscribe(
            (data) => {
                if (!onlyRows) {
                    const customStructure = this._reportPreferencesService.selectedRightButton ||
                        (this._reportPreferencesService.saveList.find(ite => ite.reportName === 'Padrão'));
                    this._createColumns(customStructure.groups);
                }
                this.followUpData = this.slick.extractRows(data['impDetails'], '');
                this._createRows(this.followUpData);

                const num = Math.ceil(data.total / this.filterState.getLimit());
                this.pages = Array.apply(null, { length: num }).map(Number.call, Number);

                this._defineTabGroups();

                this.asyncFollowUP = false;
                this.enableReportButton();
                this.isPageLoaded = true;
                const imps = this.followUpData.map(row => row.imp);
                this.alertSubscription = this._headerNavService.getMainAlerts(imps).pipe(
                    tap(alerts => {
                        alerts.forEach(item => {
                        let indexRow = this.gridRows.findIndex(row => row.imp === item.mainField);
                        this.gridRows[indexRow].validation = item.items;
                        this.gridRows[indexRow].validated = true;
                        })

                        this.customGrid.updateRows(alerts.map(item => imps.indexOf(item.mainField)));

                    }),
                    share());  
        }, (error) => {
            this._utilsService.errorHandler(error, 'Follow Up');
            this.asyncFollowUP = false;
            this.isPageLoaded = true;
        });
    }

    private resetPages() {
        this.queryId = Math.random();
        this.filterState.setOffset(0);
    }

    private _openCustomizationModal() {
        this._customDialogService.open('followup-unified-customization-modal');
    }

    private _mergeColumns(save) {
        this._reportPreferencesService.selectedSave = { ...save, title: save?.title || save?.name };
        const defaultColumns = this._reportPreferencesService.currentDefaultReport.groups || [];
        this._reportPreferencesService.defineLeftAndRightData(defaultColumns, save.groups || []);
        this._createColumns(save.groups);
        this.asyncFollowUP = false;
    }

    private _defineDinamicData(item) {
        this.detailFUPTabs.forEach((tab) => { tab.data = [] });
        this.gridColumns.forEach((column) => {
            if (column.groupName) {
                const group = this.detailFUPTabs.find(group => group.code === column.groupName)
                if (group?.data) {
                    const title = column?.name;
                    const value = Array.isArray(item[`${column.field}`]) && item[`${column.field}`].length === 1 ?
                        item[`${column.field}`][0][DINAMIC_ATRIBUTE_VALUES[column.field]] : item[`${column.field}`]
                    group.data.push({
                        ...column,
                        title,
                        formatter: 'multiple',
                        value: typeof value === 'number' ? `${this._utilsService.formatNumber(value, { style: 'currency', currency: 'BRL' })}` : value,
                    });
                }
            }
        })
    }

    getValueField(objeto, campo) {
        var campos = campo.split(".");
        var valor = objeto;
        for (var i = 0; i < campos.length; i++) {
            valor = valor[campos[i]];
        }
        return valor;
    }

    private changePaymentTerms(exchanges) {
        return exchanges.map((exchange) => {
            switch (exchange.paymentTerms) {
                case 'CLIENT':
                    return {
                        ...exchange,
                        paymentTerms: 'CLIENTE',
                        clazz: 'client',
                    };
                case 'APRAZO':
                    return {
                        ...exchange,
                        paymentTerms: 'A PRAZO',
                        clazz: 'prazo',
                    };
                case 'ANTECI':
                    return {
                        ...exchange,
                        paymentTerms: 'ANTECIPADO',
                        clazz: 'anteci',
                    };
                case 'AVIST':
                    return {
                        ...exchange,
                        paymentTerms: 'A VISTA',
                        clazz: 'vista',
                    };
            }
        });
    }

    private changeContainers(skus) {
        let valueSku = [];
        if (skus && Array.isArray(skus) && skus.length > 0) {
            skus.forEach((item) => {
                if(item.skuQuantityByNumber && Array.isArray(item.skuQuantityByNumber)) {
                    valueSku = valueSku.concat(item.skuQuantityByNumber);
                } else {
                    valueSku = valueSku.concat(item);
                }
            });
        }
        return valueSku.map(container => ({number: container.number, quantity: container.quantity}))
    }

    private changeHedges(invoices) {
        const hedges = [];
        invoices?.forEach((invoice) => {
            hedges.push(...invoice?.hedges)
        });
        return hedges;
    }

    private changeManufacturers(manufacturers) {
        let uniqueNames = Array.from(
            new Set(manufacturers.map(manufacturer => manufacturer.manufacturerName))
        );
        return uniqueNames.map(name => ({ manufacturerName: name }));
    }

    private changeManufacturersCountry(manufacturers) {
        const manufacturerCountries = [];
        manufacturers.forEach((country) => {
            country.items.forEach((invoiceItem) => {
                manufacturerCountries.push(invoiceItem);
            });
        });

        const newManufacturerCountries = manufacturerCountries.map(item => ({
            code: item.code,
            manufacturerName: item.manufacturer.name,
            manufacturerCountry: item.manufacturerCountry,
        }));
        return newManufacturerCountries;
    }

    private changeOperationalOriginalDocuments(docs) {
        return docs.filter(
            (d) => d.documentAreaIdentifier === 'OPERATIONAL'
        );
    }

    private changeClearingOriginalDocuments(docs) {
        return docs.filter(
            (d) => d.documentAreaIdentifier === 'CLEARING'
        );
    }

    private changeVendorInvoiceBillableQuantity(vendorInvoiceBillableQuantity) {
        const formatDate = (date) => date ? moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null;
        const formatLabelType = (labelType) => labelType ? labelType.replace("_", " ") : null;

        return vendorInvoiceBillableQuantity.flatMap(({ items }) =>
            items.map((invoiceItem) => ({
                ...invoiceItem,
                labelType: formatLabelType(invoiceItem.labelType),
                labelRequestDate: formatDate(invoiceItem.labelRequestDate),
                labelDeliveryDate: formatDate(invoiceItem.labelDeliveryDate),
                labelStartDate: formatDate(invoiceItem.labelStartDate),
                expirationOnDate: formatDate(invoiceItem.batches?.[0]?.expirationOnDate)
            }))
        );
    }

    private changeLiDeferment(lis) {
        const newLis = lis.map(item => ({
            liNumber: item.liNumber,
            acceptanceDate: item.dates.acceptanceDate
        }));
        return newLis;
    }

    private changeLiNumber(lis) {
        const newLis = lis.map(item => ({
            liNumber: item.liNumber,
            licensingBody: item.licensingBody,
            liStatus: item.liStatus,
            boardingLimitDate: item.dates.boardingLimitDate,
            diRegistrationExpiryDate: item.dates.diRegistrationExpiryDate,
            issueDate: item.dates.issueDate,
            protocolDate: item.dates.protocolDate,
        }));
        return newLis;
    }

    private changeGruPayments(grus) {
        const newGru = grus.map(item => {
            const gruDates = item.gruPaymentDates.map(gru => ({
                liNumber: item.liNumber,
                gruDate:  gru ? moment(gru, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
            }));
            return gruDates;
        }).flat();
        return newGru;
    }

    private changeRequestRemoveDate(docs) {
        const containerModalData = [];
        docs.forEach((container) => {
            containerModalData.push({
                number: container.containerNumber,
                field: container.requestRemoveDate,
            });
        });
        return containerModalData;
    }

    private changeRemoveDate(docs) {
        const containerModalData = [];
        docs.forEach((container) => {
            containerModalData.push({
                number: container.containerNumber,
                field: container.removeDate,
            });
        });
        return containerModalData;
    }

    private changeTransitConclusionRemoveDate(docs) {
        const containerModalData = [];
        docs.forEach((container) => {
            containerModalData.push({
                number: container.containerNumber,
                field: container.transitConclusionRemoveDate,
            });
        });
        return containerModalData;
    }

    private changeCarrierDescription(docs) {
        const containerModalData = [];
        docs.forEach((container) => {
            containerModalData.push({
                number: container.containerNumber,
                field: container.carrierDescription,
            });
        });
        return containerModalData;
    }

    private changeProgNumberRemove(docs) {
        const containerModalData = [];
        docs.forEach((container) => {
            containerModalData.push({
                number: container.containerNumber,
                field: container.progNumberRemove,
            });
        });
        return containerModalData;
    }

    private changeRequestUnloadingDate(docs) {
        const containerModalData = [];
        docs.forEach((container) => {
            containerModalData.push({
                number: container.containerNumber,
                field: container.requestUnloadingDate,
            });
        });
        return containerModalData;
    }

    private changeUnloadingDate(docs) {
        const containerModalData = [];
        docs.forEach((container) => {
            containerModalData.push({
                number: container.containerNumber,
                field: container.unloadingDate,
            });
        });
        return containerModalData;
    }

    private changeExpectedUnloadingDate(docs) {
        const containerModalData = [];
        docs.forEach((container) => {
            containerModalData.push({
                number: container.containerNumber,
                field: container.expectedUnloadingDate,
            });
        });
        return containerModalData;
    }

    private changeContainerTypeModel(docs) {
        const containerModalData = [];
        docs.forEach((container) => {
            containerModalData.push({
                number: container.containerNumber,
                field: container.containerTypeModel,
            });
        });
        return containerModalData;
    }

    private changeContainerDevolutionDate(docs) {
        const containerModalData = [];
        docs.forEach((container) => {
            containerModalData.push({
                number: container.containerNumber,
                field: container.returnDate,
            });
        });
        return containerModalData;
    }

    private _openMultipleModal(item, column, isAlert?) {
        this.selectedImp = item.imp || item.parentImp;
        if(isAlert) column = 'validation';
        if (FOLLOWUP_MODAL.hasOwnProperty(column)) {
            this.modalHeaders = _.cloneDeep(FOLLOWUP_MODAL[column]?.headers) || [];
            this.widthModal = _.cloneDeep(this.modalConfig[column]?.width);
            this.selectedModalData = _.cloneDeep(FOLLOWUP_MODAL[column]);

            const transformation = FOLLOWUP_MODAL[column]?.transformation;
            if(transformation && typeof this[transformation] === 'function') {
                let array = _.cloneDeep(item[FOLLOWUP_MODAL[column].data]) || [];
                this.modalRows = this[transformation](array);
            } else {
                this.modalRows = _.cloneDeep(this.getValueField(item, FOLLOWUP_MODAL[column].data)) || [];
            }

            if(this.modalHeaders && this.modalHeaders.length > 0) {
                this.modalHeaders = this.modalHeaders.map((ite) => {
                    return {
                        ...ite,
                        value: this.getValueField(item, ite.field)
                    }
                });
            }

            this._customDialogService.open('followup-custom-dialog');
            setTimeout(() => {
                this.gridModalMode = true;
            }, 50);
        }
    }

    private _openSlickModal(item) {
        if (!this.selectedGrid) {
            this.selectedGrid = true;
            this.previousGridRows = this.gridRows;
            this.gridRows = [item];
        }
    }

    private _defineTabGroups() {
        const groups = [...new Set(this.gridColumns.map(column => column.groupName))].filter((e) => { return e != null && e != ''; });
        this.detailFUPTabs = groups.map((group) => { return { code: group, name: group, data: [] }; });
        this.detailFUPTabs = this.detailFUPTabs.filter(ite => ite.name !== 'IMP');
    }

    private _defineHelpDescription(): object[] {
        return [
            {
                type: 'text',
                value: 'Relatório mais detalhado dos dados  sobre as importações.',
            },
            {
                type: 'text',
                value: 'As colunas são divididas por grupos: é possível navegar por eles com um clique.',
            },
            {
                type: 'text',
                value: 'É personalizável: é possível salvar modelos de relatórios para consultas frequentes.',
            },
            {
                type: 'text',
                value: 'Filtros por status, número da IMP ou REF, e período de data.',
            },
        ]
    }

    private getStatusCount() {
        if (this.countSubscription) {
            this.countSubscription.unsubscribe();
        }
        this.countSubscription = this.getFollowUpStatusCount().subscribe((data) => {
            this.allStatus = data;
            this.preSelectedStatus = this.filterState.getStatus().map((status) => status.code);
        });
    }

    private getFollowUpStatusCount(): Observable<any> {
        const request = this.filterState.getRequest(this.allStatus);
        this._setFilterParams(request);
        request.params = request.params.append('sort', this.sort);
        const clientGroups = this.filterState.getSearch() !== '' ? '' : this.filterState.getClientGroups().map((c) => c['id']).join(',');
        request['headers'] = new HttpHeaders({
            'X-Requested-Client-Groups': this.filterState.getClientGroups().length === 0 ? '' : clientGroups
        });
        return this.http.get(`${this.currentEndpoint}/followup/count`, request).pipe(map((statusList: any) => {
            const status = (statusList && statusList.chassisStatus) || statusList.impStatus || statusList.skuStatus;
            return _.reduce(status,(prev, curr) => {
                return [...prev, _.omit(_.merge(curr, curr.status), 'status')];
            }, [] )
        }));
    }

    private _setFilterParams(request) {
        if (this.selectedFilter !== '') {
            const filterSearch = _.cloneDeep(this.filterSearch);
            const startDateFilter = _.cloneDeep(this.startDateFilter);
            const endDateFilter = _.cloneDeep(this.endDateFilter);
            if (this.dateSearch) {
                if (startDateFilter && endDateFilter) {
                    request.params = request.params.append('field',`${this.selectedFilter}::${encodeURIComponent(startDateFilter)}.${encodeURIComponent(endDateFilter)}::DATE_RANGE`);
                } else if(startDateFilter && !endDateFilter) {
                    request.params = request.params.append('field',`${this.selectedFilter}::${encodeURIComponent(startDateFilter)}::DATE_GTE`);
                } else if(!startDateFilter && endDateFilter) {
                    request.params = request.params.append('field',`${this.selectedFilter}::${encodeURIComponent(endDateFilter)}::DATE_LTE`);
                }
            } else if (this.filterSelectionMode === 'range') {
                this._appendRangeParam(request, startDateFilter, endDateFilter);
            } else {
                request.params = request.params.append('field',`${this.selectedFilter}::${encodeURIComponent(filterSearch)}`);
            }
        }
    }

    private _appendRangeParam(request, startFilter, endFilter) {
        if (endFilter === '' || (endFilter && parseFloat(endFilter) === 0)) {
            endFilter = null
        }
        if (startFilter && endFilter) {
            request.params = request.params.append('field',`${this.selectedFilter}::${encodeURIComponent(startFilter)}_${encodeURIComponent(endFilter)}::RANGE`);
        } else if(startFilter && !endFilter) {
            request.params = request.params.append('field',`${this.selectedFilter}::${encodeURIComponent(startFilter)}::GTE`);
        } else if(!startFilter && endFilter) {
            request.params = request.params.append('field',`${this.selectedFilter}::${encodeURIComponent(endFilter)}::LTE`);
        }
    }

    private getSubsidiaryNames(): Observable<any> {
        return this.http.get(`${this.importationEndpoint}/imps/subsidiaryNames`);
    }

    private _determineColumns(defaultValue, customValue) {
        this._reportPreferencesService.setEnableChangeColumnGroup(true)
        this._reportPreferencesService.saveList = this._reportPreferencesService.prepareSaveList(customValue.reports);
        this._reportPreferencesService.rightButtonList = this._reportPreferencesService.prepareRightButtonList(customValue.reports)
        this._reportPreferencesService.selectedRightButton = this._reportPreferencesService.prepareRightButton(this._reportPreferencesService.saveList[0] || null);
        this._reportPreferencesService.selectedSave = this._reportPreferencesService.saveList[0] || null;
        this._reportPreferencesService.defineLeftAndRightData(defaultValue?.groups, customValue.reports[0]?.groups);
        this._reportPreferencesService.asyncValues = false;
    }

    private loadFollowUp(onlyRows: boolean) {
        this.asyncFollowUP = true;
        this.getStatusCount();
        this.loadFollowUpTable(onlyRows);
    }

    private getFollowUp(): Observable<any> {
        const request = this.filterState.getRequest(this.allStatus);
        this._setFilterParams(request);
        request.params = request.params.append('sort', this.sort);
        const clientGroups = this.filterState.getSearch() !== '' ? '' : this.filterState.getClientGroups().map((c) => c['id']).join(',');
        request['headers'] = new HttpHeaders({
            'X-Requested-Client-Groups': this.filterState.getClientGroups().length === 0 ? '' : clientGroups
        });
        return this.http.get(`${this.currentEndpoint}/followup`, request);
    }

    private _createColumns(groups) {
        const dataGroups = _.cloneDeep(groups);
        let impColumns = [];

        if (this.currentUser.has('FUP_VALIDATION')) {
            impColumns.push({
                id: 'validation',
                label: '<i class="material-icons" style="font-size:19px">warning</i>',
                width: 45.001,
                field: 'validation',
                formatterType: 'round',
                asyncPostRender: this.alerts,
                formatterData: {
                    roundType: 'empty',
                    needValue: true,
                    roundColor: '#d40000',
                    showNumberOne: true,
                },
                isSortable: false,
                isFilterable: false,
            });
        }

        impColumns.push({
            id: 'details',
            label: ' ',
            name: '<i class="material-icons" style="font-size:19px">notes</i>',
            width: 45,
            field: 'details',
            formatterType: 'round',
            formatterData: {
                roundType: 'details',
                needValue: false,
                emptyBall: true
            },
            isSortable: false,
            isFilterable: false,
        },
        {
            id: 0,
            path: 'imp',
            label: 'IMP',
            width: 100,
            field: 'imp',
            formatterType: 'collapse',
            isSortable: true,
            isFilterable: false,
            formatterData: {
                backgroundColor: '#808080',
                classes: 'pointer',
                badge: true
            }
        });

        if(this.filterState.getFollowUpType() === 'CHASSI') {
            impColumns.push({
                field: 'chassiChassisNumber',
                label: 'CHASSI',
                width: 180,
                formatterType: 'multiple',
                isSortable: true,
                isFilterable: false,
                formatterData: {
                    formatterType: 'default',
                    attributeValue: 'chassiChassisNumber',
                    backgroundColor: '#808080',
                    classes: 'pointer',
                    badge: true,
                    showRatioValues: false
                }
            });
        }

        if(this.filterState.getFollowUpType() === 'SKU') {
            impColumns.push({
                field: 'customerSku',
                label: 'SKU',
                width: 180,
                isSortable: true,
                isFilterable: false,
                formatterType: 'multiple',
                formatterData: {
                    formatterType: 'default',
                    attributeValue: 'customerSku',
                    backgroundColor: '#808080',
                    classes: 'pointer',
                    badge: true,
                    showRatioValues: false
                }
            });
        }

        const impGroup = {
            name: '',
            label: '',
            columns: [...impColumns]
        };

        dataGroups.unshift(impGroup);

        this.collapseActionColumns = this.actionColumnsMap[this.filterState.getFollowUpType()];

        this.gridColumns = [];
        const fupValidation = this.currentUser.has('FUP_VALIDATION');
        dataGroups.forEach((group) => {
            this.gridColumns = this.gridColumns.concat(group?.columns.map(c => {
                let baseColumn = this.columnsMap.get(c.field) || c;
                if (baseColumn) {
                    const column = {
                        ...baseColumn,
                        name: baseColumn.label,
                        width: parseFloat(baseColumn.width),
                        label: group?.name || group?.label || '',
                        groupName: group?.name || group?.label || '',
                        autoWidth: baseColumn.width > 0 ? false : true,
                        asyncPostRender: baseColumn.asyncPostRender || (fupValidation ? this.slick.verifyAlert : '')
                    }
                    if (baseColumn.formatter) {
                        column['formatter'] =  this._extractFormatter(baseColumn);
                        delete column['formatterType'];
                        delete column['formatterData'];
                    } else {
                        column['formatterType'] = baseColumn.formatterType || 'multiple';
                        column['formatterData'] = baseColumn.formatterData || { formatterType: 'default' , attributeValue: baseColumn.field };
                        if (!('showRatioValues' in column['formatterData'])) {
                            column['formatterData']['showRatioValues'] = true;
                        }
                    }

                    if (!this.atlasSearch) {
                        // Sobrescrever modo de seleção 'range' para os campos numéricos que não tem suporte no elastic
                        if (column.filterSelectionMode === 'range' && column.filterType !== 'date') {
                            column.filterSelectionMode = null;
                        }
                    }
                    return column;

                }
                return null;
            }).filter(c => c !== null) || [])
        });
    }

    private _extractFormatter(column) {
        const self = this;
        return eval('self.' + column.formatter);
    }

    private _updateColumnsMap(defaultValue) {
        defaultValue.groups.forEach((group) => {
            group?.columns.forEach(c => {
                this.columnsMap.set(c.field, c);
            })});
    }

    private _createRows(data) {
        const rows = [];
        let rowIndex = -1;
        let parentIndex;
        data.forEach((row, _index) => {
            rowIndex++;
            parentIndex = _.cloneDeep(rowIndex);
            let documentList = [];
            if(this.filterState.getFollowUpType() == "SKU") documentList = row.skus || [];
            if(this.filterState.getFollowUpType() == "CHASSI") documentList = row.chassis || [];

            row['id'] = rowIndex;
            row['bold'] = true;
            row['_collapsed'] = true;
            row['rowClasses'] = ['imp']
            row['documents'] = documentList;
            if (documentList && documentList.length < 1) {
                rows.push({ ...row, alone: true, isLeaf: true })
            }
            if (documentList && documentList.length === 1) {
                rows.push({ ...row, alone: true, download: true, view: true, isLeaf: true })
            }
            if (documentList && documentList.length > 1) {
                rows.push({ ...row, download: true })

                documentList.forEach((document, _docIndex) => {
                    rowIndex++;
                    rows.push({ ...document, isLeaf: true, multi: true, parent: parentIndex,
                        id: `${rowIndex}-${_docIndex}`,
                        imp: '',
                        parentImp: row.imp,
                        rowClasses: ['fupDetails']
                    })
                });
            }
        });

        this.gridRows = rows;

        this.sortedBy(this.sort.split(','));
    }

    private _loadKeyFilterTypes() {
        if (this.filterState.getFollowUpType() == "CHASSI") {
            this.keyFilterTypes = ['IMP', 'REF', "Chassis"];
        } else {
            this.keyFilterTypes = ['IMP', 'REF', "SKU"];
        }
    }

    alerts = (_row, _cell, contentValue, columnDef, _dataContext) => {
        if (!contentValue.imp) return true;

        let indexRow = this.gridRows.findIndex(row => row.imp === contentValue.imp);

        if (this.gridRows[indexRow].validated) {
            return true;
        } else {
            $(_row).empty().append('<div class="spinner"></div>');
        }

        this.alertSubscription.subscribe(res => {
            let alerts = res.find(alert => alert.mainField === contentValue.imp);
            this.gridRows[indexRow].validated = true;

            if (contentValue.imp === alerts?.mainField) {
                const result = this.formatter.formatAlert(_row, _cell, alerts.items, columnDef, _dataContext);
                $(_row).empty().append(result);
            } else {
                $(_row).empty();
            }
        }, error => $(_row).empty());
        return true;
    };

}
