import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';

import { APIKeyGenerationComponent } from './api-key-generation/api-key-generation.component';
import { TechnicalIntegrationsComponent } from './technical-integrations.component';
import { WebhooksComponent } from './webhooks/webhooks.component';

import { APIKeygeneraationService } from './api-key-generation/api-key-generation.service';
import { SubscriptionsService } from './webhooks/subscriptions.service';
import { LayoutModule } from 'app/theme/layouts/layout.module';

const routes: Routes = [
    { path: '', component: TechnicalIntegrationsComponent },
    { path: ':page', component: TechnicalIntegrationsComponent },
    { path: ':page/:subPage', component: TechnicalIntegrationsComponent },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        LayoutModule,
        SharedModule,
    ],
    declarations: [
        TechnicalIntegrationsComponent,
        WebhooksComponent,
        APIKeyGenerationComponent
    ],
    exports: [TechnicalIntegrationsComponent],
    providers: [SubscriptionsService, APIKeygeneraationService],
    entryComponents: [
        WebhooksComponent,
        APIKeyGenerationComponent
    ]
})
export class TechnicalIntegrationsModule {}
