import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { User } from '../_models/user';
import moment from 'moment';

@Injectable()
export class UserService {

    private loggedUser: BehaviorSubject<User> = new BehaviorSubject(undefined);
    private loggedUserObservable = this.loggedUser.pipe(filter((value) => {
        return value !== undefined
    }));
    private authenticatedUser: BehaviorSubject<User> = new BehaviorSubject(undefined);
    private authenticatedUserObservable = this.authenticatedUser.pipe(filter((value) => {
        return value !== undefined
    }));

    constructor(private authService: AuthenticationService) {

        if (!this.localStorageHasUserData()) {
            this.clearUser()
        }

        if(this.authService.permissionsUserApi === true || !!this.authService.permissionsUserApi === null){
            this.authService.permissionsUserApi = false;
            const currentUser = this._authUserStorage;
            const permissionsUser = this._permUserStorage;
            if(currentUser && permissionsUser){
                this.userSubscribe(currentUser.data, permissionsUser);
                this.setIsLoggedIn(true);
            }else{
                this.authService.logout();
            }
        }

        this.loggedUser.subscribe((user: any) => {

            if (user) {
                localStorage.setItem('authUserNew', btoa(JSON.stringify(user)));
                this.authService.permissionsUserApi = true;
            }
        });
    }

    get currentUser$() {

        return this.loggedUserObservable;
    }

    get authenticatedUser$() {

        return this.authenticatedUserObservable;
    }

    private get _authUserStorage(){

        const currentUser = window.localStorage.getItem('authUserNew');
        return currentUser && JSON.parse(atob(currentUser));
    }

    private get _permUserStorage(){

        const permissions = window.localStorage.getItem('permUser');
        return permissions && JSON.parse(atob(permissions));
    }

    get userSessionIdFromLocalstorage(){

        const currentSessionId = window.localStorage.getItem('sessionId');
        return currentSessionId;
    }

    getDateToExpire(){
        return moment(new Date()).add(30, 'minutes');
    }

    updateToken(currentUser){

        this.updateDateExpire();
        this.authService.refreshToken(currentUser.refreshToken).then(data => {

            const user = this._authUserStorage;

            user.data.idToken = data.id_token;
            user.data.refreshToken = data.refresh_token;
            user.data.expireIn = this.getDateToExpire();

            localStorage.setItem('authUserNew', btoa(JSON.stringify(user)));

            const permissions = this._permUserStorage;
            this.userSubscribe(user.data, permissions);
        })
    }

    updateDateExpire(){
        const user = this._authUserStorage;

        user.data.expireIn = this.getDateToExpire();

        const permissions = this._permUserStorage;
        this.userSubscribe(user.data, permissions);
    }

    retryPermissionsUser(data): Promise<any>{

        return this.authService.getUserPermissions(data.idToken, window.localStorage.getItem('sessionId'), data.email);
    }

    refreshUser() {

        return this.dataOf();
    }

    setIsLoggedIn(isLogged: boolean) {

        localStorage.setItem('isLogged', isLogged.toString());
        this.authenticatedUser$.subscribe((user) => {
            if (this.isLoggedIn() && user) {
                this.loggedUser.next(user);
            }
        });
    }

    isLoggedIn() {
        return localStorage.getItem('isLogged');
    }

    getCurrentUser() {
        return this.authenticatedUser.getValue();
    }

    verifyLinkResetPassword(oobCode: string): Observable<any> {
        return this.authService.validateOobcode(oobCode).pipe();
    }

    confirmPasswordReset(oobCode, newPassword,token): Observable<any> {

        return this.authService.resetPassword(oobCode, newPassword,token).pipe();
    }

    updatePassword(idToken,sessionId,email, newPassword, oldPassword, captchaToken, mobile): Observable<any> {
        return this.authService.updatePassword(idToken,sessionId,email, newPassword, oldPassword, captchaToken, mobile).pipe();
    }

    userSubscribe(userDetails, permissions = null, emitEvent = true){

        this.emitToUser(new User(userDetails, new Set(permissions)));
    }

    clearUser() {
        this.emitToUser(null);
    }

    private emitToUser(user: User) {

        this.authenticatedUser.next(user);
        this.loggedUser.next(user);
    }

    private dataOf() {

        return this.loggedUser.subscribe(u => u);
    }

    private localStorageHasUserData(): boolean {

        if(localStorage.getItem('authUserNew')){
            return true;
        }
        return false;
    }
}
