import { environment } from '#environment';
import { allowedModePassword } from './../shared/types/allowed-mode-password.type';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppComponent } from '../app.component';
import { LoginService } from './_services/login.service';
import { UserAuthService } from './_services/user-auth.service';
import { UserService } from './_services/user.service';
import { AuthenticationService } from './_services/authentication.service';

const ERROR_CAPTCHA_MESSAGE = 'Favor resolver a verificação de segurança';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './auth-handler.component.html',
    styleUrls: ['./auth-handler.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AuthHandlerComponent implements OnInit {

    captchaEnable = environment.captchaEnable;
    model: any = {};
    initialized = false;
    isValidCode = false;
    firstAccess = false;
    userDomain = '';
    updated = false;
    loading = false;
    returnUrl: string;
    errorPasswordReset = '';
    msgPasswordReset = '';
    asyncLogin = false;
    isIE = false;
    resendDisabled = false;
    focusHit: boolean = false;
    expiredPassword: boolean = false;

    checkTotalPassword: boolean = false;
    checkNumbersCharsPassword: boolean = false;
    checkSpecialCharPassword: boolean = false;
    passwordAccept: boolean = false;

    flagModeExpired: allowedModePassword = 'EXPIRED_PASSWORD';
    flagModeReset: allowedModePassword = 'resetPassword';
    captchaToken: string;
    mobile: any = true;

    logoLogin = this.loginService.getLogo();
    backLogin = this.loginService.getBackground();
    client = this.loginService.getClient();

    firstPasswordVisible: boolean = true;
    secondPasswordVisible: boolean = true;

    @ViewChild('captcha') captcha;
    captchaValidated: boolean = true;
    captchaKey: string;
    showCaptcha: boolean = true;
    idToken: any;
    auth: any = {
        idToken: null,
        sessionId: null
    }

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private _router: Router,
        private loginService: LoginService,
        private app: AppComponent,
        private authService: AuthenticationService,
        private userAuthService: UserAuthService
    ) {}

    checkPassword(input) {

        const p = input.target.value;

        let anUpperCase = /[A-Z]/;
        let aLowerCase = /[a-z]/;
        let aNumber = /\d/;
        let aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|.|_]/;
        let obj: any = {};
        obj.result = true;

        let numUpper = 0;
        let numLower = 0;
        let numNums = 0;
        let numSpecials = 0;
        for (const char of p) {
            if (anUpperCase.test(char))
                numUpper++;
            else if (aLowerCase.test(char))
                numLower++;
            else if (aNumber.test(char))
                numNums++;
            else if (aSpecial.test(char))
                numSpecials++;
        }

        if (p.length >= 8) {
            this.checkTotalPassword = true;
        } else {
            this.checkTotalPassword = false;
        }

        if (numUpper >= 1 && numLower >= 1 && numNums >= 1) {
            this.checkNumbersCharsPassword = true;
        } else {
            this.checkNumbersCharsPassword = false;
        }

        if (numSpecials >= 1) {
            this.checkSpecialCharPassword = true;
        } else {
            this.checkSpecialCharPassword = false;
        }

        this.passwordAccept =
            this.checkTotalPassword === true &&
            this.checkNumbersCharsPassword === true &&
            this.checkSpecialCharPassword === true
                ? true
                : false;
    }

    ngOnInit() {
        this.showCaptcha = true;
        this.captchaKey = this.authService.getCaptchaKey();
        this.route.queryParams.subscribe((params) => {
            this.model.mode = params?.mode as allowedModePassword;
            this.model.actionCode = params?.oobCode;
            this.model.continueUrl = params?.continueUrl;

            if (this.model.mode === this.flagModeReset) {
                const queryParams = this.parseQuery(this.model?.continueUrl);
                this.firstAccess = queryParams['firstAccess'] === 'true';
                this.model.email = queryParams['email'];
            } else {
                this.model.email = params.email;
            }
            this.getUser();
        });
        this.app.globalLoader = false;
        $('.m-page-loader.m-page-loader--non-block').attr('style', 'display: none');
        this.authService.getTokenExpiredPassword().subscribe((data) => {
            this.auth = data;
            if (!this.auth && this.model.mode === 'EXPIRED_PASSWORD') {
                this._router.navigate(['/login']);
            }
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (
                this.captchaEnable &&
                this.captcha &&
                this.captcha.el.nativeElement.childNodes.length > 0 &&
                this.captcha.el.nativeElement.childNodes[0].childElementCount > 0
            ) {
                this.captchaValidated = false;
            }
        }, 1000);
    }

    resendLink() {
        if (this.resendDisabled === true) {
            return false;
        }
        this.asyncLogin = true;
        this.resendDisabled = true;
        this.showCaptcha = true;
        try {
            if (this.captchaValidated) {
                const origin = location.host;
                this.userAuthService
                    .sendPasswordResetEmail(
                        this.model.email,
                        this.model.firstAccess,
                        this.captchaToken,
                        this.mobile,
                        origin
                    )
                    .subscribe(
                        (data) => {
                            this.showCaptcha = false;
                            this.asyncLogin = false;
                            this.errorPasswordReset = '';
                            this.msgPasswordReset =
                                'Link enviado com sucesso.<br />Por favor verifique sua caixa ' +
                                'de entrada e tente novamente através do novo link.';
                        },
                        (error) => {
                            this.asyncLogin = false;
                            this.reenabledResentButton();
                            if (error.code === 'auth/invalid-email') {
                                this.errorPasswordReset = 'Email inválido';
                            } else if (error.code === 'auth/user-not-found') {
                                this.errorPasswordReset = 'Email não cadastrado';
                            } else {
                                this.errorPasswordReset =
                                    'Não foi possível enviar o email para recadastro de senha. ' +
                                    'Por favor tente novamente mais tarde.';
                            }
                        }
                    );
            } else {
                this.errorPasswordReset = ERROR_CAPTCHA_MESSAGE;
                this.asyncLogin = false;
            }
        } catch (err) {
            this.asyncLogin = false;
            this.reenabledResentButton();
            this.errorPasswordReset =
                'Não foi possível enviar o email para recadastro de senha. ' + 'Por favor tente novamente mais tarde.';
        }
    }

    confirmPasswordReset() {
        this.errorPasswordReset = '';
        this.msgPasswordReset = '';
        this.updated = false;
        this.showCaptcha = true;

        if (
            typeof this.model.password !== 'string' ||
            this.model.password === undefined ||
            this.model.password === ''
        ) {
            this.errorPasswordReset = 'Insira uma senha válida';
            this.asyncLogin = false;
        } else if (
            typeof this.model.passwordConfirmation !== 'string' ||
            this.model.passwordConfirmation === undefined ||
            this.model.passwordConfirmation === ''
        ) {
            this.errorPasswordReset = 'Por favor confirme a senha';
            this.asyncLogin = false;
        } else if (this.model.passwordConfirmation !== this.model.password) {
            this.errorPasswordReset = 'A confirmação da senha está incorreta';
            this.asyncLogin = false;
        } else {
            let credential = this.model.email.split('@');
            let name = credential[0].split('.');
            let company = credential[1].split('.');
            this.asyncLogin = true;

            let regex = new RegExp(name[0] + '|' + name[1] + '|' + company[0], 'gi');

            if (this.model.password.match(regex)) {
                this.resetForm();
                this.errorPasswordReset = 'A senha não pode conter nomes e/ou domínios que existe em seu e-mail.';
                this.asyncLogin = false;
                return;
            }

            if (!this.authService.getPasswordRegex().test(this.model.password)) {
                this.resetForm();
                this.errorPasswordReset = 'A sua senha não atendeu o mínimo requerido, favor informar uma nova senha';
            } else if (this.captchaValidated) {
                if (this.model.mode === 'EXPIRED_PASSWORD') {
                    this.userService
                        .updatePassword(
                            this.auth.idToken,
                            this.auth.sessionId,
                            this.model.email,
                            this.model.password,
                            this.model.oldPassword,
                            this.captchaToken,
                            this.mobile
                        )
                        .subscribe(
                            (data) => {
                                this.msgPasswordReset = 'Senha alterada com sucesso';
                                this.updated = true;
                                this.showCaptcha = false;
                                this.asyncLogin = false;
                            },
                            (error) => {
                                if (error.code === 'auth/weak-password') {
                                    this.resetForm();
                                    if (error.message === 'Password should be at least 8 characters') {
                                        this.errorPasswordReset = 'A senha deve conter ao menos 8 caracteres';
                                    } else {
                                        this.errorPasswordReset = error.message;
                                    }
                                } else if (error.error === 'SAME_PASSWORD') {
                                    this.errorPasswordReset = 'A sua nova senha deve ser diferente da senha atual';
                                    this.asyncLogin = false;
                                } else if (error?.error === 'INVALID_CAPTCHA') {
                                    this.errorPasswordReset = 'Favor resolver a verificação de segurança';
                                    this.asyncLogin = false;
                                } else {
                                    this.errorPasswordReset = 'Não foi possível alterar a sua senha';
                                    this.asyncLogin = false;
                                }
                            }
                        );
                } else {
                    this.userService
                        .confirmPasswordReset(this.model.actionCode, this.model.password, this.captchaToken)
                        .subscribe(
                            (data) => {
                                this.msgPasswordReset = 'Senha alterada com sucesso';
                                this.updated = true;
                                this.showCaptcha = false;
                                this.asyncLogin = false;
                            },
                            (error) => {
                                if (error.code === 'auth/weak-password') {
                                    this.resetForm();
                                    if (error.message === 'Password should be at least 8 characters') {
                                        this.errorPasswordReset = 'A senha deve conter ao menos 8 caracteres';
                                    } else {
                                        this.errorPasswordReset = error.message;
                                    }
                                } else {
                                    this.errorPasswordReset = 'Não foi possível alterar a sua senha';
                                    this.asyncLogin = false;
                                }
                            }
                        );
                }
            } else {
                this.errorPasswordReset = ERROR_CAPTCHA_MESSAGE;
                this.updated = false;
                this.asyncLogin = false;
            }
        }
    }

    resetForm(): void {
        this.asyncLogin = false;
        this.model.password = null;
        this.model.passwordConfirmation = null;
        this.passwordAccept = false;
    }

    continue() {
        const clientUrl = this.getClientUrl();
        window.open(clientUrl, '_self');
    }

    goToLogin() {
        this._router.navigate(['/login']);
    }

    private getClientUrl() {
        const l = document.createElement('a');
        l.href = this.model.continueUrl;
        const queryParams = this.parseQuery(this.model.continueUrl);
        const clientDomain = queryParams['domain'];
        return `${l.protocol}//${clientDomain}/login`;
    }

    private reenabledResentButton() {
        this.resendDisabled = false;
    }

    private parseQuery(url) {
        const query = {};
        const index: number = url.indexOf('?');
        const pairs = url.substr(index + 1).split('&');
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    private getUser() {
        this.userService.authenticatedUser$.subscribe((user) => {
            if (user === null && this.isIE === true) {
                setTimeout(() => {
                    this.getUser();
                }, 2000);
            } else {
                this.initialize();
                if (this.model.mode === this.flagModeReset) {
                    this.isValidCode = true;
                    this.asyncLogin = false;
                    this._verifyLinkResetPassowrd();
                } else if (this.model.mode === this.flagModeExpired) {
                    this.isValidCode = true;
                    this.asyncLogin = false;
                    this.expiredPassword = true;
                } else {
                    this.errorPasswordReset = 'Link inválido';
                }
            }
        });
    }

    validateCaptcha($event) {
        this.captchaValidated = true;
        this.captchaToken = $event.response;
    }

    resetCaptcha() {
        this.captchaValidated = false;
        this.captcha.reset();
    }

    private initialize() {
        this.app.globalLoader = false;
        this.initialized = true;
    }

    private _verifyLinkResetPassowrd() {
        this.userService.verifyLinkResetPassword(this.model.actionCode).subscribe(
            () => {
                this.isValidCode = true;
                this.asyncLogin = false;
            },
            () => {
                if (this.firstAccess === true) {
                    this.errorPasswordReset =
                        'Link de primeiro acesso expirado. <br/>Por favor, clicar no botão abaixo para receber novo link.';
                } else {
                    this.errorPasswordReset =
                        'Link de recuperação inválido ou expirado. <br/>Por favor, clicar no botão abaixo para receber novo link.';
                }
                this.asyncLogin = false;
                this.isValidCode = false;
                this.reenabledResentButton();
            }
        );
    }

    changePasswordVisibility(firstPassword) {
        if (firstPassword) {
            this.firstPasswordVisible = !this.firstPasswordVisible;
        } else {
            this.secondPasswordVisible = !this.secondPasswordVisible;
        }
    }

    samePasswords() {
        return this.model.passwordConfirmation !== this.model.password && this.model.passwordConfirmation.length > 7;
    }
}
