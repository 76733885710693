import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/_guards/auth.guard';
import { ThemeComponent } from './theme.component';

const routes: Routes = [
    {
        path: '',
        component: ThemeComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                canActivateChild: [AuthGuard],
                children: [
                    // Menu Perfil
                    {
                        path: 'adm',
                        loadChildren: () => import('./pages/adm-panel/adm-panel.module').then(m => m.AdmModule),
                    },
                    {
                        path: 'configurations',
                        loadChildren: () => import('./pages/configuration/configurations.module').then(m => m.ConfigurationModule),
                    },
                    {
                        path: 'profile',
                        loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfileModule),
                    },
                    {
                        path: 'timesheet',
                        loadChildren: () => import('../modules/menu/pages/timesheet/timesheet.module').then(m => m.TimesheetModule),
                    },
                    {
                        path: 'integracoes-tech',
                        loadChildren: () => import('./pages/technical-integrations/technical-integrations.module').then(m => m.TechnicalIntegrationsModule),
                    },


                    // DOCUMENTOS
                    {
                        path: 'documentos',
                        loadChildren: () => import('../modules/documents/document.module').then(m => m.DocumentsModule),
                    },
                    {
                        path: 'documentos/:imp',
                        loadChildren: () => import('../modules/documents/document.module').then(m => m.DocumentsModule),
                    },


                    // LOGÍSTICA INICIO
                    {
                        path: 'logistica/carregamento',
                        loadChildren: () => import('../modules/logistic/pages/delivery-coordination/delivery-coordination.module').then(m => m.DeliveryCoordinationModule),
                    },
                    {
                        path: 'logistica/carregamento/indireta/:id',
                        loadChildren: () => import('../modules/logistic/pages/delivery-coordination/delivery-coordination-indirect-detail/delivery-coordination-indirect-detail.module').then(m => m.DeliveryCoordinationIndirectDetailModule),
                    },
                    {
                        path: 'logistica/carregamento/distribuicao/:id',
                        loadChildren: () => import('../modules/logistic/pages/delivery-coordination/delivery-coordination-textile-detail/delivery-coordination-textile-detail.module').then(m => m.DeliveryCoordinationTextileDetailModule),
                    },
                    {
                        path: 'logistica/cotacao',
                        loadChildren: () => import('../modules/logistic/pages/international-quotation/international-quotation.module').then(m => m.InternationalQuotationModule),
                    },
                    {
                        path: 'logistica/simular-frete',
                        loadChildren: () => import('../modules/logistic/pages/simulate-freight-rate/simulate-freight-rate.module').then(m => m.SimulateFreightRateModule),
                    },
                    {
                        path: 'devolucao-container',
                        loadChildren: () => import('../modules/logistic/pages/return-container/return-container.module').then(m => m.ReturnContainerModule),
                    },


                    // CADASTROS INICIO
                    {
                        path: 'cadastro/automotivo',
                        loadChildren: () => import('../modules/crud/pages/automotive/automotive.module').then(m => m.AutomotiveCrudModule),
                    },
                    {
                        path: 'cadastro/financeiro',
                        loadChildren: () => import('../modules/crud/pages/finance/finance.module').then(m => m.FinanceCRUDModule),
                    },
                    {
                        path: 'cadastro/financeiro/:redirect',
                        loadChildren: () => import('../modules/crud/pages/finance/finance.module').then(m => m.FinanceCRUDModule),
                    },
                    {
                        path: 'cadastro/supply-chain',
                        loadChildren: () => import('../modules/crud/pages/supply-chain/supply-chain.module').then(m => m.SupplyChainCrudModule),
                    },
                    {
                        path: 'cadastro/invoice-external',
                        loadChildren: () => import('./pages/default/invoice-external/invoice-external.module').then(m => m.InvoiceExternalModule),
                    },
                    {
                        path: 'cadastro/pedidos',
                        loadChildren: () => import('./pages/sales-order/sales-order.module').then(m => m.SalesOrderModule),
                    },
                    {
                        path: 'cadastro/cambio',
                        loadChildren: () => import('../modules/crud/pages/exchange/exchange.module').then(m => m.ExchangeCRUDModule),
                    },
                    {
                        path: 'cadastro/integracoes',
                        loadChildren: () => import('../modules/crud/pages/integrations/integrations.module').then(m => m.IntegrationsCRUDModule),
                    },
                    {
                        path: 'cadastro/logistica',
                        loadChildren: () => import('../modules/crud/pages/logistic/logistic.module').then(m => m.LogisticCRUDModule),
                    },
                    {
                        path: 'quotation', // Cadastro de Agente de Carga
                        loadChildren: () => import('./pages/quotation/freight-forwarder.module').then(m => m.FreightForwarderModule),
                    },
                    {
                        path: 'invoice/purchase_order/:id',
                        redirectTo: 'cadastro/pedidos/detalhe/:id'
                    },
                    {
                        path: 'invoice/:type/:id',
                        loadChildren: () => import('./pages/default/invoice-external/components/invoice-item/invoice-external-item.module').then(m => m.InvoiceExternalItemModule),
                    },
                    // CADASTROS FIM


                    // INTEGRAÇÕES INICIO
                    {
                        path: 'integracoes/fornecedores/embarque',
                        loadChildren: () => import('../modules/integrations/pages/suppliers/bill-of-lading/bill-of-lading.module').then(m => m.BillOfLadingModule),
                    },
                    {
                        path: 'integracoes/fornecedores/pagamentos',
                        loadChildren: () => import('../modules/integrations/pages/suppliers/payment-request/payment-request.module').then(m => m.PaymentRequestModule),
                    },
                    // INTEGRAÇÕES FIM


                    // RELATÓRIOS INICIO
                    {
                        path: 'report', // Verificar
                        loadChildren: () => import('./pages/reports/reports.module').then(m => m.OldReportsModule),
                    },
                    {
                        path: 'relatorios',
                        loadChildren: () => import('../modules/reports/reports.module').then(m => m.ReportsModule),
                    },
                    // RELATÓRIOS FIM


                    // PERFORMANCE INICIO
                    {
                        path: 'performance/canal-status',
                        loadChildren: () => import('../modules/performance/pages/channel-status/channel-status.module').then(m => m.ChannelStatusPerformanceModule),
                    },
                    {
                        path: 'performance/geren-alertas',
                        loadChildren: () => import('../modules/performance/pages/alerts-manager/alerts-manager.module').then(m => m.AlertsManagerPerformanceModule),
                    },
                    {
                        path: 'performance/sla-status',
                        loadChildren: () => import('../modules/performance/pages/sla-status/sla-status.module').then(m => m.SlaStatusPerformanceModule),
                    },
                    {
                        path: 'performance/validacao-di',
                        loadChildren: () => import('../modules/performance/pages/di-validation/di-validation.module').then(m => m.DiValidationPerformanceModule),
                    },
                    // PERFORMANCE FIM


                    // OPERACIONAL INICIO
                    {
                        path: 'operacional/aprovacoes',
                        loadChildren: () => import('../modules/operational/pages/approval-flow/approval-flow.module').then(m => m.ApprovalFlowModule),
                    },
                    {
                        path: 'operacional/precificacao',
                        loadChildren: () => import('../modules/operational/pages/project-pricing/project-pricing.module').then(m => m.ProjectPricingModule),
                    },
                    {
                        path: 'operacional/emissao-documentos',
                        loadChildren: () => import('../modules/operational/pages/documents-emission/documents-emission.module').then(m => m.DocumentsEmissionModule),
                    },
                    // CHASSI ROUTE
                    {
                        path: 'operacional/chassi',
                        loadChildren: () => import('./pages/supply-chain/chassi-status/chassi-status-page.module').then(m => m.ChassiStatusModule),
                    },
                    {
                        path: 'operacional/chassi/:companyCode/:startDate/:endDate',
                        loadChildren: () => import('./pages/supply-chain/chassi-status/chassi-status-page.module').then(m => m.ChassiStatusModule),
                    },
                    {
                        path: 'operacional/chassi/:chassi',
                        loadChildren: () => import('./pages/supply-chain/chassi-status/chassi-status-page.module').then(m => m.ChassiStatusModule),
                    },
                    {
                        path: 'operacional/chassi/:startDate/:endDate',
                        loadChildren: () => import('./pages/supply-chain/chassi-status/chassi-status-page.module').then(m => m.ChassiStatusModule),
                    },
                    {
                        path: 'operacional/chassi/:companyCode/:referenceDateType/:startDate/:endDate',
                        loadChildren: () => import('./pages/supply-chain/chassi-status/chassi-status-page.module').then(m => m.ChassiStatusModule),
                    },
                    // Supply-Chain Routes
                    {
                        path: 'operacional/importacao',
                        loadChildren: () => import('../modules/operational/pages/imp-status/imp-status.module').then(m => m.ImportStatusModule),
                    },
                    {
                        path: 'operacional/sku',
                        loadChildren: () => import('../modules/operational/pages/sku-status/sku-status.module').then(m => m.SkuStatusModule),
                    },
                    {
                        path: 'operacional/importacao/:companyCode/:startDate/:endDate',
                        loadChildren: () => import('../modules/operational/pages/imp-status/imp-status.module').then(m => m.ImportStatusModule),
                    },
                    {
                        path: 'operacional/importacao/:companyCode/:referenceDateType/:startDate/:endDate/:transportTypeDescription',
                        loadChildren: () => import('../modules/operational/pages/imp-status/imp-status.module').then(m => m.ImportStatusModule),
                    },
                    {
                        path: 'operacional/importacao/:companyCode/:referenceDateType/:startDate/:endDate',
                        loadChildren: () => import('../modules/operational/pages/imp-status/imp-status.module').then(m => m.ImportStatusModule),
                    },
                    {
                        path: 'operacional/importacao/faturadas/:filter',
                        loadChildren: () => import('../modules/operational/pages/imp-status/imp-status.module').then(m => m.ImportStatusModule),
                    },
                    {
                        path: 'operacional/pedidos',
                        loadChildren: () => import('../modules/operational/pages/po-status/po-status.module').then(m => m.POStatusModule),
                    },
                    // OPERACIONAL FIM


                    // FINANCEIRO INICIO
                    {
                        path: 'financeiro/custos',
                        loadChildren: () => import('./pages/costs/new-costs.module').then(m => m.NewCostsModule),
                    },
                    {
                        path: 'financeiro/resultado',
                        loadChildren: () => import('./pages/default/balance-sheet/balance-sheet.module').then(m => m.BalanceSheetModule),
                    },
                    {
                        path: 'financeiro/premissa-comercial',
                        loadChildren: () => import('../modules/financial/pages/premise/commercial-premise.module').then(m => m.CommercialPremiseModule),
                    },
                    {
                        path: 'financeiro/premissa-comercial/:id/:version',
                        loadChildren: () => import('../modules/financial/pages/premise/premise-detail/premise-detail.module').then(m => m.PremiseDetailModule),
                    },
                    {
                        path: 'financeiro/estudo-tributario',
                        loadChildren: () => import('../modules/financial/pages/tax-study/tax-study.module').then(m => m.TaxStudyModule),
                    },
                    {
                        path: 'financeiro/estudo-tributario/:id',
                        loadChildren: () => import('../modules/financial/pages/tax-study/tax-study-detail/tax-study-detail.module').then(m => m.TaxStudyDetailModule),
                    },
                    // Fechamento Financeiro
                    {
                        path: 'financeiro/fechamento',
                        loadChildren: () => import('../modules/financial/pages/payment-closing/payment-closing.module').then(m => m.PaymentClosingModule),
                    },
                    {
                        path: 'financeiro/rateio/maquina',
                        loadChildren: () => import('./pages/default/maquina-rateio/maquina-rateio.module').then(m => m.MaquinaRateioModule),
                    },
                    {
                        path: 'financeiro/taxas-cambiais',
                        loadChildren: () => import('./pages/finance/currency-exchange/currency-exchange.module').then(m => m.CurrencyExchangeModule),
                    },
                    {
                        path: 'financeiro/cambio',
                        loadChildren: () => import('./pages/finance/exchange/exchange.module').then(m => m.ExchangeModule),
                    },
                    {
                        path: 'financeiro/consulta-fiscal',
                        loadChildren: () => import('../modules/financial/pages/tax-consultation/tax-consultation.module').then(m => m.TaxConsultationModule),
                    },
                    {
                        path: 'financeiro/consulta-fiscal/:id',
                        loadChildren: () => import('../modules/financial/pages/tax-consultation/tax-consultation-details/tax-consultation-details.module').then(m => m.TaxConsultationDetailsModule),
                    },
                    {
                        path: 'financeiro/pacote',
                        loadChildren: () => import('./pages/finance/exchange/exchange-package/exchange-package.module').then(m => m.ExchangePackageModule),
                    },
                    {
                        path: 'financeiro/faturamento-cr',
                        loadChildren: () => import('./pages/cr-processes-table-page/cr-processes-table-page.module').then(m => m.CrProcessesTablePageModule),
                    },
                    // FINANCEIRO FIM


                    // External Routes
                    {
                        path: 'external',
                        loadChildren: () => import('./pages/external/external.module').then(m => m.ExternalModule),
                    },
                    // Invoice Status Routes
                    {
                        path: 'supply-chain/notas-fiscais',
                        loadChildren: () => import('./pages/invoice-status/invoice-status.module').then(m => m.InvoiceStatusModule),
                    },
                    // Invoices Receivable
                    {
                        path: 'invoices-receivable',
                        loadChildren: () => import('./pages/invoices-receivable/invoices-receivable.module').then(m => m.InvoicesReceivableModule),
                    },
                    {
                        path: 'supply-chain/importacao/:company/:imp',
                        loadChildren: () => import('./pages/import-details/import-details.module').then(m => m.ImportDetailsModule),
                    },
                    {
                        path: 'supply-chain/kpis',
                        loadChildren: () => import('./pages/supply-chain/kpi/kpi.module').then(m => m.KpiModule),
                    },
                    {
                        path: 'supply-chain/slas',
                        loadChildren: () => import('./pages/supply-chain/sla/sla.module').then(m => m.SlaModule),
                    },
                    // Fulfillment Routes
                    {
                        path: 'fulfillment/pedidos-compra',
                        loadChildren: () => import('./pages/fulfillment/pedidos-compra/pedidos-compra.module').then(m => m.PedidosCompraModule),
                    },
                    {
                        path: 'fulfillment/estoque',
                        loadChildren: () => import('./pages/fulfillment/estoque/estoque.module').then(m => m.EstoqueModule),
                    },
                    {
                        path: 'fulfillment/entradas',
                        loadChildren: () => import('./pages/fulfillment/entradas/entradas.module').then(m => m.EntradasModule),
                    },
                    {
                        path: 'fulfillment/saidas',
                        loadChildren: () => import('./pages/fulfillment/saidas/saidas.module').then(m => m.SaidasModule),
                    },
                    {
                        path: 'fulfillment/transito',
                        loadChildren: () => import('./pages/fulfillment/traffic/traffic.module').then(m => m.TrafficModule),
                    },
                    {
                        path: 'locations',
                        loadChildren: () => import('./pages/quotation/locations.module').then(m => m.LocationsModule),
                    },
                    {
                        path: 'taxes',
                        loadChildren: () => import('./pages/quotation/taxes.module').then(m => m.TaxesModule),
                    },


                    {
                        path: 'personalizacao/dashboard',
                        loadChildren: () => import('./pages/default/custom-dashboard/custom-dashboard.module').then(m => m.CustomDashboardModule),
                    },
                    // Pagina Teste Routes
                    {
                        path: 'pagina-teste',
                        loadChildren: () => import('./pages/pagina-teste/pagina-teste.module').then(m => m.PaginaTesteModule),
                    },

                    // Index and 404 Routes
                    {
                        path: 'index',
                        loadChildren: () => import('./pages/default/index/index.module').then(m => m.IndexModule),
                    },
                    {
                        path: 'dashboard/operacional',
                        loadChildren: () => import('./pages/default/index/index.module').then(m => m.IndexModule),
                    },
                ],
            },
        ],
    },
    {
        path: '',
        redirectTo: 'index',
        pathMatch: 'full',
    },
    {
        path: 'dashboard/operacional',
        redirectTo: 'index/operacional',
        pathMatch: 'full',
    },
    {
        path: '403',
        loadChildren:
            () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-1/errors-error-1.module').then(m => m.ErrorsError1Module),
    },
    {
        path: 'snippets/pages/errors/error-2',
        loadChildren:
            () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-2/errors-error-2.module').then(m => m.ErrorsError2Module),
    },
    {
        path: '404',
        loadChildren:
            () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-3/errors-error-3.module').then(m => m.ErrorsError3Module),
    },
    {
        path: 'snippets/pages/errors/error-4',
        loadChildren:
            () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-4/errors-error-4.module').then(m => m.ErrorsError4Module),
    },
    {
        path: 'snippets/pages/errors/error-5',
        loadChildren:
            () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-5/errors-error-5.module').then(m => m.ErrorsError5Module),
    },
    {
        path: 'snippets/pages/errors/error-6',
        loadChildren:
            () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-6/errors-error-6.module').then(m => m.ErrorsError6Module),
    },
    {
        path: '**',
        redirectTo: '404',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ThemeRoutingModule {}
