import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild, ViewEncapsulation, ElementRef, Renderer2 } from '@angular/core';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import QRCode from 'qrcodejs2';
import Inputmask from 'inputmask';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/auth/_services/login.service';
import { UserService } from 'app/auth/_services/user.service';
import { AuthenticationService } from 'app/auth/_services/authentication.service';

@Component({
    selector: 'edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditProfileComponent implements AfterViewInit {
    currentUser$ = this.userService.currentUser$;
    user: any = this.userService.getCurrentUser();
    currentEmail = this.user.getEmail();

    public asyncChangePassword = true;
    public asyncTwoFactor = true;
    public asyncEditPhone = true;
    public actualPassword = '';
    public newPassword = '';
    public repeatPassword = '';
    public errorMessage = '';
    public successMessage = null;
    public initialInfoMessage = 'Clique em "Habilitar" para ativar a sua autenticação por dois fatores.';
    public infoMessage = 'Utilize o QR Code a seguir para cadastrar sua conta no Google Authenticator';
    public warnMessage = null;
    public twoFactorMandatory = false;
    public confirmTwoFactorMoment = false;

    focusHit: boolean = false;
    checkTotalPassword:boolean = false;
    checkNumbersCharsPassword:boolean = false;
    checkSpecialCharPassword:boolean = false;
    passwordAccept: boolean = false;
    confirmationTwoFactorSuccess: boolean = false;

    public qrcode: any = null;
    public twoFactorKey: string = null;
    public captchaToken: string = '';
    public mobile: any = true;
    public idToken: String = ''
    public sessionId: String = ''
    public logoLogin = this.loginService.getLogo();

    whatsappUrl;
    otpInterval = null;
    phoneFeatureFlag: boolean = false;
    phoneMask: any = Inputmask({ mask: '(99) 99999-9999' });
    showOtp: boolean = false;
    phoneNumber: any = null;
    formattedPhoneNumber: any = null;
    updatePhoneNumber: boolean = false;
    phoneIntegrationData: any = null;

    @ViewChild('enableTwoFactorModal') private _elEnableTwoFactorModal: ElementRef;
    @ViewChild('enableAIModal') private _elEnableAIModal: ElementRef;
    @ViewChild('editPhoneModal') private _elEditPhoneModal: ElementRef;
    @ViewChild('closeConfirmationModal') private _elCloseConfirmationModal: ElementRef;
    @ViewChild('changePasswordModal') private _elChangePasswordModal: ElementRef;
    @ViewChild('twoFactorTutorial') private _elTwoFactorTutorial: ElementRef;

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private loginService: LoginService,
        private authService: AuthenticationService,
        private _featureFlagService: FeatureFlagService,
        private _renderer: Renderer2,
        private route: ActivatedRoute,
        private app: AppComponent,
    ) { }

    ngOnInit() {
        this.getFeatureFlag();
        this.userService.refreshUser();
        this.user = this.userService.getCurrentUser();
        this.currentEmail = this.user.getEmail();
        this.twoFactorMandatory = this.user.hasToEnableTwoFactor();
        this.twoFactorKey = this.user.getTwoFactorKey();

        this.route.queryParams.subscribe((params) => {
            if(params.mode) {
                $('edit-profile .changePasswordModal').removeClass('hidden')
                this.openChangePasswordModal();
            }
        });
        this.app.globalLoader = false;
        this.idToken = localStorage.getItem('idToken');
        this.sessionId = localStorage.getItem('sessionId');
        this.phoneMask.mask($(`phone-number`));
        this.getPhoneIntegrationData();
    }

    ngAfterViewInit() {
        this.showQRCode();
        if (this.user.hasToEnableTwoFactor()) {
            this.openTwoFactorModal();
        }
    }

    ngOnDestroy() {
        clearInterval(this.otpInterval);
    }

    startOtpCodePolling() {
        this.otpInterval = setInterval(() => this.getPhoneIntegrationData(), 60000);
    }

    async getFeatureFlag(): Promise<void> {
        this.phoneFeatureFlag = await this._featureFlagService.isFeatureFlagEnabledV2('profile_enable_ai');
    }

    getPhoneIntegrationData() {
        const uid = this.userService.getCurrentUser().getUid();
        this.http.get(`${environment.endpoints.adminService}/api/v1/phone-integration/user/${uid}`).subscribe(
            (data: any) => {
                this.phoneIntegrationData = data;
                this.phoneNumber = data.phoneNumber;
                const match = data.phoneNumber.match(/^(\d{2})(\d{5})(\d{4})$/);
                this.formattedPhoneNumber = `(${match[1]}) ${match[2]}-${match[3]}`;
                this.whatsappUrl = this._generateWhatsappUrl(data.otpCode);
                if (data.otpCode === null && this.otpInterval !== null) {
                    clearInterval(this.otpInterval);
                } else if (data.otpCode !== null && this.otpInterval === null) {
                    this.startOtpCodePolling();
                }
            }
        );
    }

    private _generateWhatsappUrl(otpCode: string | null): string {
        const baseUrl = environment.chatAI.whatsappBaseUrl;
        const name = environment.chatAI.name;
        const defaultMessage = `Olá ${name}!`;
        const messageWithOtp = `Olá ${name}, meu código de acesso é #${otpCode} !`;

        const message = otpCode === null ? defaultMessage : messageWithOtp;
        const encodedMessage = encodeURIComponent(message);

        return `${baseUrl}?text=${encodedMessage}`;
      }

    setShowOtp(showOtp: boolean) {
        this.showOtp = showOtp;
    }

    editPhoneOnKeydown(event) {
        if (event.key === 'Enter') {
            this.savePhoneIntegration();
        }
    }

    savePhoneIntegration() {
        if (this.verifyPhoneNumber(this.phoneNumber) === false) {
            this.errorMessage = 'Número de telefone inválido';
            this.phoneNumber = this.updatePhoneNumber ? this.phoneIntegrationData.phoneNumber : null;
            return;
        }

        if (this.updatePhoneNumber) {
            this.updatePhoneIntegration();
        } else {
            this.createPhoneIntegration();
        }
        this.closeEditPhoneModal();
    }

    createPhoneIntegration() {
        this.asyncEditPhone = false;
        const uid = this.userService.getCurrentUser().getUid();
        this.http.post(`${environment.endpoints.adminService}/api/v1/phone-integration/user/${uid}/${this.phoneNumber}`, '').subscribe(
            (data: any) => {
                this.asyncEditPhone = true;
                this.getPhoneIntegrationData();
                this.startOtpCodePolling();
            },
            (error) => {
                this.asyncEditPhone = true;
                this.errorMessage = 'Não foi possível adicionar o número de telefone';
            }
        );
    }

    updatePhoneIntegration() {
        this.asyncEditPhone = false;
        const uid = this.userService.getCurrentUser().getUid();
        this.http.put(`${environment.endpoints.adminService}/api/v1/phone-integration/user/${uid}/${this.phoneNumber}`, '').subscribe(
            (data: any) => {
                this.asyncEditPhone = true;
                this.getPhoneIntegrationData();
            },
            (error) => {
                this.asyncEditPhone = true;
                this.errorMessage = 'Não foi possível atualizar o número de telefone';
            }
        );
    }

    verifyPhoneNumber(number) {
        return number !== null && number.length === 11;
    }

    openChangePasswordModal() {
        if(this._elChangePasswordModal){
            this._renderer.removeClass(this._elChangePasswordModal.nativeElement, 'hidden');
        }
    }

    openTwoFactorModal() {

        this._renderer.removeClass(this._elEnableTwoFactorModal.nativeElement, 'hidden');
    }
    openEditPhoneModal() {
        this.updatePhoneNumber = this.phoneIntegrationData ? true : false;
        this._renderer.removeClass(this._elEditPhoneModal.nativeElement, 'hidden');
    }

    openEnableAIModal() {
        this._renderer.removeClass(this._elEnableAIModal.nativeElement, 'hidden');
    }

    closeChangePasswordModal() {

        if(this.successMessage){
            this.authService.logout();
        }

        this.clearInputs();
        this._renderer.addClass(this._elChangePasswordModal.nativeElement, 'hidden');
    }

    closeEditPhoneModal() {
        this.errorMessage = '';
        this._renderer.addClass(this._elEditPhoneModal.nativeElement, 'hidden');
    }

    closeEnableAIModal() {
        this._renderer.addClass(this._elEnableAIModal.nativeElement, 'hidden');
        this.setShowOtp(false);
    }

    closeEnableTwoFactorModal() {

        if(this.confirmationTwoFactorSuccess){

            this.authService.logout();
            return;
        }
        this.clearInputs();
        this._renderer.addClass(this._elEnableTwoFactorModal.nativeElement, 'hidden');
    }

    changePassword() {
        this.errorMessage = '';

        let credential = this.currentEmail.split('@');
        let nameEmail = credential[0].split('.');
        let domainEmail = credential[1].split('.');
        let regex = new RegExp(nameEmail[0] + '|' + nameEmail[1] + '|' + domainEmail[0], 'gi')

        if (this.actualPassword === '') {
            this.errorMessage = 'Insira sua senha atual';
            return;
        }

        if (this.newPassword === '') {

            this.errorMessage = 'Insira uma nova senha';
            return;
        }

        if (this.newPassword.length < 8) {
            this.errorMessage = 'Sua senha deve conter pelo menos 8 dígitos';
            return;
        }

        if (this.newPassword !== this.repeatPassword) {
            this.errorMessage = 'As senhas não correspondem';
            return;
        }

        if(this.newPassword.match(regex)){
            this.errorMessage = 'A senha não pode conter nomes e/ou domínios que existe em seu e-mail.';
            return;
        }

        if (!this.passwordAccept && !this.authService.getPasswordRegex().test(this.newPassword)) {
            this.errorMessage = 'A sua senha não atendeu o mínimo requerido, favor informar uma nova senha';
            return;
        }

        this.asyncChangePassword = false;
        this.authService.updatePassword(this.idToken,this.sessionId,this.currentEmail,this.newPassword ,this.actualPassword, this.captchaToken,this.mobile).subscribe(
            () => {
                this.successMessage = 'Senha alterada';
                this.asyncChangePassword = true;
                setTimeout(this.closeChangePasswordModal.bind(this), 5000);
            },
            (error) => {
                this.asyncChangePassword = true;

                if (error.code === 'auth/weak-password') {
                    if (error.message === 'Password should be at least 8 characters') {
                        this.errorMessage = 'A senha deve conter ao menos 8 caracteres';
                    } else {
                        this.errorMessage = error.message;
                    }
                } else if(error.error === "SAME_PASSWORD") {
                    this.errorMessage = 'A sua nova senha deve ser diferente da senha atual'
                } else if(error?.error === 'INVALID_CAPTCHA') {
                    this.errorMessage = 'Favor resolver a verificação de segurança';
                }
                else {
                    this.errorMessage = 'Não foi possível alterar a sua senha';
                }
            }
        );
    }

    checkPassword(input){

        const p = input.target.value;

        let anUpperCase = /[A-Z]/;
        let aLowerCase = /[a-z]/;
        let aNumber = /\d/;
        let aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
        let obj:any = {};
        obj.result = true;

        let numUpper = 0;
        let numLower = 0;
        let numNums = 0;
        let numSpecials = 0;
        for (const char of p) {
            if(anUpperCase.test(char))
                numUpper++;
            else if(aLowerCase.test(char))
                numLower++;
            else if(aNumber.test(char))
                numNums++;
            else if(aSpecial.test(char))
                numSpecials++;
        }

        if(p.length >= 8){
            this.checkTotalPassword = true;
        }else{
            this.checkTotalPassword = false;
        }

        if(numUpper >= 1 && numLower >= 1 && numNums >= 1){
            this.checkNumbersCharsPassword = true;
        }else{
            this.checkNumbersCharsPassword = false;
        }

        if(numSpecials >= 1){
            this.checkSpecialCharPassword = true;
        }else{
            this.checkSpecialCharPassword = false;
        }

        this.passwordAccept = this.checkTotalPassword === true && this.checkNumbersCharsPassword === true && this.checkSpecialCharPassword === true ? true : false;
    }

    getQRCodeUrl() {
        const email = this.currentEmail;
        return `otpauth://totp/Comexport:${email}?secret=${this.twoFactorKey}&issuer=Comexport&algorithm=SHA1&digits=6&period=30`;
    }

    showQRCode() {
        if (this.isTwoFactorEnabled()) {
            if (this.qrcode) {
                this.qrcode.clear();
            }
            $('#qrcode').empty();
            this.qrcode = new QRCode('qrcode', {
                text: this.getQRCodeUrl(),
                width: 128,
                height: 128,
                colorDark: '#000000',
                colorLight: '#ffffff',
            });
            $('#qrcode').prop('title', 'QR Code');
        } else {
            if (this.qrcode) {
                this.qrcode.clear();
            }
            $('#qrcode').empty();
        }
    }

    enableTwoFactor() {
        this.confirmTwoFactorMoment = true;
    }

    confirmTwoFactor() {
        this.asyncTwoFactor = false;
        this.confirmationTwoFactorSuccess = true;
        const uid = this.userService.getCurrentUser().getUid();
        this.http.put(`${environment.endpoints.adminService}/users/twoFactorKey/${uid}/true`, '').subscribe(
            (data: any) => {
                this.asyncTwoFactor = true;
                this.userService.refreshUser();
                this.twoFactorKey = data.twoFactorKey;
                this.warnMessage = `Sua autenticação por dois fatores está ativa. Não será possível
                logar no sistema sem o código de acesso.`;
                setTimeout(() => {
                    this.showQRCode();
                }, 300);
            },
            (error) => {
                this.errorMessage = 'Não foi possível habilitar a autenticação';
            }
        );
    }

    disableTwoFactor() {
        this.confirmationTwoFactorSuccess = false;
        this._renderer.removeClass(this._elCloseConfirmationModal.nativeElement, 'hidden');
    }
    closeConfirmationModal() {
        this._renderer.addClass(this._elCloseConfirmationModal.nativeElement, 'hidden');
    }

    confirmDisableTwoFactor() {
        this.asyncTwoFactor = false;
        this._renderer.addClass(this._elCloseConfirmationModal.nativeElement, 'hidden');
        const uid = this.userService.getCurrentUser().getUid();
        this.http.put(`${environment.endpoints.adminService}/users/twoFactorKey/${uid}/false`, '').subscribe(
            (data) => {
                this.asyncTwoFactor = true;
                this.userService.refreshUser();
                this.twoFactorKey = null;
                this.warnMessage = null;
                setTimeout(() => {
                    this.showQRCode();
                }, 300);
            },
            (error) => {
                this.errorMessage = 'Não foi possível desabilitar a autenticação';
            }
        );
    }

    openTutorial() {
        this._renderer.removeClass(this._elTwoFactorTutorial.nativeElement, 'hidden');
    }
    closeTutorial() {
        this._renderer.addClass(this._elTwoFactorTutorial.nativeElement, 'hidden');
    }

    isTwoFactorEnabled() {
        return this.twoFactorKey != null && this.twoFactorKey !== 'change_it';
    }

    isTwoFactorMandatory() {
        return this.twoFactorMandatory;
    }

    private clearInputs() {
        this.actualPassword = '';
        this.newPassword = '';
        this.repeatPassword = '';
        this.errorMessage = '';
        this.successMessage = null;
        this.initialInfoMessage = 'Clique em "Habilitar" para ativar a sua autenticação por dois fatores.';
        this.confirmTwoFactorMoment = false;

        this.checkTotalPassword = false;
        this.checkNumbersCharsPassword = false;
        this.checkSpecialCharPassword= false;
        this.passwordAccept = false;
    }
}
