import { Component, ViewEncapsulation } from '@angular/core';
import moment from 'moment';
import { UtilsService } from '#services/_utils/utils.service';
import { TopRegistrationService } from '../top-registration.service';
import { TopLiRegistrationFilter } from './top-li-registration.filter';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { AmCharts5Service, IChartConfig } from '#services/_charts/charts.service';

@Component({
    selector: 'top-li-registration',
    templateUrl: './top-li-registration.component.html',
    styleUrls: [ '../../../charts/charts.scss', './top-li-registration.component.scss' ],
    encapsulation: ViewEncapsulation.None,
})

export class TopLIRegistrationComponent {

    filterState: TopLiRegistrationFilter = this._filterStateService.getTopLiRegistrationFilter();
    subscription;
    startDate = moment().format('MM/YYYY');
    endDate = moment().format('MM/YYYY');
    asyncRegistrationLI = true;
    registrationLIData;
    typeRegister = 'LI';
    helpDescription = this._defineHelpDescription();

    constructor(
        private topRegistrationService: TopRegistrationService,
        private utilsService: UtilsService,
        private _filterStateService: FilterStateService,
        private _AmCharts5Service: AmCharts5Service,
    ) { }

    ngAfterViewInit() {
        this.loadRegistration();
    }

    loadRegistration() {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.asyncRegistrationLI = true;
        this.utilsService.setInvisible('top-li-registration', ['#graficoLI']);
        this.subscription = this.topRegistrationService.
        getRegistration(
            moment(this.filterState.getStartDate(), 'MM/YYYY').format('YYYY-MM'),
            moment(this.filterState.getEndDate(), 'MM/YYYY').format('YYYY-MM'),
            this.typeRegister,
            this.filterState.getClientGroups()
        ).subscribe((data) => {
            this.registrationLIData = data;
            this.asyncRegistrationLI = false;
            this.utilsService.setVisible('top-li-registration', ['#graficoLI']);
            this.loadChart();
        }, (error) => {
            this.asyncRegistrationLI = false;
        });
    }

    loadChart() {
            const currentStartDate = moment(this.registrationLIData.from, 'YYYY-MM').format('MM/YYYY');
            const currentEndDate = moment(this.registrationLIData.to, 'YYYY-MM').format('MM/YYYY');
            const previousStartDate = moment(this.registrationLIData.previousFrom, 'YYYY-MM').format('MM/YYYY');
            const previousEndDate = moment(this.registrationLIData.previousTo, 'YYYY-MM').format('MM/YYYY');
        
            const chartConfig: IChartConfig = {
                chartId: 'graficoLI',
                chartType: 'doubleBarChart',
                axis: [
                    { axisPosition: 'y', categoryField: 'clientName' },
                    { axisPosition: 'x', categoryField: 'totalCurrentYear' },
                ],
                content: [
                    {
                        name: 'Current Year',
                        valueYField: 'clientName',
                        valueXField: 'totalCurrentYear',
                        tooltipMessage: `${currentStartDate} - ${currentEndDate}: {valueX} LI's`,
                    },
                    {
                        name: 'Last Year',
                        valueYField: 'clientName',
                        valueXField: 'totalLastYear',
                        tooltipMessage: `${previousStartDate} - ${previousEndDate}: {valueX} LI's`, 
                    },
                ],
            };
        
            const data = this.registrationLIData.data.map((item) => ({
                clientName: item.clientName,
                totalCurrentYear: item.totalCurrentYear,
                totalLastYear: item.totalLastYear,
            }));
        
            this._AmCharts5Service.createChart(chartConfig, { data });
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    changeDateCustom() {
        this.loadRegistration();
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text',hasLineAfter: true, value: "Informa os 10 clientes com maior número de LI’s registradas no período selecionado."},
            { type: 'text', value: "Compara a quantidade de LI’s registradas do período selecionado com o ano anterior."},
        ]
    }

}
