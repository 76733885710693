<cmx-page-wrapper
    layout="bigHeader"
    [titleComponent]="titleComponent"
    [helpDescription]="helpDescription"
    [topButtonList]="topButtonList"
    [rightButtonList]="_reportPreferencesService.rightButtonList"
    [selectedRightButton]="_reportPreferencesService.selectedRightButton"
    [rightExtraPaddingPage]="true"
    [topButtonListOutside]="true"
    helpTitle="Follow UP Unificado"
    (onTopButtonClick)="onTopButtonClick($event)"
    (onRightButtonClick)="onRightButtonClick($event)">

    <ng-container cmx-page-wrapper-body style="padding: 20px">

        <div *ngIf="currentUser.has('ROUTE_AUTOMOTIVE') && !currentUser.has('AUTOMOTIVE_USER')" 
            title="Modo Automotivo: {{filterState.getFollowUpType() == 'CHASSI' ? 'Ligado' : 'Desligado'}}" class="filterMode"
            [ngClass]="{'clicked': (filterState.getFollowUpType() == 'CHASSI' ? 'Ligado' : 'Desligado') === 'Ligado'}"
            (click)="toggleFollowUpTypeSwitch()">
            <div><span class="material-icons">directions_car</span></div>
        </div>
        
        <cmx-marker-filter
            *ngIf="allStatus?.length"
            [filters]="allStatus"
            [preSelected]="preSelectedStatus"
            type="status"
            [leftDistance]="180"
            [smallSize]="smallSize"
            (onSelected)="onSelectedStatus($event)"
        ></cmx-marker-filter>

        <div id="searchLine" class="reduzed" style="padding: 32px">
            <div id="filters-followup" class="wrap-container-filters">

                <div class="search-container">
                    <cmx-filter-search
                            [type]="filterState.getTypeSearch()"
                            [types]="keyFilterTypes"
                            [width]="'120px'"
                            [value]="filterState.getSearch()"
                            (onInput)="filterState.setSearch($event)"
                            (onChangeType)="selectSearchType($event)"
                            (onApply)="applyFilter()"
                    ></cmx-filter-search>
                   
                </div>
                <div class="search-container container-right" *ngIf="currentUser$ | async as user">
                    <div class="input-group">
                        <cmx-dropdown
                            [styles]="{width: '123px'}"
                            name="modal"
                            [data]="options.modal"
                            [ngModel]="filterState.getModals()"
                            (ngModelChange)="filterState.setModals($event)"
                            [settings]="dropdownSettings.modals"
                        ></cmx-dropdown>
                        <span class="input-group-addon" style="margin: 0 -10px"></span>
                    </div>

                    <div class="input-group" *ngIf="user.has('INTERNAL_FILTERS')">
                        <cmx-dropdown
                            [styles]="{ width: '110px' }"
                            name="profit-centers"
                            [data]="options.profitCenters"
                            [ngModel]="filterState.getProfitCenters()"
                            (ngModelChange)="filterState.setProfitCenters($event);"
                            [settings]="dropdownSettings.profitCenters"
                        ></cmx-dropdown>
                        <span class="input-group-addon" style="margin: 0 -10px"></span>
                    </div>

                    <div class="input-group" *ngIf="user.has('INTERNAL_FILTERS')">
                        <cmx-dropdown
                            [styles]="{ width: '120px' }"
                            name="clients"
                            [data]="options.client"
                            [ngModel]="filterState.getClientGroups()"
                            (ngModelChange)="filterState.setClientGroups($event)"
                            [settings]="dropdownSettings.clientGroups"
                        ></cmx-dropdown>
                        <span class="input-group-addon" style="margin: 0 -10px"></span>
                    </div>

                    <div class="input-group">
                        <cmx-dropdown
                            [styles]="{ width: '120px' }"
                            name="subsidiary-names"
                            [data]="options.subsidiaryNames"
                            [ngModel]="filterState.getSubsidiaryNames()"
                            (ngModelChange)="filterState.setSubsidiaryNames($event)"
                            [settings]="dropdownSettings.subsidiaryNames"
                        ></cmx-dropdown>
                    </div>

                    <div class="input-group">
                        <span class="input-group-addon">Por</span>
                        <div class="input-group">
                            <cmx-dropdown
                                [styles]="{ width: '120px' }"
                                name="imp-reference-date-type"
                                [data]="options.referenceDateType"
                                [ngModel]="filterState.getReferenceDateType()"
                                (ngModelChange)="filterState.setReferenceDateType($event)"
                                [settings]="dropdownSettingsReferenceDateType"
                            ></cmx-dropdown>
                        </div>
                    </div>

                    <div class="input-group">
                        <cmx-filter-datepicker
                            id="followup-datepicker"
                            [startDate]="filterState.getStartDate()"
                            [endDate]="filterState.getEndDate()"
                            (onChangeDates)="datesChanged($event)"
                        ></cmx-filter-datepicker>
                    </div>

                    <button (click)="applyFilter()" class="applyImpStatus">
                        <em class="fa fa-check"></em>
                    </button>

                    <div *ngIf="filterState.getSearch().length !== 0"
                         class="disableSearch"
                         title="Os filtros são desativados ao realizar uma busca de IMP.">
                            <span class="material-icons">error</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-container">
            <cmx-custom-grid
                id="followup-grid"
                [columns]="gridColumns"
                [loading]="asyncFollowUP"
                [rows]="gridRows"
                [options]="gridOptions"
                [styles]="{ height: 'calc(100vh - 295px)' }"
                [style.visibility]="!asyncFollowUP ? 'visible' : 'hidden'"
                (onCellClick)="onCellClick($event)"
                (onSortColumn)="onSortColumn($event)"
                (onFilterColumn)="onFilterColumn($event)"
                [collapseActionColumns]="collapseActionColumns"
                ></cmx-custom-grid>
        </div>        

        <div *ngIf="pages.length > 1" style="position: absolute; bottom: 5px; left: 15px">
            <pagination
                [numberOfPages]="pages.length"
                [maxPages]="6"
                [queryId]="queryId"
                [style.visibility]="asyncFollowUP ? 'hidden' : 'visible'"
                (onPageChange)="changePage($event)">
            </pagination>
        </div>

        <cmx-slick-tabs-modal
            [tabs]="['followUP' + filterState.getFollowUpType()]"
            *ngIf="customDetailDataOpen"
            [hidden]="!selectedGrid"
            [contentPadding]="'40px 20px 20px 20px'"
            (onClose)="closeSlickModal()"
            >
        </cmx-slick-tabs-modal>

        <cmx-slick-tabs-modal
            id="followup-slick-modal"
            *ngIf="dinamicDetailDataOpen"
            [hidden]="!selectedGrid"
            [tabs]="detailFUPTabs"
            [dinamicTabs]="true"
            [contentPadding]="'50px 20px 20px 20px'"
            (onDinamicClick)="onDinamicClick($event)"
            (onClose)="closeSlickModal()"
            >
        </cmx-slick-tabs-modal>

        <followup-unified-customization 
            id="cmx-followup-unified-customization"
            [reportId]="this._getReportId()"
            >
        </followup-unified-customization>

        <cmx-custom-dialog
            id="followup-custom-dialog"
            [title]="'IMP ' + selectedImp + ' • ' + selectedModalData?.title"
            [internalFade]="false"
            [closeButton]="true"
            [overlay]="true"
            [widthModal]="widthModal"
            (modal-closed)="closeCustomModal()">
            <ng-container cmx-custom-dialog-body>
                <div class="modal-container" *ngIf="gridModalMode">
                    <div *ngIf="modalHeaders.length > 0">
                        <cmx-assembled-data
                            maxRowElements="3"
                            [data]="modalHeaders"
                            type="normal"
                        ></cmx-assembled-data>
                        <hr />
                    </div>
                    <cmx-custom-grid
                        id="followup-modal-grid"
                        [style.visibility]="modalRows?.length ? 'visible' : 'hidden'"
                        [columns]="selectedModalData?.columns"
                        [rows]="modalRows"
                        [options]="{ forceFitColumns: true, defaultColumnWidth: 100 }"
                        [styles]="{ margin: 0 }"
                    ></cmx-custom-grid>
                </div>
            </ng-container>
        </cmx-custom-dialog>
        <followup-unified-datamart-modal *ngIf="currentUser.has('API_DATAMART')"></followup-unified-datamart-modal>

    </ng-container>
</cmx-page-wrapper>

<div *ngIf="asyncFollowUPDetail" class="fullLoader">
    <loader></loader>
</div>

<div *ngIf="asyncFollowUP">
    <loader></loader>
</div>

<div *ngIf="asyncExport" class="fullLoader">
    <span class="exporting">Exportando</span>
    <loader></loader>
</div>