import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { DomainService } from '#services/_domain/domain.service';
import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { UserService } from "app/auth/_services/user.service";
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { AdmPanelService } from '../adm-panel.service';
import { AdmDomainTraderDirectorCrud } from './adm-domain-trader-director.crud';
import { Director } from './director';
import { Trader } from './trader';

@Component({
    selector: 'adm-domain-trader-director',
    templateUrl: './adm-domain-trader-director.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AdmTraderDirectorComponent {

    @Output()
    public onClose = new EventEmitter<any>();

    public currentUser$ = this.userService.getCurrentUser();

    public readonly dropdownSettingsDirectors = {
        singleSelection: true,
        text: 'Atribuir Diretor',
    };

    public dropdowns: {
        directors: {
            options: DropdownOption[],
            selected: DropdownOption[],
        },
    } = {
        directors: {
            options: [],
            selected: [],
        },
    };

    public index = 0;
    public traderSearch = '';
    public directorSearch = '';
    public traderDomain = true;
    public newDomain = true;
    public hasAnyChange = false;
    public asyncTraderDirectors = true;
    public asyncInit = false;

    public traders: Trader[] = [ Trader.blank() ];
    public traderGroups = [];
    public directors: Director[] = [ Director.blank() ];
    public directorTraders = [];
    public selectedTrader: Trader = this.traders[0].clone();
    public selectedDirector: Director = this.directors[0].clone();

    public modal = 'domainTraderDirectorModal';
    private scrollToggle = false;
    selectecItem;

    constructor(
        public admService: AdmPanelService,
        public crud: AdmDomainTraderDirectorCrud,
        private domainService: DomainService,
        private userService: UserService,
        private _cmxDropdownService: CmxDropdownService,
    ) {}

    public ngAfterViewInit() {
        forkJoin([
            this.domainService.getDirector(),
            this.domainService.getTrader(),
        ]).subscribe((values) => {
            const [ directors, traders ] = values;
            this.directors = directors.map((d) => new Director(d));
            this.traders = traders.map((t) => new Trader(t));
            this.dropdowns.directors.options = this._cmxDropdownService.toDropdownItems(directors);
            this.asyncInit = true;
        });
        $(document).click((e) => {
            if (!$(e.target).hasClass('selector')) {
                $('.options').attr('class', 'options hidden');
            }
        });
    }

    public createDomain() {
        this.asyncTraderDirectors = false;
        this.admService.resetPage(this.modal);
        if (this.traderDomain) {
            if (this.selectedTrader.name === null || this.selectedTrader.name === '') {
                this.callError('name');
            } else if (this.dropdowns.directors.selected[0] === null
                || this.dropdowns.directors.selected[0] === undefined) {
                this.callError('director');
            } else {
                this.crud.createTrader(this.selectedTrader, this.dropdowns.directors.selected[0])
                .subscribe((response) => {
                    this.recallValues('create');
                }, (error) => {
                    this.callError('err');
                    this.asyncTraderDirectors = true;
                });
            }
        } else {
            if (this.selectedDirector.name === null || this.selectedDirector.name === '') {
                this.callError('name');
            } else if (this.selectedDirector.code === null || this.selectedDirector.code === '') {
                this.callError('code');
            } else {
                this.crud.createDirector(this.selectedDirector).subscribe((response) => {
                    this.recallValues('create');
                }, (error) => {
                    if (error.status === 304) {
                        this.callError('directorCode');
                    } else {
                        this.callError('err');
                    }
                    this.asyncTraderDirectors = true;
                });
            }
        }
    }

    public editDomain() {
        this.asyncTraderDirectors = false;
        if (this.traderDomain) {
            if (this.selectedTrader.name === null || this.selectedTrader.name === '') {
                this.callError('name');
            } else {
                this.crud.editTrader(this.selectedTrader).subscribe((response) => {
                    this.recallValues('edit');
                }, (error) => {
                    this.callError('err');
                    this.asyncTraderDirectors = true;
                });
            }
        } else {
            if (this.selectedDirector.name === null || this.selectedDirector.name === '') {
                this.callError('name');
            } else if (this.selectedDirector.code === null || this.selectedDirector.code === '') {
                this.callError('code');
            } else {
                this.crud.editDirector(this.selectedDirector).subscribe((response) => {
                    this.recallValues('edit');
                }, (error) => {
                    this.callError('err');
                    this.asyncTraderDirectors = true;
                });
            }
        }
    }

    public confirmDelete() {
        this.asyncTraderDirectors = false;
        this.admService.resetPage(this.modal);
        if (this.traderDomain) {
            this.crud.deleteTrader(this.selectedTrader).subscribe((response) => {
                this.recallValues('delete');
            }, (error) => {
                this.callError('err');
                this.asyncTraderDirectors = true;
            });
        } else {
            if (this.selectedDirector.traders.length > 0) {
                this.callError('directorWithTrader');
            } else {
                this.crud.deleteDirector(this.selectedDirector).subscribe((response) => {
                    this.recallValues('delete');
                }, (error) => {
                    this.callError('err');
                    this.asyncTraderDirectors = true;
                });
            }
        }
    }

    selectPermissionTrader(item) {
        this.selectecItem = item;
        this.newDomain = false;
        this.traderDomain = true;
        this.selectedTrader = item.clone();
        this.dropdowns.directors.selected = this.dropdowns.directors.options.filter(
                (d) => this.selectedTrader.director.code === d.id,
            );
        this.traderGroups = this.selectedTrader.clientGroups;
    }

    selectPermissionDirector(item) {
        this.selectecItem = item;
        this.newDomain = false;
        this.traderDomain = false;
        this.selectedDirector = item.clone();
        this.directorTraders = this.selectedDirector.traders;
    }

    public clearUserForm(domain) {
        this.newDomain = true;
        domain === 'trader' ? this.traderDomain = true : this.traderDomain = false;
        this.selectedTrader = Trader.blank();
        this.selectedDirector = Director.blank();
        this.dropdowns.directors.selected = [];
        $('tr').removeClass('selected');
        this.asyncTraderDirectors = true;
    }

    public traderFilteredByName(trader): boolean {
        if (this.traderSearch === '') {
            return true;
        } else if (!trader.name) {
            return false;
        }
        return trader.name.toLowerCase().includes(this.traderSearch.toLowerCase());
    }
    public directorFilteredByName(director): boolean {
        if (this.directorSearch === '') {
            return true;
        } else if (!director.name) {
            return false;
        }
        return director.name.toLowerCase().includes(this.directorSearch.toLowerCase());
    }

    public scrollList(e) {
        if ((e.direction === 'up' && e.scrollTop !== 0)
              || (e.direction === 'down' && e.scrollTop + 20 < e.scrollHeight)
              || Math.abs(e.previousScrollTop - e.scrollTop) > 30) {
            this.scrollToggle = !this.scrollToggle;
        }
        if (this.scrollToggle) {
            let delta;
            if (e.direction === 'up') {
                delta = 20;
            } else {
                delta = -20;
            }
            $('.c-list').scrollTop(e.scrollTop + delta);
        }
    }

    public close() {
        this.onClose.emit(true);
    }

    public identifyChange(domain) {
        if (domain === 'trader') {
            const original = _.cloneDeep(this.traders[this.index]);
            this.applyDropDownChanges(domain);
            this.hasAnyChange = !original.equals(this.selectedTrader);
        } else {
            const original = _.cloneDeep(this.directors[this.index]);
            this.hasAnyChange = !original.equals(this.selectedDirector);
        }
    }

    private applyDropDownChanges(domain) {
        if (domain === 'trader') {
            if (this.dropdowns.directors.selected.length > 0) {
                this.selectedTrader.director.code = this.dropdowns.directors.selected[0].id;
                this.selectedTrader.director.name = this.dropdowns.directors.selected[0].itemName;
            } else {
                this.selectedTrader.director = {
                    name: null,
                    code: null,
                };
            }
        }
    }

    private callError(type) {
        this.admService.showError(this.modal, type);
        this.asyncTraderDirectors = true;
    }

    private callSuccess(type) {
        this.admService.showSuccess(this.modal, type);
        this.asyncTraderDirectors = true;
    }

    private recallValues(verb) {
        forkJoin([
            this.domainService.getTrader(),
            this.domainService.getDirector(),
        ]).subscribe((values) => {
            const [ traders, directors ] = values;
            this.directors = directors.map((director) => new Director(director));
            this.dropdowns.directors.options = this._cmxDropdownService.toDropdownItems(directors);
            this.traders = traders.map((trader) => new Trader(trader));
            this.clearUserForm('director');
            this.clearUserForm('trader');
            this.callSuccess(verb);
        });
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
