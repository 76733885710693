import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ImportationServiceService {

    constructor(private _http: HttpClient) {}

    getSubsidiaryNames(): Observable<any> {
        return this._http.get(`${environment.endpoints.importationMongoDBService}/imps/subsidiaryNames`);
    }

}
