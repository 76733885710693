<div *ngIf="!skeletonLoader" class="content-grid" [ngStyle]="styles">
    <div [id]="gridId" class="custom-grid" [ngStyle]="styles"></div>
    <div class="overlay-loader-infinite-scroll" [class.show-overlay]="loaderInfiniteScroll"></div>
    <div class="content-loader" *ngIf="loaderInfiniteScroll">
        <loader></loader>
    </div>
</div>

<div *ngIf="skeletonLoader" class="content-grid" [ngStyle]="styles">
    <cmx-skeleton-loader [type]="'grid'"></cmx-skeleton-loader>
</div>

<div id="fullLoader" *ngIf="!rows?.length && loading">
    <loader></loader>
</div>
