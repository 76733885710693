
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { DownloadModalService } from '#services/_download/download-modal.service';
import { NewFeaturesConsumerService } from '#services/_newFeatureConsumer/newFeatureConsumer.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { AuthenticationService } from 'app/auth/_services/authentication.service';

@Component({
    selector: 'profile-display-menu',
    templateUrl: './profile-display-menu.component.html',
    styleUrls: ['./profile-display-menu.component.scss', './profile-display-menu.theme.scss'],
})
export class ProfileDisplayMenuComponent {

    userInitials: string;
    dropdownSettings = DROPDOWN_SETTINGS.userPreferences;

    userPreference: {
        darkTheme?: boolean,
        enable?: boolean
    } = {};

    @Input() user;
    @Input() hidden: boolean = true;
    @Input('cmx-dark-theme') darkTheme: boolean = true;
    themeSelected = null;

    @Output() onChangeTheme: EventEmitter<any> = new EventEmitter()

    constructor(
        public router: Router,
        public downloadModalService: DownloadModalService,
        public newFeaturesConsumerService: NewFeaturesConsumerService,
        private authService$: AuthenticationService,
        private _userCacheService: UserCacheService,
    ) { }

    ngOnInit(){

        this.userPreference = {
            ...this._userCacheService.getUserPreferences(),
            darkTheme: this._userCacheService.getUserDarkTheme(),
            enable: false
        };

        if(this.user){
            this.userInitials = this.user.data.name.split(' ')[0].charAt(0);
        }
    }

    onTryChangeTheme(){
        this.themeSelected = !this.darkTheme;
        this.userPreference.darkTheme = this.themeSelected;
        this.onChangeTheme.emit(this.themeSelected);
    }

    goToLogin() {

        this.authService$.logout();
    }
}
