import { Component, ViewEncapsulation } from '@angular/core';
import { ImportDetailsService } from '../import-details.service';
import moment from 'moment';
import { UserService } from "app/auth/_services/user.service";
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { CLEARANCE_PACKAGE_COLUMNS } from './constants/clearance-package-columns.constants';
import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';
import { CLEARANCE_DI_COLUMNS } from './constants/clearance-di-columns.constants';
import { Router } from '@angular/router';
import { ImportDetailItem } from '../import-details-item';

@Component({
    selector: 'app-import-details-clearance',
    templateUrl: './clearance.component.html',
    styleUrls: ['./clearance.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ClearanceComponent extends ImportDetailItem {
    currentUser = this._userService.getCurrentUser();
    asyncDetail = !this.showLoader;
    showPackageModal = false;
    showDiModal = false;

    dataLoaded;

    clearenceDataLoaded;
    cargaDetails;
    dtaDetails;
    vistoriasDetails;
    daRegisterDetails;
    daClearanceDetails;
    diRegisterDetails;
    diClearanceDetails;

    packageList;
    diList;

    originalDocuments = [];
    originalDocumentsOperational = [];

    gridPackageColumns: IGridColumn[] = CLEARANCE_PACKAGE_COLUMNS;
    gridDiColumns: IGridColumn[] = CLEARANCE_DI_COLUMNS;

    constructor(
        private _importDetailsService: ImportDetailsService,
        private _userService: UserService,
        private _customDialogService: CustomDialogService,
        protected _router: Router,
    ) {
        super(_router);
    }

    ngOnInit() {
        (this.showLoader) ?
            setTimeout(() => {this.loadDetails();}, 1000) :
            this.loadDetails();
    }

    loadDetails() {
        this.asyncDetail = false;
        this.dataLoaded = this._importDetailsService.getDetails();
        if (this.dataLoaded) {
            this.clearenceDataLoaded = this.dataLoaded.customsClearance;
            const clearanceData = this.clearenceDataLoaded ? this.clearenceDataLoaded.clearanceData : null;
            this.diList = this._verifyColor(null, clearanceData ? clearanceData.diDataList || [] : []);
            this.packageList = this.dataLoaded.packages || [];

            this._getDocuments();

            const cargoPresence = this.clearenceDataLoaded.cargoPresence || ' - ';
            const masterNumber = this.clearenceDataLoaded.masterNumber || ' - ';

            this.cargaDetails = [
                {
                    title: 'URF Nacionalização',
                    value: this.clearenceDataLoaded.urfNationalizationWithCode,
                },
                {
                    title: 'URF Entrada',
                    value: this.clearenceDataLoaded.entry,
                    permission: 'INTERNAL_FILTERS',
                },
                {
                    title: 'Recinto',
                    value: this.clearenceDataLoaded.customs,
                },
                {
                    title: 'Chegada no Recinto',
                    value: this.clearenceDataLoaded.removalDate,
                },
                {
                    title: 'Nº Presença de Carga',
                    value: `${cargoPresence} / ${masterNumber}`,
                    permission: 'INTERNAL_FILTERS',
                },
                {
                    title: 'Presença de Carga',
                    value: this.clearenceDataLoaded.cargoPresenceDate,
                },
            ];

            this.dtaDetails = [
                {
                    title: 'Tipo do Documento',
                    value: this.clearenceDataLoaded.docTypeDescription,
                    permission: 'INTERNAL_FILTERS',
                },
                {
                    title: 'DTA',
                    value: this.clearenceDataLoaded.dtaNumber,
                },
                {
                    title: 'Canal DTA',
                    value: this.clearenceDataLoaded.dtaChannel,
                    permission: 'INTERNAL_FILTERS',
                    formatter: this._verifyColor(this.clearenceDataLoaded.dtaChannel) ? 'alert' : null,
                    color: this._verifyColor(this.clearenceDataLoaded.dtaChannel),
                },
                {
                    title: 'Data DTA',
                    value: this.clearenceDataLoaded.dates.customDate,
                },
            ];

            this.vistoriasDetails = [
                {
                    title: 'Data Desova',
                    value: this.clearenceDataLoaded.unloadingDate,
                },
                {
                    title: 'Vistoria Mapa',
                    value: this.clearenceDataLoaded.dates.inspectionMapDate,
                },
                {
                    title: 'Conferência Física',
                    value: this.clearenceDataLoaded.dates.conferenceDate,
                    permission: 'INTERNAL_FILTERS',
                },
                {
                    title: 'Solicitação Mapa',
                    value: this.clearenceDataLoaded.mapaSubmissionDate,
                    permission: 'INTERNAL_FILTERS',
                },
                {
                    title: 'Liberação Mapa',
                    value: this.clearenceDataLoaded.mapaClearanceDate,
                    permission: 'INTERNAL_FILTERS',
                },
                {
                    title: 'Volume',
                    value: this._formatList(this.dataLoaded.packages, 'description'),
                    formatter: 'multiple',
                },
                {
                    title: 'Qtd. Volumes',
                    value: this._formatList(this.dataLoaded.packages, 'quantity'),
                    formatter: 'multiple',
                },
            ];

            this.daRegisterDetails = [
                {
                    title: 'Nº DA',
                    value: this.clearenceDataLoaded.clearanceData.daData?.registrationNumber,
                },
                {
                    title: 'Data Registro DA',
                    value: this.clearenceDataLoaded.clearanceData.daData?.registrationDate,
                },
            ];

            this.daClearanceDetails = [
                {
                    title: 'Canal DA',
                    value: this.clearenceDataLoaded.clearanceData?.daData?.channel,
                    formatter: this._verifyColor(this.clearenceDataLoaded.clearanceData.daData?.channel)
                        ? 'alert'
                        : null,
                    color: this._verifyColor(this.clearenceDataLoaded.clearanceData.daData?.channel),
                },
                {
                    title: 'Data CA',
                    value: this.clearenceDataLoaded.clearanceData?.daData?.clearanceDate,
                },
            ];

            this.diRegisterDetails = [
                {
                    title: 'Nº DI',
                    value: this._formatList(this.diList, 'registrationNumber'),
                    formatter: 'multiple',
                },
                {
                    title: 'Data Registro DI',
                    value: this._formatList(this.diList, 'registrationDate'),
                    formatter: 'multiple',
                },
            ];

            this.diClearanceDetails = [
                {
                    title: 'Canal DI',
                    value: this._formatList(this.diList, 'channel'),
                    formatter: this._verifyColor(this._formatList(this.diList, 'channel')) ? 'alert' : 'multiple',
                    color: this._verifyColor(this._formatList(this.diList, 'channel')),
                },
                {
                    title: 'Data CI',
                    value: this._formatList(this.diList, 'clearanceDate'),
                    formatter: 'multiple',
                },
            ];
        }

        this.asyncDetail = true;
    }

    onPackagesClick(event) {
        if ((event.title == 'Volume' || event.title == 'Qtd. Volumes') && this.packageList.length > 1) {
            this._customDialogService.open('import-details-clearance-packeage-dialog');

            setTimeout(() => {
                this.showPackageModal = true;
            }, 100);
        }
    }

    onDiClick() {
        if (this.diList.length > 1) {
            this._customDialogService.open('import-details-clearance-di-dialog');

            setTimeout(() => {
                this.showDiModal = true;
            }, 100);
        }
    }

    private _formatList(container, field) {
        const formatedList = [];
        container.forEach((element) => {
            formatedList.push(element[field]);
        });
        return formatedList;
    }

    private _verifyColor(channel, rows?) {
        const colors = {
            VERMELHO: 'red',
            AMARELO: 'yellow',
            VERDE: 'green',
            CINZA: 'grey',
        };

        if (rows) {
            rows.forEach((row) => {
                row.badgeColor = colors[row.channel] || 'grey';
            });
            return rows;
        } else {
            return colors[channel] || null;
        }
    }

    private _getDocuments() {
        const originalDocuments = [];
        const originalDocumentsOperational = [];

        this.clearenceDataLoaded.documentDeliveries.forEach((document) => {
            document.documentDeliveryDate = moment(document.documentDeliveryDate).format('DD/MM/YYYY');
            if (document.documentTypeIdentifier === 'DOCUMENTOS_ORIGINAIS') {
                if (document.documentAreaIdentifier === 'OPERATIONAL') {
                    originalDocumentsOperational.push(document);
                } else {
                    originalDocuments.push(document);
                }
            }
        });

        this.originalDocuments = originalDocuments;
        this.originalDocumentsOperational = originalDocumentsOperational;
    }
}
