import { Injectable } from '@angular/core';
import { DomainService } from '#services/_domain/domain.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { UserPreferencesService } from './user-preferences.service';
import { UserService } from "app/auth/_services/user.service";

@Injectable()
export class UserCacheService {
    private _clientGroups: any[];
    private _subsidiaries: any[];
    private _profitCenters: any[];
    private _userPreferences;

    constructor(
        private _domainService: DomainService,
        private _featureFlagService: FeatureFlagService,
        private _userPreferencesService: UserPreferencesService,
        private _userService: UserService
    ) { }

    async Init() {
        await this.checkCache();
    }

    getClientGroups(): any[] {
        return this._clientGroups || [];
    }

    getSubsidiaries(): any[] {
        return this._subsidiaries || [];
    }

    getProfitCenters(): any[] {
        return this._profitCenters || [];;
    }

    getUserPreferences() {
        return this._userPreferences;
    }

    getUserDarkTheme(): boolean {
        const internalUser = this._userService.getCurrentUser().has('INTERNAL_FILTERS');
        if(!internalUser){
            return false;
        }
        return typeof this._userPreferences.darkTheme === 'boolean' ? this._userPreferences.darkTheme : true;
    }

    setUserDarkTheme(darkTheme: boolean): void {
        this._userPreferences.darkTheme = darkTheme;
    }

    getDefaultUserPreference(): string {
        return this._userPreferences.id || null;
    }

    private async checkCache() {
        if (!this._clientGroups || !this._clientGroups.length) {
            await this.loadClientGroups();
        }
        if (!this._subsidiaries || !this._subsidiaries.length) {
            await this.loadSubsidiaries();
        }
        if (!this._profitCenters || !this._profitCenters.length) {
            await this.loadProfitCenters();
        }
        if (!this._userPreferences) {
            await this.loadUserPreferences();
        }
        if (!this._featureFlagService.getEnabledList() || !this._featureFlagService.getEnabledList().length) {
            await this.loadEnabledFeatureFlag();
        }
    }

    private async loadClientGroups() {
        return new Promise<any>((resolve, reject) => {
            if (this._clientGroups && this._clientGroups.length) {
                resolve(this._clientGroups);
            } else {
                this._domainService.getClientGroup().subscribe(
                    (clientGroups) => {
                        this._clientGroups = clientGroups;
                        resolve(clientGroups);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private async loadSubsidiaries() {
        return new Promise<any>((resolve, reject) => {
            if (this._subsidiaries && this._subsidiaries.length) {
                resolve(this._subsidiaries);
            } else {
                this._domainService.getSubsidiary().subscribe(
                    (subsidiaries) => {
                        this._subsidiaries = subsidiaries;
                        resolve(subsidiaries);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private async loadProfitCenters() {
        return new Promise<any>((resolve, reject) => {
            if (this._profitCenters && this._profitCenters.length) {
                resolve(this._profitCenters);
            } else {
                this._domainService.getProfitCenter().subscribe(
                    (profitCenters) => {
                        this._profitCenters = profitCenters;
                        resolve(profitCenters);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private async loadUserPreferences() {
        return new Promise<any>((resolve, reject) => {
            if (this._userPreferences) {
                resolve(this._userPreferences);
            } else {
                this._userPreferencesService.getDefaultFilterPreferences().subscribe((filters) => {
                        this._userPreferences = filters;
                        resolve(filters);
                    },
                    (error) => {
                        // Ignorando erro em caso de incidente com user preference.
                        this._userPreferences = {};
                        resolve({});
                    }
                );
            }
        });
    }

    private async loadEnabledFeatureFlag() {
        return new Promise<any>((resolve, reject) => {
            if (this._featureFlagService.getEnabledList() && this._featureFlagService.getEnabledList().length) {
                resolve(this._featureFlagService.getEnabledList());
            } else {
                this._featureFlagService.getFeatureFlagEnabled().subscribe(
                    (enabledList) => {
                        this._featureFlagService.setEnabledList(enabledList);
                        resolve(enabledList);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }
}
