import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserService } from "app/auth/_services/user.service";

import { AdmAccountComponent } from './adm-account-permission/adm-account-permission.component';
import { AdmProfileComponent } from './adm-account-profile/adm-account-profile.component';
import { AdmUserComponent } from './adm-account-user/adm-account-user.component';

@Component({
    selector: 'adm-panel',
    templateUrl: './adm-panel.component.html',
    styleUrls: ['./adm-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AdmPanelComponent {
    @ViewChild(AdmAccountComponent, { static: true })
    public accountComponent: AdmAccountComponent;
    @ViewChild(AdmProfileComponent, { static: true })
    public profileComponent: AdmProfileComponent;
    @ViewChild(AdmUserComponent, { static: true })
    public userComponent: AdmUserComponent;

    public currentUser$ = this.userService.getCurrentUser();

    constructor(private http: HttpClient, private userService: UserService) {}

    public closeAdmModal() {
        $('.error').addClass('hidden');
        $('.success').addClass('hidden');
        $('button').removeClass('grey');
        $('.menuItem').removeClass('activated');
        $('.admModal').addClass('hidden');
        $('.deleteModal').addClass('hidden');
        $('.userListModal').addClass('hidden');
    }

    public openAccountPermission() {
        $('.admModal').addClass('hidden');
        $('#accountPermissionModal').removeClass('hidden');
    }
    public openAccountProfile() {
        $('.admModal').addClass('hidden');
        $('#accountProfileModal').removeClass('hidden');
    }
    public openAccountUser() {
        $('.admModal').addClass('hidden');
        $('#accountUserModal').removeClass('hidden');
    }
    public openDomainTraderDirector() {
        $('.admModal').addClass('hidden');
        $('#domainTraderDirectorModal').removeClass('hidden');
    }
    public openDomainClientGroup() {
        $('.admModal').addClass('hidden');
        $('#domainClientGroupModal').removeClass('hidden');
    }
    public openReleases() {
        $('.admModal').addClass('hidden');
        $('#releasesModal').removeClass('hidden');
    }

    public refreshProfiles(profiles) {
        this.userComponent.refreshProfiles(profiles);
    }

    public refreshSelectedUser(user) {
        this.userComponent.selectUser(user, true);
    }

}
