import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import Inputmask from 'inputmask';
import * as _ from 'lodash';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { DocumentConsolidationFilter } from 'app/theme/layouts/operational/document-consolidation/document-consolidation.model';
import { IConsolidationDetail } from './interfaces/document-consolidation-detail.interface';
import { IConsolidation } from './interfaces/document-consolidation.interface';

import { UserService } from "app/auth/_services/user.service";
import { FilterStateService } from '#services/_filters/filter-state.service';
import { DocumentConsolidationService } from './document-consolidation.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { DomainService } from '#services/_domain/domain.service';

@Component({
    selector: 'document-consolidation',
    templateUrl: './document-consolidation.component.html',
    styleUrls: ['../../charts/charts.scss', './document-consolidation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DocumentConsolidationComponent {
    asyncChart = false;
    asyncDocumentConsolidation = true;
    consolidationPage = 0;
    detailData = [];
    diData: IConsolidation;
    liData: IConsolidation;
    diNameList = [];
    liNameList = [];
    liConsolidationPages = [];
    selectedType = '';
    diColorList = ['#ffa500', '#a06801', '#9d9d9d'];
    filterState: DocumentConsolidationFilter;
    currentUser = this.userService.getCurrentUser();
    isInternalUser = this.currentUser.has('INTERNAL_FILTERS') ? true : false;
    helpDescription = this._defineHelpDescription();

    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    options: {
        subsidiaryNames: DropdownOption[];
        client: DropdownOption[];
        urf: DropdownOption[];
    } = {
        subsidiaryNames: [],
        client: [],
        urf: [],
    };

    preSelectedPeriod = ['CURRENT_YEAR'];
    selectedPeriod = _.cloneDeep(this.preSelectedPeriod);
    periodTypes = [
        {
            code: 'CURRENT_YEAR',
            name: 'Ano Corrente',
        },
        {
            code: 'TWELVE_MONTHS',
            name: '12 Meses',
        },
        {
            code: 'LAST_MONTH',
            name: 'Mês Anterior',
        },
        {
            code: 'CURRENT_MONTH',
            name: 'Mês Corrente',
        },
    ];

    private _subscription;

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this._utilsService.autoIncrementValue);
    }

    constructor(
        private _documentConsolidationService: DocumentConsolidationService,
        private _filterStateService: FilterStateService,
        private userService: UserService,
        private _userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _utilsService: UtilsService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _domainService: DomainService,
    ) {
        this.filterState = this._filterStateService.getDocumentConsolidationFilter();
        this.options.client = this._cmxDropdownService.toDropdownItems(this._userCacheService.getClientGroups());
        this.options.subsidiaryNames = this._cmxDropdownService.toDropdownItems(
            this._userCacheService.getSubsidiaries()
        );
        this._domainService.getUrfs().subscribe((data) => {
            this.options.urf = this._cmxDropdownService.toDropdownItems(data, true);
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.updateDateInputs();
            this.verifyPreDefinitionDate(this.filterState.getStartDate(), this.filterState.getEndDate());
        }, 300);
        this.loadConsolidation();
    }

    ngOnDestroy() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    loadConsolidation() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
        this.asyncDocumentConsolidation = true;
        this.resetStates();
        this.filterState.savePreferences();
        this._subscription = forkJoin([
            this._documentConsolidationService.getDocumentConsolidation(
                'DI',
                moment(this.filterState.getStartDate(), 'MM/YYYY').format('YYYY-MM'),
                moment(this.filterState.getEndDate(), 'MM/YYYY').format('YYYY-MM'),
                this.filterState.getClients(),
                this.filterState.getSubsidiaryNames().map((c) => c.itemName).join(','),
                this.filterState.getUrfs().map((c) => c.id).join(',')
            ),
            this._documentConsolidationService.getDocumentConsolidation(
                'LI',
                moment(this.filterState.getStartDate(), 'MM/YYYY').format('YYYY-MM'),
                moment(this.filterState.getEndDate(), 'MM/YYYY').format('YYYY-MM'),
                this.filterState.getClients(),
                this.filterState.getSubsidiaryNames().map((c) => c.itemName).join(','),
                this.filterState.getUrfs().map((c) => c.id).join(',')
            ),
        ]).subscribe(
            (values: [IConsolidation, IConsolidation]) => {
                const [diData, liData] = values;
                this.diData = diData;
                this.liData = liData;
                this.paginateLiConsolidation();
                diData.data.forEach((element) => {
                    this.diNameList.push({ name: element.name });
                });
                liData.data.forEach((element) => {
                    this.liNameList.push({ name: element.name });
                });
                setTimeout(() => {
                    this.verifyPreDefinitionDate(this.filterState.getStartDate(), this.filterState.getEndDate());
                }, 300);
                $('.document-details-container').css('border-top', '1px solid grey');
                if (this.selectedType !== '') {
                    this.loadDetails(this.selectedType);
                }
                this.asyncDocumentConsolidation = false;
            },
            (error) => {
                this.asyncDocumentConsolidation = false;
                this._utilsService.errorHandler(error, "Consolidação de DI's e LI's");
            }
        );
    }

    loadDetails(type) {
        this.asyncChart = true;
        this.openChart(type);
        this._documentConsolidationService
            .getDocumentConsolidationDetails(
                type,
                moment(this.filterState.getStartDate(), 'MM/YYYY').format('YYYY-MM'),
                moment(this.filterState.getEndDate(), 'MM/YYYY').format('YYYY-MM'),
                this.filterState.getClients(),
                this.filterState.getSubsidiaryNames().map((c) => c.itemName).join(','),
                this.filterState.getUrfs().map((c) => c.id).join(',')
            )
            .subscribe(
                (data: IConsolidationDetail[]) => {
                    this.prepareData(data);
                    setTimeout(() => {
                        this.loadChart();
                    }, 1000);
                },
                (error) => {
                    this.asyncChart = false;
                    this._utilsService.errorHandler(error, "Consolidação de DI's e LI's");
                }
            );
    }

    openChart(type) {
        this.selectedType = type;
        $('#documentDetailChart').addClass('invisible');
        $('.document-details-container').removeClass('invisible');
    }

    closeChart(type?) {
        this.selectedType = type ? type : '';
        if (!type) {
            $('#documentDetailChart').removeClass('invisible');
            $('.document-details-container').addClass('invisible');
        } else {
            $('#documentDetailChart').addClass('invisible');
            $('.document-details-container #close').addClass('invisible');
            $('.document-details-container').css('border-top', '0px solid grey');
        }
    }

    paginateLiConsolidation() {
        const data = _.cloneDeep(this.liData);
        const arrayNumbers = Math.ceil(data.data.length / 5);
        this.liConsolidationPages = [];
        for (let index = 0; index < arrayNumbers; index++) {
            this.liConsolidationPages[index] = [];
            for (let arrarIndex = 0; arrarIndex < 5; arrarIndex++) {
                if (data.data.length !== 0) {
                    const value = data.data.shift();
                    this.liConsolidationPages[index].push(value);
                }
            }
        }
    }

    changeConsolidatedPage(value) {
        if (
            !(this.consolidationPage + value > this.liConsolidationPages.length - 1) &&
            this.consolidationPage + value >= 0
        ) {
            this.consolidationPage += value;
        }
    }

    onSelectedPeriod({ selected }): void {
        let periodCod = selected[0].code;

        if (periodCod === 'CURRENT_YEAR') {
            this.changeDateParam(99);
        }

        if (periodCod === 'TWELVE_MONTHS') {
            this.changeDateParam(11);
        }

        if (periodCod === 'LAST_MONTH') {
            this.changeDateParam(98);
        }

        if (periodCod === 'CURRENT_MONTH') {
            this.changeDateParam(0);
        }
    }

    changeDateParam(period) {
        this.filterState.setStartDate(moment().format('MM/YYYY'));
        this.filterState.setEndDate(moment().format('MM/YYYY'));
        this.asyncDocumentConsolidation = false;
        if (period === 99) {
            this.filterState.setStartDate(moment().format('01/YYYY'));
        } else if (period === 98) {
            this.filterState.setStartDate(moment().add(-1, 'M').format('MM/YYYY'));
            this.filterState.setEndDate(moment().add(-1, 'M').format('MM/YYYY'));
        } else {
            this.filterState.setStartDate(moment().add(-period, 'M').format('MM/YYYY'));
        }
        this.loadConsolidation();
    }

    verifyDates(datePicker, date) {
        if (date.replace(/\D/g, '').length === 6) {
            if (datePicker === 'startDate') {
                if (moment(date, 'MM/YYYY').isAfter(moment(this.filterState.getEndDate(), 'MM/YYYY'))) {
                    this.filterState.setStartDate(date);
                    this.filterState.setEndDate(date);
                    setTimeout(() => {
                        this.updateDateInputs();
                    }, 300);
                } else {
                    this.filterState.setStartDate(date);
                    this.updateDateInputs();
                }
            } else {
                if (moment(this.filterState.getStartDate(), 'MM/YYYY').isAfter(moment(date, 'MM/YYYY'))) {
                    this.filterState.setStartDate(date);
                    this.filterState.setEndDate(date);
                    setTimeout(() => {
                        this.updateDateInputs();
                    }, 300);
                } else {
                    this.filterState.setEndDate(date);
                    this.updateDateInputs();
                }
            }
        }
    }

    private resetStates() {
        this.diNameList = [];
        this.liNameList = [];
        this.consolidationPage = 0;
        this.closeChart(this.selectedType);
    }

    private prepareData(data: IConsolidationDetail[]) {
        this.detailData = [];
        data.forEach((month, index) => {
            this.detailData.push({
                date: moment(month.date, 'YYYY-MM').format('MM/YYYY'),
                total: month.total,
                totalValue: `${this.selectedType}'s Registradas: <span style='font-weight: 500'>${month.total}</span>`,
            });
            const dataNames = this.selectedType === 'DI' ? this.diNameList : this.liNameList;
            dataNames.forEach((name) => {
                month.data.forEach((monthData) => {
                    if (name.name === monthData.name) {
                        const totalValue = ` | ${name.name} : <span style='font-weight: 500'>${monthData.value}</span> (${monthData.percentage}%)`;
                        this.detailData[index][name.name] = monthData.value;
                        this.detailData[index].totalValue += totalValue;
                    }
                });
            });
        });
    }

    private loadChart() {
        const dataNames = this.selectedType === 'DI' ? this.diNameList : this.liNameList;
        const graphs = [];
        dataNames.forEach((name, index) => {
            graphs.push({
                id: `g${index}`,
                bullet: 'round',
                bulletSize: 6,
                lineColor: `${this.selectedType === 'DI' ? this.diColorList[index] || this.diColorList[0] : '#9838b1'}`,
                lineThickness: 2,
                negativeLineColor: `${
                    this.selectedType === 'DI' ? this.diColorList[index] || this.diColorList[0] : '#9838b1'
                }`,
                type: 'smoothedLine',
                useLineColorForBulletBorder: true,
                valueField: name.name,
                balloonText: '[[totalValue]]',
            });
        });
        const chart = AmCharts.makeChart('documentDetailChart', {
            type: 'serial',
            theme: 'none',
            marginRight: 40,
            marginLeft: 40,
            autoMarginOffset: 20,
            dataDateFormat: 'MM/YYYY',
            valueAxes: [
                {
                    axisAlpha: 0,
                    inside: true,
                    position: 'left',
                    ignoreAxisWidth: true,
                },
            ],
            balloon: {
                fixedPosition: true,
                maxWidth: 10000,
            },
            graphs:
                this.selectedType === 'DI'
                    ? graphs
                    : [
                          {
                              id: 'g1',
                              bullet: 'round',
                              bulletSize: 6,
                              lineColor: `${this.selectedType === 'DI' ? '#ffa500' : '#9838b1'}`,
                              lineThickness: 2,
                              negativeLineColor: `${this.selectedType === 'DI' ? '#ffa500' : '#9838b1'}`,
                              type: 'smoothedLine',
                              useLineColorForBulletBorder: true,
                              valueField: 'total',
                              balloonText: '[[totalValue]]',
                          },
                      ],
            legend: {
                enable: true,
                valueFunction: this._formatLegendLabel.bind(this),
                valueAlign: 'left',
                valueWidth: 120,
                bulletType: 'square',
            },
            chartCursor: {
                oneBalloonOnly: true,
                cursorAlpha: 0.5,
                cursorColor: `${this.selectedType === 'DI' ? '#ffa500' : '#9838b1'}`,
                cursorPosition: 'mouse',
                categoryBalloonColor: `${this.selectedType === 'DI' ? '#ffa500' : '#9838b1'}`,
            },
            categoryField: 'date',
            categoryAxis: {
                fontSize: 9.5,
            },
            dataProvider: this.detailData,
            listeners: [
                {
                    event: 'init',
                    method: (event) => {
                        $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
                    },
                },
                {
                    event: 'drawn',
                    method: (event) => {
                        $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
                    },
                },
            ],
        });
        $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
        $('#documentDetailChart').removeClass('invisible');
        $('.document-details-container #close').removeClass('invisible');
        this.asyncChart = false;
    }

    private _formatLegendLabel(data) {
        const valueField = data?.valueField ? data?.valueField : data?.graph?.valueField;
        return valueField === 'total' ? "Total de LI's Registradas" : valueField;
    }

    private updateDateInputs() {
        if (!moment(this.filterState.getStartDate(), 'MM/YYYY').isValid()) {
            this.filterState.setStartDate(moment().format('MM/YYYY'));
        }
        if (!moment(this.filterState.getEndDate(), 'MM/YYYY').isValid()) {
            this.filterState.setEndDate(moment().format('MM/YYYY'));
        }
        ($('document-consolidation input#startDatePicker') as any)
            .val(this.filterState.getStartDate())
            .Zebra_DatePicker({
                format: 'm Y',
                onSelect: (selectedDate) => {
                    this.verifyDates('startDate', moment(selectedDate, 'MM YYYY').format('MM/YYYY'));
                },
                onClear: () => {
                    this.filterState.setStartDate(undefined);
                    this.filterState.setEndDate(undefined);
                    this.updateDateInputs();
                },
            });
        setTimeout(() => {
            ($('document-consolidation input#endDatePicker') as any)
                .val(this.filterState.getEndDate())
                .Zebra_DatePicker({
                    format: 'm Y',
                    direction: [moment(this.filterState.getStartDate(), 'MM/YYYY').format('MM YYYY'), false],
                    onSelect: (selectedDate) => {
                        this.filterState.setEndDate(moment(selectedDate, 'MM YYYY').format('MM/YYYY'));
                    },
                });
        }, 300);
        Inputmask({ mask: '99/9999' }).mask($('document-consolidation input#startDatePicker'));
        Inputmask({ mask: '99/9999' }).mask($('document-consolidation input#endDatePicker'));
        this.verifyPreDefinitionDate(this.filterState.getStartDate(), this.filterState.getEndDate());
    }

    private verifyPreDefinitionDate(start, end) {
        if (start === moment().format('01/YYYY') && end === moment().format('MM/YYYY')) {
            $('.checkmark').removeClass('checked');
            $('.checkmark#ano-corrente').addClass('checked');
        } else if (start === moment().add(-11, 'M').format('MM/YYYY') && end === moment().format('MM/YYYY')) {
            $('.checkmark').removeClass('checked');
            $('.checkmark#doze-meses').addClass('checked');
        } else if (end === moment().format('MM/YYYY') && start === moment().add(-5, 'M').format('MM/YYYY')) {
            $('.checkmark').removeClass('checked');
            $('.checkmark#seis-meses').addClass('checked');
        } else if (end === moment().format('MM/YYYY') && start === moment().add(-2, 'M').format('MM/YYYY')) {
            $('.checkmark').removeClass('checked');
            $('.checkmark#tres-meses').addClass('checked');
        } else {
            $('.checkmark').removeClass('checked');
        }
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text',hasLineAfter: true, value: "Informa a quantidade total de DI’s registradas referente ao período selecionado e por modal embarcado , sendo eles :  aéreo , marítimo e rodoviário."},
            { type: 'text', value: "Informa a quantidade de LI’s registradas referente ao período selecionado dividindo por órgão anuente."},
        ]
    }
}
