import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class CustomGridUtilsService {

    // Lista de Grids, 
    // contendo linhas selecionadas em cada grid (usado para controle após filtros)
    // contendo linhas visíveis em cada grid
    gridList = [];

    getCurrentGridByGridId(gridId: string) {
        return this.gridList.find((grid) => grid.gridId === gridId);
    }

    onDropdownApply(gridColumns, { column, dropdownSelection }) {
        const selectedColumn = gridColumns.find((f) => f.field == column.field );
        selectedColumn.selectedDropdownOptions = dropdownSelection || [];
        selectedColumn.filtering = false;
    }

}
