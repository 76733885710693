import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import {map } from 'rxjs/operators';
import { environment } from '#environment';
import { User } from './user';

import * as _ from 'lodash';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { Profile } from '../adm-account-profile/profile';

import { UserService } from "app/auth/_services/user.service";
import { UserAuthService } from 'app/auth/_services/user-auth.service';
import { AdmPanelService } from '../adm-panel.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { DomainService } from '#services/_domain/domain.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';


@Component({
    selector: 'adm-account-user',
    templateUrl: './adm-account-user.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AdmUserComponent {
    @Output()
    onClose = new EventEmitter<any>();

    currentUser$ = this.userService.getCurrentUser();

    readonly dropdownSettingsClients = {
        singleSelection: false,
        text: 'Atribuir Clientes',
        badgeShowLabel: 'Clientes selecionados'
    };

    readonly dropdownSettingsDomains = {
        singleSelection: false,
        text: 'Atribuir Domínios',
        maxWidth: 220,
        badgeShowLabel: 'Domínios selecionados'
    };

    readonly dropdownSettingsProfiles = {
        singleSelection: true,
        text: 'Atribuir Perfil',
        enableCheckAll: false,
    };

    userDomainDisabled = true;
    newUser = true;
    index = null;
    element = null;
    selectedProfileDropDown = 'Atribuir Perfil';
    asyncUser = false;
    twoFactorMandatory = false;
    twoFactorEnabled = null;
    captchaToken = null;
    mobile;

    dropdowns: {
        clientGroups: {
            options: DropdownOption[];
            selected: DropdownOption[];
        };
        profiles: {
            options: DropdownOption[];
            selected: DropdownOption[];
        };
        domains: {
            options: DropdownOption[];
            selected: DropdownOption[];
        };
    } = {
        clientGroups: {
            options: [],
            selected: [],
        },
        profiles: {
            options: [],
            selected: [],
        },
        domains: {
            options: [
                '2p.comexport.com.br',
                'acacia.comexport.com.br',
                'aeropipa.comexport.com.br',
                'agfa.comexport.com.br',
                'allied.comexport.com.br',
                'allnations.comexport.com.br',
                'alpina.comexport.com.br',
                'alumax.comexport.com.br',
                'amata.comexport.com.br',
                'anglogold.comexport.com.br',
                'ap-pharma.comexport.com.br',
                'araxa.comexport.com.br',
                'arvedi.comexport.com.br',
                'aurora.comexport.com.br',
                'bacardi.comexport.com.br',
                'bacio-di-latte.comexport.com.br',
                'basf.comexport.com.br',
                'bauko.comexport.com.br',
                'beam-suntory.comexport.com.br',
                'belcorp.comexport.com.br',
                'bi-stage.comexport.com.br',
                'bi.comexport.com.br',
                'blau-farmaceutica.comexport.com.br',
                'brigida.comexport.com.br',
                'brotolegal.comexport.com.br',
                'bonnome.comexport.com.br',
                'bolzoni.comexport.com.br',
                'byd.comexport.com.br',
                'bydauto.comexport.com.br',
                'broenergy.comexport.com.br',
                'calsimec.comexport.com.br',
                'canadian.comexport.com.br',
                'casaflora.comexport.com.br',
                'carbonblindados.comexport.com.br',
                'cardinalhealth.comexport.com.br',
                'ceratti.comexport.com.br',
                'cleancoat.comexport.com.br',
                'cms.comexport.com.br',
                'cmtenergia.comexport.com.br',
                'coca-cola.comexport.com.br',
                'comtrafo.comexport.com.br',
                'copernico.comexport.com.br',
                'cpe.comexport.com.br',
                'ctbotelho.comexport.com.br',
                'cxt.comexport.com.br',
                'davo.comexport.com.br',
                'demo.comexport.com.br',
                'destro.comexport.com.br',
                'demarchi.comexport.com.br',
                'dematic.comexport.com.br',
                'dimensiondata.comexport.com.br',
                'doremus.comexport.com.br',
                'dpa.comexport.com.br',
                'ebes.comexport.com.br',
                'ecoenergia.comexport.com.br',
                'edpgrid.comexport.com.br',
                'equitech-sfb.comexport.com.br',
                'edpgrid.comexport.com.br',
                'eurocar.comexport.com.br',
                'farmabase.comexport.com.br',
                'filare.comexport.com.br',
                'flextronics.comexport.com.br',
                'focusenergia.comexport.com.br',
                'freudenberg.comexport.com.br',
                'freudenbergnaotecidos.comexport.com.br',
                'gallo.comexport.com.br',
                'galileo.comexport.com.br',
                'gama.comexport.com.br',
                'gdsunenergia.comexport.com.br',
                'gera.comexport.com.br',
                'gel.comexport.com.br',
                'gdsolar.comexport.com.br',
                'go-eyewear.comexport.com.br',
                'golden.comexport.com.br',
                'greenyellow.comexport.com.br',
                'greif.comexport.com.br',
                'grupoenergia.comexport.com.br',
                'guess.comexport.com.br',
                'hbfuller.comexport.com.br',
                'hdtenergy.comexport.com.br',
                'henniges.comexport.com.br',
                'honda.comexport.com.br',
                'hunter-fan.comexport.com.br',
                'iff.comexport.com.br',
                'ilumisol.comexport.com.br',
                'isapa.comexport.com.br',
                'isdin.comexport.com.br',
                'jatinox.comexport.com.br',
                'jingjin.comexport.com.br',
                'jm.comexport.com.br',
                'jomarca.comexport.com.br',
                'kachani.comexport.com.br',
                'kalium.comexport.com.br',
                'kathrein.comexport.com.br',
                'kion.comexport.com.br',
                'kriwan.comexport.com.br',
                'kwpenergia.comexport.com.br',
                'lactalis.comexport.com.br',
                'lincoln.comexport.com.br',
                'lipson.comexport.com.br',
                'locar.comexport.com.br',
                'lucca.comexport.com.br',
                'lvmh.comexport.com.br',
                'macleanpower.comexport.com.br',
                'makro.comexport.com.br',
                'martinbauer.comexport.com.br',
                'martin-brower.comexport.com.br',
                'mazer.comexport.com.br',
                'mclaren.comexport.com.br',
                'megacommenergia.comexport.com.br',
                'mercado-livre.comexport.com.br',
                'mercedes-benz.comexport.com.br',
                'mercury.comexport.com.br',
                'metalcorp.comexport.com.br',
                'mitsubishi.comexport.com.br',
                'motociclo.comexport.com.br',
                'mtrsolar.comexport.com.br',
                'mxt.comexport.com.br',
                'najar.comexport.com.br',
                'naos.comexport.com.br',
                'nexans.comexport.com.br',
                'niquelfer.comexport.com.br',
                'nivea.comexport.com.br',
                'nlmk.comexport.com.br',
                'nokia.comexport.com.br',
                'omexom.comexport.com.br',
                'pactoenergia.comexport.com.br',
                'perfect.comexport.com.br',
                'perfil.comexport.com.br',
                'pernod-ricard.comexport.com.br',
                'pet2pet.comexport.com.br',
                'phb.comexport.com.br',
                'piacentini.comexport.com.br',
                'purifarma.comexport.com.br',
                'polishop.comexport.com.br',
                'piacentini.comexport.com.br',
                'raia-drogasil.comexport.com.br',
                'restoque.comexport.com.br',
                'rgdsolarenergia.comexport.com.br',
                'rumolog.comexport.com.br',
                'rioaltoenergia.comexport.com.br',
                'saint-gobain.comexport.com.br',
                'salmeron.comexport.com.br',
                'santosflora.comexport.com.br',
                'savegnago.comexport.com.br',
                'sestini.comexport.com.br',
                'galienergia.comexport.com.br',
                'simpress.comexport.com.br',
                'solargrid.comexport.com.br',
                'soltec.comexport.com.br',
                'sony.comexport.com.br',
                'spal.comexport.com.br',
                'st-marche.comexport.com.br',
                'staedtler.comexport.com.br',
                'sungrow.comexport.com.br',
                'takeda.comexport.com.br',
                'tania-bulhoes.comexport.com.br',
                'telefonica.comexport.com.br',
                'telesystem.comexport.com.br',
                'timken.comexport.com.br',
                'tokstok.comexport.com.br',
                'toyota.comexport.com.br',
                'tricon.comexport.com.br',
                'trinity.comexport.com.br',
                'uem.comexport.com.br',
                'fortbras.comexport.com.br',
                'unique.comexport.com.br',
                'universal.comexport.com.br',
                'ukmotors.comexport.com.br',
                'usiminas.comexport.com.br',
                'valeu.comexport.com.br',
                'vix.comexport.com.br',
                'vkn.comexport.com.br',
                'vli.comexport.com.br',
                'vollmensfragrances.comexport.com.br',
                'volvo-stage.comexport.com.br',
                'volvo.comexport.com.br',
                'wacker.comexport.com.br',
                'walmart.comexport.com.br',
                'wanhua.comexport.com.br',
                'wdcnet.comexport.com.br',
                'wwt.comexport.com.br',
                'wyda.comexport.com.br',
                'wwt.comexport.com.br',
                'yamaha.comexport.com.br',
                'zaraplast.comexport.com.br',
                'stage.mercanta.com.br',
                'mercanta.com.br',
                'zoomlion.comexport.com.br'
            ]
                .sort()
                .map((d) => new DropdownOption(d, d)),
            selected: [],
        },
    };

    profitCenters = [];
    profitCenterCodes = [];
    profiles: Profile[] = [Profile.blank()];

    users: User[] = [User.blank()];
    selectedUser = User.blank();
    userSearch = '';

    modal = 'accountUserModal';

    private clientGroupCodes = [];
    private scrollToggle = false;

    constructor(
        public admService: AdmPanelService,
        private http: HttpClient,
        private domainService: DomainService,
        private userService: UserService,
        private userCacheService: UserCacheService,
        private userAuthService: UserAuthService,
        private _cmxDropdownService: CmxDropdownService,
        private _featureFlagsService: FeatureFlagService,
    ) {}

    close() {
        this.onClose.emit(true);
    }

    refreshState() {
        this.applyDropDownChanges();
    }

    scrollList(e) {
        if ((e.direction === 'up' && e.scrollTop !== 0) ||
        (e.direction === 'down' && e.scrollTop + 20 < e.scrollHeight) ||
        Math.abs(e.previousScrollTop - e.scrollTop) > 30) {
            this.scrollToggle = !this.scrollToggle;
        }
        if (this.scrollToggle) {
            let delta;
            if (e.direction === 'up') {
                delta = 20;
            } else {
                delta = -20;
            }
            $('.c-list').scrollTop(e.scrollTop + delta);
        }
    }

    ngAfterViewInit() {
        this.userDomainDisabled = this._featureFlagsService.isFeatureFlagEnabled(`authentication-service:user-domain-disabled`);
        forkJoin([this.domainService.getProfitCenter(), this.getProfiles()]).subscribe((values) => {
            const [profitCenters, profiles] = values;
            const clientGroups = this.userCacheService.getClientGroups();
            this.clientGroupCodes = clientGroups.map((c) => c.code);
            this.dropdowns.clientGroups.options = this._cmxDropdownService.toDropdownItems(clientGroups);
            this.profitCenterCodes = profitCenters.map((p) => p.code);
            this.profitCenters = profitCenters;
            this.dropdowns.profiles.options = this.toDropdownItemsforProfiles(profiles);
            this.loadUsers();
        });
        $(document).click((e) => {
            if (!$(e.target).hasClass('selector')) {
                $('.options').attr('class', 'options hidden');
            }
        });
    }

    clearUserForm() {
        this.admService.resetPage(this.modal);
        this.newUser = true;
        this.selectedUser = User.blank();
        this.dropdowns.clientGroups.selected = [];
        this.dropdowns.profiles.selected = [];
        this.dropdowns.domains.selected = [];
        this.refreshState();
    }

    selectUser(item, fromProfiles?) {
        this.admService.resetPage(this.modal);

        if (fromProfiles) {
            this.users.map((user, i) => {
                if (user.uid === item.uid) {
                    this.index = i;
                }
            })
            this.selectedUser = _.cloneDeep(this.users[this.index]);
            $('.admModal').addClass('hidden');
            $('.userListModal').addClass('hidden');
            $('#accountUserModal').removeClass('hidden');
        } else {
            this.selectedUser = item;
        }

        this.twoFactorMandatory =
            this.selectedUser.twoFactorMandatory && this.selectedUser.twoFactorMandatory !== null ? true : false;
        this.twoFactorEnabled =
            this.selectedUser.twoFactorKey && this.selectedUser.twoFactorKey !== null ? true : false;


        this.newUser = false;

        this.dropdowns.profiles.selected = this.dropdowns.profiles.options.filter(
            (p) => this.selectedUser.accessProfile === p.id
        );

        this.dropdowns.clientGroups.selected = this.dropdowns.clientGroups.options.filter(
            (g) => this.selectedUser.clientGroups.includes('all') || this.selectedUser.clientGroups.includes(g.id)
        );

        this.dropdowns.domains.selected = this.dropdowns.domains.options.filter((d) =>
            this.selectedUser.domains.includes(d.itemName)
        );

        if (this.selectedUser.profitCenters.includes('all')) {
            this.selectedUser.profitCenters = [].concat(...this.profitCenterCodes);
        }

        this.refreshState();
    }

    addUser() {
        const newUser = this.selectedUser;

        this.asyncUser = false;
        this.admService.resetPage(this.modal);
        this.applyDropDownChanges();

        this.createUser().subscribe(
            () => {
                this.getUsers().subscribe(() => {
                    this.clearUserForm();
                    $('#accountUserModal .success.created').removeClass('hidden');
                    this.sendSignInEmail(newUser);
                });
            },
            (response) => {
                if (response.status === 304 || response.status === 500) {
                    $('#accountUserModal .error.duplicatedEmail').removeClass('hidden');
                }
                if (response.error) {
                    if (response.error.name) {
                        $('#accountUserModal .error.name').removeClass('hidden');
                    }
                    if (response.error.email) {
                        $('#accountUserModal .error.email').removeClass('hidden');
                    }
                    if (response.error.clientGroups) {
                        $('#accountUserModal .error.clientGroups').removeClass('hidden');
                    }
                    if (response.error.profitCenters) {
                        $('#accountUserModal .error.profitCenters').removeClass('hidden');
                    }
                    if (response.error.domains) {
                        $('#accountUserModal .error.domains').removeClass('hidden');
                    }
                }
                this.asyncUser = true;
            }
        );
    }

    saveUser() {
        this.asyncUser = false;
        this.admService.resetPage(this.modal);
        this.applyDropDownChanges();

        this.editUser().subscribe(
            () => {
                this.getUsers().subscribe(() => {
                    this.index = this.users.findIndex((u) => u.uid === this.selectedUser.uid);
                    this.selectUser(this.users[this.index]);
                    $('#accountUserModal .success.edited').removeClass('hidden');
                    this.asyncUser = true;
                });
            },
            (response) => {
                if (response.error.name) {
                    $('#accountUserModal .error.name').removeClass('hidden');
                } else if (response.error.email) {
                    $('#accountUserModal .error.email').removeClass('hidden');
                } else if (response.error.clientGroups) {
                    $('#accountUserModal .error.clientGroups').removeClass('hidden');
                } else if (response.error.clientGroups) {
                    $('#accountUserModal .error.profitCenters').removeClass('hidden');
                } else if (response.error.domains) {
                    $('#accountUserModal .error.domains').removeClass('hidden');
                }
                this.asyncUser = true;
            }
        );
    }

    confirmDeleteUser() {
        this.asyncUser = false;
        this.admService.resetPage(this.modal);
        this.deleteUser().subscribe(
            () => {
                this.getUsers().subscribe(() => {
                    this.clearUserForm();
                    this.refreshState();
                    $('#accountUserModal .success.deleted').removeClass('hidden');
                    this.asyncUser = true;
                });
            },
            (response) => {
                if (response.status === 404) {
                    this.clearUserForm();
                    $('#accountUserModal .error.404').removeClass('hidden');
                }
                this.asyncUser = true;
            }
        );
    }

    inputClicked(cr) {
        if (this.selectedUser.profitCenters.includes(cr.code)) {
            this.selectedUser.profitCenters.splice(this.selectedUser.profitCenters.indexOf(cr.code), 1);
        } else {
            this.selectedUser.profitCenters.push(cr.code);
        }
        this.refreshState();
    }

    selectAllProfit() {
        if (this.selectedUser.profitCenters.length === this.profitCenters.length) {
            this.selectedUser.profitCenters = [];
        } else {
            this.selectedUser.profitCenters = [].concat(...this.profitCenterCodes);
        }
        this.refreshState();
    }

    // Show options of selector
    activeSelector() {
        $('#accountUserModal .options').attr('class', 'options');
    }

    filterByName(user: User): boolean {
        if (this.userSearch === '') {
            return true;
        } else if (!user.name) {
            return false;
        }
        return (
            user.name.toLowerCase().includes(this.userSearch.toLowerCase()) ||
            user.email.toLowerCase().includes(this.userSearch.toLowerCase())
        );
    }

    refreshProfiles(profiles: Profile[]) {
        this.dropdowns.profiles.options = this.toDropdownItemsforProfiles(profiles);
    }

    changeTwoFactor() {
        this.twoFactorMandatory = !this.twoFactorMandatory;
        this.refreshState();
    }

    resetTwoFactor() {
        this.twoFactorEnabled = !this.twoFactorEnabled;
        this.refreshState();
    }

    private sendSignInEmail(newUser: User) {
        this.mobile = true;
        const origin = location.host;
        this.userAuthService.sendPasswordResetEmail(newUser.email, true,this.captchaToken,this.mobile, origin ).subscribe(
            (data) => {
                this.asyncUser = true;
            },
            (error) => {
                this.asyncUser = true;
                $('#accountUserModal .error.signInEmail').removeClass('hidden');
            }
        );
    }

    private toDropdownItemsforProfiles(items: any[]) {
        return items != null ? items.map(this.toDropdownItemforProfiles) : [];
    }

    private applyDropDownChanges() {
        this.selectedUser.accessProfile =
            this.dropdowns.profiles.selected.length > 0 ? this.dropdowns.profiles.selected[0].id : null;
        this.selectedUser.clientGroups = this.dropdowns.clientGroups.selected.map((g) => g.id);
        this.selectedUser.domains = this.dropdowns.domains.selected.map((d) => d.itemName);
        if (this.twoFactorMandatory) {
            this.selectedUser.twoFactorMandatory = true;
        } else {
            this.selectedUser.twoFactorMandatory = false;
        }

        if (this.twoFactorEnabled) {
            this.selectedUser.twoFactorKey = this.selectedUser.twoFactorKey || 'change_it';
        } else {
            this.selectedUser.twoFactorKey = null;
        }
    }

    private toDropdownItemforProfiles(e: any): DropdownOption {
        return new DropdownOption(e.name, `${e.description}`);
    }

    // All Profiles
    private getProfiles(): Observable<any> {
        return this.http.get(`${environment.endpoints.adminService}/profiles`);
    }

    // All Users
    private getUsers(): Observable<any> {
        return this.http.get(`${environment.endpoints.adminService}/users`).pipe(map((usersList: any) => {
            this.users = _.sortBy(usersList.map((u) => new User(u)).filter((u) => u.isActive()), ['name']);
            this.users.forEach((user) => {
                if (user.clientGroups[0] === 'all') {
                    user.clientGroups = [].concat(...this.clientGroupCodes);
                }
                if (user.profitCenters[0] === 'all') {
                    user.profitCenters = [].concat(...this.profitCenterCodes);
                }
            });
        }));
    }

    // Create a single User
    private createUser(): Observable<HttpResponse<any>> {
        return this.http.post(
            `${environment.endpoints.adminService}/users`,
            this.prepareUserForRequest(this.selectedUser),
            { observe: 'response', responseType: 'json' }
        );
    }

    // Edit a single User
    private editUser(): Observable<HttpResponse<any>> {
        return this.http.put(
            `${environment.endpoints.adminService}/users/${this.selectedUser.uid}`,
            this.prepareUserForRequest(this.selectedUser),
            { observe: 'response', responseType: 'json' }
        );
    }

    // Delete a single User
    private deleteUser(): Observable<HttpResponse<any>> {
        return this.http.delete(`${environment.endpoints.adminService}/users/${this.selectedUser.uid}`, {
            observe: 'response',
            responseType: 'json',
        });
    }

    private loadUsers() {
        this.getUsers().subscribe(() => {
            this.asyncUser = true;
        }, () => {
            this.asyncUser = true;
        });
    }

    private prepareUserForRequest(originalUser: User) {
        const user = originalUser.clone();
        if (user.clientGroups.length === this.dropdowns.clientGroups.options.length) {
            user.clientGroups = ['all'];
        }
        if (user.profitCenters.length === this.profitCenters.length) {
            user.profitCenters = ['all'];
        }
        return user;
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
