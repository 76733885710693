import { HttpParams } from '@angular/common/http';
import { UserService } from "app/auth/_services/user.service";
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IFupImpFilter } from './interfaces/fup-imp-filter.interface';
import { TransportModalService } from '#services/_transportModal/transportModal.service';

export class FupImpFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _userService = AppModule.injector.get(UserService);

    private _initialFupImpFilter: IFupImpFilter = <IFupImpFilter>{};
    private _fupImpFilter: IFupImpFilter = <IFupImpFilter>{
        imp: '',
        clientGroups: [],
        status: [
            {name: 'Aguard. Aut. Embarque', code: 'INICIADO', statusEntityName: 'newProcess'},
            {name: 'Aguard. Embarque', code: 'EMBARQUE', statusEntityName: 'preShipping'},
            {name: 'Em Trânsito', code: 'TRANSITO', statusEntityName: 'inTransit'},
            {name: 'Aguard. Presença de Carga', code: 'CARGA', statusEntityName: 'cargoPresence'},
            {name: 'Aguard. Liberação', code: 'LIBERACAO', statusEntityName: 'liberation'},
            {name: 'Aguard. Registro DA', code: 'REGISTRO_DA', statusEntityName: 'registrationDa'},
            {name: 'Aguard. Registro DI', code: 'REGISTRO', statusEntityName: 'registration'},
            {name: 'Aguard. Desembaraço DA', code: 'DESEMBARACO_DA', statusEntityName: 'clearanceDa'},
            {name: 'Aguard. Desembaraço DI', code: 'DESEMBARACO', statusEntityName: 'clearance'},
            {name: 'Aguard. Faturamento', code: 'FATURAMENTO', statusEntityName: 'invoicing'},
            {name: 'Entrega', code: 'ENTREGA', statusEntityName: 'delivery'},
        ],
        referenceDateType: [new DropdownOption('registration','Criação')],
        clientReference: '',
        typeSearch: this._userService.getCurrentUser().has('INTERNAL_FILTERS') ? 'IMP' : 'REF',
        subsidiaryNames: [],
        profitCenters: [],
        modals: [
            { id: 'assets/img/icons/air.png', itemName: 'Aéreo' },
            { id: 'assets/img/icons/maritime.png', itemName: 'Marítimo' },
            { id: 'assets/img/icons/roads.png', itemName: 'Rodoviário' },
        ],
        followUpType: ''
    };

    private startDate;
    private endDate;
    private offset: number;
    private limit: number;

    constructor(private _transportModalService: TransportModalService, userService: UserService, filter?: FupImpFilter) {
        this._fupImpFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'fupImpFilter', this._fupImpFilter, filter);
        this._initialFupImpFilter = _.cloneDeep(this._fupImpFilter);

        this.startDate = filter ? filter.startDate : moment().add(-24, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 50;
    }

    public getSearch(): string { return this._fupImpFilter.imp; }
    public getClientGroups(): string[] { return this._fupImpFilter.clientGroups; }
    public getClientReference(): string { return this._fupImpFilter.clientReference; }
    public getStatus(): any[] { return this._fupImpFilter.status; }
    public getStartDate() { return this.startDate; }
    public getEndDate() { return this.endDate; }
    public getReferenceDateType(): DropdownOption[] { return this._fupImpFilter.referenceDateType; }
    public getOffset(): number { return this.offset; }
    public getLimit(): number { return this.limit; }
    public getTypeSearch(): string { return this._fupImpFilter.typeSearch; }
    public getSubsidiaryNames(): string[] { return this._fupImpFilter.subsidiaryNames; }
    public getProfitCenters(): string[] { return this._fupImpFilter.profitCenters; }
    public getModals(): object[] { return this._fupImpFilter.modals; }
    public getFollowUpType(): string { return this._fupImpFilter.followUpType; }

    public setSearch(imp): void { this._fupImpFilter.imp = imp; }
    public setClientGroups(clientGroups): void { this._fupImpFilter.clientGroups = clientGroups; }
    public setClientReference(clientReference): void { this._fupImpFilter.clientReference = clientReference; }
    public setStatus(status): void { this._fupImpFilter.status = status; }
    public setStartDate(startDate): void { this.startDate = startDate; }
    public setEndDate(endDate): void { this.endDate = endDate; }
    public setReferenceDateType(referenceDateType): void { this._fupImpFilter.referenceDateType = referenceDateType; }
    public setOffset(offset): void { this.offset = offset; }
    public setLimit(limit): void { this.limit = limit; }
    public setTypeSearch(typeSearch): void { this._fupImpFilter.typeSearch = typeSearch; }
    public setSubsidiaryNames(subsidiaryNames): void { this._fupImpFilter.subsidiaryNames = subsidiaryNames; }
    public setProfitCenters(profitCenters): void { this._fupImpFilter.profitCenters = profitCenters; }
    public setModals(modals): void { this._fupImpFilter.modals = modals; }
    public setFollowUpType(followUpType): void { this._fupImpFilter.followUpType = followUpType; }

    public getFilterData() {
        const data = {
            imp: this.getSearch(),
            clientGroups: this.getClientGroups(),
            status: this.getStatus(),
            clientReference: this.getClientReference(),
            subsidiaryNames: this.getSubsidiaryNames(),
            typeSearch: this.getTypeSearch(),
            referenceDateType: this.getReferenceDateType(),
            profitCenters: this.getProfitCenters(),
            modals: this.getModals(),
            startDate: this.getStartDate(),
            endDate: this.getEndDate(),
            offset: this.getOffset(),
            limit: this.getLimit()
        } as const;
        return data;
    }

    public getRequest(allStatus, currentType?) {
        this._initialFupImpFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialFupImpFilter, this._fupImpFilter, 'fupImpFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        const status = this.getSearch() === '' ? this.getStatus() : allStatus;
        params = params.append('imp', `${this.getTypeSearch() === 'IMP' ? this.getSearch() : ''}`);
        params = params.append('field', `status.code::${status.map((c) => c['code']).join(',')}`);
        params = params.append('startDate', `${this.getSearch() === '' ?
                moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                moment().add(-90, 'days').format('YYYY-MM-DD')}`);
        params = params.append('endDate', `${this.getSearch() === '' ?
                moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                moment().format('YYYY-MM-DD')}`);

        const selectedReferenceDateType = this.getReferenceDateType();
        const referenceDateType = selectedReferenceDateType && selectedReferenceDateType.length
                ? selectedReferenceDateType[0].id
                : 'registration';
        params = params.append('referenceDateType', referenceDateType);
        const subsidiaryNames = this.getSubsidiaryNames().map((c) => c['itemName']).join(',');
        const profitCenters = this.getProfitCenters().map((c) => c['id']).join(',');
        const modals = this.getSearch() !== '' ? '' : this.getModals().map((c) => this._transportModalService.getEnglishName(c['itemName'])).join(',');
        params = params.append('subsidiaryNames', subsidiaryNames);
        params = params.append('field',`costCenter::${profitCenters}`);
        params = params.append('modalTransportations', modals);
        params = params.append('limit', `${this.getLimit()}`);
        params = params.append('offset', `${this.getOffset() * this.getLimit()}`);
        params = params.append('origin', 'IMP');
        params = params.append('imps', '');
        params = params.append('clientReference', `${this.getTypeSearch() === 'REF' ? this.getSearch() : ''}`);
        return { params };
    }
}
