import * as am5 from '@amcharts/amcharts5';
import * as am5pie from '@amcharts/amcharts5/percent';

export function createPieChart(root, chartConfig, data, clickChartCallback) {
    const chart = root.container.children.push(
        am5pie.PieChart.new(root, {
            layout: root.verticalLayout,
            paddingTop: chartConfig?.padding?.top || 20,
            paddingBottom: chartConfig?.padding?.bottom || 20,
            paddingLeft: chartConfig?.padding?.left || 0,
            paddingRight: chartConfig?.padding?.right || 20,
        })
    );

    const customColors = [
        '#1f3d7a',
        '#2a52a2',
        '#3466cb',
        '#5d85d5',
        '#85a3e0',
        '#aec2ea',
        '#3c4861',
        '#505f7c',
        '#7585a5',
        '#54585f',
        '#73767c',
        '#433f79',
        '#454091',
        '#26235a'
    ];

    const dataWithColors = data.map((item, index) => ({
        ...item,
        sliceSettings: {
            fill: am5.color(customColors[index % customColors.length])
        }
    }));

    const pieSeries = chart.series.push(
        am5pie.PieSeries.new(root, {
            valueField: 'value',
            categoryField: 'category',
            balloonText: 'balloonText',
        })
    );

    pieSeries.slices.template.adapters.add("fill", (fill, target) => {
        return target.dataItem.dataContext.sliceSettings.fill;
    });

    pieSeries.slices.template.setAll({
        stroke: am5.color(0xffffff), 
        strokeWidth: 1,
        strokeOpacity: 1,
    });

    const tooltip = am5.Tooltip.new(root, {
        labelText: '{balloonText}', 
        autoTextColor: true,
    });

    tooltip.label.setAll({
        fontSize: 12,
        textAlign: "center",
        maxWidth: 200, 
        oversizedBehavior: "wrap",
    });

    pieSeries.set("tooltip", tooltip);

    pieSeries.labels.template.setAll({
        centerX: am5.p50, 
        centerY: am5.p50,
        fontSize: 10, 
        text: '{category}', 
        wrap: true, 
        maxWidth: 200,
        oversizedBehavior: "wrap",
        fill: am5.color(0x000000),
    });    

    if (clickChartCallback) {
        pieSeries.slices.template.events.on("click", function (event) {
            const slice = event.target;
            slice.set("active", !slice.get("active"));
            clickChartCallback(slice.dataItem.dataContext);
        });
    }

    pieSeries.data.setAll(dataWithColors);

    return chart;
}
