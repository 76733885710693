import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { Profile } from './profile';
import { environment } from '#environment';
import { UserService } from "app/auth/_services/user.service";
import { UtilsService } from '#services/_utils/utils.service';
import { User } from '../adm-account-user/user';
import { AdmPanelService } from '../adm-panel.service';

@Component({
    selector: 'adm-account-profile',
    templateUrl: './adm-account-profile.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AdmProfileComponent {
    @Output() onClose = new EventEmitter<any>();
    @Output() onUpdate = new EventEmitter<Profile[]>();
    @Output() onSelectUser = new EventEmitter<User[]>();

    newProfile = true;
    index = 0;
    element = '';
    hasAnyChange = true;
    conflictProfile = null;
    asyncProfile = false;
    asyncUserList = false;
    selectecItem;

    permissionTags = [];
    permissionGroups = {};
    userList = [];
    startHour = '00:00';
    endHour = '00:00';
    profiles: Profile[] = [];
    selectedProfile = Profile.blank();

    modal = 'accountProfileModal';

    currentUser$ = this.userService.getCurrentUser();

    constructor(
        public utilsService: UtilsService,
        public admService: AdmPanelService,
        private http: HttpClient,
        private userService: UserService
    ) { }

    close() {
        this.onClose.emit(true);
    }

    ngAfterViewInit() {
        this.loadAllPermissions();
        this.loadProfiles();
        $('.timeInput').keyup(() => {
            this.refreshState();
        });
    }

    refreshState() {
        if (this.newProfile) {
            this.hasAnyChange = true;
        } else {
            const original = this.profiles[this.index];
            this.hasAnyChange = !original.equals(this.selectedProfile);
        }
    }

    toggleTimeSwitch(e) {
        if ($('.externalAccess .toogleLeft').hasClass('clicked')) {
            this.selectedProfile.externalAccess = false;
            $('.externalAccess .toogleLeft').removeClass('clicked');
        } else {
            this.selectedProfile.externalAccess = true;
            $('.externalAccess .toogleLeft').addClass('clicked');
        }
        this.refreshState();
    }

    toggleSimultaneousLoginSwitch(e) {
        if ($('.simultaneousLogin .toogleLeft').hasClass('clicked')) {
            this.selectedProfile.simultaneousLogin = false;
            $('.simultaneousLogin .toogleLeft').removeClass('clicked');
        } else {
            this.selectedProfile.simultaneousLogin = true;
            $('.simultaneousLogin .toogleLeft').addClass('clicked');
        }
        this.refreshState();
    }

    selectProfile(item, fromIcon?) {
        this.asyncUserList = false;
        this.admService.resetPage(this.modal);
        this.selectecItem = item;
        this.newProfile = false;
        this.selectedProfile = _.cloneDeep(item);
        this.getUserList().subscribe((userList) => {
            this.userList = _.orderBy(userList, 'name');
            this.asyncUserList = true;
        });
        if (this.selectedProfile.externalAccess === true) {
            $('.externalAccess .toogleLeft').addClass('clicked');
        } else {
            $('.externalAccess .toogleLeft').removeClass('clicked');
        }
        if (this.selectedProfile.simultaneousLogin === true) {
            $('.simultaneousLogin .toogleLeft').addClass('clicked');
        } else {
            $('.simultaneousLogin .toogleLeft').removeClass('clicked');
        }
        this.refreshState();
        if (fromIcon) {
            $('.userListModal').removeClass('hidden');
        }
    }

    selectUser(item) {
        this.onSelectUser.emit(item);
    }

    closeUserList() {
        $('.userListModal').addClass('hidden');
    }

    profileContainsAny(route) {
        const permissions = this.permissionGroups[route];
        return permissions.find((p) => this.selectedProfile.has(p)) !== undefined;
    }

    clearProfileForm() {
        $('tr').removeClass('selected');
        this.admService.resetPage(this.modal);
        this.newProfile = true;
        this.selectedProfile = Profile.blank();
        $('.timeContainer .toogleLeft').removeClass('clicked');
        this.refreshState();
    }

    inputClicked(permissionGroup) {
        if (this.selectedProfile.has(permissionGroup)) {
            this.selectedProfile.remove(permissionGroup);
        } else {
            this.selectedProfile.add(permissionGroup);
        }
        this.refreshState();
    }

    routeClick(route, e) {
        const tag = e.target.nodeName.toLowerCase();
        if (tag === 'label') {
            const master = e.target.parentElement;
            const input = master.children[0];
            $(input).trigger('click'); // Click on corresponding input when clicking on some label
            return;
        }
        if ($(`[id='${route}']`).is(':checked')) {
            $(`.${route}`).prop('checked', true);
            this.permissionGroups[route].forEach((permissionGroup) => {
                this.selectedProfile.add(permissionGroup);
            });
        } else {
            $(`.${route}`).prop('checked', false);
            this.permissionGroups[route].forEach((permissionGroup) => {
                this.selectedProfile.remove(permissionGroup);
            });
        }

        this.refreshState();
    }

    addProfile() {
        this.admService.resetPage(this.modal);
        this.asyncProfile = false;
        this.createProfile().subscribe(() => {
            this.getProfiles().subscribe((profileList) => {
                this.profiles = profileList.map((p) => new Profile(p));
                this._setProfiles(profileList);
                this.clearProfileForm();
                this.asyncProfile = true;
                $('#accountProfileModal .success.created').removeClass('hidden');
                this.onUpdate.emit(this.profiles);
            });
        }, (error) => {
            if (error.status === 409) {
                $('#accountProfileModal button').addClass('grey');
                if (error.error.source) {
                    $('#accountProfileModal .error.conflictSource').removeClass('hidden');
                    this.conflictProfile = error.error.source.description;
                } else {
                    $('#accountProfileModal .error.conflict').removeClass('hidden');
                }
            }
            this.asyncProfile = true;
        });
    }

    saveProfile() {
        this.admService.resetPage(this.modal);
        this.asyncProfile = false;
        this.editProfile().subscribe(() => {
            this.getProfiles().subscribe((profileList) => {
                this._setProfiles(profileList);
                const index = this.profiles.findIndex((p) => p.code === this.selectedProfile.code);
                this.selectProfile(index);
                this.asyncProfile = true;
                $('#accountProfileModal .success.edited').removeClass('hidden');
                this.onUpdate.emit(this.profiles);
            })
        }, (error) => {
            if (error.status === 409) {
                $('#accountProfileModal button').addClass('grey');
                if (error.error.source) {
                    $('#accountProfileModal .error.conflictSource').removeClass('hidden');
                    this.conflictProfile = error.error.source.description;
                } else {
                    $('#accountProfileModal .error.conflict').removeClass('hidden');
                }
            }
            this.asyncProfile = true;
        });
    }

    confirmDeleteProfile() {
        this.admService.resetPage(this.modal);
        this.asyncProfile = false;
        this.deleteProfile().subscribe(() => {
            this.profiles = this.profiles.filter((item) => item.code !== this.selectedProfile.code);
            this.clearProfileForm();
            $('#accountProfileModal .success.deleted').removeClass('hidden');
            this.asyncProfile = true;
            this.onUpdate.emit(this.profiles);
        }, (error) => {
            if (error.status === 404) {
                $('#accountProfileModal button').addClass('grey');
                $('#accountProfileModal .error.404').removeClass('hidden');
            }
            this.asyncProfile = true;
        });
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    // List of all permissions
    private getAllPermissions(): Observable<any> {
        return this.http.get(`${environment.endpoints.adminService}/permission-groups`);
    }

    // Get all Profiles
    private getProfiles(): Observable<any> {
        return this.http.get(`${environment.endpoints.adminService}/profiles`);
    }

    // Get all Profiles
    private getUserList(): Observable<any> {
        return this.http.get(`${environment.endpoints.adminService}/users?profile=${this.selectedProfile.code}`);
    }

    // Create a single Profile
    private createProfile(): Observable<HttpResponse<any>> {
        const profile = _.cloneDeep(this.selectedProfile);
        profile.description = this.selectedProfile.name;
        return this.http.post(`${environment.endpoints.adminService}/profiles`, profile, {
            observe: 'response',
            responseType: 'json',
        });
    }

    // Edit a single Profile
    private editProfile(): Observable<HttpResponse<any>> {
        const profile = _.cloneDeep(this.selectedProfile);
        profile.description = this.selectedProfile.name;
        return this.http.put(
            `${environment.endpoints.adminService}/profiles/${this.selectedProfile.code}`,
            profile, { observe: 'response', responseType: 'json' }
        );
    }

    // Delete a single Profile
    private deleteProfile(): Observable<HttpResponse<any>> {
        return this.http.delete(`${environment.endpoints.adminService}/profiles/${this.selectedProfile.code}`, {
            observe: 'response',
            responseType: 'json',
        });
    }

    private loadAllPermissions() {
        this.getAllPermissions().subscribe((permissionMap) => {
            this.permissionGroups = permissionMap;
            this.permissionTags = Object.keys(permissionMap);
        });
    }

    private loadProfiles() {
        this.getProfiles().subscribe((profileList) => {
            this._setProfiles(profileList)
            this.asyncProfile = true;
        });
    }

    private _setProfiles(profileList) {
        this.profiles = profileList.map((p) => {
            const profile = new Profile(p);
            return Object.assign(profile, {
                iconCount: p.assignedUsers || 0
            })
        })
    }
}
