import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { environment } from '#environment';

import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserService } from "app/auth/_services/user.service";
import { ChartsServices } from './../charts.services';
import { UtilsService } from '#services/_utils/utils.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { CostDistribuitionFilter } from './cost-distribuition.filter';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { AmCharts5Service } from '#services/_charts/charts.service';

declare let AmCharts: any;

@Component({
    selector: 'cost-distribution-chart',
    templateUrl: './cost-distribution.component.html',
    styleUrls: ['../charts.scss', './cost-distribution.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CostDistributionComponent {
    filterState: CostDistribuitionFilter = this._filterStateService.getCostDistribuitionFilter();
    currentUser = this.userService.getCurrentUser();
    currentUser$ = this.userService.currentUser$;
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    options: { clients: DropdownOption[] } = { clients: [] };
    costsDistributionChart: any;
    asyncDistributionCost: boolean = true;
    total: number;
    selectedClients = [];
    selectedClientGroup;
    editableElements = ['.chart'];
    currentCategory = '';
    zeroedData = {};
    chartData;
    helpDescription = this._defineHelpDescription();

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private http: HttpClient,
        private utilsService: UtilsService,
        private userService: UserService,
        private userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _AmCharts5Service: AmCharts5Service,
        private _filterStateService: FilterStateService
    ) {}

    ngAfterViewInit() {
        this.loadCostsDistribution();
        this.options.clients = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
    }

    applyFilter() {
        this.loadCostsDistribution();
    }

    detailsCosts() {
        let startDateFormat = moment(this.filterState.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        let endDateFormat = moment(this.filterState.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        let params;
        this.selectedClients.length === 0
            ? (params = {
                  startDate: startDateFormat,
                  endDate: endDateFormat,
              })
            : (params = {
                  clientGroupCode: this.selectedClients[0].id,
                  clientGroupName: this.selectedClients[0].itemName,
                  startDate: startDateFormat,
                  endDate: endDateFormat,
              });
        this.router.navigate(['./financeiro/custos'], { queryParams: params });
    }

    loadCostsDistribution() {
        this.asyncDistributionCost = true;
        this.utilsService.setInvisible('cost-distribution-chart', this.editableElements);
        this.getCostsDistribution().subscribe(
            (costsResponse) => {
                this.chartData = this._absoluteValue(costsResponse.distributedCosts);
                this.createChart('');
                this.verifyZeroedData();
                this.asyncDistributionCost = false;
                this.utilsService.setVisible('cost-distribution-chart', this.editableElements);
            },
            (error) => {
                this.chartData = undefined;
                this.asyncDistributionCost = false;
                this.utilsService.errorHandler(error, 'Custos Distribuídos');
            }
        );
    }

    toggleTypeSwitch(mode, element) {
        if ($(element.target).hasClass('checked')) {
            $(element.target).removeClass('checked');
            this.createChart('');
        } else {
            $('cost-distribution-chart .checkmark').removeClass('checked');
            $(element.target).addClass('checked');
            const chartMode = mode;
            this.createChart(chartMode);
        }
    }

    createChart(type) {
        let filteredData;

        const validData = this.chartData.filter((item) => item.name !== 'INVÁLIDO');

        if (type) {
            const selectedCategory = validData.find((item) => item.name === type);
            if (selectedCategory && selectedCategory.expensesItems?.length > 0) {
                this._handleChartClick(selectedCategory);
                return;
            }

            filteredData = validData.filter((item) => item.name === type);
        } else {
            filteredData = validData;
        }

        const chartData = filteredData.map((item) => ({
            category: item.labelText,
            value: item.absoluteValue,
            percentage: item.percentage,
            balloonText: item.balloonText,
            name: item.name,
            expensesItems: item.expensesItems,
        }));

        const chartConfig = {
            chartId: 'costsDistributionChart',
            chartType: 'PieChart',
            axis: [],
            showLegend: true,
        };

        const onClickChart = (clickedData) => {
            this._handleChartClick(clickedData);
        };

        this._AmCharts5Service.createChart(
            chartConfig,
            {
                data: chartData,
                labelText: '[[balloonText]]',
            },
            undefined,
            onClickChart
        );
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    changeSearchType(event) {
        this.filterState.setSearchType(event);
    }

    setSearch(event) {
        this.filterState.setSearchValue(event);
    }

    private _absoluteValue(categories) {
        return categories.map((category) => {
            if (category.value === 0 || category.percentage === 0 || category.name === 'INVÁLIDO') {
                return null;
            }

            const absoluteValue = Math.abs(category.value);
            const formattedValue = Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
            }).format(category.value);

            return {
                ...category,
                absoluteValue,
                name: category.name,
                labelText: `${category.name} • ${category.percentage.toFixed(2)}%`,
                balloonText: `[bold] ${category.name} [/]\n ${formattedValue} (${category.percentage.toFixed(2)}%)`,
            };
        }).filter(Boolean);
    }

    private _handleChartClick(clickedData) {
        if (clickedData.expensesItems && clickedData.expensesItems.length > 0) {
            const formattedData = clickedData.expensesItems
                .filter((item) => item.value !== 0)
                .map((item) => {
                    const absoluteValue = Math.abs(item.value);
                    const formattedValue = Intl.NumberFormat('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                    }).format(item.value);

                    return {
                        category: `${item.name} • ${item.percentage.toFixed(2)}%`,
                        value: absoluteValue,
                        percentage: item.percentage,
                        balloonText: `[bold] ${item.name} [/]\n ${formattedValue} (${item.percentage.toFixed(2)}%)`,
                        name: item.name,
                    };
                });

            if (formattedData.length === 0) {
                return;
            }

            const chartConfig = {
                chartId: 'costsDistributionChart',
                chartType: 'PieChart',
                axis: [],
                showLegend: true,
            };

            this.determineMode(clickedData.name);

            this._AmCharts5Service.createChart(chartConfig, {
                data: formattedData,
                labelText: '[[balloonText]]',
            });
        }
    }

    private determineMode(mode) {
        $('cost-distribution-chart .checkmark').removeClass('checked');

        switch (mode) {
            case 'Valor CIF':
                $('cost-distribution-chart .checkmark#valor').addClass('checked');
                break;
            case 'Impostos da Nacionalização':
                $('cost-distribution-chart .checkmark#impostos').addClass('checked');
                break;
            case 'Despesas Operacionais':
                $('cost-distribution-chart .checkmark#operacionais').addClass('checked');
                break;
            case 'Despesas Financeiras':
                $('cost-distribution-chart .checkmark#financeiras').addClass('checked');
                break;
            case 'Despesas Serviços':
                $('cost-distribution-chart .checkmark#servicos').addClass('checked');
                break;
            case 'Crédito Impostos Entrada':
                $('cost-distribution-chart .checkmark#entrada').addClass('checked');
                break;
            case 'Impostos de Saída':
                $('cost-distribution-chart .checkmark#saida').addClass('checked');
                break;
            case 'IPI':
                $('cost-distribution-chart .checkmark#ipi').addClass('checked');
                break;
        }
    }

    private verifyZeroedData() {
        this.chartData.forEach((cost) => {
            this.zeroedData[
                cost.name
                    .replace(/\s/g, '')
                    .replace(/[^\w\s]/gi, '')
                    .toLowerCase()
            ] = cost.value === 0 ? true : false;
        });
    }

    private getCostsDistribution(): Observable<any> {
        return this.http.get(`${environment.endpoints.expensesService}/summary/importationExpenses?resumed=true`, this.filterState.getRequest());
    }

    private _defineHelpDescription(): object[] {
        return [{ type: 'text', value: 'Informa a porcentagem de custos para cada categoria de custo selecionada.' }];
    }
}
