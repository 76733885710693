import { UserPreferencesService } from "#services/_user/user-preferences.service";
import { AppModule } from "app/app.module";
import { ITaxStudyFilter } from "./interfaces/tax-study-filter.interface";
import { UserCacheService } from "#services/_user/app-user-cache.service";
import * as _ from 'lodash';
import { HttpHeaders, HttpParams } from "@angular/common/http";

export class TaxStudyFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private activeFilters: { [key: string]: string } = {};
    private filterField: string;
    private filterValue: string;
    private sort: string;
    private _page: number;
    private _size: number;
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialTaxStudyFilter: ITaxStudyFilter = <ITaxStudyFilter>{};
    private _taxStudyFilter: ITaxStudyFilter = <ITaxStudyFilter> {
        sortField: '',
        status: [],
        costCenter: [],
        frozenColumn: 2,
    };

    constructor(filter?: TaxStudyFilter) {
        this._taxStudyFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'taxStudyFilter', this._taxStudyFilter, filter);
        this._initialTaxStudyFilter = _.cloneDeep(this._taxStudyFilter);
        this.filterField = filter ? filter.filterField : '';
        this.filterValue = filter ? filter.filterValue : '';
        this.sort = filter ? filter.sort : 'desc';
        this._page = filter ? filter._page : 0;
        this._size = filter ? filter._size : 50;
    }

    getFilter(filter): any { return this._taxStudyFilter[filter]; }
    getStatus(): any { return this._taxStudyFilter.status; }
    getCostCenter(): any { return this._taxStudyFilter.costCenter; }
    getResponsibles() { return this._taxStudyFilter.responsibles; }
    getSortField(): string { return this._taxStudyFilter.sortField; }
    getSort(): string { return this.sort; }
    getPage(): number { return this._page; }
    getSize(): number { return this._size; }
    getFilterField() { return this.filterField; }
    getFilterValue() { return this.filterValue; }

    setFilter(filter, value): void { this._taxStudyFilter[filter] = value; }
    setStatus(status): void { this._taxStudyFilter.status = status; }
    setCostCenter(costCenter): void { this._taxStudyFilter.costCenter = costCenter; }
    setResponsibles(responsibles): void { this._taxStudyFilter.responsibles = responsibles; }
    setSortField(sortField): void { this._taxStudyFilter.sortField = sortField; }
    setSort(sort): void { this.sort = sort; }
    setPage(page): void { this._page = page; }
    setSize(size): void { this._size = size; }
    setFilterField(field): void {if (field !== undefined && field !== null) {this.filterField = field}}
    setFilterValue(value): void {
        this.filterValue = value;
        this.activeFilters[this.filterField] = this.filterValue;
    }

    public getRequest() {
        this._initialTaxStudyFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialTaxStudyFilter, this._taxStudyFilter, 'taxStudyFilter', this._userCacheService.getUserPreferences().id);
        let params = new HttpParams();
        let headers = new HttpHeaders();
        Object.keys(this.activeFilters).forEach(key => {
            let filterValue = this.activeFilters[key];
            if (filterValue) {
                if (key === 'taxStudyId' && filterValue) {
                    filterValue += `%%ncmItens[*].ncm::${filterValue}`;
                }
                params = params.append('field', `${key}::${filterValue}`);
            }
        });
        if (this.getSortField() && this.getSort()) {
            params = params.append('sort', `${this.getSortField()},${this.getSort()}`);
        }
        if(this.getStatus()?.length > 0) {
            params = params.append('field', `status::${this.getStatus().map((c) => (c.id !== c.itemName ? c.id : null)).join(',')}`);
        }
        if(this.getCostCenter()?.length > 0) {
            params = params.append('field', `clientData.costCenter::${this.getCostCenter().map((c) => c.itemName).join(',')}`);
        }
        if(this.getResponsibles()?.length > 0) {
            params = params.append('field', `ncmItens[*].responsible.userName::${this.getResponsibles().map((c) => c.itemName).join(',')}`);
        }
        params = params.append('page', `${ this.getPage() }`);
        params = params.append('size', `${ this.getSize() }`);
        return { params, headers };
    }

}
