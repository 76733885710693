import { Injectable } from '@angular/core';
import { EventSourcePolyfill } from 'event-source-polyfill/src/eventsource.min.js';
import { Observable, Subject } from 'rxjs';

import { Notification } from 'app/models/_notification/notification.data';
import { NotificationService } from '../_notification/notification.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from "app/auth/_services/user.service";


@Injectable()
export class EventSourceService {

    private events = new Subject<Notification>();

    constructor(
        private userService: UserService,
        public notification: NotificationService,
        private utilsService: UtilsService,
    ) {
       this.handleReportEvents();
    }

    public openEventSource(url, clientGroups, preset?, type?) {
        const currentUser = this.userService.getCurrentUser();
        currentUser.getToken().subscribe((token) => {
                const source = new EventSourcePolyfill(`${url}${preset}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'text/event-stream',
                    'X-Requested-Client-Groups': clientGroups,
                    'X-Authorization-SessionId': localStorage.getItem('sessionId')
                },
            });
                source.addEventListener('creating-report', (message) => {
                this.onCreatingReport();
            });
                source.addEventListener('keep-alive', (message) => {
                // connection ongoing
            });
                source.addEventListener('report-finished', (message) => {
                this.onReportFinished();
                source.close();
            });
                source.addEventListener('report-error', (message) => {
                this.onDownloadError();
                source.close();
            });
                source.addEventListener('error', (error) => {
                source.close();
            });
        }, (error) => {
            this.utilsService.errorHandler(error);
        });
    }

    getEvents(): Observable<Notification> {
        return this.events.asObservable();
    }

    private handleReportEvents(): void {
        this.events.subscribe( (message: Notification) => {
            switch (message?.type) {
                case 'creating-report':
                    this.onCreatingReport();
                    break;
                case 'report-finished':
                    this.onReportFinished();
                    break;
                case 'report-error':
                    this.onDownloadError();
                    break;
                default:
                    break;
            }
        });
    }

    private onCreatingReport(): void {
        this.notification.openNotification('downloadInfo',
                'Geração do relatório em andamento.', 'Criando Relatório');
    }
    private onReportFinished(): void {
        this.notification.openNotification('downloadSuccess',
               'Você possui downloads solicitados que estão prontos, clique aqui para conferir.', 'Relatório Criado');
    }

    private onDownloadError(): void {
        this.notification.openNotification('downloadError',
            'O relatório solicitado para download não pôde ser gerado, por favor, tente novamente.');
    }


}
