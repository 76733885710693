import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './_guards/auth.guard';
import { LoginInterceptor } from './_services/login.interceptor';
import { CaptchaModule } from 'primeng/captcha';
import { NgxMaskModule } from 'ngx-mask';

import { AlertComponent } from './_directives/alert.component';
import { AuthHandlerComponent } from './auth-handler.component';
import { AuthComponent } from './auth.component';
import { LogoutComponent } from './logout/logout.component';
import { LogoutMsgComponent } from './logout-msg.component';
import { PwdRecoveryComponent } from './pwd-recovery.component';
import { LoginService } from './_services/login.service';
import { UserAuthService } from './_services/user-auth.service';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from './_services/authentication.service';
import { UserService } from './_services/user.service';
import { AuthorizationTokenInterceptor } from './_services/authorization-token.interceptor';

@NgModule({
    declarations: [
        AuthComponent,
        AlertComponent,
        LogoutComponent,
        LogoutMsgComponent,
        PwdRecoveryComponent,
        AuthHandlerComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CaptchaModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService,
        UserAuthService,
        LoginService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoginInterceptor,
            multi: true,
        },
    ],
    entryComponents: [AlertComponent],
})
export class AuthModule {}
