import { Component, ViewEncapsulation } from '@angular/core';
import moment from 'moment';
import { TopRegistrationService } from '../top-registration.service';
import { UtilsService } from '#services/_utils/utils.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { TopDiRegistrationFilter } from './top-di-registration.filter';
import { AmCharts5Service, IChartConfig } from '#services/_charts/charts.service';
import { NotificationService } from '#services/_notification/notification.service';

@Component({
    selector: 'top-di-registration',
    templateUrl: './top-di-registration.component.html',
    styleUrls: [ '../../../charts/charts.scss', './top-di-registration.component.scss' ],
    encapsulation: ViewEncapsulation.None,
})

export class TopDIRegistrationComponent {

    filterState: TopDiRegistrationFilter = this._filterStateService.getTopDiRegistrationFilter();
    subscription;
    startDate = moment().format('MM/YYYY');
    endDate = moment().format('MM/YYYY');
    asyncRegistrationDI = true;
    registrationDIData;
    typeRegister = 'DI';
    helpDescription = this._defineHelpDescription();

    constructor(
        private topRegistrationService: TopRegistrationService,
        private utilsService: UtilsService,
        private _AmCharts5Service: AmCharts5Service,
        private _filterStateService: FilterStateService 
    ) { }

    ngAfterViewInit() {
        this.loadRegistration();
    }

    loadRegistration() {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.asyncRegistrationDI = true;
        this.utilsService.setInvisible('top-di-registration', ['#graficoDI']);
        this.subscription = this.topRegistrationService.
        getRegistration(
            moment(this.filterState.getStartDate(), 'MM/YYYY').format('YYYY-MM'),
            moment(this.filterState.getEndDate(), 'MM/YYYY').format('YYYY-MM'),
            this.typeRegister,
            this.filterState.getClientGroups()
        ).subscribe((data) => {
            this.registrationDIData = data;
            this.asyncRegistrationDI = false;
            this.utilsService.setVisible('top-di-registration', ['#graficoDI']);
            this.loadChart();
        }, (error) => {
            this.asyncRegistrationDI = false;
        });
    }

    loadChart() {
        const currentStartDate = moment(this.registrationDIData.from, 'YYYY-MM').format('MM/YYYY');
        const currentEndDate = moment(this.registrationDIData.to, 'YYYY-MM').format('MM/YYYY');
        const previousStartDate = moment(this.registrationDIData.previousFrom, 'YYYY-MM').format('MM/YYYY');
        const previousEndDate = moment(this.registrationDIData.previousTo, 'YYYY-MM').format('MM/YYYY');
    
        const chartConfig: IChartConfig = {
            chartId: 'graficoDI',
            chartType: 'doubleBarChart',
            axis: [
                { axisPosition: 'y', categoryField: 'clientName' },
                { axisPosition: 'x', categoryField: 'totalCurrentYear' },
            ],
            content: [
                {
                    name: 'Current Year',
                    valueYField: 'clientName',
                    valueXField: 'totalCurrentYear',
                    tooltipMessage: `${currentStartDate} - ${currentEndDate}: {valueX} DI's`,
                },
                {
                    name: 'Last Year',
                    valueYField: 'clientName',
                    valueXField: 'totalLastYear',
                    tooltipMessage: `${previousStartDate} - ${previousEndDate}: {valueX} DI's`, 
                },
            ],
        };
    
        const data = this.registrationDIData.data.map((item) => ({
            clientName: item.clientName,
            totalCurrentYear: item.totalCurrentYear,
            totalLastYear: item.totalLastYear,
        }));
    
        this._AmCharts5Service.createChart(chartConfig, { data });
    }
    
    changeDateCustom() {
        this.loadRegistration();
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text',hasLineAfter: true, value: "Informa os 10 clientes com maior número de DI’s registradas no período selecionado."},
            { type: 'text', value: "Compara a quantidade de DI’s registradas do período selecionado com o ano anterior."},
        ]
    }

}
