import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import * as _ from 'lodash';
import * as moment from 'moment';

import { environment } from '#environment';
import { DownloadService } from '#services/_download/download.service';
import { UserService } from "app/auth/_services/user.service";

@Injectable()
export class DamageChartService {
    private _baseUrl: string;

    graphConfig = {
        graphs: [
            {
                negativeBase: 2,
                balloonText:
                    "<b><span style='font-size:12px;'>[[totalVehicles]] Veículos • [[totalVehiclesUnconformities]] Veículos Não Conformes ([[totalPercentage]]%)</span></b>",
                bullet: 'round',
                bulletSize: 12,
                lineColor: '#808080',
                lineThickness: 2,
                negativeLineColor: '#808080',
                type: 'smoothedLine',
                valueField: 'totalVehiclesUnconformities',
            },
            {
                negativeBase: 2,
                balloonText:
                    "<b><span style='font-size:12px;'>[[totalChassi]] Chassis • [[chassiVehiclesUnconformities]] Chassis Não Conformes ([[damagedPercentage]]%)</span></b>",
                bullet: 'round',
                bulletSize: 12,
                lineColor: '#1f3d7a',
                lineThickness: 2,
                negativeLineColor: '#1f3d7a',
                type: 'smoothedLine',
                valueField: 'chassiVehiclesUnconformities',
            },
            {
                negativeBase: 2,
                balloonText:
                    "<b><span style='font-size:12px;'>[[totalFurgao]] Furgões • [[furgaoVehiclesUnconformities]] Furgões Não Conformes ([[furgaoPercentage]]%)</span></b>",
                bullet: 'round',
                bulletSize: 12,
                lineColor: '#4a6db3',
                lineThickness: 2,
                negativeLineColor: '#4a6db3',
                type: 'smoothedLine',
                valueField: 'furgaoVehiclesUnconformities',
            },
            {
                negativeBase: 2,
                balloonText:
                    "<b><span style='font-size:12px;'>[[totalMicro]] Micro-Ônibus • [[microVehiclesUnconformities]] Micro-Ônibus Não Conformes ([[microPercentage]]%)</span></b>",
                bullet: 'round',
                bulletSize: 12,
                lineColor: '#90b3f4',
                lineThickness: 2,
                negativeLineColor: '#90b3f4',
                type: 'smoothedLine',
                valueField: 'microVehiclesUnconformities',
            },
        ],
        balloon: {
            fixedPosition: true,
            maxWidth: 10000,
        },
        valueAxes: [
            {
                axisAlpha: 0,
                inside: true,
                position: 'left',
                ignoreAxisWidth: true,
            },
        ],
        categoryAxis: {
            minorGridAlpha: 2,
            minorGridEnabled: true,
            fontSize: 9.5,
        },
        chartCursor: {
            cursorAlpha: 0.5,
            cursorColor: '#1f3d7a',
            cursorPosition: 'mouse',
            categoryBalloonColor: '#1f3d7a',
        },
    };

    private originCountries = [
        { code: 'ar', name: 'Argentina', selected: true,
        permissionRequired: 'AUTOMOTIVE_MANUFACTURER_COUNTRY_ARGENTINA' },
        { code: 'be', name: 'Bélgica', selected: true,
        permissionRequired: 'AUTOMOTIVE_MANUFACTURER_COUNTRY_BELGIUM' },
        { code: 'cn', name: 'China', selected: true,
        permissionRequired: 'AUTOMOTIVE_MANUFACTURER_COUNTRY_CHINA' },
        { code: 'us', name: 'EUA', selected: true,
        permissionRequired: 'AUTOMOTIVE_MANUFACTURER_COUNTRY_USA' },
        { code: 'uk', name: 'Inglaterra', selected: true,
        permissionRequired: 'AUTOMOTIVE_MANUFACTURER_COUNTRY_ENGLAND' },
        { code: 'se', name: 'Suécia', selected: true,
        permissionRequired: 'AUTOMOTIVE_MANUFACTURER_COUNTRY_SWEDEN' },
    ];

    constructor(private _http: HttpClient,
        private _downloadService: DownloadService,
        private _userService: UserService,
    ) {
        this._baseUrl =`${environment.endpoints.automotiveV2QueryService}/api/v2/damage/stats/type`;
    }

    export(filterParams: { [key: string]: string }, clientGroupCodes: string): Observable<any> {
        const options = this._downloadService.getSourceOptions();
        options.headers = new HttpHeaders()
            .set('Accept', 'application/vnd.ms-excel')
            .set('X-Requested-Client-Groups', clientGroupCodes);

        const params = this._addFilterParams(filterParams);
        const xls = this._http.post(this._baseUrl, params, options);

        return this._downloadService.downloadFrom(xls, `Nao-Conformidades-${moment().format('YYYY-MM-DD-HHMMSS')}.xls`);
    }

    getDamageConsolidation(filterParams: { [key: string]: string }, clientGroupCodes: string): Observable<any> {
        const params = this._addFilterParams(filterParams);
        const headers = new HttpHeaders().set('X-Requested-Client-Groups', clientGroupCodes);

        return this._http.get(this._baseUrl, { params, headers });
    }

    prepareChartData(damageData) {
        const periods = [];
        damageData.forEach((periodDamage) => {
            let totalChassi = 0;
            let chassiVehiclesUnconformities = 0;
            let chassiUnconformities = 0;
            let damagedPercentage = 0;
            let chassiDamage = 0;
            let chassiDamagePercentage = 0;
            let chassiAbsence = 0;
            let chassiAbsencePercentage = 0;
            let totalFurgao = 0;
            let furgaoVehiclesUnconformities = 0;
            let furgaoUnconformities = 0;
            let furgaoPercentage = 0;
            let furgaoDamage = 0;
            let furgaoDamagePercentage = 0;
            let furgaoAbsence = 0;
            let furgaoAbsencePercentage = 0;
            let totalMicro = 0;
            let microVehiclesUnconformities = 0;
            let microUnconformities = 0;
            let microPercentage = 0;
            let microDamage = 0;
            let microDamagePercentage = 0;
            let microAbsence = 0;
            let microAbsencePercentage = 0;
            let totalVehicles = 0;
            let totalVehiclesUnconformities = 0;
            let totalUnconformities = 0;
            let totalPercentage = 0;
            let totalDamage = 0;
            let totalDamagePercentage = 0;
            let totalAbsence = 0;
            let totalAbsencePercentage = 0;
            if ('CHASSI' in periodDamage.damageByChassisType) {
                totalChassi = periodDamage.damageByChassisType.CHASSI.totalChassis || 0;
                chassiVehiclesUnconformities = periodDamage.damageByChassisType.CHASSI.totalDamagedChassis || 0;
                chassiUnconformities = periodDamage.damageByChassisType.CHASSI.total || 0;
                damagedPercentage = periodDamage.damageByChassisType.CHASSI.damagedPercentage || 0;
                if ('DAMAGE' in periodDamage.damageByChassisType.CHASSI.damageByEntityType) {
                    chassiDamage = periodDamage.damageByChassisType.CHASSI.damageByEntityType.DAMAGE.total;
                    chassiDamagePercentage =
                        periodDamage.damageByChassisType.CHASSI.damageByEntityType.DAMAGE.percentage;
                }
                if ('ABSENCE' in periodDamage.damageByChassisType.CHASSI.damageByEntityType) {
                    chassiAbsence = periodDamage.damageByChassisType.CHASSI.damageByEntityType.ABSENCE.total;
                    chassiAbsencePercentage =
                        periodDamage.damageByChassisType.CHASSI.damageByEntityType.ABSENCE.percentage;
                }
            }
            if ('FURGAO' in periodDamage.damageByChassisType) {
                totalFurgao = periodDamage.damageByChassisType.FURGAO.totalChassis || 0;
                furgaoVehiclesUnconformities = periodDamage.damageByChassisType.FURGAO.totalDamagedChassis || 0;
                furgaoUnconformities = periodDamage.damageByChassisType.FURGAO.total || 0;
                furgaoPercentage = periodDamage.damageByChassisType.FURGAO.damagedPercentage || 0;
                if ('DAMAGE' in periodDamage.damageByChassisType.FURGAO.damageByEntityType) {
                    furgaoDamage = periodDamage.damageByChassisType.FURGAO.damageByEntityType.DAMAGE.total;
                    furgaoDamagePercentage =
                        periodDamage.damageByChassisType.FURGAO.damageByEntityType.DAMAGE.percentage;
                }
                if ('ABSENCE' in periodDamage.damageByChassisType.FURGAO.damageByEntityType) {
                    furgaoAbsence = periodDamage.damageByChassisType.FURGAO.damageByEntityType.ABSENCE.total;
                    furgaoAbsencePercentage =
                        periodDamage.damageByChassisType.FURGAO.damageByEntityType.ABSENCE.percentage;
                }
            }
            if ('MICRO-ONIBUS' in periodDamage.damageByChassisType) {
                totalMicro = periodDamage.damageByChassisType['MICRO-ONIBUS'].totalChassis || 0;
                microVehiclesUnconformities = periodDamage.damageByChassisType['MICRO-ONIBUS'].totalDamagedChassis || 0;
                microUnconformities = periodDamage.damageByChassisType['MICRO-ONIBUS'].total || 0;
                microPercentage = periodDamage.damageByChassisType['MICRO-ONIBUS'].damagedPercentage || 0;
                if ('DAMAGE' in periodDamage.damageByChassisType['MICRO-ONIBUS'].damageByEntityType) {
                    microDamage = periodDamage.damageByChassisType['MICRO-ONIBUS'].damageByEntityType.DAMAGE.total;
                    microDamagePercentage =
                        periodDamage.damageByChassisType['MICRO-ONIBUS'].damageByEntityType.DAMAGE.percentage;
                }
                if ('ABSENCE' in periodDamage.damageByChassisType['MICRO-ONIBUS'].damageByEntityType) {
                    microAbsence = periodDamage.damageByChassisType['MICRO-ONIBUS'].damageByEntityType.ABSENCE.total;
                    microAbsencePercentage =
                        periodDamage.damageByChassisType['MICRO-ONIBUS'].damageByEntityType.ABSENCE.percentage;
                }
            }
            if ('TOTAL' in periodDamage.damageByChassisType) {
                totalVehicles = periodDamage.damageByChassisType.TOTAL.totalChassis || 0;
                totalVehiclesUnconformities = periodDamage.damageByChassisType.TOTAL.totalDamagedChassis || 0;
                totalUnconformities = periodDamage.damageByChassisType.TOTAL.total || 0;
                totalPercentage = periodDamage.damageByChassisType.TOTAL.damagedPercentage || 0;
                if ('DAMAGE' in periodDamage.damageByChassisType.TOTAL.damageByEntityType) {
                    totalDamage = periodDamage.damageByChassisType.TOTAL.damageByEntityType.DAMAGE.total;
                    totalDamagePercentage = periodDamage.damageByChassisType.TOTAL.damageByEntityType.DAMAGE.percentage;
                }
                if ('ABSENCE' in periodDamage.damageByChassisType.TOTAL.damageByEntityType) {
                    totalAbsence = periodDamage.damageByChassisType.TOTAL.damageByEntityType.ABSENCE.total;
                    totalAbsencePercentage =
                        periodDamage.damageByChassisType.TOTAL.damageByEntityType.ABSENCE.percentage;
                }
            }
            periods.push({
                period: periodDamage.period,
                totalVehicles,
                totalVehiclesUnconformities,
                totalUnconformities,
                totalPercentage,
                totalDamage,
                totalDamagePercentage,
                totalAbsence,
                totalAbsencePercentage,
                totalChassi,
                chassiVehiclesUnconformities,
                chassiUnconformities,
                damagedPercentage,
                chassiDamage,
                chassiDamagePercentage,
                chassiAbsence,
                chassiAbsencePercentage,
                totalMicro,
                microVehiclesUnconformities,
                microUnconformities,
                furgaoPercentage,
                microDamage,
                furgaoDamagePercentage,
                furgaoAbsence,
                furgaoAbsencePercentage,
                totalFurgao,
                furgaoVehiclesUnconformities,
                furgaoUnconformities,
                microPercentage,
                furgaoDamage,
                microDamagePercentage,
                microAbsence,
                microAbsencePercentage,
            });
        });
        return periods;
    }

    getOriginCountries() { return this.originCountries; }

    getPermitedOriginCountries() {
        const countries = [];
        const currentUser = this._userService.getCurrentUser();
        this.originCountries.forEach((country) => {
            if (currentUser.has(country.permissionRequired)) {
                countries.push(country);
            }
        });
        return countries;
    }

    private _addFilterParams(filterParams: { [key: string]: string }): any {
        let params = new HttpParams();

        for (const [key, value] of Object.entries(filterParams)) {
            params = params.append(key, value);
        }

        return params;
    }
}
