import { AfterViewInit, Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CostCategoryDetail } from './costCategoryDetail';
import { CostsFilter } from 'app/theme/pages/finance/costs/costsFilter.model';
import { Cost } from './costs';
import { CostsService } from './costs.service';
import { UserService } from "app/auth/_services/user.service";
import { FilterStateService } from '#services/_filters/filter-state.service';
import { UtilsService } from '#services/_utils/utils.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { IPageWrapperTitle } from 'app/shared/components/cmx-page-wrapper/page-wrapper-interface';
import { ITopButton } from 'app/shared/components/cmx-page-wrapper/interfaces/top-button.interface';
import { DetailedCostsComponent } from '../detailed/costs-detailed.component';
import { PageTabControlService } from '#services/_page-tab-control/page-tab-control.service';
import { CostsDropdownService } from '../filters/costs-dropdown.service';
import * as _ from 'lodash';
import moment from 'moment';

@Component({
    selector: 'costs-consolidated',
    templateUrl: './costs-consolidated.component.html',
    styleUrls: ['./costs-consolidated.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [UserService, CostsDropdownService],
})
export class ConsolidatedCostsComponent implements AfterViewInit, OnDestroy {
    @ViewChild(DetailedCostsComponent, { static: true })
    detailCosts: DetailedCostsComponent;
    titleComponent: IPageWrapperTitle = { title: 'Custos' }
    topButtonList: ITopButton[] = [{
        code: 'details', leftIcon: 'notes', name: 'Acessar Detalhamento dos Custos',
        color: '#2a61a4', hoverColor: '#20518d', textColor: 'white'
    }, {
        code: 'download', name: 'Download', leftIcon: 'file_download'
    }, {
        code: 'swap', name: 'Mudar Visão', leftIcon: 'swap_vert'
    }];

    tabs = [
        { code: 'CURRENT_YEAR', name: 'Ano Corrente' },
        { code: 'LAST_12_MONTHS', name: '12 Meses' },
        { code: 'CURRENT_MONTH', name: 'Mês Corrente' },
        { code: 'PREVIOUS_MONTH', name: 'Mês Anterior' },
    ]
    preSelectedPreset = 'null';

    filterState: CostsFilter = this.filterStateService.getCostsFilter();
    startDate;
    endDate;
    subscription;
    asyncSkuValues = false;
    asyncCosts = false;
    costs: Cost = Cost.blank();
    percentageCosts: Cost = Cost.blank();
    currentUser = this.userService.getCurrentUser();
    allCosts;
    selectedcategoryName;
    selectedsubCategoryName;
    skuPage = 0;
    skuPages = [];
    queryId = Math.random();
    costCategoryDetail: CostCategoryDetail = new CostCategoryDetail();
    placeholderSearch: string;
    showWarning =  false;
    topButtonSubscription;
    firstTopButtonTrigger: boolean = true;

    constructor(
        public pageTabControlService: PageTabControlService,
        public costsDropdownService: CostsDropdownService,
        public filterStateService: FilterStateService,
        public costsService: CostsService,
        private userService: UserService,
        private urlValue: ActivatedRoute,
        private _utilsService: UtilsService,
        private _customDialogService: CustomDialogService,
        private _router: Router,
    ) {
        this.costsService.detailedPage = false;
    }

    ngOnDestroy() {
        $(document).off('keyup');
        if (this.subscription) { this.subscription.unsubscribe() }
        if (this.topButtonSubscription) { this.topButtonSubscription.unsubscribe() }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.costsService.detailGroupTransition = '';
            this.costsService.detailColumnTransition = '';
            $(document).ready(() => { $(document).on('keyup', this.enterBtn.bind(this)) });
            this.topButtonSubscription = this.pageTabControlService.topButtonSubject$.subscribe((button) => {
                this.firstTopButtonTrigger ? this.firstTopButtonTrigger = false : this.onTopButtonClick(button);
            });
            this.userService.currentUser$.subscribe((user) => {
                this.currentUser = user;
                if (this.urlValue.snapshot.queryParams.startDate !== undefined ||
                    this.urlValue.snapshot.queryParams.endDate !== undefined ) {
                    this.filterState.setStartDate(moment(this.urlValue.snapshot.queryParams.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                    this.filterState.setEndDate(moment(this.urlValue.snapshot.queryParams.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                    if (this.urlValue.snapshot.queryParams.clientGroupCode !== undefined ||
                        this.urlValue.snapshot.queryParams.clientGroupName !== undefined) {
                        const clientGroupCode = this.urlValue.snapshot.queryParams.clientGroupCode;
                        const clientGroupName = this.urlValue.snapshot.queryParams.clientGroupName;
                        const clientGroup = { id: clientGroupCode, itemName: clientGroupName };
                        this.filterState.getClientGroups().push(clientGroup);
                    }
                    if (this.filterState.getStartDate() !== moment().add(-12, 'M').format('DD/MM/YYYY') &&
                        this.filterState.getEndDate() !== moment().format('DD/MM/YYYY')) {
                        $('.checkmark').removeClass('checked');
                    }
                    this.loadCosts();
                } else {
                    this.loadCosts();
                }
            });
        });
    }

    openAnaliticModal(group, column?) {
        this.costsService.detailGroupTransition = group || '';
        this.costsService.detailColumnTransition = column || '';
        this._router.navigate([`/financeiro/custos/detalhado/${this.filterState.getContext()}`]);
    }

    openDetailModal(categoryName, subCategoryName?) {
        this._customDialogService.open('cmx-custom-dialog-costs-sku')
        this.queryId = Math.random();
        this.skuPage = 0;
        this.loadSkuModalValues(categoryName, subCategoryName);
    }

    closeDetailModal() {
        if (!$('costs .categoryDetailModal').hasClass('hidden')) {
            $('costs .categoryDetailModal').addClass('hidden');
        }
    }

    onTopButtonClick(button) {
        if (!button) return;
        if (button.code === 'costs-consolidated-download') {
            this.costsService.exportCosts('detail', true);
        } else if (button.code === 'costs-consolidated-swap') {
            this._swapVision();
        }
    }

    datesChanged(dates) {
        this.filterState.setStartDate(dates.startDate);
        this.filterState.setEndDate(dates.endDate);
    }

    loadCosts(resetPreset?) {
        if (resetPreset) {
            $('.checkmark').removeClass('checked');
            this.filterState.setPeriodType(null);
        }
        this.asyncCosts = false;
        if (this.subscription) { this.subscription.unsubscribe() }
        this.subscription = this.costsService
            .getCosts(this.filterState.getRequest(this.filterState.getAutomotiveMode()))
            .subscribe(
                (costs) => {
                    this.allCosts = costs.expensesCategories;
                    this.costs = costs.sum;
                    this.showWarning = costs.partialBilling;
                    this.percentageCosts = costs.comparison;
                    this.asyncCosts = true;
                    setTimeout(() => {
                        this.verifyPreDefinitionDate(this.filterState.getStartDate(), this.filterState.getEndDate());
                    }, 500);
                },
                (error) => {
                    this.asyncCosts = true;
                    this._utilsService.errorHandler(error);
                }
            );
    }

    formatCategoryName(name) {
        if (name.includes('Composicao da NF')) {
            return 'composicaodanf';
        } else if (name.includes('Valor de Mercadoria') && this.filterState.getAutomotiveMode() === 'Ligado') {
            return 'valordemercadoriaAutomotivo';
        } else if (name.includes('Valor de Mercadoria') && this.filterState.getAutomotiveMode() === 'Desligado') {
            return 'valordemercadoriaImport';
        } else {
            return name.replace(/\s/g, '').toLowerCase();
        }
    }

    changePreset(selectedPreset) {
        if (!selectedPreset.firstChange) {
            const preset = selectedPreset.selected[0]
            if (preset) {
                this.filterState.setPeriodType(preset);
                const currentDay = moment().format('DD/MM/YYYY');
                const firstDayOfMonth = moment().format('01/MM/YYYY');
                switch (preset) {
                    case 'CURRENT_YEAR':
                    this.filterState.setStartDate(moment().format('01/01/YYYY'));
                    this.filterState.setEndDate(currentDay);
                    break;
                    case 'LAST_12_MONTHS':
                    this.filterState.setStartDate(moment().add('-12', 'M').format('DD/MM/YYYY'));
                    this.filterState.setEndDate(currentDay);
                    break;
                    case 'CURRENT_MONTH':
                    this.filterState.setStartDate(firstDayOfMonth);
                    this.filterState.setEndDate(currentDay);
                    break;
                    case 'PREVIOUS_MONTH':
                    this.filterState.setStartDate(moment().add('-1', 'M').format('01/MM/YYYY'));
                    this.filterState.setEndDate(moment(firstDayOfMonth, 'DD/MM/YYYY').add('-1', 'days').format('DD/MM/YYYY'));
                    break;
                }
                this.loadCosts();
            }
        }
    }

    changeSkuModalPage(pageNumber) {
        $('costs .categoryDetailModal pagination').addClass('invisible');
        this.skuPage = pageNumber - 1;
        this.loadSkuModalValues(this.selectedcategoryName, this.selectedsubCategoryName);
    }

    enterBtn(element) {
        if (element.keyCode === 13) {
            this.loadCosts(true);
        }
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    private _swapVision() {
        if (this.filterState.getVision() === 'expandida') {
            $('.costs-container').addClass('linear');
            this.filterState.setVision('reduzida');
        } else {
            $('.costs-container').removeClass('linear');
            this.filterState.setVision('expandida');
        }
    }

    private loadSkuModalValues(categoryName, subCategoryName) {
        this.asyncSkuValues = false;
        this.selectedcategoryName = categoryName;
        this.selectedsubCategoryName = subCategoryName;
        forkJoin([
            this.costsService.getContainerCosts(this.getSkuRequest('container', categoryName, subCategoryName)),
            this.costsService.getSKUCosts(this.getSkuRequest('sku', categoryName, subCategoryName))
        ]).subscribe((responses) => {
            const [containerResponse, skuResponse] = responses;
            this.skuPages = Array.apply(null, { length: Math.ceil(skuResponse.total / 30) }).map(Number.call, Number);
            this.costCategoryDetail.containerAverageValue = containerResponse.containerAverageValue;
            this.costCategoryDetail.containerQuantity = containerResponse.containerQuantity;
            this.costCategoryDetail.name = containerResponse.name;
            this.costCategoryDetail.skuValueList = skuResponse.skuList;
            this.asyncSkuValues = true;
            $('costs .categoryDetailModal pagination').removeClass('invisible');
        });
    }

    private verifyPreDefinitionDate(start, end) {
        const currentDay = moment().format('DD/MM/YYYY');
        const firstDayOfMonth = moment().format('01/MM/YYYY');
        if ( (start === moment().format('01/01/YYYY') && end === currentDay) ) {
            this.preSelectedPreset = 'CURRENT_YEAR';
        } else if ( (start === moment().add(-12, 'M').format('DD/MM/YYYY') && end === currentDay ) ) {
            this.preSelectedPreset = 'LAST_12_MONTHS';
        } else if ( (start === firstDayOfMonth && end === currentDay) ) {
            this.preSelectedPreset = 'CURRENT_MONTH';
        } else if ( (start === moment().add('-1', 'M').format('01/MM/YYYY')
            && end === moment(firstDayOfMonth, 'DD/MM/YYYY').add('-1', 'days').format('DD/MM/YYYY')) ) {
            this.preSelectedPreset = 'PREVIOUS_MONTH';
        } else {
            this.preSelectedPreset = 'null';
        }
    }

    private getSkuRequest(type, categoryName, subCategoryName) {
        let params = new HttpParams();
        let headers = new HttpHeaders();
        if (this.filterState.getPeriodType() !== undefined && this.filterState.getPeriodType() !== null) {
            params = params.append('periodType', this.filterState.getPeriodType());
        } else {
            if (this.filterState.getStartDate() !== undefined && this.filterState.getStartDate() !== null) {
                params = params.append(
                    'startDate',
                    moment(this.filterState.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                );
            }
            if (this.filterState.getEndDate() !== undefined && this.filterState.getEndDate() !== null) {
                params = params.append(
                    'endDate',
                    moment(this.filterState.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                );
            }
        }
        params = params.append('imp', this.filterState.getImpSearch());
        params = params.append('expensesCategory', categoryName);
        if (subCategoryName) {
            params = params.append('expensesCategoryValue', subCategoryName);
        }
        if (type === 'sku') {
            params = params.append('page', `${this.skuPage}`);
            params = params.append('size', '30');
        }
        headers = headers.set(
            'X-Requested-Client-Groups',
            this.filterState
                .getClientGroups()
                .map((c) => c['id'])
                .join(',')
        );
        return { params, headers };
    }

}
