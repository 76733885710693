import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ILogisticCostsFilter } from './interface/logistic.costs.interface';
import { UserService } from "app/auth/_services/user.service";
import { TransportModalService } from '#services/_transportModal/transportModal.service';

export class LogisticCostsFilter {
    private _userService = AppModule.injector.get(UserService);
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialLogisticCostsFilter: ILogisticCostsFilter = <ILogisticCostsFilter>{};
    private _logisticCostsFilter: ILogisticCostsFilter = <ILogisticCostsFilter>{
        clientGroups: [],
        logisticCosts: [],
        modal: [
            { id: 'assets/img/icons/air.png', itemName: 'Aéreo' },
            { id: 'assets/img/icons/maritime.png', itemName: 'Marítimo' },
            { id: 'assets/img/icons/roads.png', itemName: 'Rodoviário' },
        ],
        typeSwitch: 'Média',
        visualSwitch: 'Colunas',
        storageToogle: 'Considerando',
        automotiveMode: 'Desligado',
        subsidiaryNames: [],
    };

    private startDate;
    private endDate;

    constructor(private _transportModalService: TransportModalService, filter?: LogisticCostsFilter) {
        this._logisticCostsFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'logisticCostsFilter', this._logisticCostsFilter, filter);
        this._initialLogisticCostsFilter = _.cloneDeep(this._logisticCostsFilter);
        this.startDate = filter ? filter.startDate : moment().format('01/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('01/MM/YYYY');
    }

    getClientGroups(): string[] {
        return this._logisticCostsFilter.clientGroups;
    }
    getLogisticCosts(): string[] {
        return this._logisticCostsFilter.logisticCosts;
    }
    getModal(){
        return this._logisticCostsFilter.modal;
    }
    getType() {
        return this._logisticCostsFilter.typeSwitch;
    }
    getVisual() {
        return this._logisticCostsFilter.visualSwitch;
    }
    getStorageToogle() {
        return this._logisticCostsFilter.storageToogle;
    }
    getAutomotiveMode() {
        return this._logisticCostsFilter.automotiveMode;
    }
    getStartDate() {
        return this.startDate;
    }
    getEndDate() {
        return this.endDate;
    }
    getSubsidiaryNames() {
        return this._logisticCostsFilter.subsidiaryNames || [];
    }

    setClientGroups(clientGroups): void {
        this._logisticCostsFilter.clientGroups = clientGroups;
    }
    setLogisticCosts(logisticCosts): void {
        this._logisticCostsFilter.logisticCosts = logisticCosts;
    }
    setModal(modal): void {
        this._logisticCostsFilter.modal = modal;
    }
    setType(typeSwitch): void {
        this._logisticCostsFilter.typeSwitch = typeSwitch;
    }
    setVisual(visualSwitch): void {
        this._logisticCostsFilter.visualSwitch = visualSwitch;
    }
    setStorageToogle(storageToogle): void {
        this._logisticCostsFilter.storageToogle = storageToogle;
    }
    setAutomotiveMode(automotiveMode): void {
        this._logisticCostsFilter.automotiveMode = automotiveMode;
    }
    setStartDate(startDate): void {
        this.startDate = startDate;
    }
    setEndDate(endDate): void {
        this.endDate = endDate;
    }
    setSubsidiaryNames(subsidiaryNames): void {
        this._logisticCostsFilter.subsidiaryNames = subsidiaryNames;
    }

    getRequest(costsOptions, preset?) {
        this._initialLogisticCostsFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialLogisticCostsFilter, this._logisticCostsFilter, 'logisticCostsFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        let headers = new HttpHeaders();

        params = params.append('storage', this.getStorageToogle() === 'Considerando' ? 'true' : 'false');
        params = params.append(
            'isAutomotive',
            this._userService.getCurrentUser().has('AUTOMOTIVE_USER') ||
                (this._userService.getCurrentUser().has('ROUTE_AUTOMOTIVE') &&
                    !this._userService.getCurrentUser().has('AUTOMOTIVE_USER') &&
                    this.getAutomotiveMode() === 'Ligado')
                ? 'true'
                : 'false'
        );
        if (preset !== undefined && preset !== null && preset !== '') {
            params = params.append('periodType', preset);
        } else {
            if (this.startDate !== undefined && this.startDate !== null) {
                params = params.append('startDate', moment(this.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            }
            if (this.endDate !== undefined && this.endDate !== null) {
                params = params.append('endDate', moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            }
        }
        if (this.getLogisticCosts()?.length !== costsOptions.length) {
            const includeFields = this.getLogisticCosts().map((cost) => encodeURIComponent(cost['id'])).join(',');
            params = params.append('includeFields', includeFields);
        }
        params = params.append('subsidiaryNames', this.getSubsidiaryNames().map((subsidiary) => subsidiary['itemName']).join(','));
        params = params.append('statisticType', this.getType() === 'Tonelada Líquida' ? 'byTons' : '');
        if (this.getModal().length > 0) {
            this.getModal().forEach((modal) => {
                params = params.append('modals', this._transportModalService.getEnglishName(modal.itemName));
            });
        }
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c['id']).join(','));

        return { headers, params };
    }
}
