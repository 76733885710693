import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from "app/auth/_services/user.service";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PageTabControlService {

    currentPageTitle;
    currentSwitches;
    selectedSwitch;
    currentTopButtonList = [];
    changeSwitch: BehaviorSubject<any> = new BehaviorSubject(null);
    topButtonSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    rightButtonSubject: BehaviorSubject<any> = new BehaviorSubject(null);

    private _currentMainTitle;
    private _currentUrl;
    private _currentBodyContent;
    private _currentPages;
    private _currentTabSelected;
    private _currentUrlParams;

    private _currentUser = this._userService.getCurrentUser();

    constructor(
        private _router: Router,
        private _resolver: ComponentFactoryResolver,
        private _userService: UserService
    ) { }

    get currentTabSelected() {
        return this._currentTabSelected;
    }

    get topButtonSubject$() {
        return this.topButtonSubject.asObservable();
    }

    get rightButtonSubject$() {
        return this.rightButtonSubject.asObservable();
    }

    setPageConfigs(pageConfig, bodyContent) {
        this._currentBodyContent = bodyContent;
        this._currentMainTitle = pageConfig.title;
        this._currentUrl = pageConfig.url;
        this._currentPages = pageConfig.tabs;
        this._currentUrlParams = pageConfig.urlParams;
        this._verifyInitialPermissions(this._currentUrlParams.page);
    }

    onNavigate(tab, switchOption?, manualTab?) {
        let manualSwitch
        if (manualTab) {
            this._currentTabSelected = undefined;
            this._verifyInitialPermissions(tab.code);
            manualSwitch = this._currentTabSelected?.switch ? this._currentTabSelected?.switch[0] : null;
        }
        const currentSwitch = switchOption ? switchOption : manualSwitch ? manualSwitch : null;
        const url = `${ this._currentUrl + '/' + tab.code }${currentSwitch ? '/' + currentSwitch.name.replace(/\s/g, '').toLowerCase() : ''}`;
        this._router.navigate([url]);
        this.applyChanges(tab.code, switchOption);
    }

    applyChanges(manualInsertCode?, switchOption?) {
        if (manualInsertCode) this._setCurrentSelectedTab(manualInsertCode);
        if (this._currentBodyContent) this._currentBodyContent.clear();
        this._setSwitches();
        this._setTopButtonList();
        this.setCurrentPageTitle();
        const currentSwitch = switchOption ? switchOption.name : this._currentUrlParams.subPage;
        let selectedSwitchOption;
        if (currentSwitch && this.currentSwitches) {
            selectedSwitchOption = this.currentSwitches.filter((switche) => {
                return switche.name.replace(/\s/g, '').toLowerCase() === currentSwitch.replace(/\s/g, '').toLowerCase()
            })[0]
            this.selectedSwitch = selectedSwitchOption?.name || '';
        }
        const component = selectedSwitchOption ? (selectedSwitchOption.component ? selectedSwitchOption.component : this._currentTabSelected.component) : this._currentTabSelected.component;
        const createComponent = this._resolver.resolveComponentFactory(component);
        if (this._currentBodyContent) this._currentBodyContent.createComponent(createComponent);
    }

    onSwitchSelect(option) {
        if (option.component) {
            this.onNavigate(this._currentTabSelected, option);
        } else {
            window.history.pushState('', '',
                `${this._currentUrl}/${this._currentTabSelected.code}/${option.name.replace(/\s/g, '').toLowerCase()}`);
            this.changeSwitch.next(option);
        }
    }

    onTopButtonClick(button) {
        this.topButtonSubject.next(button);
    }

    onRightButtonClick(button) {
        this.rightButtonSubject.next(button);
    }

    getSwitchChange() {
        return this.changeSwitch.asObservable();
    }

    setCurrentSelectedTab(tab) {
        this._currentTabSelected = tab;
    }

    setCurrentPageTitle(tabTitle?) {
        this.currentPageTitle = `${ this._currentMainTitle } • ${ tabTitle ? tabTitle : this._currentTabSelected.title }`;
    }

    _verifyInitialPermissions(tab) {
        this._setCurrentSelectedTab(tab);
        if (!this._currentTabSelected) {
            return
        }
        if (this._currentTabSelected.permission) {
            if (!this._currentUser.has(this._currentTabSelected.permission)) {
                this._toFirstPermited();
            }
        }
        this.setCurrentPageTitle();
        const switche = this._currentUrlParams.subPage ? this._findSwitch(this._currentUrlParams.subPage) :
            this._currentTabSelected?.switch ? this._currentTabSelected?.switch[0] : null
        if (switche) {
            this.onNavigate(this._currentTabSelected, switche);
        } else {
            this.applyChanges(null, this._currentTabSelected?.switch ? this._currentTabSelected?.switch[0] : null);
        }
    }

    private _setCurrentSelectedTab(tab) {
        this._currentTabSelected = tab ? this._currentPages.filter((page) => { return page.code === tab })[0] : this._findFirstPermitedTab();
    }

    private _setSwitches() {
        this.selectedSwitch = null;
        this.currentSwitches = this._currentTabSelected.switch ? this._currentTabSelected.switch : null;
    }

    private _setTopButtonList() {
        this.currentTopButtonList = this._currentTabSelected.topButtonList ? this._currentTabSelected.topButtonList : [];
    }

    private _toFirstPermited() {
        const permited = this._findFirstPermitedTab();
        permited ? this.onNavigate(permited) : this._router.navigate(['/']);
    }

    private _findFirstPermitedTab() {
        return this._currentPages.filter((page) => { return !page.permission || this._currentUser.has(page.permission) })[0]
    }

    private _findSwitch(name) {
        if (this._currentTabSelected.switch) {
            return this._currentTabSelected.switch.filter((s) => {
                return s.name.replace(/\s/g, '').toLowerCase() === name })[0]
        } else {
            return null;
        }

    }

}
