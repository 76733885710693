import { AppModule } from 'app/app.module';
import { HttpParams } from '@angular/common/http';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IReportsFilter } from 'app/modules/reports/interface/reports.interface';
import moment from 'moment';
import * as _ from 'lodash';

export class ExpenseReportsFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialExpenseReportsFilter: IReportsFilter = <IReportsFilter>{};
    private _expenseReportsFilter: IReportsFilter = <IReportsFilter>{
        content: [],
        amountsFormat: [],
        companies: [],
        profitCenters: [],
        traders: [],
        directors: [],
        clients: [],
    };

    private _startDate;
    private _endDate;

    constructor(filter?: ExpenseReportsFilter) {
        this._expenseReportsFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'expenseReportsFilter', this._expenseReportsFilter, filter);
        this._initialExpenseReportsFilter = _.cloneDeep(this._expenseReportsFilter);
        this._startDate = filter ? filter._startDate : moment().format('01/YYYY');
        this._endDate = filter ? filter._endDate : moment().format('MM/YYYY');
    }

    getContent() { return this._expenseReportsFilter.content }
    getAmountsFormat() { return this._expenseReportsFilter.amountsFormat; }
    getCompanies() { return this._expenseReportsFilter.companies; }
    getCostCenter() { return this._expenseReportsFilter.profitCenters; }
    getTrader() { return this._expenseReportsFilter.traders; }
    getDirector() { return this._expenseReportsFilter.directors; }
    getClients() { return this._expenseReportsFilter.clients; }
    getStartDate() { return this._startDate; }
    getEndDate() { return this._endDate; }

    setContent(content): void {this._expenseReportsFilter.content = content; }
    setAmountsFormat(amountsFormat): void {this._expenseReportsFilter.amountsFormat = amountsFormat; }
    setCompanies(companies): void {this._expenseReportsFilter.companies = companies; }
    setCostCenter(profitCenters): void {this._expenseReportsFilter.profitCenters = profitCenters; }
    setTrader(traders): void {this._expenseReportsFilter.traders = traders; }
    setDirector(directors): void {this._expenseReportsFilter.directors = directors; }
    setClients(clients): void {this._expenseReportsFilter.clients = clients; }
    setStartDate(startDate): void { this._startDate = startDate; }
    setEndDate(endDate): void { this._endDate = endDate; }

    updatePreferences() {
        this._initialExpenseReportsFilter = this._userPreferencesService.verifyFilterChanges(this._initialExpenseReportsFilter,this._expenseReportsFilter,'expenseReportsFilter',this._userCacheService.getUserPreferences().id);
    }

    getDownloadParams() {
        this.updatePreferences();

        let params = new HttpParams();

        params = params.append('amountFormat', this.getAmountsFormat()[0]?.id || 'thousands');
        params = params.append('showEmptyValues', this.getContent().some(item => item.id === 'zeroedNodes'));
        params = params.append('clientGrouping', this.getContent().some(item => item.id === 'showLeaseHolder') ? 'leaseHolder' : 'group');
        params = params.append('showServiceInfo', this.getContent().some(item => item.id === 'showServiceInfo'));

        return params;
    }

    getRowsSettings() {
        this.updatePreferences();

        let settings = {
            amountsFormat: this.getAmountsFormat()[0]?.id || 'thousands',
            displayAccountCodes: false,
            displayZeroedNodes: this.getContent().some(item => item.id === 'zeroedNodes'),
            groupingClients: '',
            unknownLines: 'hideUnknownLines',
            columnTotal: 'hideColumnTotal',
            accumulateBalance: true,
            periodGrouping: 'month',
            allProfitCenter: false,
            showLeaseHolder: this.getContent().some(item => item.id === 'showLeaseHolder'),
            showServiceInfo: this.getContent().some(item => item.id === 'showServiceInfo')
        };

        return settings;
    }

    getParams() {
        this.updatePreferences();

        let params = {
            type: 'expenses-report',
            companies: this.getCompanies()?.map((companies) => companies.id) || [],
            profitCenters: this.getCostCenter()?.map((costCenter) => costCenter.id) || [],
            traders: this.getTrader()?.map((trader) => trader.id) || [],
            directors: this.getDirector()?.map((director) => director.id) || [],
            clients: this.getClients()?.map((clients) => clients.id) || [],
            currency: 'BRL',
            startMonth: moment(this.getStartDate(), 'MM/YYYY').format('YYYY-MM'),
            endMonth: moment(this.getEndDate(), 'MM/YYYY').format('YYYY-MM'),
            byLeaseHolder: this.getContent().some(item => item.id === 'showLeaseHolder')
        };

        return params;
    }

    removeNullValuesFromQueryParams(params: HttpParams) {
        const paramsKeysAux = params.keys();
        paramsKeysAux.forEach((key) => {
          const value = params.get(key);
          if (value === null || value === undefined || value === '') {
            params['map'].delete(key);
          }
        });

        return params;
    }
}
