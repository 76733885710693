
import {map} from 'rxjs/operators';
import { environment } from '#environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UserService } from "app/auth/_services/user.service";
import { Observable } from 'rxjs';

import { AdmPanelService } from '../adm-panel.service';
import { Permission } from './permission';

@Component({
    selector: 'adm-account-permission',
    templateUrl: './adm-account-permission.component.html',
    styleUrls: ['./adm-account-permission.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AdmAccountComponent implements OnInit {

    @Output() onClose = new EventEmitter<any>();

    newPermission = true;
    hasAnyChange = true;
    asyncPermissions = false;
    selectecItem;

    permissions: Permission[] = [ Permission.blank() ];

    selectedPermission: Permission = this.permissions[0].clone();

    modal = 'accountPermissionModal';

    currentUser$ = this.userService.getCurrentUser();

    constructor(private http: HttpClient,public admService: AdmPanelService, private userService: UserService) {}

    ngOnInit() {
        this.admService.resetPage(this.modal);
        this.loadPermissions().subscribe(() => {
            // no action
        });
    }

    close() {
        this.onClose.emit(true);
    }

    refreshState() {
        if (this.newPermission) {
            this.hasAnyChange = true;
        } else {
            const original = this.selectecItem;
            this.hasAnyChange = !original.equals(this.selectedPermission);
        }
    }

    selectPermission(item) {
        this.admService.resetPage(this.modal);
        this.selectecItem = item;
        this.newPermission = false;
        this.selectedPermission = item.clone();
        this.refreshState();
    }

    prepareNewPermission() {
        $('tr').removeClass('selected');
        this.admService.resetPage(this.modal);
        this.newPermission = true;
        this.selectedPermission = Permission.blank();
        this.hasAnyChange = false;
    }

    loadPermissions() {
        $('tr').removeClass('selected');
        this.asyncPermissions = false;
        return this.getPermissions().pipe(map((data) => {
            this.permissions = data.map((p) => new Permission(p));
            this.asyncPermissions = true;
        }));
    }

    addPermission() {
        this.admService.resetPage(this.modal);
        this.asyncPermissions = false;

        this.createPermission().subscribe(() => {
            this.loadPermissions().subscribe(() => {
                this.prepareNewPermission();
                $('.success.created').removeClass('hidden');
            });
        },
        (response) => {
            if (response.status === 409) {
                $('.error.409').removeClass('hidden');
            }
            if (response.status === 400) {
                if (response.error.fieldError === 'name') {
                    $('.error.name').removeClass('hidden');
                }
                if (response.error.fieldError === 'route' || response.error.fieldError === 'api') {
                    $('.error.path').removeClass('hidden');
                }
            }
            this.asyncPermissions = true;
        },
        () => {
            $('button').addClass('grey');
        });
    }

    savePermission() {
        this.admService.resetPage(this.modal);
        this.asyncPermissions = false;

        this.editPermission().subscribe(() => {
            this.loadPermissions().subscribe(() => {
                this.refreshState();
                $('.success.edited').removeClass('hidden');
            });

        }, (response) => {
            if (response.status === 404) {
                $('.error.404').removeClass('hidden');
            }
            if (response.status === 409) {
                $('.error.409').removeClass('hidden');
            }
            if (response.status === 400) {
                if (response.error.fieldError === 'name') {
                    $('.error.name').removeClass('hidden');
                }
                if (response.error.fieldError === 'route' || response.error.fieldError === 'api') {
                    $('.error.path').removeClass('hidden');
                }
            }
            this.asyncPermissions = true;
        },
        () => {
            $('button').addClass('grey');
        });
    }

    confirmDeletePermission() {
        this.admService.resetPage(this.modal);
        this.asyncPermissions = false;

        this.deletePermission().subscribe(() => {
            this.permissions = this.permissions.filter((item) => item.name !== this.selectedPermission.name);
            this.prepareNewPermission();
            $('.success.deleted').removeClass('hidden');

        }, ((error) => {
            if (error.status === 404) {
                $('.error.404').removeClass('hidden');
            }
        }),
        () => {
            this.asyncPermissions = true;
            $('button').addClass('grey');
        });
    }

    private getPermissions(): Observable<any> {
        return this.http.get(
            `${environment.endpoints.adminService}/permissions`,
        );
    }

    private createPermission(): Observable<HttpResponse<any>> {
        return this.http.post(`${environment.endpoints.adminService}/permissions`
        , this.selectedPermission, { observe: 'response', responseType: 'json' });
    }

    private editPermission(): Observable<HttpResponse<any>> {
        return this.http.put(`${environment.endpoints.adminService}/permissions/${this.selectedPermission.name}`
        , this.selectedPermission, { observe: 'response', responseType: 'json' });
    }

    private deletePermission(): Observable<HttpResponse<any>> {
        return this.http.delete(`${environment.endpoints.adminService}/permissions/${this.selectedPermission.name}`,
        { observe: 'response', responseType: 'json' });
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
