import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, timer } from 'rxjs';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { environment } from '#environment';

import { ChassiStatusService } from './chassi-status.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { UtilsService } from '#services/_utils/utils.service';
import { StatusService } from '#services/_status/status.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { EventSourceService } from '#services/_eventSource/eventSource.service';
import { ChartsServices } from 'app/theme/layouts/charts/charts.services';
import { ChassiStatusFilter } from './chassiStatusFilter.model';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { IPageWrapperTitle } from 'app/shared/components/cmx-page-wrapper/page-wrapper-interface';
import { CHASSI_STATUS_SLICK_COLUMNS } from 'app/theme/layouts/charts/chassi-status/constants/chassi-status.slickdgrid';
import { UserService } from 'app/auth/_services/user.service';

declare let Slick: any;

@Component({
    selector: 'chassi-status-chart',
    templateUrl: './chassi-status.component.html',
    styleUrls: ['../../../../theme/layouts/charts/charts.scss', './chassi-status.component.scss'],
    providers: [ChassiStatusService],
    encapsulation: ViewEncapsulation.None,
})
export class ChassiStatusComponent implements AfterViewInit, OnDestroy {
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    titleComponent: IPageWrapperTitle = {
        title: 'Chassi',
    };

    readonly dropdownSettingsModels = {
        singleSelection: false,
        text: 'Modelo',
        badgeShowLabel: 'Modelos selecionados',
    };
    readonly dropdownSettingsColors = {
        singleSelection: false,
        text: 'Cores',
        badgeShowLabel: 'Cores selecionadas',
    };

    options: {
        colors: DropdownOption[];
        models: DropdownOption[];
        clients: DropdownOption[];
        status: DropdownOption[];
        diNumber: DropdownOption[];
    } = {
        colors: [],
        models: [],
        clients: [],
        status: [],
        diNumber: [],
    };

    typeSwitch = {
        values: ['Chegada', 'Criação'],
        index: 1,
    }

    componentId: string = `ImpStatusGrid-${String(Math.round(Math.random() * 1000000000))}`;

    filterState: ChassiStatusFilter = this.filterStateService.getChassiStatusFilter();
    asyncChassiStatusTable: boolean = true;
    asyncCount = false;
    moment = moment;

    gridColumns: IGridColumn [] = _.cloneDeep(CHASSI_STATUS_SLICK_COLUMNS);
    gridRows: IGridRow[] = [];
    gridOptions = {
        forceFitColumns: false,
        rowHover: true
    };

    topButton = [
        {
            name: 'Download',
            code: 'download',
            leftIcon: 'download',
        },
    ];

    loadingChassiStatus: boolean = false;

    selectedColors = [];
    selectedModels = [];
    validDate = true;
    startDate = moment().add(-6, 'M').format('DD/MM/YYYY');
    endDate = moment().format('DD/MM/YYYY');
    chassiSearch = '';
    pages = [];
    allStatus = [];
    preSelectedStatus = [];
    selectedStatus = [{ name: 'Finalizado', code: 'FINALIZADO', statusEntityName: 'finished' }];
    refParam = 'registration';
    selectedClients = [];
    subscription;
    gridData;
    countStatus: any = [];
    imp = null;
    companyCode = null;
    impNumber = null;

    queryId = Math.random();

    countToRequest = {
        startDate: this.startDate,
        endDate: this.endDate,
        colors: null,
        models: null,
        status: null,
        chassi: this.chassiSearch,
        referenceDateType: this.refParam,
    };

    currentUser$;
    fromOperationalMetrics = false;
    diList = null;
    daNumber = null;
    exportAsync = false;
    params;
    helpDescription = this._defineHelpDescription();

    constructor(
        public router: Router,
        public chartService: ChartsServices,
        public notification: NotificationService,
        private userService: UserService,
        private http: HttpClient,
        private el: ElementRef,
        private permissionBlocker: UserPermissionBlockerService,
        private utilsService: UtilsService,
        private statusService: StatusService,
        private filterStateService: FilterStateService,
        private urlValue: ActivatedRoute,
        private userCacheService: UserCacheService,
        private chassiStatusService: ChassiStatusService,
        private eventChannel: EventSourceService,
        private _cmxDropdownService: CmxDropdownService,

    ) {
        const urlParams = this.urlValue.snapshot.params;
        if (
            urlParams.hasOwnProperty('companyCode') &&
            urlParams.hasOwnProperty('referenceDateType') &&
            urlParams.hasOwnProperty('startDate') &&
            urlParams.hasOwnProperty('endDate')
        ) {
            this.filterState.setSearch('');
            this.filterState.setReferenceDateType(urlParams.referenceDateType);
            this.filterState.setStartDate(moment(urlParams.startDate).format('DD/MM/YYYY'));
            this.filterState.setEndDate(moment(urlParams.endDate).format('DD/MM/YYYY'));
            this.fromOperationalMetrics = true;
        }
        if (urlParams.hasOwnProperty('chassi')) {
            this.filterState.setSearch(urlParams.chassi);
            this.fromOperationalMetrics = true;
        }
        this.currentUser$ = this.userService.currentUser$;
    }

    ngOnDestroy() {
        $(document).off('keyup');
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.filterState.setStatus([]);
    }

    ngAfterViewInit() {
        this.options.clients = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
        if (this.fromOperationalMetrics) {
            this.options.clients.forEach((client) => {
                if (client.id === this.urlValue.snapshot.params.companyCode) {
                    this.filterState.setClientGroups([client]);
                }
            });
        }
        this.getAutomotiveDomainFilters().subscribe((autoFilters) => {
            this.options.models = this._cmxDropdownService.toDropdownItems(autoFilters.models);
            this.options.colors = this._cmxDropdownService.toDropdownItems(autoFilters.colors);
        });
        this._getChassiStatus();
        $(document).on('click', '.m-datatable__body', (e) => {
            e.preventDefault();
            this.router.navigate(['./supply-chain/importacao']);
        });
    }

    applyFilter() {
        this.resetPages();
        this._getChassiStatus();
        this.loadChassiStatusTable(this.startDate, this.endDate);
    }

    onSelectedStatus({ selected }): void {
        this.filterState.setStatus(_.cloneDeep(selected));
        this.loadChassiStatusTable(this.startDate, this.endDate);
    }

    toggleTypeSwitch(event) {
        const referenceDateType = event.value === 'Criação' ? 'registration' : 'arrival';
        this.filterState.setReferenceDateType(referenceDateType);
        this.updateReferenceDateType(referenceDateType);
    }

      updateReferenceDateType(referenceDateType: string) {
        const params = { ...this.filterState.getRequest().params };
        params.referenceDateType = referenceDateType;
        this.filterState.getRequest().params = params;
    }

    findStatusCount(status) {
        const index = this.countStatus.findIndex((statusFound) => statusFound.statusCode === status.code);
        return this.countStatus[index].totalChassis;
    }

    loadChassiStatusTable(start, end) {
        if (this.permissionBlocker.permissionValidator(this.el.nativeElement.tagName)) {
            if (this.subscription) {
                this.subscription.unsubscribe();
            }

            const request = this.filterState.getRequest(this.statusService.getChassiStatusList());
            const clientGroups = this.filterState.getClientGroups();
            this.asyncChassiStatusTable = false;

            this.subscription = timer(500).subscribe(() => this.getChassiStatus(request, clientGroups).subscribe(
                (response) => {
                    const num = Math.ceil(response.total / 30);
                    this.gridData = response;
                    this.gridRows = this.extractRows(response.chassisStatusDetails);
                    this.pages = Array.apply(null, { length: num }).map(Number.call, Number);
                    this.asyncChassiStatusTable = false;
                },
                (error) => {
                    this.gridData = undefined;
                    this.asyncChassiStatusTable = false;
                    this.utilsService.errorHandler(error, 'Chassi Status');
                }
            ));
        }
    }

    changePage(pageNumber) {
        this.filterState.setOffset(pageNumber - 1);
        this.loadChassiStatusTable(this.startDate, this.endDate);
    }

    datesChanged(dates) {
        this.filterState.setStartDate(dates.startDate);
        this.filterState.setEndDate(dates.endDate);
    }

    trackByFn(index: number): number {
        return index;
    }

    export(type) {
        const currentUser = this.userService.getCurrentUser();
        this.callExportMessage();
        const headers = `${this.filterState
            .getClientGroups()
            .map((c) => c['id'])
            .join(',')}`;
        const params = this.filterState.getRequestParams(currentUser);

        this.eventChannel.openEventSource(`${environment.endpoints.automotiveV2QueryService}/api/v2/chassis-status/report/async`, headers, params, type);
    }

    onCellClick(event) {
        this.impNumber = event.item.imp;
        if(event.column === 'imp' && event.item.imp)  {
            window.open(`./supply-chain/importacao/0/${this.impNumber }`);
        }
    }

    private callExportMessage() {
        this.exportAsync = true;
        setTimeout(() => {
            this.exportAsync = false;
        }, 5000);
    }

    private _getChassiStatus() {
        const request = this.filterState.getRequest();
        const statusCodes = this.filterState.getStatus().map((status) => status && status.code);

        this.chassiStatusService.getChassiStatusList(request).subscribe((chassiStatusList) => {
            this.allStatus = chassiStatusList;
            this.preSelectedStatus = this.allStatus
                .filter((status) => {
                    return statusCodes.length
                        ? statusCodes.indexOf(status.code) !== -1
                        : status.code !== 'FINALIZADO'
                })
                .map((status) => status.code);
        });
    }

    private extractRows(chassiRows) {
        const rows = [];

        for (let i = 0; i < chassiRows.length; i++) {
            const row = chassiRows[i];

            let diList = [
                {
                    channel: '',
                    registrationDate: null,
                    registrationNumber: '',
                },
            ];

            let daNumber = '';
            let daChannel = '';
            let daRegistrationDate = null;

            let diNumber = '';
            let diChannel = '';
            let diRegistrationDate = null;

            if (row.clearanceData !== null && row.clearanceData !== undefined) {
                if (row.clearanceData.daData !== null && row.clearanceData.daData !== undefined) {
                    daNumber = row.clearanceData.daData.registrationNumber;
                    this.daNumber = daNumber;
                    daChannel = row.clearanceData.daData.channel;
                    daRegistrationDate = row.clearanceData.daData.registrationDate;
                }

                const diDataList = row.clearanceData.diDataList;
                if (diDataList !== null) {
                    if (diDataList.length === 1) {
                        const di = diDataList[0];
                        diChannel = di.channel;
                        diRegistrationDate = di.registrationDate;
                        diNumber = di.registrationNumber;
                    }
                    if (diDataList.length > 1) {
                        const multipleString = `Várias (${diDataList.length})`;
                        diChannel = multipleString;
                        diRegistrationDate = multipleString;
                        diNumber = multipleString;
                        diDataList.forEach((data) => {
                            data.registrationDate = moment(data.registrationDate).format('DD/MM/YYYY');
                            const channel = data.channel.toLowerCase();
                            data.channel = `<div class='channelBall ${channel}'></div>`;
                        });
                        diList = diDataList;
                    }
                }
            }

            const {
                imp,
                chassi,
                client,
                companyCode,
                bl,
                model,
                color,
                endProduction,
                etd,
                eta,
                invoiceAmount,
                previsionPdiDate,
                pdiDate,
                billingDate,
                cdDate,
                deliveryDate,
                status,
                checkoutDate,
                shippingDate,
                cargoPresenceDate,
                currency,
                boardingNumber,
                blockStartDate,
                blockEndDate,
                vehicleType,
            } = row;
            const clazz = `label ${status.code.toLowerCase()}`;
            rows.push({
                id: i,
                imp,
                chassi,
                client,
                companyCode,
                bl,
                model,
                color,
                endProduction,
                etd,
                eta,
                invoiceAmount,
                previsionPdiDate,
                pdiDate,
                billingDate,
                cdDate,
                deliveryDate,
                status,
                clazz,
                pdiSituation: this._defineSituationName(row.pdiSituation),
                checkoutDate,
                shippingDate,
                cargoPresenceDate,
                currency,
                boardingNumber,
                blockStartDate,
                blockEndDate,
                vehicleType,
                daNumber,
                daRegistrationDate,
                daChannel,
                diNumber,
                diRegistrationDate,
                diChannel,
                diList,
            });
        }
        return rows;
    }

    private _defineSituationName(pdiSituation) {
        if(pdiSituation === 'process') {
            return 'EM PROCESSO'
        } else if (pdiSituation === 'released') {
            return 'LIBERADO'
        } else {
            return ''
        }
    }

    private resetPages() {
        this.queryId = Math.random();
        this.filterState.setOffset(0);
    }

    private getAutomotiveDomainFilters(): Observable<any> {
        return this.http.get(`${environment.endpoints.importationMongoDBService}/chassis/filters`);
    }

    private getChassiStatus(request, clientGroups): Observable<any> {
        return this.http.post(`${environment.endpoints.automotiveV2QueryService}/api/v2/chassis-status`, request.params, {
            headers: new HttpHeaders().set('X-Requested-Client-Groups', request.headers.clientCodes),
        });
    }

    private _defineHelpDescription(): object[] {
        return [
            {
                type: 'text',
                value: 'Visão rápida do chassi com diversas possibilidades de filtros.',
            },
            {
                type: 'text',
                value: 'Apresenta o status por etapa lógica em que os chassis se encontram.',
            },
            {
                type: 'text',
                value: 'Facilitar o acompanhamento dos chassis, com acesso rápido às informações.',
            },
        ]
    }
}
