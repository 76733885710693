import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

import { AlertClientDialogComponent } from '../alert-client-dialog/alert-client-dialog.component';
import { Utils } from '../../utils/utils';

import { UtilsService } from '#services/_utils/utils.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { OperationalAlertsService } from '../../services/operational-alerts.service';
import { UserService } from "app/auth/_services/user.service";

import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import {
    IOperationalAlertClientsInfo,
    IOperationalAlertClientsResponse,
} from 'app/shared/interfaces/operational-alerts.interface';
import { OperationalAlertsFilter } from '../../operationalAlertsFilter.model';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { environment } from '#environment';

@Component({
    selector: 'alert-clients-dialog',
    templateUrl: './alert-clients-dialog.component.html',
    styleUrls: ['./alert-clients-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AlertClientsDialogComponent implements OnInit, OnDestroy {
    @ViewChild(AlertClientDialogComponent, { static: true })
    alertClientDialog: AlertClientDialogComponent;

    filterState: OperationalAlertsFilter = this._filterStateService.getOperationalAlertsFilter();

    dialogId: string;
    childDialogId: string;

    gridColumns: IGridColumn[];
    gridRows: IGridRow[];
    headerInfo: IOperationalAlertClientsInfo;
    link: string;
    loading: boolean;
    selectedFilter: string;
    utils: Utils;
    useMongodbService: boolean = false;

    private _links: string[];
    private _unsubscribeAll$: Subject<any>;

    constructor(
        private _customDialogService: CustomDialogService,
        private _operationalAlertsService: OperationalAlertsService,
        private _featureFlagService: FeatureFlagService,
        private _utilsService: UtilsService,
        private _userService: UserService,
        private _filterStateService: FilterStateService,
    ) {
        this.dialogId = this._utilsService.generateGUID('dialog');
        this.loading = true;
        this.utils = Utils;

        this._unsubscribeAll$ = new Subject();
    }

    async ngOnInit() {
        this.useMongodbService = await this._featureFlagService.isFeatureFlagEnabledV2('operational-alerts-mongodb-enabled');
        if (this.useMongodbService) {
            this._operationalAlertsService.setBaseUrl(environment.endpoints.importationMongoDBService);
        }
        this.childDialogId = this.alertClientDialog.id;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    open(): void {
        if (!this.link) {
            throw new Error('[AlertDetail] Must have link to render dialog!');
        }

        this._customDialogService.open(this.dialogId);
        this._loadGrid();
    }

    onGridCallback({ grid }): void {
        grid.onClick.subscribe((e, args) => {
            const cell = grid.getCellFromEvent(e);

            if (grid.getColumns()[cell.cell].field === 'impQuantity' && grid.getDataItem(args.row).impQuantity > 0) {
                this.alertClientDialog.link = this._links[cell.row];
                this.alertClientDialog.open();
            }
        });
    }

    private _loadGrid(): void {
        this.loading = true;
        this._operationalAlertsService
            .getOperationalAlertClients(this.link, this.filterState.getRequest()).pipe(
            takeUntil(this._unsubscribeAll$))
            .subscribe((response: IOperationalAlertClientsResponse) => {
                const { data, columns, info } = response;

                this.headerInfo = info;
                this._links = data.map((d: IGridRow) => d._link);
                this.gridColumns = this._createColumns(columns);
                this.gridRows = data;

                const timeout = setTimeout(() => {
                    this.loading = false;
                    clearTimeout(timeout);
                }, 1000);
            });
    }

    private _createColumns(columns: IGridColumn[]): IGridColumn[] {
        const cssClasses = {
            headerCssClass: 'grid-header align-center',
            cssClass: 'grid-cell align-center',
        };

        return columns
            .filter((column: IGridColumn) => {
                return column.field !== 'clientGroupName' || this._userService.getCurrentUser().has('INTERNAL_FILTERS');
            })
            .reduce((accumulator: IGridColumn[], currentValue: IGridColumn, currentIndex: number) => {
                const fieldOption = { ...currentValue, id: currentValue.order, name: currentValue.label };

                if (currentIndex === 0 && currentValue.field === 'clientGroupName') {
                    _.assign(fieldOption, { width: 150 });
                    return [...accumulator, fieldOption];
                }

                if (currentValue.field === 'impQuantity') {
                    _.assign(fieldOption, {
                        ...cssClasses,
                        formatterType: 'badge',
                        formatterData: { classes: 'pointer' },
                    });
                    return [...accumulator, fieldOption];
                }

                if (currentValue.field === 'merchandiseNeededDate' || currentValue.field === 'daExpirationDate' 
                    || currentValue.field === 'shippingDate' || currentValue.field === 'eta') {
                    _.assign(fieldOption, {
                        ...cssClasses,
                        formatterType: 'date',
                    });
                    return [...accumulator, fieldOption];
                }

                _.assign(fieldOption, cssClasses);

                return [...accumulator, fieldOption];
            }, []);
    }
}
