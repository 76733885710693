import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { StompRService } from '@stomp/ng2-stompjs';
import { Message } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';

import { environment } from '#environment';
import { UserService } from "app/auth/_services/user.service";
import { Notification } from 'app/models/_notification/notification.data';
import { FeatureFlagsService } from '#services/_feature-flag/feature-flags.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy {

  private events = new Subject<Notification>();
  private userTopicSubscription: Subscription;

  constructor(
    private stompService: StompRService,
    private userService: UserService,
    featureFlagService: FeatureFlagsService,
  ) {

    if (featureFlagService.isFeatureFlagEnabled('asyncNotification')) {
      this.subscribe();
      this.health();
      this.connect();

      this.userService.authenticatedUser$.subscribe(user => {
        if (user) {
          if (!this.stompService.active) {
            this.connect();
          }
        } else {
          this.disconnect();
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.userTopicSubscription?.unsubscribe();
    this.stompService?.deactivate();
  }

  getEvents(): Observable<Notification> {
    return this.events.asObservable();
  }

  private health() {
      this.stompService.stompErrors$.subscribe((state: any) => {
        if (state?.headers?.status === '401') {
          this.disconnect();
        }
    });
  }

  public connect() {
    this.stompService.configure({
      webSocketFactory:  () => new SockJS(`${environment.endpoints.notificationWS}`),
      debug: this.debug(),
      reconnectDelay: 2_000,
      heartbeatIncoming: 10_000,
      heartbeatOutgoing: 30_000,
      connectHeaders: {'X-Authorization-SessionId': localStorage.getItem('sessionId')},
      beforeConnect: (client: any): Promise<void> => {
        return new Promise<void>( (resolve, _) => {
          this.userService.getCurrentUser().getToken().subscribe(token => {
            if (token) {
              client.stompClient.connectHeaders = {
                'Authorization': `Bearer ${token}`,
                'X-Authorization-SessionId': localStorage.getItem('sessionId')
              }
            } else {
              this.disconnect()
            }
            resolve()
          })
        });
      }
    });
    this.stompService.initAndConnect();
  }

  private disconnect() {
    this.stompService.deactivate();
  }

  private debug() {
    if (environment.production) {
      return (msg: string): void => {};
    }
    return (msg: string): void =>  {};
  }

  private subscribe() {
    if (!this.userTopicSubscription) {
      this.subscribeUserTopic();
    }
  }

  private subscribeUserTopic(): void  {
    this.userTopicSubscription = this.stompService.watch('/user/topic/cms').subscribe((message: Message) => {
        var data = JSON.parse(message.body)
        this.events.next(data);
    });
  }

}
