import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_pt_BR from '@amcharts/amcharts5/locales/pt_BR';

export function createXYChart(root, chartConfig, data) {
    root.locale = am5locales_pt_BR;

    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            layout: root.verticalLayout,
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            paddingTop: chartConfig?.padding?.top || 20,
            paddingBottom: chartConfig?.padding?.bottom || 20,
            paddingLeft: chartConfig?.padding?.left || 0,
            paddingRight: chartConfig?.padding?.right || 20,
        })
    );

    if (chartConfig.title) {
        chart.children.unshift(
            am5.Label.new(root, {
                text: chartConfig.title.text,
                fontSize: chartConfig.title.size || 12,
                fontWeight: 'bold',
                x: am5.p50,
                centerX: am5.p50,
                paddingBottom: 10,
                paddingTop: 10,
            })
        );
    }

    const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: chartConfig.axisConfig?.x?.minGridDistance || 20,
            }),
            categoryField: chartConfig.axis.find((axis) => axis.axisPosition === 'x').categoryField,
            tooltip: chartConfig.showTooltip ? am5.Tooltip.new(root, {}) : undefined,
        })
    );

    xAxis.get('renderer').labels.template.setAll({
        oversizedBehavior: 'wrap',
        textAlign: 'center',
        text: '{category}',
        maxWidth: chartConfig.labelConfig?.maxWidth || 100,
        fontSize: chartConfig.labelConfig?.fontSize || 12,
        fontWeight: chartConfig.labelConfig?.fontWeight || 'normal',
        paddingTop: chartConfig.labelConfig?.paddingTop || 10,
        wrap: true,
    });
    xAxis.data.setAll(data);

    const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                minGridDistance: 20,
                inside: false,
            }),
            extraMax: chartConfig.axisConfig?.y?.extraMax || 0.15,
        })
    );

    yAxis.children.unshift(
        am5.Label.new(root, {
            text: chartConfig.axisTitle?.y || '',
            rotation: -90,
            fontSize: chartConfig.axisTitle?.fontSize || 12,
            fontWeight: 'bold',
            y: am5.percent(85),
            centerY: am5.p50,
            paddingRight: 15,
        })
    );

    chartConfig.content.forEach((seriesConfig) => {
        const series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: seriesConfig.name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: seriesConfig.valueYField,
                categoryXField: seriesConfig.valueXField,
                tooltip: chartConfig.showTooltip ? am5.Tooltip.new(root, { labelText: '{valueY}' }) : undefined,
            })
        );

        series.columns.template.adapters.add('fill', (fill, target) => {
            const category = target.dataItem?.get('categoryX');
            const item = data.find((d) => d.label === category || d.code === category);
            return item?.color ? am5.color(item.color) : fill;
        });

        series.columns.template.adapters.add('stroke', (stroke, target) => {
            const category = target.dataItem?.get('categoryX');
            const item = data.find((d) => d.label === category || d.code === category);
            return item?.color ? am5.color(item.color) : stroke;
        });

        series.data.setAll(data);

        if (chartConfig.showValuesAboveBars) {
            series.bullets.push(() => {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Label.new(root, {
                        text: '{valueY}',
                        centerX: am5.p50,
                        centerY: am5.p0,
                        dy: -20,
                        fontSize: chartConfig.labelConfig?.fontSize || 12,
                        fontWeight: chartConfig.labelConfig?.fontWeight || 'bold',
                        populateText: true,
                        fill: am5.color(0x000000),
                    }),
                });
            });
        }
    });

    if (chartConfig.showTooltip) {
        chart.set('cursor', am5xy.XYCursor.new(root, { behavior: 'zoomX' }));
    }

    chart.zoomOutButton.set('forceHidden', true);

    return chart;
}
