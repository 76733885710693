import { of as observableOf, Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { environment } from '#environment';
import { Entity } from './domain.entity';
import { Filter } from './domain.filter';
import { Type } from './domain.type';
import { Mock } from 'app/shared/mocks/domain-service.mock';
import * as _ from 'lodash';


@Injectable()
export class DomainService {
    private readonly endpoint;

    constructor(private http: HttpClient) {
        this.endpoint = environment.endpoints.domainService;
    }

    public getCompanies(): Observable<Entity[]> {
        return this.sort(this.get(Type.company), this.byCode);
    }

    public getProfitCenter(optionalProfitCenter?): Observable<Entity[]> {
        return this.sort(this.get(Type.profitCenter, optionalProfitCenter), this.byCode);
    }

    public getSubsidiary(): Observable<any> {
        return this.sort(this.get(Type.subsidiary), this.byCode);
    }

    public getTrader(withoutDirector?: boolean, completeInfo?: boolean): Observable<Entity[]> {
        let traders: Observable<any>;
        if (completeInfo || completeInfo === false) {
            traders = this.http.get<Entity[]>(`${this.endpoint}/traders?completeInfo=${completeInfo}`).pipe(catchError((err: any) => observableOf([])));
        } else {
            traders = this.http.get<Entity[]>(`${this.endpoint}/traders`).pipe(catchError((err: any) => observableOf([])));
        }

        return withoutDirector ? this._removeDirectorFromTrader(traders) : this.pagebleSort(traders, this.byName);
    }

    public getDirector(): Observable<Entity[]> {
        return this.pagebleSort(this.get('directors'), this.byName);
    }

    public getClient(): Observable<any> {
        return this.pagebleSort(this.get('clients'), this.byCode);
    }

    public getClientPage(size, page, name?: any): Observable<any> {
        return this.pagebleSortComplete(this.getPage('clients', size, page, name), this.byCode);
    }

    public getAllClientGroup(): Observable<any> {
        return this.pagebleSort(this.get('client-groups'), this.byName);
    }

    public getClientGroup(): Observable<any> {
        return this.pagebleSort(this.get(`client-groups?allClientGroups=false`), this.byName);
    }

    public getClientGroupByName(name): Observable<any> {
        return this.pagebleSort(this.get(`client-groups/?clientGroupName=${name}`), this.byName);
    }

    public getClientGroupPage(size, page, name?: any): Observable<any> {
        return this.pagebleSortComplete(this.getPage('client-groups', size, page, name), this.byName);
    }

    public getImpsByGroup(startDate, endDate, clientGroupCodes): Observable<any> {
        return this.sort(
            this.http.get<Entity[]>(
                `${environment.endpoints.resultsService}/imp-list?initRefDate=${startDate}&endRefDate=${endDate}&clientGroups=${clientGroupCodes}`
            ),
            this.byName
        );
    }

    public getUrfs(): Observable<any> {
        return this.http.get<any>(`${environment.endpoints.importationMongoDBService}/imps/urfNationalization`)
            .pipe(map(response => { return _.sortBy(this._getOnlyConexosURFs(response), ['name']) }),
        );
    }

    public getTransactionTypes(): Observable<any> {
        return this.http.get(`${environment.endpoints.resultsService}/transaction-types`);
    }

    public getUfs(withExterior): Observable<any> {
        return this.http.get(`${this.endpoint}/ufs/initials?withExterior=${withExterior}`);
    }

    public derive(entityType: Type, dados: Filter): Observable<any> {
        const code = dados.codes;
        const startDate = dados.startDate;
        const endDate = dados.endDate;

        return this.http
            .get(
                `${this.endpoint}/filters/${entityType.toString()}/derives?code=${code}&from=${startDate}&to=${endDate}`
            )
            .pipe(catchError((err: any) => observableOf([])));
    }

    private _getOnlyConexosURFs(urfs) {
        // Regra: Removidas URFs com zero à esquerda, por serem registros do Protheus e causarem duplicidade no dropdown,
        // adicionados posteriormente no interceptor: UrfCodesInterceptor
        return urfs.filter((urf) => { return urf.code.charAt(0) !== '0' });
    }

    private _removeDirectorFromTrader(traderList) {
        return traderList.pipe(
            map((trader: any) => {
                return _.uniqBy(trader?.content?.map((trader) => { return { ...trader,
                    name: trader?.name?.replace(new RegExp(String.raw`[^0-9a-zA-Z]*\(.*?$`, "g"), '')?.trim()
                }}), 'name');
            })
        );
    }

    private get(type: string, optionalProfitCenter?) {
        if (optionalProfitCenter === undefined) {
            return this.http.get<Entity[]>(`${this.endpoint}/${type}`).pipe(catchError((err: any) => observableOf([])));
        } else {
            return this.http
                .get<Entity[]>(`${this.endpoint}/${type}?optionalProfitCenters=${optionalProfitCenter}`)
                .pipe(catchError((err: any) => observableOf([])));
        }
    }

    private getPage(type: string, size: number, page: number, name?: any) {
        let nameParam = name && Object.entries(name).pop();
        let keyName = nameParam && nameParam[0];
        let valueName = nameParam && nameParam[1];

        let params = `size=${size}&page=${page}`;
        if (keyName && valueName) {
            params += `&${keyName}=${valueName}`;
        }

        return this.http
            .get<Entity[]>(`${this.endpoint}/${type}?`.concat(params))
            .pipe(catchError((err: any) => observableOf([])));
    }

    private sort(entities: Observable<Entity[]>, comparator: (a: Entity, b: Entity) => number) {
        return entities.pipe(
            map((value: Entity[]) => {
                value.sort(comparator);
                return value;
            })
        );
    }

    private pagebleSort(entities: Observable<any>, comparator: (a: Entity, b: Entity) => number) {
        return entities.pipe(
            map((value: any) => {
                value.content.sort(comparator);
                return value.content;
            })
        );
    }

    private pagebleSortComplete(entities: Observable<any>, comparator: (a: Entity, b: Entity) => number) {
        return entities.pipe(
            map((value: any) => {
                value.content && value.content.sort(comparator);
                return value;
            })
        );
    }

    private byCode(a: Entity, b: Entity) {
        return a.code === b.code ? 0 : a.code > b.code ? 1 : -1;
    }

    private byName(a: Entity, b: Entity) {
        return a.name === b.name ? 0 : a.name > b.name ? 1 : -1;
    }
}

@Injectable()
export class MockedDomainService {
    public getCompanies() {
        return observableOf(Mock.companies);
    }

    public getProfitCenter() {
        return observableOf(Mock.profitCenter);
    }

    public getTrader() {
        return observableOf(Mock.traders);
    }

    public getDirector() {
        return observableOf(Mock.directors);
    }

    public getClient() {
        return observableOf(Mock.clients);
    }

    public getClientGroup() {
        return observableOf(Mock.clientGroups);
    }

    public derive(): Observable<any> {
        return observableOf([]);
    }
}
