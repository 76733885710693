import { Component, ViewEncapsulation } from "@angular/core";
import { ImportDetailsService } from "../import-details.service";
import { UserService } from "app/auth/_services/user.service";
import { CustomDialogService } from "app/shared/components/cmx-custom-dialog/custom-dialog.service";
import { BOARDING_CONTAINER_COLUMNS } from "./constants/boarding-container-columns.constants";
import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";
import { ImportDetailItem } from "../import-details-item";
import { Router } from "@angular/router";

@Component({
    selector: 'app-import-details-boarding',
    templateUrl: './boarding.component.html',
    styleUrls: ['./boarding.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class BoardingComponent extends ImportDetailItem {
    currentUser = this._userService.getCurrentUser();
    asyncDetail = !this.showLoader;
    showBoardingContainerModal = false;

    dataLoaded;
    boardingDetails;
    arrivalDetails;
    othersDetails;
    containerList;

    gridContainerColumns: IGridColumn[] = BOARDING_CONTAINER_COLUMNS;

    constructor(
        private _importDetailsService: ImportDetailsService,
        private _userService: UserService,
        private _customDialogService: CustomDialogService,
        protected _router: Router,
    ) {
        super(_router);
    }

    ngOnInit() {
        (this.showLoader) ?
            setTimeout(() => {this.loadDetails();}, 1000) :
            this.loadDetails();
    }

    loadDetails() {
        this.asyncDetail = false;
        this.dataLoaded = this._importDetailsService.getDetails();
        if(this.dataLoaded) {
            this.containerList = this.dataLoaded.containerControls;
            this.boardingDetails = [
                {
                    title: 'Aut. Embarque',
                    value: this.dataLoaded.boardingAuthorizationDate
                },
                {
                    title: 'País Procedência',
                    value: this.dataLoaded.customsClearance.countryFrom,
                },
                {
                    title: 'Porto Embarque',
                    value: this.dataLoaded.customsClearance.harborFrom,
                },
                {
                    title: 'Destino',
                    value: this.dataLoaded.customsClearance.harborTo,
                },
                {
                    title: 'AWB BL',
                    value: this.dataLoaded.customsClearance.trackingNumber,
                },
                {
                    title: 'Modal',
                    value: this.dataLoaded.customsClearance.transportationTypeDescription,
                    permission: 'INTERNAL_FILTERS',
                },
                {
                    title: 'Veículo',
                    value: this.dataLoaded.customsClearance.transportationDetail,
                },
                {
                    title: 'Armador',
                    value: this.dataLoaded.customsClearance.shipOwner,
                    permission: 'INTERNAL_FILTERS',
                },
                {
                    title: 'Agente de Cargas',
                    value: this.dataLoaded.customsClearance.customAgentName,
                }
            ];

            this.arrivalDetails = [
                {
                    title: 'Data Embarque',
                    value: this.dataLoaded.customsClearance.shippingDate
                },
                {
                    title: 'Data Atracação',
                    value: this.dataLoaded.customsClearance.arrivalDate
                },
                {
                    title: 'Previsão Atracação',
                    value: this.dataLoaded.customsClearance.eta
                },
                {
                    title: 'Data Conclusão de Remoção',
                    value: this.dataLoaded.customsClearance.removalDate
                }
            ];

            this.othersDetails = [
                {
                    title: 'FreeTime',
                    value: this.dataLoaded.customsClearance.demurrageExpirationDays,
                    permission: 'INTERNAL_FILTERS',
                },
                {
                    title: 'Vencimento FreeTime',
                    value: this.dataLoaded.customsClearance.dates?.demurrageExpirationDate,
                    permission: 'INTERNAL_FILTERS',
                },
                {
                    title: 'Contâiner',
                    value: this._formatList(this.containerList, 'containerNumber'),
                    permission: 'INTERNAL_FILTERS',
                    formatter: 'multiple'
                },
                {
                    title: 'Devolução',
                    value: this._formatList(this.containerList, 'returnDate'),
                    permission: 'INTERNAL_FILTERS',
                    formatter: 'multiple'
                }
            ];
        }

        this.asyncDetail = true;
    }

    onContainerClick(event) {
        if((event.title == 'Devolução' || event.title == 'Contâiner') && this.containerList.length > 1) {
            this._customDialogService.open('import-details-boarding-container-dialog');

            setTimeout(() => {
                this.showBoardingContainerModal = true;
            }, 100);
        }
    }

    private _formatList(container, field) {
        const formatedList = [];
        container.forEach(element => {
            formatedList.push(element[field]);
        });

        return formatedList;
    }
}
