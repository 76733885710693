import { Component, ViewEncapsulation, Output, EventEmitter } from "@angular/core";
import { CostsDropdownService } from "./costs-dropdown.service";
import { FilterStateService } from "#services/_filters/filter-state.service";
import { CostsService } from "../consolidated/costs.service";
import { UserService } from "app/auth/_services/user.service";

@Component({
    selector: 'costs-filters',
    templateUrl: './costs-filters.component.html',
    styleUrls: ['./costs-filters.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [CostsDropdownService],
})
export class CostsFiltersComponent {

    @Output('onLoadCost') onLoadCost = new EventEmitter();

    ucSwitch = {
        values: ['com U.C', 'sem U.C'],
        index: 0,
    }
    currentUser;
    filterState: any = this._filterStateService.getCostsFilter();

    constructor(
        public costsDropdownService: CostsDropdownService,
        public costsService: CostsService,
        private _filterStateService: FilterStateService,
        private _userService: UserService,
    ) {
        this._userService.currentUser$.subscribe((user) => {
            this.currentUser = user;
            if (user.has('AUTOMOTIVE_USER')) {
                this.filterState.setAutomotiveMode('Ligado');
            }
            this._defineInitialTypeSearch(user);
        });
    }

    datesChanged(dates) {
        this.filterState.setStartDate(dates.startDate);
        this.filterState.setEndDate(dates.endDate);
    }

    toggleUcSwitch(values) {
        this.filterState.setShowUc(values.value === 'com U.C' ? true : false);
    }

    toggleFilterMode() {
        this.filterState.setAutomotiveMode(
            this.filterState.getAutomotiveMode() === 'Ligado' ? 'Desligado' : 'Ligado');
        if (this.filterState.getAutomotiveMode() === 'Ligado') {
            this.filterState.setImpSearch('');
            this.filterState.setTypeSearch('Chassi');
        } else {
            this.filterState.setChassiSearch('');
            this.filterState.setTypeSearch(this.currentUser.has('INTERNAL_FILTERS') ? 'IMP' : 'REF');
        }
        this.costsService.datamartFilters = this.costsService.determineDatamartFilters(this.currentUser);
    }

    loadCost() {
        this.onLoadCost.emit();
    }

    private _defineInitialTypeSearch(user) {
        this.filterState.setTypeSearch(this.filterState.getAutomotiveMode() === 'Ligado' ? 'Chassi' :
                user.has('INTERNAL_FILTERS') ? 'IMP' : 'REF');
    }

}
